<template>
  <div class="InviteFormContainer">
    <v-card class="pt-6 pl-10 pr-10">
      <v-tabs :vertical="isMobile ? true : false" class="tabInvite mb-5" :model-value="tab" @update:model-value="tab = $event; tabChanged($event)">
        <v-tab :disabled="disabledConferenceTab" >
          {{ $t("components.groups.konferenzen") }} 
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <font-awesome-icon
                v-bind="props"
                color="#2a3133"
                class="ml-1"
                :icon="['fal', 'info-circle']"
                :style="{
                  fontSize: '17px',
                  paddingBottom: 2
                }"
              />
            </template>
            <span>
               {{$t('components.conferences.tabTT.conference')}}
            </span>
          </v-tooltip></v-tab>
        <v-tab v-if="showConferenceHallTab" :disabled="disabledConferenceHallTab">
          {{$t('components.conferenceForm.conferenceHalls')}} 
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <font-awesome-icon
                v-bind="props"
                color="#2a3133"
                class="ml-1"
                :icon="['fal', 'info-circle']"
                :style="{
                  fontSize: '17px',
                  paddingBottom: 2
                }"
              />
            </template>
            <span>
               {{$t('components.conferences.tabTT.event')}}
            </span>
          </v-tooltip>
        </v-tab>
        <!-- <div v-if="totalParticipants" :class="isMobile ? 'participantsButMobile' : 'participantsBut'" class="participantsButton">
          <v-btn  variant="text" color="primary" :disabled="true"
              >{{ $t("components.conferenceForm.inviteeTotal") }}:
              {{ totalParticipants }}
          </v-btn>
        </div> -->
      </v-tabs>
      <!-- <v-tabs-items :model-value="tab" @update:model-value="tab = $event"> -->
        <v-window :model-value="tab" @update:model-value="tab = $event">
          <v-window-item :value="0">
            <div>
              <ConferenceGrid
                ref="confGrid"
                :existingConference="existingConference"
                :updateGroup="updateGroup"
                :groupFromConference="groupFromConference"
                :sendMailToAll="sendMailToAll"
                :justSendMailToAll="justSendMailToAll"
                :callGroup="callGroup"
                :saveGroup="saveGroup"
                :sendUserMessageParent="sendUserMessage"
                :currentTab="tab == 0 ? 'normalConference' : 'hallConference'"
              />
            </div>
          </v-window-item>
        <v-window-item  v-if="showConferenceHallTab"  :value="1">
          <div>
            <ConferenceHallForm
              ref="confHall"
              :existingConference="existingConference"
              :updateGroup="updateGroup"
              :groupFromConference="groupFromConference"
              :sendMailToAll="sendMailToAll"
              :justSendMailToAll="justSendMailToAll"
              :callGroup="callGroup"
              :saveGroup="saveGroup"
              :sendUserMessageParent="sendUserMessage"
              :currentTab="tab == 0 ? 'normalConference' : 'hallConference'"
            />
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store, { EventBus } from "../../store";
import { wsCallStartBridgeCall, userInStaticRoom, getBridgeCallUUidByPerson } from "../../utils/calls";
import { hasPrivilege } from "../../utils/privileges";
import { markAsCallWaitingRoomUserEvent } from "../../effector/users/waitingInfo";
// import { v4 as uuidv4 } from "uuid";
import GroupGrid from "./groupGrid.vue";
import ConferenceGrid from "./conferenceGrid.vue";
import ConferenceHallForm from "./conferenceHalls/conferenceHallForm.vue";
import { sendVisitorEmail } from "../../lib/inviteHelpers";
import { sendInviteMailCustom } from "../../lib/wsMsg";
import { parallel } from "../../lib/asyncUtil";
import { isMobile } from "../../lib/mobileUtil";
import { wsCall } from "../../lib/wsConnect";
import { uid, isAiUser } from "../../utils/basicFunctions";
import {
  myConferenceHallDetails,
} from "../../effector/conferenceHalls";
import {
  prepadeDataToSendMessageEffector,
  newMessageEvent,
} from "../../effector/message";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../effector/alerts";
import {
  timelineEvents,
} from "../../effector/timeline";
export default {
  props: [],
  components: { GroupGrid, ConferenceGrid, ConferenceHallForm },
  data() {
    const effector = {
      myConferenceHallDetails: myConferenceHallDetails,
      timelineEvents: timelineEvents,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      isMobile: isMobile(),
      tab: 0,
      state: store.state,
      loadGroup: false,
      membersFromConference: null,
      loadConferenceForm: null,
      isInserting: false,
      totalParticipants: 0,
      // Effector
      ...effector,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (from.path !== "/newconference") next();
    if (this.$refs.confGrid) {
      if (this.$refs.confGrid.viewingConferences) next();
    }
    if (
      to.path.indexOf("/newconference") !== -1 &&
      from.path.indexOf("/newconference") !== -1
    ) {
      if (this.$router.params?.id || from.params?.id) {
        // comming from click on timeline
        if(this.$refs.confGrid){
          this.$refs.confGrid.ignoreDirty = true;
          this.$refs.confGrid.getInitSnapShot();
        }
      } else {
        if(this.$refs.confGrid){
          this.$refs.confGrid.ignoreDirty = false;
         }
      }
    }
    if(this.$refs.confGrid){
      if (!this.$refs.confGrid.viewingConferences) {
        this.$refs.confGrid.getPostSnapShot();
        if (this.$refs.confGrid.isDirty) {
          this.$refs.confGrid.showConfirmation = true;
          this.$refs.confGrid.confrimFrom = to.fullPath;
        } else {
          next();
        }
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (to, from) {
        this.checkRoute(from,to);
      },
    },
    haveCalls: {
       handler(calls) {
        if (calls && this.$refs.confGrid) {
          this.$refs.confGrid.showConfirmation = false;
          this.$refs.confGrid.confrimFrom = false;
        }
      }
    }
  },
  beforeMount() {
    EventBus.$on("loadFormHeader", (data) => {
      const groupId = data.id;
      const isConferenceHall =
        data.formType === "conferenceHall" ? true : false;
      this.tab = isConferenceHall ? 1 : 0;
      this.loadConferenceForm = setTimeout(() => {
        if (isConferenceHall) {
          EventBus.$emit("loadConferenceHallTemplate", { id: groupId });
        } else {
          EventBus.$emit("loadTemplate", { id: groupId });
        }
      }, 1);
    });
    // check is conference or conferencehall and change tab
    const conferenceID = this.$router?.params?.id
    if(conferenceID){
      const confData = this.myConferenceHallDetails.filter((e) => e.confId == conferenceID);
      this.tab = (confData[0] && confData[0].conferenceHall) ? 1 : 0
    }
  },
  mounted() {
    EventBus.$on('emitTotalInvitedParticipants', this.updateTotalParticipants);
  },
  unmounted() {
    clearTimeout(this.loadConferenceForm);
    this.loadConferenceForm = null;
    EventBus.$off('emitTotalInvitedParticipants', this.updateTotalParticipants);
  },
  methods: {
    updateTotalParticipants (value) {
      this.totalParticipants = value;
    },
    checkRoute(from, to) {
      const infoFromId = from?.params?.id || '0';
      const infoToId = to?.params?.id || '0';
      if (infoFromId !== infoToId) {
        if (infoToId !== "" && !infoToId.includes('00-') && infoFromId === '0') {
           // check is conference or conferencehall and change tab
           const conferenceID = infoToId;
           if(conferenceID){
            const confData = this.myConferenceHallDetails.filter((e) => e.confId == conferenceID);
            this.tab = (confData[0] && confData[0].conferenceHall) ? 1 : 0
           }
          // this.tab = 1;
          this.$nextTick(() => {
            if(this.tab === 1){
              if(this.$refs.confHall){
                this.$refs.confHall.loadConference(infoToId);
              }
              }else {
                  if (this.$refs.confGrid) {

                  this.$refs.confGrid.loadConference(infoToId);
                }
              }
          });
        } else if (infoFromId === "0") {
          setTimeout(() => {
            if (this.$refs.confGrid) {
              const routeDate = infoToId.replace('00-', '');
              const date = new Date(Number(routeDate));
              if (!isNaN(date.getTime())) { 
                this.$refs.confGrid.clickOnAddCard(date);
              } else {
                this.$refs.confGrid.clickOnAddCard();
              }
            }
          }, 50);
        }
      }
    },
    getPodiumParticipantUUID(uuid, participants) {
      for (let index = 0; index < participants.length; index++) {
        const member = participants[index];
        if(member.uuid === uuid) return member.isPodium
      }
    },
    disabledTabHeaders(isInserting){
      this.isInserting = isInserting;
    },
    getModerators(members) {
      let mods = [];
      members.forEach((member) => {
        if (member.isModerator === true) {
          mods.push(member.uuid);
        }
      });
      return mods;
    },
    async saveGroup(tmpGroupData) {
      try {
        const groupData = {
          groupId: tmpGroupData.groupId,
          groupName: tmpGroupData.groupName,
          groupImage:
            tmpGroupData.groupImage == null
              ? "/img/default_profile_picture.png"
              : tmpGroupData.groupImage,
          groupDescription: tmpGroupData.groupDescription,
          groupMembers: tmpGroupData.groupMembers,
          groupUUIDS: await this.extractUsers(
            store.state.user.uuid,
            tmpGroupData.groupMembers
          ),
          conferenceHall: tmpGroupData.conferenceHall,
        };
        this.createGroup(groupData);
        // this.goBackToGrid();
        dispatchSuccessAlert(this.$t("components.groups.groupCreated"));
      } catch (err) {
        console.warn("Error creating group", err);
        dispatchErrorAlert("Error creating group");
      }
    },
    extractUsers(owner, groupMembers) {
      let users = [];
      groupMembers.forEach((member) => {
        if (member.isTeam) {
          const extant =
            this.state.namespaceSettings.processedOrganisation.teams[
              member.name
            ];
          const tempMembers = extant.members;
          extant.supervisors.forEach((element) => {
            if (tempMembers.indexOf(element.uuid) == -1) {
              tempMembers.push(element.uuid);
            }
          });
          tempMembers.forEach((id) => {
            if (users.indexOf(id) == -1) {
              users.push(id);
            }
          });
        } else if (member.isGroup) {
          const extant = store.state.group[owner].user.groups.find(
            (e) => e.groupId == member.uuid
          );
          const extantMmembers = extant.groupMembers;
          const members = extantMmembers.map((member) => member.uuid);
          members.forEach((el) => {
            if (users.indexOf(el) == -1) {
              users.push(el);
            }
          });
        } else if (member.uuid) {
          if (users.indexOf(member.uuid) == -1) {
            users.push(member.uuid);
          }
        }
      });
      return users;
    },
    tabChanged(event) {
      if (event === 0) {
        if (this.$refs.confGrid) {
          this.$refs.confGrid.goBackToGrid();
        }
      } else if (event === 1) {
        // conference halls
        if (this.$refs.confGrid) {
          this.$refs.confGrid.goBackToGrid();
        }
      } else {
        if (this.$refs.groupGrid) {
          this.$refs.groupGrid.goBackToGrid();
        }
      }
    },
    async justSendMailToAll(
      uuids,
      subject,
      text,
      mssgType = "",
      dates = null,
      evName,
      sendMsg = false,
      noInviteLink
    ) {
      const mailBody = text.replace(/<br ?\/?>/g, "\n");
      await parallel(1, [...uuids], async (uuid) => {
        if (store.state.ownUUID === uuid) return;
        if (!(uuid in store.state.group)) return;
        const language = store.state.group[uuid].user?.language || "en";
        const email = store.state.group[uuid].user?.email || "";
        if (email && email.indexOf("@") !== -1) {
          const textToSend = mailBody;
          await sendInviteMailCustom(uuid, {
            guest:
              (store.state.group[uuid] || {}).user?.permissions === 10,
            name: (store.state.group[uuid] || {}).user?.name,
            email,
            subject: subject,
            language,
            customMessage: textToSend,
            conferenceNotification: true,
            noInviteLink: noInviteLink,
          });
        }
      });
      if (mssgType && mssgType != "" && sendMsg) {
        const realUsers = uuids.filter(
          (e) => (e in store.state.group && (store.state.group[e] || {}).user?.permissions !== 10)
        );
        await this.sendUserMessage(realUsers, mssgType, evName, dates, text);
      }
    },
    async sendMailToAll(
      uuids,
      subject,
      text,
      mssgType,
      eventName,
      dates,
      sendMsg
    ) {
      const mailBody = text.replace(/<br ?\/?>/g, "\n");
      await parallel(1, [...uuids], async (uuid) => {
        if (store.state.ownUUID === uuid) return;
        if (!(uuid in store.state.group)) return;
        const language = store.state.group[uuid].user?.language || "en";
        const email = store.state.group[uuid].user?.email || "";
        if (email && email.indexOf("@") !== -1) {
          const textToSend = mailBody;
          await sendInviteMailCustom(uuid, {
            guest:
              (store.state.group[uuid] || {}).user?.permissions === 10,
            name: (store.state.group[uuid] || {}).user?.name,
            email,
            subject: subject,
            language,
            customMessage: textToSend,
            conferenceNotification: true,
            noInviteLink: true,
          });
        }
      });
      if (mssgType && mssgType != "" && sendMsg) {
        const realUsers = uuids.filter(
          (e) => (e in store.state.group && (store.state.group[e] || {}).user?.permissions !== 10)
        );
        await this.sendUserMessage(realUsers, mssgType, eventName, dates, text);
      }
    },
    setdurationMeeting(duration = null) {
      return store.setdurationMeeting(duration || 300000);
    },
    hasUserRejected(uuid, confId){
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === confId
      );
      if (!timelineEv || !timelineEv.rsvp) return false;
      const extant = timelineEv.rsvp[uuid] || {};
      if (extant.status) {
        return (extant.status == "rejected");
      }
      return false;
    },
    async callGroup(conf, members) {
      await this.$refs.confGrid?.resetSnapShots();
      const duration = 24 * 3600 * 1000;
      this.setdurationMeeting(duration);
      if (conf.confUUIDS.length > 0) {
        let usersToCall = [];
        const usersInRooms = [];
        const usersFunction = [];
        const regUsers = [];
        const mobUsers = [];
        let doCall = true;
        this.$router.push({ path: "/home" });
        conf.confUUIDS.forEach((user) => {
          if (user !== this.state.ownUUID) {
            const isConnected =
              (store.state.group[user] || {}).connected || false;
            const person = store.state.group[user];
            if (person && person.user && !person.user.inBridgeCall) {
              if (!this.amInACall) {
                if (
                  isConnected && (
                  ((store.state.group[user] || {}).user?.activity ||
                    false) !== "Holidays" &&
                  ((store.state.group[user] || {}).user?.activity ||
                    false) !== "Out of Office" &&
                  ((store.state.group[user] || {}).user?.activity ||
                    false) !== "No Calls" &&
                  ((store.state.group[user] || {}).user?.activity ||
                    false) !== "Break") &&
                    this.hasUserRejected(user, conf.confId) == false
                ) {
                  if (person.user.isMobile) {
                    mobUsers.push(user);
                  } else {
                    regUsers.push(user);
                  }
                  usersToCall.push(user);
                }
              }
              if (!usersFunction[user]) {
                if (
                  this.state.group[user].user.position ||
                  this.state.group[user].user.visitorData?.function
                ) {
                  usersFunction[user] =
                    this.state.group[user].user.position ||
                    this.state.group[user].user.visitorData.function;
                } else {
                  usersFunction[user] = "";
                }
              }
            }
            if (person && person.user && person.user.inBridgeCall && (userInStaticRoom(person.user.uuid) || person.user.aiUser)) {
              usersToCall.push(user);
            } else if (person && person.user && person.user.inBridgeCall && !person.user.aiUser) {
              usersInRooms.push(user);
            }
          }
        });
        /*
        if (mobUsers.length && regUsers.length) {
          // SHOW POPUP MOBILES CANT BE CALLED
          dispatchErrorAlert(this.$t("components.conferenceForm.mobAdvice"));
          if (usersToCall.length) usersToCall = regUsers;
        } else if (!regUsers.length && mobUsers.length > 1) {
          // SHOW POPUP MOBILES CANT BE CALLED
          dispatchErrorAlert(this.$t("components.conferenceForm.mobAdvice"));
          if (usersToCall.length) doCall = false;
        }
        */
        if (doCall) {
          const id = uid();
          const info = {
            callUUID: id,
            calling: usersToCall,
            isNormalMode: true,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs: duration,
            infiniteCall: false,
            isConference: true,
            conferenceAttendees: this.getAttendeesObject(conf.confUUIDS, members),
            isConferenceCall: conf.confId || undefined,
            confModerators: await this.getModerators(members),
            confId: conf.confId,
            conferenceHall: conf.conferenceHall,
            liveBroadcastId: conf.liveBroadcastId || undefined,
            recording: conf.recording
          };
          usersToCall.forEach(user => {
            if (isAiUser(user)) {
              const callUUID = getBridgeCallUUidByPerson(user);
              if (callUUID) { // send a message to remove the store
                wsCall("sendToUUID", user, {
                  type: "bridge-signal",
                  action: "hangup_listener",
                  sender: this.state.ownUUID,
                  callUUID: callUUID,
                });
              }
            }
          });
          store.addRemoteBridgeStream(id, info);
          store.setCurrentContentVisile("", false, this.$router);
          const interval = setInterval(() => {
            if (store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[id] && store.state.remoteBridgeStreams[id].roomId) {
              clearInterval(interval);
              usersInRooms.forEach((uuid, idx) => {
                  wsCall("sendToUUID", uuid, {
                    type: "popUp",
                    message: "components.addConferenceModal.conferenceStarted",
                    title: "components.addConferenceModal.conferenceStartedTitle",
                    params: [conf.confName],
                    sender: store.state.ownUUID,
                });
              })
              usersToCall.forEach((user, idx) => {
                const infoStartBridgeCall = {
                  callUUID: id,
                  isNormalMode: conf.confUUIDS.length > 0 ? true : false,
                  initiator: this.state.ownUUID,
                  callStartTs: Date.now(),
                  callDurationMs: info.callDurationMs,
                  infiniteCall: false,
                  conferenceDocuments: [],
                  userConferencePosition: usersFunction || [],
                  excludedFromCalling: [],
                  conferenceAttendees: this.getAttendeesObject(conf.confUUIDS, members),
                  conferenceSettings: {},
                  usersToHideName: [],
                  isConference: true,
                  confId: conf.confId,
                  isConferenceCall: conf.confId || undefined,
                  confModerators: this.getModerators(members),
                  isPinProtected: conf.confPin.length > 0,
                  pinCode: conf.confPin.length > 0 ? conf.confPin : false,
                  conferenceHall: conf.conferenceHall,
                  liveBroadcastId: conf.liveBroadcastId || undefined,
                  roomGuid: store.state.remoteBridgeStreams[id].roomGuid || undefined,
                  roomId: store.state.remoteBridgeStreams[id].roomId || undefined,
                  recording: conf.recording
                };
                if (!hasPrivilege(user)) {
                  markAsCallWaitingRoomUserEvent(user);
                }
                wsCallStartBridgeCall(
                  user,
                  this.state.ownUUID,
                  infoStartBridgeCall,
                  idx > 0
                );
              });
            } else if (!store.state.remoteBridgeStreams[id]) {
              clearInterval(interval);
            }
          }, 200);
        }
      }
    },
    getAttendeesObject(uuids, members) {
      let myMembers = [];
      uuids.forEach((uuid) => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true,
            isPodium: this.getPodiumParticipantUUID(uuid, members) ? true : false
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    async sendUserMessage(uuids, type, title, dates, text = undefined) {
      if (uuids.length > 0) {
        let notificationMssg = "";
        let translateKey = "";
        let params = [];
        const timezone =
          store.state.namespaceSettings.timezone || "Europe/Berlin";
        if (type === "conf-delete") {
          const date =
            dates.startEvent && dates.timeStart
              ? `${moment(dates.startEvent + " " + dates.timeStart).tz(timezone).format(
                  "DD.MM.YY, HH:mm"
                )}` +
                " " +
                this.$t("components.meetingLineModal.hours") +
                ". " +
                `(${timezone})`
              : `${moment().tz(timezone).format("DD.MM.YY, HH:mm")}` +
                " " +
                this.$t("components.meetingLineModal.hours") +
                ". " +
                `(${timezone})`;

          notificationMssg =
            this.$t("components.addConferenceModal.headerConferenceCancelled", [
              title,
              store.state.group[store.state.ownUUID].user.name,
              date,
            ]) +
            " " +
            this.$t("components.meetingLineModal.hours");
          translateKey =
            "components.addConferenceModal.headerConferenceCancelled";
          params = [
            title,
            store.state.group[store.state.ownUUID].user.name,
            date,
          ];
        } else if (type == "conf-update") {
          const date =
            dates.startEvent && dates.timeStart
              ? moment(dates.startEvent + " " + dates.timeStart).format(
                  "DD.MM.YY, HH:mm"
                ) +
                " " +
                this.$t("components.meetingLineModal.hours")
              : moment().format("DD.MM.YY, HH:mm") +
                " " +
                this.$t("components.meetingLineModal.hours");
          notificationMssg =
            this.$t("components.addConferenceModal.headerConferenceChanged", [
              title,
              store.state.group[store.state.ownUUID].user.name,
              date,
            ]) +
            " " +
            this.$t("components.meetingLineModal.hours");
          translateKey =
            "components.addConferenceModal.headerConferenceChanged";
          params = [
            title,
            store.state.group[store.state.ownUUID].user.name,
            date,
          ];
        } else if (type == "conf-reminder") {
          notificationMssg = text;
          translateKey = "components.reminderModal.reminderInfo";
          const formattedStart = moment(dates.startEvent).format("DD.MM.YY");
          const formattedTimeStart =
            dates.timeStart +
            " " +
            this.$t("components.meetingLineModal.hours");
          params = [
            store.state.group[store.state.ownUUID].user.name,
            formattedStart, //dates.startEvent,
            formattedTimeStart,
            dates.timezone,
          ];
        } else {
          return false;
        }

        const header = {
          translateMessage: {
            translateKey: translateKey,
            params: params,
          },
        };
        if (uuids && uuids.length) {
          uuids = uuids.filter((uuid) => uuid !== store.state.ownUUID);
          await parallel(1, [...uuids], async (uuid) => {
            const dataMsg = prepadeDataToSendMessageEffector(
              uuid,
              notificationMssg,
              header,
              "plannerEvent",
              true
            );
            newMessageEvent(dataMsg);
          });
        }
      }
    },
    existingConference() {},
    existingGroup(groupId) {
      this.loadGroup = false;
      this.tab = 0;
      this.loadGroup = groupId;
    },
    resetLoadFromGroup() {
      this.loadGroup = false;
    },
    async createGroup(data) {
      const groupData = {};
      groupData.groupId = data.groupId;
      groupData.groupCreated = Date.now();
      groupData.groupName = data.groupName;
      groupData.favorite = false;
      groupData.groupImage = data.groupImage;
      groupData.groupDescription = data.groupDescription || "";
      groupData.groupMembers = data.groupMembers;
      groupData.owner = store.state.user?.name || "";
      groupData.groupUUIDS = data.groupUUIDS || [];
      groupData.ownerUUID = store.state.ownUUID;
      groupData.lastUsed = Date.now();
      groupData.conferenceHall = data.conferenceHall || false;
      store.state.user.groups.push(groupData);
      return data.groupId;
      // const guests = data.groupMembers.filter(e => e.isGuest === true);
      // guests.forEach(guest => {
      //   if ((store.state.group[guest.uuid] || {}).user || false) {
      //     this.simpleUserInvite(store.state.group[guest.uuid].user);
      //   }
      // });
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    async simpleUserInvite(guestObject) {
      // const uuid = actingAsUuidStore.getState();
      const subject = this.$t("components.inviteVisitor.line1", [
        window.location.hostname,
        this.getUserTitel(this.state.ownUUID) + " " + store.state.user.name,
      ]);
      /*
      let inviteText =
        this.state.user.name +
        " " +
        this.$t("components.inviteVisitor.hasInvitedYou") +
        +"\n" +
        this.$t("components.inviteVisitor.line2") +
        "\n" +
        this.$t("components.inviteVisitor.line4_1") +
        "\n" +
        this.$t("components.inviteVisitor.line5_0") +
        " " +
        "\n" +
        this.$tm("components.inviteVisitor.line3").join("\n") +
        "\n" +
        "\n" +
        this.$t("components.inviteVisitor.yourLink") +
        ":\n" +
        "%INVITE_LINK%\n\n" +
        "\n";

      if (!guestObject.email && guestObject.mobilePhone) {
        // Override invite text if WhatsApp invitation
        inviteText =
          this.state.user.name + " " + this.virtualOfficePrefix + "\n";
        inviteText +=
          this.state.namespaceSettings.customVisitorWhatsAppInviteText ||
          this.$tm("components.inviteVisitor.whatsappText").join("\n");
      }
      */
      const odata = {
        visitorId: guestObject.visitorData.visitorId,
        name: guestObject.name,
        email: guestObject.email || "",
        mobilePhone: guestObject.mobilePhone || "",
        subject: subject,
        variables: {},
      };
      return sendVisitorEmail(odata);
    },
    updateGroup(data) {
      const groupId = data.groupId;
      const groupIndex = store.state.user.groups.findIndex(
        (e) => e.groupId == groupId
      );
      const extant = store.state.user.groups[groupIndex];
      extant.groupName = data.groupName;
      extant.groupDescription = data.groupDescription;
      extant.groupMembers = data.groupMembers;
      extant.groupImage = data.groupImage;
      extant.groupUUIDS = data.groupUUIDS;
      store.state.user.groups.splice(groupIndex, 1);
      store.state.user.groups.push(extant);
    },
    selectOrUnselectFavorite(groupId) {
      const groupIndex = store.state.user.groups.findIndex(
        (e) => e.groupId == groupId
      );
      const extant = store.state.user.groups[groupIndex];
      extant.favorite = !extant.favorite;
      store.state.user.groups.splice(groupIndex, 1);
      store.state.user.groups.push(extant);
    },
    groupFromConference(data) {
      this.membersFromConference = data;
      this.tab = 1;
    },
  },
  computed: {
    haveCalls() {
      return this.state.user.inBridgeCall && Object.keys(this.state.remoteBridgeStreams).length;
    },
    showConferenceHallTab(){
      return store.state.namespaceSettings.showConferencehall || false
    },
    disabledConferenceTab(){
      if (this.isInserting && this.tab == 1) return true;
      return false;
    },
    disabledConferenceHallTab(){
      if (this.isInserting && this.tab == 0) return true;
      return false;
    },
    loadFromGroup() {
      return this.loadGroup;
    },
    virtualOfficePrefix() {
      return (
        this.state.namespaceSettings.companyInfo.virtualOfficePrefix ||
        store.getVirtualOfficePrefix()
      );
    },
  },
};
</script>
<style scoped lang="scss">
.participantsButton {
  position: absolute;
  right: 5px;
  top: 6px;
}
.participantsButMobile {
  background-color: white;
  height: 36px;
  padding-left: 12px;
}
.participantsBut {
  background-color: white;
  // position: absolute;
  // top: -62px;
  // right: 2px;
  height: 36px;
}
.v-theme--dark .participantsButMobile, .v-theme--dark .participantsBut {
  background-color: #313131;
  color: white;
}
.InviteFormContainer .v-card {
  box-shadow: none;
}
.InviteFormContainer {
  /*width: 100%;
  height: calc(100vh - 124px);*/
  height: calc(100vh - 124px);
  overflow: auto;
}
</style>

<style lang="scss">
.AvatarImage .v-image__image {
  border-radius: 4px;
}
.listTitle {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 2rem !important;
}
.listSubtitle {
  font-size: 15px !important;
  font-weight: 300 !important;
}
.inviteTextAreaClass textarea {
  align-self: auto !important;
  line-height: 1.5rem !important;
  padding-right: 30px !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
  margin-bottom: 15px;
  max-height: calc(100vh - 431px) !important;
  overflow-y: auto !important;
}
.inviteTextAreaClass fieldset {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.textAreaSubject {
  & textarea {
    overflow: scroll;
    max-height: 32px;
    border-bottom: 1px #f2f2f2;
  }
}
.textAreaInvite {
  & .v-input__slot {
    height: auto !important;
    max-height: none;
  }
}
.textAreaInviteText {
  & .v-input__slot {
    height: auto !important;
    max-height: none;
  }
}

.__view {
  width: unset !important;
}
.v-theme--light.v-input textarea {
  &::placeholder {
    color: black !important;
  }
}

.v-theme--dark.v-input textarea {
  &::placeholder {
    color: white !important;
  }
}

.cccheckbox {
  max-width: 50px !important;
  padding: 0 !important;
  padding-top: 21px !important;
  max-height: 46px !important;
}

.checkCallPeriod {
  & .v-input__control {
    max-height: 35px;
  }
}

.invitecheckb {
  margin-top: 0 !important;
  padding-top: 0px !important;
}
#inviteVisitorMenu {
  height: 100% !important;
  & .v-label {
    font-weight: 500 !important;
    font-size: 14px;
  }
  & .invitationMenuList {
    width: 100% !important;
    padding-top: 0px;
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100%;
  }
}

.checkCallPeriod {
  margin-top: 0px !important;
  padding-top: 0px !important;
  max-height: 25px;
}

.checkFixedDate {
  margin-top: 0px !important;
  padding-top: 0px !important;
  max-height: 31px;
}

.inviteHeader {
  background-color: #2a3133;
  height: 40px;
  margin: 0px;
  text-align: center;
}

.inviteTextTitle {
  background-color: transparent;
  color: white;
  margin: 0 auto;
  font-size: 17px;
  font-weight: bold;
  top: 2px;
  position: relative;
}
.invitationList {
  position: absolute;
  top: 0;
  right: 0;
}
.mt-17 {
  margin-top: 17px !important;
}
.mt-10 {
  margin-top: 10px !important;
}

.headselect label {
  color: white !important;
}
.headselect .v-icon {
  color: white !important;
}

.headselect .v-theme--light.v-text-field > .v-input__control > .v-input__slot {
  border-color: white !important;
}
.headselect > .v-input__control > .v-input__slot:before {
  border-color: rgb(255, 255, 255) !important;
}

.participantsList {
  margin: 0px;
  padding: 0px !important;
  max-height: 25px;
}
</style>
