<template>
  <v-dialog :model-value="showPaymentCanceled" @update:model-value="showPaymentCanceled = $event" class="paymentModal" persistent max-width="570" hide-overlay :attach="'#videos'">
    <template>
      <v-card class="mx-auto">
         <!-- Title -->
        <HeaderModal
          :titleModal="$t('components.paymentModal.titleRequestPayment')"
          :closeModalFunction="declinePayment"
          :actualDate="actualDate"
        />
        <!-- End title -->
        <!-- Modal body -->
        <v-card-text>
          <v-row class="mx-0 mb-2">
            <v-col cols="12">
              <p class="mb-0">{{$t('components.paymentModal.paymentHasBeenCancelled')}}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- End modal body -->
        <!-- Footer -->
        <v-card color="primary" class="py-2 pl-4 pr-2 footerModal">
          <v-row class="px-2 btns w-100 mx-0 my-0">
            <template>
              <v-tooltip location="top">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" variant="outlined" icon color="white" @click="declinePayment($event)" class="ml-2 closeButton">
                    <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('generics.close') }}</span>
              </v-tooltip>
          </template>
        </v-row>
      </v-card>
        <!-- End footer -->
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import moment from 'moment';
export default {
  components:{HeaderModal},
  props: [],
  data: () => ({
    state: store.state,
    isMobile: isMobile()
  }),
  methods: {
    declinePayment(event){
      // Something else
      event.preventDefault();
      event.stopPropagation();
      this.state.user.paymentState.showCancelPayment = false;
      this.state.user.paymentState.attachTo = false;
    },
  },
  computed: {
   actualDate(){
     return moment(new Date(Date.now())).format("DD.MM.YYYY HH:mm")
   },
   showPaymentCanceled(){
     return this.state.user.paymentState.showCancelPayment && this.state.user.paymentState.attachTo;
   },
  }
};
</script>
<style scoped lang="scss">
.closeButton {
  border-radius: 4px;
  min-width: 45px;
  }
.v-theme--light .v-dialog{
  .v-card {
    background: rgba(255,255,255,.7);
    word-break: break-word;
  }
}
.v-theme--dark .v-dialog{
  .v-card {
    background: rgba(30,30,30,.7);
    word-break: break-word;
  }
}
@media screen and (min-width: 993px) {
  .v-dialog__content{
    position: absolute !important
  }
}
</style>
<style lang="scss">
.videBridgeContainer{
  .v-dialog{
    background-color: transparent !important;
  }
}
</style>