<template>
  <div>
    <v-container class="pb-0" fluid>
      <v-layout>
        <v-row class="pt-0 px-3 Width100">
          <v-col v-if="isBeaconInvite || (!hasPrivilege(ownUUID) && !hasVisitorNameOnStart)" cols="12" class="pt-6">
            <v-text-field
              v-model="visitorName"
              :label="$t('components.inviteVisitor.typeYourName') "
              variant="outlined"
              density="compact"
              hide-details
              :maxlength="120"
            ></v-text-field>
          </v-col>
          <v-col
            v-for="n in 3"
            :key="n"
            :lg="cols[n - 1]"
            :md="cols[n - 1]"
            :sm="cols[n - 1]"
            :xl="cols[n - 1]"
            xs="12"
            class="col-12"
          >
            <v-card flat>
              <div class="video-container" v-if="n == 1">
                <video
                  ref="video"
                  id="video"
                  playsinline
                  autoplay
                  :src-object.prop.camel="videoSourceObject"
                ></video>
              </div>
              <div v-if="n == 2">
                <v-row class="mx-0 px-0 cameraSelection">
                  <v-tooltip  location="top" :disables="!showUpdateAdvise">
                    <template v-slot:activator="{ props }">
                      <v-col cols="12" v-bind="props">
                        <v-select
                          hide-details
                          :items="optionsVideoSource"
                          :label="$t('generics.camera')"
                          :model-value="videoOptionSelected"
                          @update:model-value="videoOptionSelected = $event;handleChange('video', videoOptionSelected)"
                          class="pt-3"
                          variant="outlined"
                          density="compact"
                          ref="videoSource"
                          id="videoSource"
                          item-title = "text"
                          item-value ="value"
                        ></v-select>
                        <!-- <span v-if="showUpdateAdvise" class="pt-5">{{
                          $t("components.camAndMic.updateAdvise")
                        }}</span> -->
                      </v-col>
                    </template>
                    <span>{{
                      $t("components.camAndMic.updateAdvise")
                    }}</span>
                  </v-tooltip>
                </v-row>
              </div>
              <div v-if="n == 3" cols="12 px-0 py-0">
                <v-row class="mx-0 my-0">
                  <v-col cols="12 pt-2 px-0 py-0">
                    <v-select
                      class="selAudio pt-3"
                      :items="optionsAudioSource"
                      :label="$t('components.callsContent.mic')"
                      :model-value="audioOptionSelected" @update:model-value="audioOptionSelected = $event; handleChange('audioInput', audioOptionSelected)"
                      variant="outlined"
                      density="compact"
                      ref="audioSource"
                      id="audioSource"
                      item-title = "text"
                      item-value ="value"
                    ></v-select>
                    <canvas v-show="expandedContent && hasPrivilege(ownUUID)" id="canvasAudio" ref="canvasAudio"></canvas>
                  </v-col>
                  <!-- <v-col cols="12 px-0 pt-5" v-if="expandedContent">
                    <v-switch
                      class="my-0 py-2 px-2"
                      :model-value="automaticMic" @update:model-value="automaticMic = $event; changeMicSetting()"
                      variant="outlined"
                      density="compact"
                      :label="$t('components.camAndMic.autoDeviceChanging')"
                    ></v-switch> 
                  </v-col> -->
                  <v-col cols="12 px-0 py-0" v-show="expandedContent && hasPrivilege(ownUUID)">
                    <div
                      class="pl-0 mb-1 font-weight-light text-caption"
                      >{{
                        $t("components.camAndMic.ringerVolume")
                      }}</div
                    >
                    <v-slider
                      class="micSlider pr-4"
                      :model-value="volumeCallToneModel" @update:model-value="volumeCallToneModel = $event"
                      thumb-label="always"
                    ></v-slider>
                  </v-col>

                  <v-col cols="12 px-0 py-0">
                    <v-select
                      :items="optionsAudioOutput"
                      :label="$t('components.callsContent.loudspeaker')"
                      :model-value="audioOutputOptionSelected" @update:model-value="audioOutputOptionSelected = $event; handleChange('audioOutput', audioOutputOptionSelected)"
                      variant="outlined"
                      density="compact"
                      hide-details
                      ref="audioOutput"
                      id="audioOutput"
                      item-title = "text"
                      item-value ="value"
                    ></v-select>
                    <v-alert
                      type="warning"
                      :dismissible="false"
                      v-if="!!audioOutputError"
                    >
                      Output: {{ audioOutputError }}
                    </v-alert>
                  </v-col>
                  <v-col cols="12 px-0 py-0 pt-4">
                    <v-select
                      v-show="hasPrivilege(ownUUID)"
                      :items="optionsAudioOutput"
                      :label="$t('components.camAndMic.ringingOutput')"
                      v-model="ringingOutputOptionSelected"
                      hide-details
                      variant="outlined"
                      density="compact"
                      ref="ringingOutput"
                      id="ringingOutput"
                      class="pt-3"
                      item-title = "text"
                      item-value ="value"
                      @update:model-value="
                        handleChange('ringingOutput', ringingOutputOptionSelected)
                      "
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-3 mb-6">
                  <v-btn @click="expandedContent=!expandedContent" variant="text" >
                    {{$t("generics.aditionalSettings")}}
                    <font-awesome-icon
                      class="ml-2"
                      v-if="!expandedContent"
                      :icon="['fal', 'chevron-down']"
                      :style="{ fontSize: '16px' }"
                      color="primary"
                    />
                    <font-awesome-icon
                      class="ml-2"
                      v-else
                      :icon="['fal', 'chevron-up']"
                      :style="{ fontSize: '16px' }"
                      color="primary"
                    />
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col cols="12" class="" v-if="expandedContent">
                    <span>{{$t('components.callsContent.videoBackground')}}</span>
                    <div class="gridVideoBackground mt-2 pb-2">
                      <div :key="index" v-for="(background, index) in dataVideoBackground" :class="(videoBackground && JSON.stringify(videoBackground) === JSON.stringify(background)) ? 'imageBackgroundSelected' : 'imageBackground'" @click="selectVideoBackground(background)">
                        <img v-if="background.image" :src="background.url">
                        <img class="imgIcon" v-if="background.icon" :src="background.icon">
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
    <v-alert type="error" :dismissible="true" v-if="mediaDevicesError">{{
      mediaDevicesError
    }}</v-alert>
    <FooterModal :hideDefaultButtons="!closeModal || hideClose" :showCancel="closeModal && !hideClose" :closeModalFunction="closeModal">
      <v-btn :disabled="disableSave" flat color="primary" @click="saveMediaSetup()">{{
        $t("generics.save")
      }}</v-btn>
    </FooterModal>
  </div>
</template>

<script>
import store from "../../store";
import { cycleUserMedia } from "../../lib/mediaDevices";
import { prepareDataForVirtualBackground } from "../../utils/calls";
import {
  hasPrivilege,
} from "../../utils/privileges";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { FooterModal },
  props: ["isInModal", "hideClose", "closeModal"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      mediaDevicesError: "",
      setShowSetup: store.setShowSetup,
      videoSourceObject: null,
      videoOptionSelected:
        store.state.persisted.mediaDeviceSetup.videoDeviceId || "",
      audioOptionSelected:
        store.state.persisted.mediaDeviceSetup.audioDeviceId || "",
      audioOutputOptionSelected:
        store.state.persisted.mediaDeviceSetup.audioOutputId || "",
      ringingOutputOptionSelected:
        store.state.persisted.mediaDeviceSetup.ringingOutputId || "",
      optionsVideoSource: [],
      optionsAudioSource: [],
      optionsAudioOutput: [],
      volumeCallToneModel:
        store.state.persisted.mediaDeviceSetup.volumeCallTone,
      automaticMic: store.state.persisted.reactToDeviceChanges,
      audioOutputError: null,
      audioContext: null,
      videoBackground: store.state.persisted.mediaDeviceSetup.videoBackground || undefined,
      dataVideoBackground: prepareDataForVirtualBackground(),
      expandedContent: false,
      visitorName: store.state.user.name,
      hasVisitorNameOnStart: !!store.state.user.name,
    };
  },
  computed: {
    isBeaconInvite() {
      return store.state.group[this.ownUUID].beaconCallInvite;
    },
    disableSave() {
      if(!this.hasPrivilege(this.ownUUID) && !this.visitorName.length){
        return true
      }
      return false;
    },
    showUpdateAdvise() {
      if (
        this.videoOptionSelected &&
        this.videoOptionSelected.length > 2 &&
        !this.mediaDevicesError
      ) {
        return false;
      } else if (
        this.mediaDevicesError &&
        this.mediaDevicesError.indexOf("Could not start video source") !== -1
      ) {
        return navigator.userAgent.includes("Windows NT 10");
      } else {
        return navigator.userAgent.includes("Windows NT 10");
      }
    },
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.display;
      return lg
        ? [5, 7, 12]
        : sm
        ? [5, 7, 12]
        : md
        ? [5, 7, 12]
        : xl
        ? [5, 7, 12]
        : [12, 12, 12];
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    setVisitorName(){
      return store.setUserName(this.visitorName)
    },
    selectVideoBackground(videoBackground) {
      this.videoBackground = videoBackground;
    },
    changeMicSetting() {
      this.automaticMic = !store.state.persisted.reactToDeviceChanges;
    },
    handleChange(type, value) {
      this.mediaDevicesError = null;
      switch (type) {
        case "video":
          this.videoOptionSelected = value;
          break;
        case "audioInput":
          this.audioOptionSelected = value;
          break;
        case "audioOutput":
          this.audioOutputOptionSelected = value;
          break;
        case "ringingOutput":
          this.ringingOutputOptionSelected = value;
        break;
      }
      this.start();
    },
    gotDevices(deviceInfos) {
      // Handles being called several times to update labels. Preserve values.
      const selectors = [
        this.$refs.video,
        this.$refs.audioSource,
        this.$refs.audioOutput,
        this.$refs.ringingOutput,
        this.$refs.videoSource,
      ];
      selectors.forEach((select) => {
        while (select.firstChild) {
          select.removeChild(select.firstChild);
        }
      });
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        const option = { text: "", value: deviceInfo.deviceId };
        if (deviceInfo.kind === "audioinput") {
          option.text =
            deviceInfo.label ||
            `microphone ${this.$refs.audioSource.length + 1}`;
          if (!this.optionsAudioSource.find(v => v.value === option.value)) {
            this.optionsAudioSource.push(option);
          }
          if (
            !this.state.persisted.mediaFirstSetupDone &&
            this.audioOptionSelected == ""
          ) {
            this.audioOptionSelected = option.value;
          }
        } else if (deviceInfo.kind === "audiooutput") {
          option.text =
            deviceInfo.label || `speaker ${this.$refs.audioOutput.length + 1}`;
          if (!this.optionsAudioOutput.find(v => v.value === option.value)) {
            this.optionsAudioOutput.push(option);
          }
        } else if (deviceInfo.kind === "ringingoutput") {
          option.text =
            deviceInfo.label || `speaker ${this.$refs.ringingOutput.length + 1}`;
          if (!this.optionsAudioOutput.find(v => v.value === option.value)) {
            this.optionsAudioOutput.push(option);
          }
        } else if (deviceInfo.kind === "videoinput") {
          option.text =
            deviceInfo.label || `camera ${this.$refs.videoSource.length + 1}`;
          if (!this.optionsVideoSource.find(v => v.value === option.value)) {
            this.optionsVideoSource.push(option);
          }
          if (
            !this.state.persisted.mediaFirstSetupDone &&
            (this.videoOptionSelected == "" || undefined)
          ) {
            this.videoOptionSelected = option.value;
          }
        }
      }
      let max_level_L = 0;
      let old_level_L = 0;
      const cnvs = document.getElementById("canvasAudio");
      const cnvs_cntxt = cnvs.getContext("2d");

      if (this.audioContext && typeof this.audioContext.close === 'function') {
        this.audioContext.close();
      }

      const audioContext = this.audioContext = new window.AudioContext();
      const microphone = audioContext.createMediaStreamSource(window.stream);
      const javascriptNode = audioContext.createScriptProcessor(1024, 1, 1);
      microphone.connect(javascriptNode);
      javascriptNode.connect(audioContext.destination);
      javascriptNode.onaudioprocess = function (event) {
        const inpt_L = event.inputBuffer.getChannelData(0);
        let instant_L = 0.0;

        let sum_L = 0.0;
        for (let i = 0; i < inpt_L.length; ++i) {
          sum_L += inpt_L[i] * inpt_L[i];
        }
        instant_L = Math.sqrt(sum_L / inpt_L.length);
        max_level_L = Math.max(max_level_L, instant_L);
        instant_L = Math.max(instant_L, old_level_L - 0.008);
        old_level_L = instant_L;

        cnvs_cntxt.clearRect(0, 0, cnvs.width, cnvs.height);
        cnvs_cntxt.fillStyle = "#2a3133";
        cnvs_cntxt.fillRect(
          2,
          2,
          cnvs.width * (instant_L / max_level_L),
          cnvs.height - 5
        ); // x,y,w,h
      };
    },
    attachSinkId(element, sinkId) {
      try {
        if (Array.isArray(element)) element = element[0];
        this.audioOutputError = null;
        if (typeof element.sinkId !== "undefined") {
          element
            .setSinkId(sinkId)
            .then(() => {})
            .catch((error) => {
              let errorMessage = error;
              if (error.name === "SecurityError") {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
              }
              console.error(errorMessage);
              this.audioOutputError = errorMessage;
              // Jump back to first output device in the list as it's the default.
              if (this.$refs.audioOutput.selectedIndex)
                this.$refs.audioOutput.selectedIndex = 0;
            });
        } else {
          console.warn("Browser does not support output device selection.");
          if (this.$refs.audioOutput.selectedIndex)
            this.$refs.audioOutput.selectedIndex = 0;
          this.audioOutputError = this.$t("components.camAndMic.notSupported");
        }
      } catch (err) {
        console.warn("attachSinkId err", err);
      }
    },
    changeAudioDestination() {
      const audioDestination = this.audioOutputOptionSelected;
      this.attachSinkId(this.$refs.video, audioDestination);
    },
    gotStream(stream) {
      if (!this.$refs.video || !this.$refs.video.length) {
        // Component already dismounted. Stop tracks
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        return;
      }
      window.stream = stream; // make stream available to console
      this.videoSourceObject = stream;
      this.changeAudioDestination();
      // Refresh button list in case labels have become available
      return navigator.mediaDevices.enumerateDevices();
    },
    start() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (isNaN(store.state.persisted.mediaDeviceSetup.volumeCallTone)) {
        this.volumeCallToneModel = 100;
      }
      const audioSource = this.audioOptionSelected;
      const videoSource = this.videoOptionSelected;
      let constraints = {
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
        video: { 
            deviceId: videoSource ? { exact: videoSource } : undefined,
            aspectRatio: 16/9,
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 360, ideal: 720, max: 1080 },
            frameRate: { ideal: 30 },
            advanced: [
              { width: 1280, height: 720 },  // Try for 720p first
              { width: 640, height: 360 }    // Fallback to 360p
            ]
          },
      };
      const mediaDevices = navigator.mediaDevices;
      if (mediaDevices) {
        const audioOutputs = [this.audioOutputOptionSelected, this.ringingOutputOptionSelected];
        this.optionsVideoSource = this.optionsVideoSource.filter(v => v.value === this.videoOptionSelected);
        this.optionsAudioSource = this.optionsAudioSource.filter(v => v.value === this.audioOptionSelected);
        this.optionsAudioOutput = this.optionsAudioOutput.filter(v => audioOutputs.includes(v.value));
        navigator.mediaDevices
          .getUserMedia(constraints)
          .catch((error) => {
            if (error && error.name === "OverconstrainedError") {
              this.state.persisted.mediaDeviceSetup.videoDeviceId = undefined;
              this.state.persisted.mediaDeviceSetup.audioDeviceId = undefined;
              constraints = {
                audio: { deviceId: undefined },
                video: { deviceId: undefined },
              };
              return navigator.mediaDevices.getUserMedia(constraints);
            }
            if (error && error.name !== "NotFoundError") this.handleError(error);
            return navigator.mediaDevices.getUserMedia({
              audio: constraints.audio,
            });
          })
          .then(this.gotStream)
          .then(this.gotDevices)
          .catch(this.handleError);
      }
    },
    handleError(error) {
      this.mediaDevicesError = `[${error.name}] ${error.message}`;
    },
    saveMediaSetup() {
      store.state.persisted.reactToDeviceChanges = this.automaticMic;
      const audioSource = this.audioOptionSelected;
      const videoSource = this.videoOptionSelected;
      const audioOutput = this.audioOutputOptionSelected;
      const ringingOutput = this.ringingOutputOptionSelected;
      const videoBackground = this.videoBackground;
      store.state.persisted.mediaDeviceSetup.volumeCallTone = this.volumeCallToneModel;
      store.state.persisted.mediaDeviceSetup.audioDeviceId =
        audioSource || undefined;
      store.state.persisted.mediaDeviceSetup.videoDeviceId =
        videoSource || undefined;
      store.state.persisted.mediaDeviceSetup.audioOutputId =
        audioOutput || undefined;
      store.state.persisted.mediaDeviceSetup.ringingOutputId =
        ringingOutput || undefined;
      store.state.persisted.mediaDeviceSetup.videoBackground = videoBackground || undefined;
      if (!store.state.persisted.mediaFirstSetupDone) {
        store.state.persisted.mediaFirstSetupDone = true;
      }
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      cycleUserMedia();
      if (this.isInModal) {
        this.closeModal();
      } else {
        this.setShowSetup(false);
      }
      this.state.user.guestDeviceSetted = true;
      if(!this.hasPrivilege(this.ownUUID)){
        this.setVisitorName();
      }
    },
  },
  mounted() {
    this.start();
  },
  unmounted() {
    if (this.audioContext && typeof this.audioContext.close === 'function') {
      this.audioContext.close();
    }
  },
};
</script>
<style scoped lang="scss">
.imgIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
.gridVideoBackground {
  display: grid;
  gap: 8px;
  grid-auto-rows: 55px;
  grid-template-columns: repeat(auto-fit, 78px);
  -webkit-box-pack: center;
  justify-content: center;
}
.imageBackground {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  // box-shadow: rgb(55, 113, 224) 0px 0px 0px 2px;
}
.imageBackgroundSelected {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: rgb(55, 113, 224) 0px 0px 0px 2px;
}
.cameraSelection {
  min-height:160px;
  max-height:160px;
  overflow: auto;
}
.Width100 {
  width: 100%;
}
.v-slider.v-slider--horizontal.v-theme--light {
  margin-left: 0px !important;
}
// .setupSubHeader {
//   height: 20px;
//   margin-bottom: -22px !important;
// }
.v-slider--horizontal {
  margin-left: 0px !important;
}
.w-960 {
  max-width: 960px;
}
.right {
  justify-content: flex-end;
}
.video-container {
  position: relative;
  width: 235px; /* or any specific width */
  height: 152px;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  display: flex;
  align-items: center; /* Center video vertically */
  justify-content: center; /* Center video horizontally */
}
video {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; 
  -webkit-transform: scaleX(-1); /* Chrome, Safari, Opera */
  -moz-transform: scaleX(-1);    /* Firefox */
  -ms-transform: scaleX(-1);     /* IE 9 */
  -o-transform: scaleX(-1);      /* Opera */
  transform: scaleX(-1);

}
.titleForm {
  height: 16px;
  font-size: 15px;
  color: #2a3133;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
canvas {
  background-color: black;
  width: 100%;
  height: 15px;
  margin-top: -5px;
  background: #cccccc;
}
@media (max-width: 605px) {
  video {
    height: auto;
    max-height: 153px;
  }
}
</style>
<style>
.micSlider .v-slider {
  margin-left: 0px !important;
}
.v-input, .v-label {
  font-weight: 300 !important;
  letter-spacing: normal !important;
}
</style>