// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-5.use[1]!../../node_modules/vuetify/dist/vuetify.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-5.use[1]!../../node_modules/vue-popperjs/dist/vue-popper.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-tour[data-v-20a30e69]{z-index:202}.visitorPanel[data-v-20a30e69]{width:200px;height:100px;position:absolute;top:25px;left:25px}`, "",{"version":3,"sources":["webpack://./websrc/vue/main.vue"],"names":[],"mappings":"AAGA,yBACE,WAAA,CAEF,+BACE,WAAA,CACA,YAAA,CACA,iBAAA,CACA,QAAA,CACA,SAAA","sourcesContent":["\n@import \"~vuetify/dist/vuetify.min.css\";\n@import \"~vue-popperjs/dist/vue-popper.css\";\n.v-tour {\n  z-index: 202;\n}\n.visitorPanel {\n  width: 200px;\n  height: 100px;\n  position: absolute;\n  top: 25px;\n  left: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
