<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="popUpTitle"
        :closeModalFunction="closeModal"
      />
       <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
       <v-card-text>
          <p class="breakSpaces">{{ popUpMsg }}</p>
       </v-card-text>
      </v-row>
      <FooterModal :closeModalFunction="closeModal" />
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    closeModal() {
       return store.setmodalPopUp(undefined);
    },
  },
  computed: {
    popUpMsg() {
      if (this.state.modalPopUp?.msg && /^[\w\d_.-]+$/.test(this.state.modalPopUp.msg)) {
        return this.$t(this.state.modalPopUp.msg) || this.state.modalPopUp.msg;
      }
      return this.state.modalPopUp?.msg || "";
    },
    popUpTitle() {
      const params = (this.state.modalPopUp || {}).params || [];
      return this.$t((this.state.modalPopUp || {}).title || 'generics.info', params);
    },
    showModal() {
      return store.state.modalPopUp && store.state.modalPopUp.type === "popUp";
    },
  },
};
</script>
<style scoped lang="scss">
.breakSpaces{
  white-space: break-spaces;
}

</style>
