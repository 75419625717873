<template>
  <div>
    <div
      v-if="viewingConferences"
      :class="`px-0 py-0 pb-10 ${removeGeneralWrapper ? null : 'contentGridWrapper'}`"
      v-on:scroll="handleScroll"
    >
      <!-- <UserProfle
        v-if="showUserProfile"
        :showUserProfile="showUserProfile"
        :toggleUserProfile="toggleUserProfile"
        :enableCam="true"
      /> -->
      <v-row class="mx-auto">
        <v-col class="custom6cols pb-1 px-2" cols="6">
          <v-card
            class="cardWidth cursorPointer"
            :style="{ border: `1px solid #efefef` }"
            @click="clickOnAddCard()"
          >
            <div style="height: 180px; display: table; width: 100%; padding:10px">
              <div class="divAdd">
                <font-awesome-icon :icon="['fal', 'plus']" class="buttonAdd" />
                <p class="mb-0 mt-2">
                  {{ $t("components.conferenceForm.createConference") }}
                </p>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          v-for="(conference, index) in getMyConferenceDetailsConfIsTemp"
          :key="index"
          class="custom6cols pb-1 px-2"
          cols="6"
        >
          <v-card
            class="cardWidth"
            style="background-color: #eaeaea"
            @click="editConference(conference)"
          >
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <div v-bind="props">
                  <v-img
                    :src="conference.confAvatar"
                    class="text-white align-end"
                    style="border-radius: 4px"
                    height="180px"
                  >
                    <ConferenceDateTimeOverlay :conference="conference" />
                    <v-tooltip bottom>
                      <template #activator="{ props }">
                        <font-awesome-icon
                          v-bind="props"
                          v-if="conference.confIsReocurring"
                          :icon="['far', 'repeat-alt']"
                          color="orange"
                          :style="{
                            fontSize: '20px',
                            position: 'absolute',
                            top: 45,
                            right: 5,
                          }"
                        />
                      </template>
                      <span>{{
                        $t("components.conferenceForm.reocurringConf")
                      }}</span>
                    </v-tooltip>
                    <div class="gradientBg">
                      <v-card-subtitle
                        style="color: #fff !important"
                        class="
                          titleCard
                          colorWhite
                          pt-1
                          pb-1
                          px-2
                          text-truncate
                        "
                      >
                        <!--  elipses menu -->
                        <ConferenceCardActions
                          :conference="conference"
                          :editConference="editConference"
                          :deleteConference="deleteConference"
                          :checkSendMessage="checkSendMessage"
                          :existingConference="existingConference"
                          :duplicateConference="duplicateConference"
                          :callEveryone="callEveryone"
                          :reminderConference="reminderConference"
                          :amInACall="amInACall"
                        />
                      </v-card-subtitle>
                    </div>
                  </v-img>
                </div>
              </template>
              <ConferenceInfoTooltip
                :conference="conference"
              ></ConferenceInfoTooltip>
            </v-tooltip>
          </v-card>
        </v-col>
        <infinite-loading :distance="5" @infinite="infiniteHandler">
          <template #error>
            <div v-html="'&nbsp;'"></div>
          </template>
          <template #complete>
            <div v-html="'&nbsp;'"></div>
          </template>
        </infinite-loading>
      </v-row>
    </div>
    <div
      v-if="!viewingConferences"
      :class="`px-0 py-0 pb-4 ${removeGeneralWrapper ? null : 'contentGridWrapper'}`"
      v-on:scroll="handleScroll"
    >
      <v-row :class="isMobile ? null : 'heightScroll'">
        <div :class="isMobile ? 'participantsButMobile' : 'participantsBut'">
        <v-tooltip
          v-if="showJoinConferenceButton && !amInACall"
          location="top"
          max-width="210"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mr-1 mb-2 text-white joinConferenceClass"
              @click="joinToConference"
              variant="text"
              color="#FFA500"
              >{{ $t("components.conferenceForm.joinConference") }}</v-btn
            >
          </template>
          <span>{{ $t("components.conferenceForm.joinConference") }} </span>
        </v-tooltip>
          <v-btn :class="(!showJoinConferenceButton && !amInACall) ? 'totalParticipantsClassRight' : 'totalParticipantsClass'" class="mr-1 mb-2" variant="text" color="primary" :disabled="true"
            >{{ $t("components.conferenceForm.inviteeTotal") }}:
            {{ getTotalInvited }}</v-btn
          >
          <v-col class="py-0 pl-0 font-weight-bold" v-if="uuidToRepresentStore">{{$t('components.conferenceForm.conferenceOf',[getNameForUuid(uuidToRepresentStore)])}}</v-col>
        </div>
        <v-col class="mx-0" :cols="isMobile ? 12 : 7" :md="isMobile ? 12 : 5" v-if="!isPreviewingEmail">
          <v-row class="mx-0">
            <v-col cols="12" class="pl-0 pb-0">
              <v-combobox
                ref="templatesDropDown"
                :model-value="conferenceName" @update:model-value="conferenceName = $event; changeGroupCombo($event)"
                :error="conferenceName === null || conferenceName === ''"
                class="mt-2 height-40"
                :disabled="!canEditEvent"
                :items="getUserGroups"
                :label="$t('components.conferenceForm.conferenceName')"
                variant="outlined"
                density="compact"
                item-title="groupName"
                item-value="groupId"
                return-object
                maxlength="100"
                :counter="100"
              >
                <template v-slot:append v-if="canEditEvent">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        v-bind="props"
                        small
                        variant="text"
                        :disabled="!canSaveGroup"
                        @click="savingTemplate($event)"
                      >
                        <font-awesome-icon
                          :style="{ fontSize: '26px' }"
                          class="cursorPointer mb-1"
                          :icon="['fal', 'save']"
                          :color="isEditingTemplate ? 'red' : ''"
                        ></font-awesome-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      isTemplate && !isEditingTemplate
                        ? $t("components.conferenceForm.editTemplate")
                        : isTemplate && isEditingTemplate
                        ? $t("generics.cancel")
                        : $t("components.conferenceForm.saveAsTemplate")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="
                      isEditingTemplate || typeof conferenceName === 'object'
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        v-bind="props"
                        small
                        variant="text"
                        @click="deleteTemplate($event)"
                      >
                        <font-awesome-icon
                          :style="{ fontSize: '23px' }"
                          class="cursorPointer"
                          :icon="['fal', 'trash']"
                          :color="!deleteTemplateActive ? '' : 'red'"
                        ></font-awesome-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.conferenceForm.deleteTemplate")
                    }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-title @click="changeGroupCombo(item.raw)"
                    class="py-2 px-3 cursor-pointer hover-gray"
                    >{{ item.raw.groupName }} -
                    {{
                      moment(item.raw.groupCreated).format("DD.MM.YYYY HH:mm")
                    }}</v-list-item-title
                  >
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" class="pl-0 pb-0 pt-0" v-if="canEditEvent">
              <v-textarea
                variant="outlined"
                density="compact"
                rows="2"
                :model-value="personalizedGreeting" @update:model-value="personalizedGreeting = $event"
                :counter="150"
                maxlength="150"
                :label="$t('components.conferenceForm.personalizedSalutation')"
                no-resize
              />
            </v-col>
            <v-col :cols="isMobile ? 8 : 10" class="pl-0 pt-0 pb-6" v-if=" canEditEvent">
              <v-autocomplete
                ref="autocompleteAddUsersGuests"
                :model-value="selectedDropDowItem" @update:model-value="selectedDropDowItem = $event; handleSelection($event); searchInput = ''"
                :items="fillDropDown"
                variant="outlined"
                density="compact"
                color="blue-grey-lighten-2"
                :label="$t('components.conferenceForm.selectParticipants')"
                item-title="name"
                item-value="uuid"
                multiple
                hide-selected
                hide-details
                class="mb-0"
                :disabled="isConferenceFull"
                :search-input.sync="searchInput"
                :no-data-text="
                  !isConferenceFull
                    ? $vuetify.noDataText
                    : $t('components.conferenceForm.limitUsers')
                "
              >
                <template v-slot:selection="{ item }">{{
                  item.raw.name
                }}</template>

                <template v-slot:item="{ item, props }">
                  <!-- @mousedown.prevent="selectItem(item)" -->
                  <v-list-item
                    v-bind="props"
                    :title="null"
                    :disabled="!!item.raw.header"
                  >
                
                  <div class="text-caption mx-2 mb-1" v-if="item.raw.header">
                    {{ item.raw.header }}
                  </div>
                  <div v-else-if="!!item.raw.divider">
                    <v-divider class="pb-3 mt-3"></v-divider>
                  </div>
                  <div v-else class="selectorBack d-flex py-2 mb-2 ml-2">
                      <v-avatar class="borderRadius-4 mr-2">
                        <v-img :src="item.raw.avatar" />
                      </v-avatar>

                      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                      <div v-if="item.raw.group !== 'Teams'">
                        <v-list-item-title
                          class="text-bold participantName"
                          v-html="
                            $sanitize(
                              !item.raw.isGroup
                                ? item.raw.name
                                : item.raw.groupName
                            )
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          class="participantCategory"
                          v-html="
                            !item.raw.isGroup
                              ? getTypeOfParticipant(item.raw.group)
                              : $t('components.addConferenceModal.group') +
                                ` - ${item.raw.groupMembers.length}` +
                                ' ' +
                                $t('components.addConferenceModal.members')
                          "
                        ></v-list-item-subtitle>
                      </div>

                      <div v-else>
                        <v-list-item-title
                          class="text-bold participantName"
                          v-html="$sanitize(item.raw.name)"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          class="participantCategory"
                          v-html="
                            $t('components.addConferenceModal.team') +
                            ` - ${item.raw.members.length}` +
                            ' ' +
                            $t('components.addConferenceModal.members')
                          "
                        ></v-list-item-subtitle>
                      </div>
                    </div>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              :cols="isMobile ? 4 : 2"
              class="pl-0 pt-0 pb-6 text-center"
              style="align-items: center; display: flex"
              v-if="canEditEvent"
            >
              <span class="text-caption">{{
                $t("components.conferenceForm.guest")
              }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ props }">
                  <font-awesome-icon
                    v-bind="props"
                    @click="
                      isConferenceFull ? null : (showAddGuestModal = true)
                    "
                    class="text-primary cursorPointer ml-3"
                    :icon="['fal', 'plus-circle']"
                    :style="{ fontSize: '26px' }"
                    :disabled="isConferenceFull"
                  />
                </template>
                <span>{{ $t("components.conferenceForm.ttAddNewGuest") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" class="pl-0 pt-0 pb-6">
              <v-row>
                <v-col cols="4">
                  <v-menu
                    ref="startDateMenu"
                    id="startDateMenu"
                    :model-value="startDateMenu" @update:model-value="startDateMenu = $event"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :disabled="!canEditEvent || isNotMyConference"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        :model-value="formatedStart" @update:model-value="formatedStart = $event"
                        :disabled="!canEditEvent || isNotMyConference"
                        readonly
                        v-bind="props"
                        class="height-40"
                        density="compact"
                        hide-details
                        variant="outlined"
                        :label="$t('components.conferenceForm.startDate')"
                      >
                      <!-- https://gitlab.ra-micro.de/devcups/voffice/-/issues/455#note_27704 decided to remove trash icon -->
                        <!-- <template
                          slot="append"
                          v-if="formatedStart != null || isNotMyConference"
                        >
                          <DeleteBasket
                            v-if="canEditEvent"
                            indexData
                            :delFunction="cleanDate"
                            extraClasses="cursorPointer deleteBasketPosition"
                            fontSize="18"
                          />
                        </template> -->
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :min="moment(Date.now()).format('YYYY-MM-DD')"
                      @click:date="$refs.startDateMenu.save(startEvent)"
                      :model-value="startEvent" @update:model-value="startEvent = $event"
                      no-title
                      scrollable
                      :locale="this.$locale.current()"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="">
                  <v-menu
                    ref="startTimeMenu"
                    :disabled="!canEditEvent"
                    :model-value="startTimeMenu" @update:model-value="startTimeMenu = $event"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time2"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        @keydown.space="(event) => event.preventDefault()"
                        :model-value="timeStart" @update:model-value="timeStart = $event"
                        type="time"
                        class="timePicker height-40"
                        v-bind="props"
                        density="compact"
                        hide-details
                        variant="outlined"
                        :label="$t('components.conferenceForm.from')"
                        :disabled="!canEditEvent || !formatedStart"
                      >
                      </v-text-field>
                    </template>
                    <v-time-picker
                      class="font16"
                      v-if="startTimeMenu"
                      :disabled="!canEditEvent"
                      :model-value="timeStart" @update:model-value="timeStart = $event"
                      full-width
                      format="24hr"
                      @update:minute="handleMinuteClick($event)"
                      @update:hour="autocompleteMinutes($event)"
                      min="00:00"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-menu
                    ref="endTimeMenu"
                    :disabled="!canEditEvent"
                    :model-value="endTimeMenu" @update:model-value="endTimeMenu = $event"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        @keydown.space="(event) => event.preventDefault()"
                        type="time"
                        class="timePicker"
                        :model-value="timeEnd" @update:model-value="timeEnd = $event"
                        v-bind="props"
                        density="compact"
                        variant="outlined"
                        :disabled="!canEditEvent || !timeStart || !startEvent"
                        hide-details
                        :label="$t('components.conferenceForm.to')"
                        :min="new Date().toISOString().slice(0, 10)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      class="font16 height-40"
                      v-if="endTimeMenu"
                      :disabled="!canEditEvent"
                      :model-value="timeEnd" @update:model-value="timeEnd = $event"
                      full-width
                      format="24hr"
                      @update:minute="handleMinuteClickEnd($event)"
                      @update:hour="autocompleteMinutesEnd($event)"
                      :min="minTimeEndPicker"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="isMobile ? 12 : 8" class="pl-0 pt-0" :class="!isMobile ? 'pr-4' : ''" style="max-height: 75px">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-textarea
                    v-bind="props"
                    :disabled="!canEditEvent"
                    variant="outlined"
                    density="compact"
                    rows="2"
                    :model-value="conferenceDescription" @update:model-value="conferenceDescription = $event"
                    :counter="150"
                    maxlength="150"
                    :label="$t('components.conferenceForm.description')"
                    no-resize
                    @blur="isEditingTemplate ? updateTemplate() : null"
                  />
                </template>
                <span>{{ $t("components.conferenceForm.ttDescription") }}</span>
              </v-tooltip>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4" :class="`${isMobile ? 'pb-4' : 'pb-0'} pt-0 text-center`">
              <span class="text-caption">{{
                $t("components.conferenceForm.conferenceLogo")
              }}</span>
              <v-row>
                <v-col col="12">
                  <v-img
                    :src="conferenceImage"
                    width="80px"
                    height="80px"
                    max-height="80px"
                    max-width="80px"
                    class="confImage borderRadius-4 center"
                  >
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          class="mx-2 borderRadius-4 uploadGroupImage"
                          variant="flat"
                          icon
                          dark
                          color="primary"
                          v-bind="props"
                          @click="setConferenceImage"
                          :disabled="!canEditEvent"
                        >
                          <font-awesome-icon
                            :icon="['fal', 'upload']"
                            :style="{ fontSize: '18px' }"
                          />
                        </v-btn>
                      </template>
                      <span>{{
                        $t("components.groups.uploadConferenceImage")
                      }}</span>
                    </v-tooltip>
                  </v-img>
                  <input
                    type="file"
                    accept="image/x-png, image/gif, image/jpeg"
                    ref="conferenceImageInput"
                    class="d-none"
                    @change="saveConferenceImage($event)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
              style="max-height: 75px"
              v-if="!canEditEvent && !confHasEnded"
            >
              <v-radio-group
                row
                :model-value="rsvpValue" @update:model-value="rsvpValue = $event; conferenceRSVP()"
                class="ml-2"
              >
                <v-radio
                  :value="true"
                  :label="$t('components.meetingTime.accept')"
                ></v-radio>
                <v-radio
                  :value="false"
                  :label="$t('components.meetingTime.decline')"
                ></v-radio>
              </v-radio-group>
            </v-col>
            </v-row>
            <v-row>
            <v-col
              cols="12"
              class="pt-0"
              style="max-height: 75px"
              v-if="isMod && confHasEnded">
                <v-tooltip location="top" max-width="210">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="ml-2"
                      v-bind="props"
                      variant="text"
                      color="primary"
                      @click="duplicateConference(conferenceToEdit.confId)"
                      >{{
                          $t("components.addConferenceModal.dupConfNewDate")
                      }}</v-btn
                    >
                  </template>
                  <span>{{ $t("components.addConferenceModal.dupConfNewDate") }} </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="canEditEvent">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :label="$t('components.inviteVisitor.pinAccess')"
                variant="outlined"
                class="mr-2 mt-0 pt-0"
                autocomplete="off"
                :model-value="pinCode" @update:model-value="pinCode = $event"
                density="compact"
                @keydown.space.prevent
                @blur="pinCode ? checkPin : null"
                :maxlength="6"
                :rules="[
                  (v) => {
                    if (v) {
                      if (v.length<6) {
                        return this.$t('components.2faPin.sixDigitsRequired')
                      }
                      if (!(/^\d{6}$/.test(v))) {
                        return this.$t('components.2faPin.sixDigitsRequired')
                      }
                    }
                    return true;
                  }
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="text-body-2 pt-1" v-if="pinCode">
                {{ $t("components.inviteGuestPanel.pinGuestSenden") }}
              </p>
            </v-col>
            <v-col cols="12" md-6 class="py-0 d-flex">
              <div>
                <div>
                  {{$t('components.meetingTime.recording')}}
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <font-awesome-icon
                         v-bind="props"
                        color="primary"
                        class="ml-1"
                        :icon="['fal', 'info-circle']"
                        :style="{}"
                      />
                    </template>
                      <span>{{
                        $t('components.meetingTime.recordingText')
                      }}</span>
                    </v-tooltip>
                </div>
                <div class="mb-2 pl-3">
                  <v-switch class="mt-0"
                    v-model="recording"
                    :label="$t('components.meetingTime.turnOn')"
                    hide-details
                  ></v-switch>
                </div>
              </div>
              <div class="ml-12">
                <div>
                  {{$t('components.meetingTime.transcript')}}
                  <!-- <v-tooltip location="top">
                    <template v-slot:activator="{ on }">
                      <font-awesome-icon
                        v-on="on"
                        color="primary"
                        class="ml-1"
                        :icon="['fal', 'info-circle']"
                        :style="{}"
                      />
                    </template>
                      <span>{{
                        $t('components.meetingTime.recordingText')
                      }}</span>
                    </v-tooltip> -->
                </div>
                <div class="mb-2">
                  <v-switch class="mt-0"
                    v-model="transcript"
                    :label="$t('components.meetingTime.turnOn')"
                    hide-details
                  ></v-switch>
                </div>
                <div v-if="transcript && state.namespaceSettings.confidentialAiEndpoint" style="margin-top: -30px">
                  <v-switch class="mt-0"
                    v-model="privateTranscript"
                    :label="$t('components.meetingTime.isPrivate')"
                    hide-details
                  ></v-switch>
                </div>
              </div>
            </v-col>
            <!-- Documents begin -->
            <v-col cols="12" class="pb-1 pt-0">
              <v-list class="py-0">
                <v-list-group
                  :append-icon="null"
                  no-action
                  class="scheculedConf"
                >
                  <template v-slot:activator>
                    <div class="">
                      <v-list-item-title>
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              v-bind="props"
                              @click="toggleDocumentsView()"
                              :disabled="!canEditEvent"
                              class="justStart no-bg list"
                              variant="text"
                            >
                              <font-awesome-icon
                                :icon="['fal', 'file-pdf']"
                                :style="{ fontSize: '18px' }"
                              />
                              <span class="ml-3">{{
                                $t("components.conferenceForm.addpdfConference")
                              }}</span>
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.conferenceForm.ttDocuments")
                          }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </div>
                  </template>
                </v-list-group>
              </v-list>
            </v-col>
            <!-- Documents end -->
            <v-col :cols="isMobile ? 12 : 12" class="pb-1 pt-0">
              <v-list :opened="expandReocurring" @update:opened="newOpened => expandReocurring = newOpened" class="py-0">
                <v-list-group
                  value="reocurringOpened"
                  class="scheculedConf"
                >
                  <template v-slot:activator>
                    <div class="py-0">
                      <v-list-item-title>
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              :disabled="!canEditEvent"
                              v-bind="props"
                              class="justStart no-bg list"
                              variant="text"
                              @click="removeScheduledConferences(expandReocurring)"
                            >
                              <font-awesome-icon
                                :icon="['fal', 'copy']"
                                :style="{ fontSize: '18px' }"
                              />
                              <span class="ml-3">{{
                                $t(
                                  "components.addConferenceModal.confFrequency"
                                )
                              }}</span>
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.conferenceForm.ttReoccurring")
                          }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </div>
                  </template>
                  <v-list-item class="pl-0 w-90">
                    <div class="d-flex flex-wrap w100 align-center">
                      <div class="">
                        <v-radio-group
                          :model-value="scheduledInterval"
                          class="mt-1"
                          @update:model-value="scheduledInterval = $event;confFrequencyUpdated()"
                        >
                          <v-radio
                            class="ml-3"
                            :label="$t('components.addConferenceModal.confDaily')"
                            value="d"
                          ></v-radio>
                          <v-radio
                            class="ml-3"
                            :label="
                              $t('components.addConferenceModal.confWeekly')
                            "
                            value="w"
                          ></v-radio>
                          <v-radio
                            class="ml-3"
                            :label="
                              $t('components.addConferenceModal.confMonthly')
                            "
                            value="m"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="ml-6" styles="min-width: 150px;">
                        <v-text-field
                          v-if="scheduledInterval && scheduledInterval !== ''"
                          class=""
                          clearable
                          dense
                          :label="getFrequencyText"
                          v-model="confFrequency"
                          :disabled="!canEditEvent"
                          type="number"
                          min="-1"
                          max="365"
                          step="1"
                          hide-details
                          outlined
                          @keypress="checkCodeEntered($event)"
                          @keyup="checkFrequencyValue($event)"
                          @click:clear="removeScheduledConferences()"
                        ></v-text-field>
                      </div>
                    </div>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-col>
          </v-row>
          <v-row v-if="canEditEvent && isEditingConference">
            <v-col cols="6" class="pb-1 pt-0">
              <v-btn
                :disabled="!canEditEvent"
                class="justStart no-bg list"
                @click="doResendInviteToAll"
                variant="text"
              >
                <font-awesome-icon
                  :icon="['fal', 'redo']"
                  :style="{ fontSize: '18px' }"
                />
                <span class="ml-3">{{
                  $t("components.conferenceForm.resendInviteAll")
                }}</span>
              </v-btn>
            </v-col>
            <v-col cols="6" class="pb-1 pt-0">
              <v-btn
                class="justStart no-bg list"
                @click="doSendMessageToAll(conferenceToEdit.confId)"
                variant="text"
                :disabled="false"
              >
                <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '18px' }"
                />
                <span class="ml-3">{{
                  $t("components.conferenceForm.sendMessageAll")
                }}</span>
              </v-btn>
            </v-col>
            <v-col cols="6" class="pb-1 pt-0">
              <v-tooltip location="top" max-width="210">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    class="justStart no-bg list"
                    @click="callEveryone(true, null)"
                    variant="text"
                    :disabled="!canEditEvent"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'loveseat']"
                      :style="{ fontSize: '18px' }"
                    />
                    <span class="ml-3">{{
                      $t("components.conferenceForm.sendConfToWaitingRoom")
                    }}</span>
                  </v-btn>
                </template>
                <span
                  >{{ $t("components.conferenceForm.sendConfToWaitingRoomTT") }}
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="6" class="pb-1 pt-0">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    class="justStart no-bg list deleteBasketPosition"
                    :class="
                      !deleteActive
                        ? 'deleteBasketButtonNormal'
                        : 'deleteBasketButtonActive'
                    "
                    @click="deleteConferenceSafely($event, conferenceToEdit.confId)"
                    variant="text"
                    :disabled="!canEditEvent"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      :style="{ fontSize: '18px' }"
                    />
                    <span class="ml-3">{{
                      $t("components.conferenceForm.deleteConference")
                    }}</span>
                  </v-btn>
                </template>
                <span>{{
                  $t("components.conferenceForm.confirmRemove")
                }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="isMobile ? 12 : 5" :md="isMobile ? 12 : 7" class="py-1 userList" v-if="!isPreviewingEmail">
          <div
            class="no-gutters flex-fill d-flex rowUsersConference"
            :class="{
              justifyRight:
                getConferenceMembers && getConferenceMembers.length <= 4,
            }"
          >
              <!-- style="border: 4px solid #fff" -->
            <div class="d-flex flex-wrap w100" v-if="getConferenceMembers.length <= 4">
              <v-col
                class="pr-2 pt-2 pl-0 pb-0"
                :cols="getCols"
                v-for="user in getConferenceMembers"
                :key="user.uuid"
                :class="`${isMobile ? null : getWidth} ${
                  getConferenceMembers.length == 3 ? 'mx-auto' : ''
                }`"
              >
                <ListConferencesUsers
                  :isEditingConference="isEditingConference"
                  :deleteUser="deleteUser"
                  :user="user"
                  :toggleModerator="toggleModerator"
                  :groupModerator="conferenceModerator"
                  :canEditEvent="canEditEvent"
                  :conference="conferenceToEdit"
                  :isConferenceUser="true"
                  :isCardStyle="true"
                  :createGuestInvite="createGuestInvite"
                  :createUserInvite="createUserInvite"
                  :createUserReminderInvite="createUserReminderInvite"
                  :createGuestReminderInvite="createGuestReminderInvite"
                />
              </v-col>
            </div>
            <div class="w-100" v-if="getConferenceMembers.length > 4">
              <div
                class="userDiv"
                v-for="user in getConferenceMembers"
                :key="user.uuid"
              >
                <ListConferencesUsers
                  :isEditingConference="isEditingConference"
                  :deleteUser="deleteUser"
                  :user="user"
                  :toggleModerator="toggleModerator"
                  :groupModerator="conferenceModerator"
                  :canEditEvent="canEditEvent"
                  :conference="conferenceToEdit"
                  :isConferenceUser="true"
                  :isCardStyle="false"
                  :createGuestInvite="createGuestInvite"
                  :createUserInvite="createUserInvite"
                  :createUserReminderInvite="createUserReminderInvite"
                  :createGuestReminderInvite="createGuestReminderInvite"
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          class="mx-0 py-0 my-12"
          cols="12"
          md="12"
          v-if="isPreviewingEmail"
        >
          <span
            class="text-primary mt-10 mb-6 px-4"
            style="text-transform: uppercase; font-weight: 400"
            >{{ $t("components.conferenceForm.emailPreview") }}</span
          >
          <v-divider class="pb-3 mt-3"></v-divider>
          <span
            class="text-primary mt-10 mb-6 px-4"
            style="font-size: 13px; text-transform: uppercase; font-weight: 400"
            >{{ $t("components.invitePreview.invitePreviewForUser") }}</span
          >
          <v-card elevation="2" outlined>
            <v-card-text>
              <v-row align="center" class="mx-0">
                <v-col
                  class="text-grey ml-4 cols"
                  cols="12"
                  md="12"
                  v-html="$sanitize(userInvText)"
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider class="pb-3 mt-3"></v-divider>
          <span
            class="text-primary mt-10 mb-6 px-4"
            style="font-size: 13px; text-transform: uppercase; font-weight: 400"
            >{{ $t("components.invitePreview.invitePreviewForGuest") }}</span
          >
          <v-card elevation="2" outlined>
            <v-card-text>
              <v-row align="center" class="mx-0">
                <v-col
                  class="text-grey ml-4 cols"
                  cols="12"
                  md="12"
                  v-html="$sanitize(guestInvText)"
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!--  Buttons -->
      <v-row class="mx-0">
        <v-col cols="12" :class="`${isMobile ? 'text-center' : 'text-right'} pl-0`">
          <v-divider class="pb-4"></v-divider>

          <v-tooltip location="top" max-width="210">
            <template v-slot:activator="{ props }">
              <v-btn
                v-show="isPreviewingEmail && canEditEvent  && isNameValid"
                class="ml-2"
                v-bind="props"
                @click="toggleEmailPreview()"
                flat
                color="primary"
                >{{
                     $t("components.conferenceForm.back")
                }}</v-btn
              >
            </template>
            <span>{{ $t("components.conferenceForm.backTT") }} </span>
          </v-tooltip>

          <v-tooltip location="top" max-width="210" v-if="canEditEvent">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                :class="`${isMobile ? 'mb-2' : null} ml-2`"
                :disabled="!isNameValid || amInACall || !checkPin()"
                @click="callNow()"
                flat
                color="primary"
                >
                {{ $t("components.conferenceForm.startConference") }}
                </v-btn>
            </template>
            <span>
              {{ $t("components.conferenceForm.startConferenceTT") }}
            </span>
          </v-tooltip>

          <v-tooltip location="top" max-width="210">
            <template v-slot:activator="{ props }">
              <v-btn
                class="ml-2"
                v-bind="props"
                @click="toggleEmailPreview()"
                flat
                color="primary"
                v-show="!isPreviewingEmail && canEditEvent && isNameValid"
                >{{
                     $t("components.conferenceForm.emailPreview")
                }}</v-btn
              >
            </template>
            <span>{{ $t("components.conferenceForm.emailPreview") }} </span>
          </v-tooltip>

          <v-btn
            class="ml-2"
            v-if="!isEditingConference"
            @click="saveConference()"
            flat
            color="primary"
            :disabled="disableSaveButton"
            >{{ $t("components.conferenceForm.groupSave") }}
            </v-btn>

          <!-- <p>{{disableSaveButton}} + " - " + {{canEditEvent}} + " - "+ {{isEditingConference}}</p>  -->
          <v-tooltip location="top" max-width="210" v-if="isEditingConference && canEditEvent">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                class="ml-2"
                :disabled="!canEditEvent || disableSaveButton"
                @click="doUpdateConference"
                flat
                color="primary"
                >{{ $t("components.footer.doUpdate") }}</v-btn
              >
            </template>
            <span>{{ $t("components.footer.doUpdateTT") }} </span>
          </v-tooltip>

          <v-tooltip location="top" v-if="isEditingConference && canEditEvent">
            <template v-slot:activator="{ props }">
              <v-btn
                class="ml-2"
                v-bind="props"
                @click="callEveryone(false, null)"
                flat
                color="primary"
                :disabled="disableCallConference || amInACall"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span
              >{{ $t("components.conferenceForm.startConferencePlanetTT") }}
            </span>
          </v-tooltip>

          <v-tooltip location="top" max-width="210">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                class="ml-2 borderRadius-4"
                style="height: 36px"
                @click="goBackToGrid(true)"
                icon
                variant="outlined"
                small
                fab
                color="primary"
                density="comfortable"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.close") }} </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <!-- End buttons -->
      <!-- Modals -->
      <div v-if="showAddGuestModal">
        <AddGuestModal
          :addMember="addMember"
          :members="conferenceMembers"
          :isConference="true"
          :showModal="showAddGuestModal"
          :closeModal="closeGuestModal"
          :conferenceId="getConferenceId"
        />
      </div>
      <div v-if="showCroppie">
        <UploadGroupConfImg
          :confOrGroupId="conferenceId"
          :uploadConferenceImage="uploadConferenceImage"
          :showCroppie="showCroppie"
          :temporalImage="temporalImage"
          :resetInputImg="resetConferenceImageInput"
          :closeCroppie="openCloseCroppie"
          :isConference="true"
        />
      </div>
      <DocumentsManager
        v-if="showConferenceDocumentsModal"
        :setTempDocStore="setTempDocStore"
        eventType="conference"
        :documentsStore="conferenceStore"
        :showModal="showConferenceDocumentsModal"
        :amIModerator="canEditEvent"
      />
      <confirmLeave
        v-if="showConfrimModal"
        :getDiff="getDiff"
        :showConfrimModal="showConfrimModal"
        :stayInPage="stayInPage"
        :leavePage="leavePage"
      />
      <!-- End modals -->
    </div>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import { v4 as uuidv4 } from "uuid";
import moment from "../../../sharedsrc/moment";
import store, { EventBus } from "../../store";
import DocumentsManager from "../../components/documents/documentsMgr.vue";
import {
  timelineEvents,
  updateTimelineItemEvent,
  deleteTimelineItemEvent,
  createTimelineEntryEffect,
  dispatchRsvpTimelineEntry,
  dispatchFetchMyItemsForDateEvent,
  resetAndRefetchDatesEvent
} from "../../effector/timeline";
import {
  myCompanyDocumentsDetails,
  newCompanyDocumentEvent,
  updateCompanyDocumentEvent,
  dispatchDeleteCompanyDocumentEvent,
} from "../../effector/companyDocuments";
import { markAsCallWaitingRoomUserEvent } from "../../effector/users/waitingInfo";
import { uploadConferenceImage, setRemoteUser, sendMailConferenceCall } from "../../lib/wsMsg";
import { hasPrivilege } from "../../utils/privileges";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../effector/alerts";
import { getTimezone, uid, getOrganigramTeams } from "../../utils/basicFunctions";
import { isMobile } from "../../lib/mobileUtil";
import {
  sendVisitorEmail,
  sendUserEmail,
  getInviteTextForGuest,
  getInviteTextForUser,
  getInviteTextForRem,
  getInviteTextForCancellation,
  getInviteTextGuestChange,
  getInviteTextUserChange,
} from "../../lib/inviteHelpers";
import {
  prepadeDataToSendMessageEffector,
  newMessageEvent,
} from "../../effector/message";
import {
  setSendMessageModalEvent,
  conferenceDocumentsStore,
  setConferenceDocumentsEvent
} from "../../effector/modals";
import ConferenceInfoTooltip from "./conferenceInfoTooltip.vue";
import ConferenceCardActions from "./conferenceCardActions.vue";
import ConferenceDateTimeOverlay from "./conferenceDateTimeOverlay.vue";
import {
  wsCallStartBridgeCall,
  amInABridgeCall,
   disableCallBtn,
   personIsInBridgeCall,
   userInStaticRoom
} from "../../utils/calls";
import {
  dispatchDeleteGroupConferenceEvent,
  myGroupConferenceStore,
  newGroupConferenceEvent,
  myConferenceDetails,
  updateGroupConferenceEvent,
} from "../../effector/groupConferences";
import ListConferencesUsers from "./listConferencesUsers.vue";
import AddGuestModal from "../modal/groups-conferences/addGuestModal.vue";
import DeleteBasket from "../ui/deleteBasket.vue";
import confirmLeave from "./conferenceDataConfirmDialog.vue";
import UploadGroupConfImg from "./uploadGroupConfImg.vue";
import { parallel } from "../../lib/asyncUtil";
import { uuidToRepresentStore } from '../../effector/representative';
import InfiniteLoading from "v3-infinite-loading";

export default {
  props: [
    "removeGeneralWrapper",
    "existingConference",
    "groupFromConference",
    "sendMailToAll",
    "justSendMailToAll",
    "callGroup",
    "updateGroup",
    "saveGroup",
    "sendUserMessageParent",
    "currentTab"
  ],
  components: {
    ListConferencesUsers,
    AddGuestModal,
    DeleteBasket,
    ConferenceInfoTooltip,
    ConferenceCardActions,
    ConferenceDateTimeOverlay,
    UploadGroupConfImg,
    DocumentsManager,
    confirmLeave,
    "infinite-loading": InfiniteLoading,
  },
  data() {
    const effector = {
      myConferences: myGroupConferenceStore,
      myConferenceDetails: myConferenceDetails,
      timelineEvents: timelineEvents,
      myDocuments: myCompanyDocumentsDetails,
      showConferenceDocumentsModal: conferenceDocumentsStore,
      uuidToRepresentStore: uuidToRepresentStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      isMobile: isMobile(),
      state: store.state,
      showUserProfile: false,
      viewingConferences: true,
      conferenceToEdit: null,
      scheduledInterval: null,
      confFrequency: 0,
      conferenceModerator: store.state.ownUUID,
      sliceIndex: 48,
      isNotMyConference: false,
      isEditingConference: false,
      isInserting: false,
      isDuplicating: false,
      complexPinActive: false,
      complexPinRegex:
        /^(?=.*[0-9])(?=.*[a-zñöäü])(?=.*[A-ZÖÄÜÑ])(?=.*[*.!@$%^&(){}[\]\/:;<>,.?~_\+\-\=|]).{12,}$/,
      pinCode: "",
      startDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      durationMin: null,
      startEvent: "",
      endEvent: "",
      timeStart: null,
      timeEnd: null,
      time1: null,
      time2: null,
      conferenceName: "",
      conferenceId: uuidv4(),
      conferenceDescription: "",
      conferenceMembers: [],
      totalMembers: 0,
      conferenceImage: store.state.namespaceSettings.companyLogo
        ? store.state.namespaceSettings.companyLogo
        : "/img/icon.png",
      conferenceTime: null,
      conferenceDate: null,
      selectedDropDowItem: [],
      allUsers: {},
      allTeams: {},
      allGroups: [],
      showAddGuestModal: false,
      searchInput: null,
      isUnlimited: false,
      noticeText: "",
      deleteActive: false,
      canEdit: false,
      rsvpValue: null,
      maxFrequency: 0,
      showCroppie: false,
      temporalImage: "",
      isEditingTemplate: false,
      moment: moment,
      deleteTemplateActive: false,
      pdfDocuments: [],
      tempDocumentStore: null,
      personalizedGreeting: "",
      isPreviewingEmail: false,
      uInviteText: "",
      gInviteText: "",
      initSnapShot: {},
      postSnapShot: {},
      ignoreDirty: false,
      showConfirmation: false,
      confrimFrom: false,
      isChanging: false,
      tobeNotifiedDeleteUser: [],
      recording: false,
      transcript: false,
      privateTranscript: false,
      expandReocurring: [],
      forceExit: false,
      totalParticipants: 0,
      // Effector
      ...effector,
    };
  },
  watch: {
    conferenceToEdit: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.isInserting = false;
          this.isDuplicating = false;
          this.loadExistingConference();
          this.isEditingConference = true;
          this.getInitSnapShot();
        } else {
          if (!this.isDuplicating) {
            this.resetForm();
          }
        }
      },
    },
    durationMin() {
      setImmediate(() => {
        if (this.durationMin == null) return;
        if (typeof this.durationMin !== "number" && this.durationMin !== "") {
          this.durationMin = parseInt(this.durationMin, 10);
        } else if (this.durationMin > 1440 && this.durationMin !== "") {
          this.durationMin = 1440;
        } else if (this.durationMin < 1 && this.durationMin !== "") {
          this.durationMin = 0;
        } else {
          if (this.durationMin !== "" && typeof this.durationMin === "number") {
            this.recalcEnd();
          }
        }
      });
    },
    conferenceName(newValue, oldValue) {},
    conferenceDescription(newValue, oldValue) {
      if (newValue !== oldValue) {
      }
    },
    personalizedGreeting(newValue, oldValue) {
      if (newValue !== oldValue) {
      }
    },
    viewingConferences(value) {
      if (value) return;
      this.allUsers = JSON.parse(JSON.stringify(this.state.group || {}));
      this.allTeams = JSON.parse(
        JSON.stringify(
          // this.state.namespaceSettings.processedOrganisation.teams || {}
          this.getOrganigramTeams
        )
      );
      this.allGroups = JSON.parse(JSON.stringify(this.state.user.groups || []));
    },
    startEvent() {
      this.startChanged();
    },
    timeStart() {
      this.recalcEnd(false);
      this.startChanged();
    },
    endEvent() {
      this.endChanged();
    },
    timeEnd() {
      this.endChanged();
    },
  },
  beforeMount(){
    EventBus.$on("loadInserting", this.clickOnAddCard);
    EventBus.$on("loadTemplate", this.loadTemplateMethod);
  },
  mounted() {
    EventBus.$on("loadInsertingTodayNormal", this.loadInsertingTodayMethod);
    EventBus.$on("loadMainConference", this.goBackToGrid);
    //clickOnAddCard
    let extant = undefined;
    if ( this.$router.params?.id) {
      extant = this.myConferenceDetails.find(
        (e) => e.confId ===  this.$router.params?.id
      );
      if (extant) {
        this.editConference(extant);
        this.$router.push({ path: "/newconference/" });
      } else if ( this.$router.params?.id.substring(0,3) == "00-") {
        // let data =  this.$router.params?.id.split("-");
        // if (data[1] !== 'form'){
        //   this.clickOnAddCard(data);
        // } else if(data[1] === 'form'){
        //   this.clickOnAddCard();
        // }
      } else if (
         this.$router.params?.id.indexOf("template:") !== -1
      ) {
        const groupId =  this.$router.params?.id.split(":")[1];
        const extant = store.state.user.groups.find(
          (e) => e.groupId === groupId
        );
        if (extant) {
          this.$router.push({ path: "/newconference/" });
          this.viewingConferences = false;
          this.changeGroupCombo(extant);
          this.isEditingTemplate = extant.groupId;
        }
      }
    }
    if (!this.isEditingConference) {
      this.populateDefaultModerator();
    }
  },
  unmounted() {
    this.tobeNotifiedDeleteUser = [];
    EventBus.$emit("emitTotalInvitedParticipants", 0);
    EventBus.$off("loadInserting", this.clickOnAddCard);
    EventBus.$off("loadTemplate", this.loadTemplateMethod);
    EventBus.$off("loadInsertingTodayNormal", this.loadInsertingTodayMethod);
    EventBus.$off("loadMainConference", this.goBackToGrid);
  },
  computed: {
    haveCalls() {
      return this.state.user.inBridgeCall && Object.keys(this.state.remoteBridgeStreams).length;
    },
    minTimeEndPicker() {
      const today = new Date();
      const someDate = new Date(this.startEvent);
      if (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      ) {
        const minTime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(11, 16);
        return this.timeStart > minTime ? this.timeStart : minTime;
      }
      return this.timeStart || "00:00";
    },
    showJoinConferenceButton(){
      if(!this.getConference) return false;
      // if(this.getConference?.confOwner !== this.state.ownUUID) return false
      const conferenceMemberArray = this.conferenceMembers.map(user=>{
        return user.uuid
      })
      const userInConferenceCall = conferenceMemberArray.filter(uuid=>{
        const getCallInfo = this.state.group[uuid]?.user?.bridgeCallInfo || undefined;
        const getCallUUID = (this.state.group[uuid]?.user?.inBridgeCall || "").toString();
        return getCallInfo && !getCallInfo.conferenceHall && getCallInfo.isConferenceCall && getCallInfo.confId === this.getConference?.confId?.toString() && getCallUUID;
      })
      const amIInThisConference = conferenceMemberArray.find(uuid => uuid == this.state.ownUUID);
      if(userInConferenceCall.length && amIInThisConference){
        return true
      }else{
        return false
      }
    },
    getMyConferenceDetailsConfIsTemp(){
      const finalResult = this.myConferenceDetails.filter(
        (e) => !e.confIsTemp
      );
      return this.sortConferences(finalResult);
    },
    confHasEnded() {
      let hasEnded = false;
      if (this.conferenceToEdit) {
      let confId = this.conferenceToEdit.confId || 0;
      const extantEv = this.timelineEvents.find((e) => e.confId === confId);
      if (extantEv && extantEv.end) {
        const orgEnd = moment(new Date(extantEv.end));
        const elapsedMins = moment().diff(orgEnd, "minutes");
        if (elapsedMins > 2) {
          hasEnded = !extantEv.isReOcurring;
        }
      }
      } else {
        hasEnded = false;
      }
      return hasEnded;
    },
    getDurationMin() {
      if (
        !this.durationMin ||
        this.durationMin.length <= 0 ||
        this.durationMin <= 0
      ) {
        return null;
      } else {
        return this.durationMin;
      }
    },
    isNameValid() {
      if (
        this.isTemplate &&
        typeof this.conferenceName === "object" &&
        this.conferenceName !== null
      ) {
        return true;
      } else {
        return (this.conferenceName || " ").trim().length > 0;
      }
    },
    isDirty() {
      if (this.ignoreDirty) {
        return false;
      }
      return (
        JSON.stringify(this.initSnapShot) !== JSON.stringify(this.postSnapShot)
      );
    },
    showConfrimModal() {
      if(this.showConfirmation && this.forceExit){
          return true;
      }
      return this.showConfirmation && !this.haveCalls;
    },
    userInvText() {
      return this.uInviteText;
    },
    guestInvText() {
      return this.gInviteText;
    },
    conferenceStore() {
      let docStore = null;
      const foundStore = this.myDocuments.find(
        (e) => e.confId === this.conferenceId
      );
      if (foundStore && foundStore.confId) {
        docStore = foundStore;
        return docStore;
      } else if (this.tempDocumentStore) {
        docStore = this.tempDocumentStore;
        return docStore;
      } else {
        return docStore;
      }
    },
    isTemplate() {
      return typeof this.conferenceName == "object";
    },
    canSaveGroup() {
      if (
        typeof this.conferenceName == "string" &&
        this.conferenceName !== ""
      ) {
        return true;
      } else if (
        typeof this.conferenceName == "object" &&
        this.conferenceName &&
        this.conferenceName.groupName !== ""
      ) {
        return true;
      }
      return false;
    },
    getUserGroups() {
      let myGroups = store.state.user.groups || [];
      return myGroups.filter( (e) => e.conferenceHall === false || undefined);
    },
    getCols() {
      if (this.getConferenceMembers.length <= 4) {
        return 6;
      } else if (
        this.getConferenceMembers.length > 4 &&
        this.getConferenceMembers.length <= 15
      ) {
        return 4;
      } else if (this.getConferenceMembers.length > 15) {
        return 3;
      }
    },
    getWidth() {
      if (this.getConferenceMembers.length <= 4) {
        return "maxWidth35";
      } else if (
        this.getConferenceMembers.length > 4 &&
        this.getConferenceMembers.length <= 9
      ) {
        return "maxWidth33";
      } else if (
        this.getConferenceMembers.length > 9 &&
        this.getConferenceMembers.length <= 12
      ) {
        return "maxWidth25";
      } else if (
        this.getConferenceMembers.length > 12 &&
        this.getConferenceMembers.length <= 15
      ) {
        return "maxWidth20";
      } else if (
        this.getConferenceMembers.length > 15 &&
        this.getConferenceMembers.length <= 16
      ) {
        return "maxWidth25";
      } else if (this.getConferenceMembers.length > 16) {
        return "maxWidth20";
      }
    },
    isReOcurring() {
      if (!this.scheduledInterval || this.scheduledInterval == "") {
        return false;
      } else {
        return true;
      }
    },
    isDevelopment() {
      return webLicensedBaseFeatures.isDev;
    },
    getFrequencyText() {
      let messg = "";
      switch (this.scheduledInterval?.value || this.scheduledInterval) {
        case "d":
          messg =
            this.confFrequency && this.confFrequency > 1
              ? this.$t("components.addConferenceModal.days")
              : this.$t("components.addConferenceModal.day");
          break;
        case "w":
          messg =
            this.confFrequency && this.confFrequency > 1
              ? this.$t("components.addConferenceModal.weeks")
              : this.$t("components.addConferenceModal.week");
          break;
        case "m":
          messg =
            this.confFrequency && this.confFrequency > 1
              ? this.$t("components.addConferenceModal.months")
              : this.$t("components.addConferenceModal.month");
          break;
        default:
          messg = "";
          break;
      }
      return messg;
    },
    getConferenceId() {
      return this.conferenceId;
    },
    isMod(){
      if (this.conferenceToEdit) {
        if (this.conferenceToEdit.confModerators.indexOf(store.state.ownUUID) !== -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    canEditEvent() {
      if (this.isInserting || this.isDuplicating) return true;
      if (this.conferenceToEdit) {
        if (this.conferenceToEdit.confModerators.indexOf(store.state.ownUUID) !== -1 && !this.confHasEnded ) {
          return true;
        } else if(this.uuidToRepresentStore && this.conferenceToEdit.confModerators.indexOf(this.uuidToRepresentStore) !== -1 && !this.confHasEnded){
          return true;
        } else {
          return false;
        }
      }
    },
    conferenceIsValid() {
      if (this.conferenceName) {
        if (
          Object.prototype.hasOwnProperty.call(this.conferenceName, "groupName")
        ) {
          return this.conferenceName.groupName.trim().length > 0;
        } else {
          return this.conferenceName.trim().length > 0;
        }
      } else {
        return false;
      }
    },
    formatedStart() {
      if (!this.startEvent) {
        return null;
      }
      return this.moment(new Date(this.startEvent)).format("DD.MM.YYYY");
    },
    isConferenceFull() {
      return this.conferenceMembers.length >= 100;
    },
    getTotalInvited() {
      this.totalMembers = 0;
      this.conferenceMembers.forEach((user) => {
        if (user.isTeam || user.isGroup) {
          this.totalMembers = this.totalMembers + user.memberCount;
        } else {
          this.totalMembers = this.totalMembers + 1;
        }
      });
      // if (!this.viewingConferences) {
      //   EventBus.$emit('emitTotalInvitedParticipants', this.totalMembers);
      // } else {
      //   EventBus.$emit('emitTotalInvitedParticipants', 0);
      // }
      return this.totalMembers;
    },
    disableSaveButton() {
      return (
        !this.conferenceName ||
        !this.startEvent ||
        this.timeStart == null ||
        this.timeEnd == null ||
        (this.timeEnd && (this.durationMin == 0 || !this.durationMin)) ||
        (this.isReOcurring &&
          (isNaN(this.confFrequency) || this.confFrequency == 0)) ||
        (this.isReOcurring && !this.scheduledInterval)
        || !this.checkPin()
      );
    },
    disableCallConference() {
      return this.conferenceMembers.length < 1;
    },
    fillDropDown() {
      if (
        !this.conferenceToEdit &&
        (!this.isInserting || this.isEditingConference)
      )
        return;
      let jumpRec = false;
      let result;
      const users = this.allUsers;
      const tmpUsersArray = [];
      const tmpVisitorsArray = [];
      const tmpUserTeamsArray = [];
      // get team members
      let data;
      for (const key in users) {
        data = {
          name: (users[key].user || {}).name || "",
          uuid: key,
          avatar:
            (users[key].user || {}).avatar || "img/default_profile_picture.png",
        };
        if (tmpUsersArray.length === 0) {
          tmpUsersArray.push({
            header: this.$t("components.addConferenceModal.users"),
          });
        }
        if (!users[key].user.visitor && key !== store.state.ownUUID) {
          data.group = "Users";
          tmpUsersArray.push(data);
        } else if (
          store.state.persisted.userVisitors[key] &&
          users[key].user.visitor &&
          users[key].user.visitorData.userInviter === this.state.ownUUID
        ) {
          data.group = "Visitors";
          tmpVisitorsArray.push(data);
        }
      }

      if (tmpUsersArray.length > 0) {
        if (tmpVisitorsArray.length > 0) {
          tmpUsersArray.push({ divider: true });
          tmpUsersArray.push({
            header: this.$t("components.addConferenceModal.visitors"),
          });
          tmpUsersArray.sort((a, b) => {
            if (a.name && b.name) {
              let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
            }
            return 0;
          });
          tmpVisitorsArray.sort((a, b) => {
            if (a.name && b.name) {
              let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
            }
            return 0;
          });
          Array.prototype.push.apply(tmpUsersArray, tmpVisitorsArray);
        }
        for (const key in this.allTeams) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.allTeams && this.allTeams.hasOwnProperty(key)) {
            const team = this.allTeams[key];
            if (this.isEditingConference) {
              if (
                this.conferenceToEdit.members.findIndex(
                  (e) => e.name === team.name
                ) !== -1
              ) {
                jumpRec = true;
              }
            }
            if (!jumpRec) {
              const tmpTeamObj = {
                name: team.name,
                members: team.members,
                group: "Teams",
                avatar: "img/default_profile_picture.png",
              };
              tmpUserTeamsArray.push(tmpTeamObj);
            } else {
              jumpRec = false;
            }
          }
        }
        if (tmpUserTeamsArray.length > 0) {
          tmpUsersArray.push({ divider: true });
          tmpUsersArray.push({
            header: this.$t("components.addConferenceModal.teams"),
          });
          tmpUserTeamsArray.sort((a, b) => {
            if (a.name && b.name) {
              let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
            }
            return 0;
          });
          Array.prototype.push.apply(tmpUsersArray, tmpUserTeamsArray);
        }
        result = tmpUsersArray;
      }
      if (!this.isConferenceFull) {
        return result;
      } else {
        return [];
      }
    },
    getConferenceMembers() {
      return this.conferenceMembers;
    },
    getConference() {
      return this.conferenceToEdit;
    },
    amInACall() {
      return amInABridgeCall();
    },
    getOrganigramTeams() {
      const organigram = JSON.parse(
          JSON.stringify(this.state.namespaceSettings.newOrganigram || [])
        );
      const organigramTeams = getOrganigramTeams(organigram);
      // Map through the array to add 'members' array constructed from 'children.uuid'
      const updatedArray = organigramTeams.map(item => ({
        ...item,
        members: item.children.map(child => child.uuid)
      }));
      return updatedArray
    }
  },
  methods: {
    sortConferences(conferenceList) {
      const now = new Date();

      // Function to parse the date and time strings into a Date object
      function parseDateTime(dateStr, timeStr) {
        const [year, month, day] = dateStr.split('-').map(Number);
        const [hour, minute] = timeStr.split(':').map(Number);
        return new Date(year, month - 1, day, hour, minute, 0, 0);
      }

      conferenceList.sort((a, b) => {
        const dateA = parseDateTime(a.confStartDate, a.confStartTime);
        const dateB = parseDateTime(b.confStartDate, b.confStartTime);

        const isPastA = dateA < now;
        const isPastB = dateB < now;
        const isTodayA = dateA.toDateString() === now.toDateString();
        const isTodayB = dateB.toDateString() === now.toDateString();

        if (isPastA && isPastB) {
          // Both are past: order by date ascending (oldest first)
          return dateA - dateB;
        } else if (isPastA) {
          // A is past and should come first
          return -1;
        } else if (isPastB) {
          // B is past and should come second
          return 1;
        } else if (isTodayA && isTodayB) {
          // Both are today: order by start time
          return dateA - dateB;
        } else {
          // All future dates or one today and one future: order by full date
          return dateA - dateB;
        }
      });

      return conferenceList;
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    joinToConference(){
      const conferenceMemberArray = this.conferenceMembers.map(user=>{
        return user.uuid
      })
      const userInConferenceCall = conferenceMemberArray.filter(uuid=>{
        const getCallInfo = this.state.group[uuid]?.user?.bridgeCallInfo || undefined;
        const getCallUUID = (this.state.group[uuid]?.user?.inBridgeCall || "").toString();
        return getCallInfo && !getCallInfo.conferenceHall && getCallInfo.isConferenceCall && getCallInfo.confId === this.getConference?.confId?.toString() && getCallUUID;
      })
      if(userInConferenceCall.length>0){
        const usersUuid = userInConferenceCall[0]
        // condition to know if the conference is in process and get the info and get inside the call as a listener
        const getCallInfo = this.state.group[usersUuid]?.user?.bridgeCallInfo || undefined;
        if(getCallInfo) {
          // there is a conference call working which the user is part of it but user doesn't be inside atm
          const userData = getCallInfo?.conferenceAttendees.find(e => e.uuid == this.state.ownUUID);
          const getCallUUID = (this.state.group[usersUuid]?.user?.inBridgeCall || "").toString();
          if(userData && getCallUUID) {
            store.addRemoteBridgeStream(getCallUUID, { ...getCallInfo, ...getCallInfo.infoCall, isAudioOnly: true, calling: [] })
            // store.setInBridgeCall(getCallUUID)
            // store.setRemoteBridgeStream(getCallUUID, { ...getCallInfo, ...getCallInfo.infoCall, isAudioOnly: true, calling: [] });
            store.setCurrentContentVisile("", false, this.$router);
          }
        }
      }
    },
    loadTemplateMethod(data){
      EventBus.$emit("loadInserting", data.id);
      this.doLoadTemplate(data.id);
    },
    loadInsertingTodayMethod(date = null){
      if(this.currentTab === 'normalConference') {
        // const finalData = [ '00', date]
        this.clickOnAddCard(date);
      }
    },
    sendMailConferenceCallOfflineUser(users, options ){
      let userOffline = users.filter((uuid)=>uuid!==this.state.ownUUID &&  (disableCallBtn(store.state.group[uuid]) || personIsInBridgeCall(uuid)))
      if(userOffline && userOffline.length>0){
        sendMailConferenceCall(userOffline, options)
      }
    },
    getDiff() {
      return this.showMeDaMoney(this.initSnapShot, this.postSnapShot);
    },
    stayInPage() {
      this.showConfirmation = false;
    },
    resetSnapShots() {
      this.initSnapShot = {};
      this.postSnapShot = {};
      this.ignoreDirty = true;
    },
    async leavePage() {
      // clean up snapshots
      await this.resetSnapShots();
      this.showConfirmation = false;
      if (this.confrimFrom == "goBackToGrid") {
        this.confrimFrom = false;
        this.goBackToGrid();
      } else {
        let whereTo = this.confrimFrom;
        this.confrimFrom = false;
        if (this.$route.path !== whereTo) {
          this.$router.push({ path: whereTo });
        }
      }
    },
    async goBackToGrid(force=false) {
      if (force) {
        this.forceExit = true;
      }
      if (!this.viewingConferences) {
        if (this.$refs.templatesDropDown) {
          if (
            this.$refs.templatesDropDown.internalSearch &&
            this.$refs.templatesDropDown.internalSearch !== ""
          ) {
            this.conferenceName = this.$refs.templatesDropDown.internalSearch;
          }
        }
        await this.getPostSnapShot();
        if (this.isDirty && !this.isChanging && !this.isMod) {
          this.confrimFrom = "goBackToGrid";
          this.showConfirmation = true;
        } else {
          await this.resetForm();
          this.isPreviewingEmail = false;
          this.viewingConferences = true;
          this.conferenceToEdit = null;
          this.isEditingConference = false;
          EventBus.$emit("emitTotalInvitedParticipants", 0);
        }
        this.isChanging = false;
      } else {
      }
    },
    toggleEmailPreview() {
      this.isPreviewingEmail = !this.isPreviewingEmail;
      if (this.isPreviewingEmail) {
        this.uInviteText = this.doGetInviteTextForUser(true);
        this.gInviteText = this.doGetInviteTextForGuest(true);
      }
    },
    setTempDocStore(tempStore) {
      this.tempDocumentStore = tempStore;
    },
    toggleDocumentsView() {
      setConferenceDocumentsEvent(true);
      // this.showConferenceDocumentsModal = !this.showConferenceDocumentsModal;
    },
    doLoadTemplate(groupId) {
      this.resetForm();
      this.ignoreDirty = false;
      const extant = store.state.user.groups.find((e) => e.groupId === groupId);
      if (extant) {
        // this.$router.push({ path: "/newconference/" });
        this.viewingConferences = false;
        this.changeGroupCombo(extant);
        this.isEditingTemplate = extant.groupId;
      }
    },
    deleteTemplate(event) {
      event.stopPropagation();
      event.preventDefault();
      try {
        if (!this.deleteTemplateActive) {
          this.deleteTemplateActive = true;
          setTimeout(() => {
            this.deleteTemplateActive = false;
          }, 1500);
        } else {
          this.deleteTemplateActive = false;
          const groupIndex = store.state.user.groups.findIndex(
            (e) => e.groupId === this.isEditingTemplate
          );
          const groups = store.state.user.groups;
          groups.splice(groupIndex, 1);
          store.state.user.groups = groups;
          dispatchSuccessAlert(
            this.$t("components.conferenceForm.templateDeleted")
          );
          this.resetForm();

          this.syncSelector();
        }
      } catch (err) {
        this.deleteTemplateActive = false;
        console.warn("Error deleting group", err);
        dispatchErrorAlert("Error deleting template");
      }
    },
    async savingTemplate(evt) {
      if (!this.isEditingTemplate) {
        evt.preventDefault();
        evt.stopPropagation();
        if (typeof this.conferenceName == "string") {
          if (
            this.conferenceName !== "" &&
            this.conferenceName.trim().length > 0
          ) {
            const groupData = {
              groupId: uuidv4(),
              groupName: this.conferenceName,
              groupImage: this.conferenceImage,
              groupDescription: this.conferenceDescription || "",
              groupMembers: this.conferenceMembers,
            };
            await this.saveGroup(groupData);
            // const obj = store.state.user.groups.find(
            //   (e) => e.groupId === groupData.groupId
            // );
            // this.changeGroupCombo(obj);
            this.$refs.templatesDropDown.blur();
            this.isEditingTemplate = groupData.groupId;
          }
        } else {
          this.isEditingTemplate = this.conferenceName.groupId;
          this.$refs.templatesDropDown.blur();
        }
      } else {
        this.isEditingTemplate = false;
      }
    },
    async updateTemplate() {
      try {
        const tmpObj = {
          groupId: this.isEditingTemplate,
          groupName:
            typeof this.conferenceName == "object"
              ? this.conferenceName.groupName
              : this.conferenceName,
          groupImage: this.conferenceImage,
          groupDescription: this.conferenceDescription || "",
          groupMembers: this.conferenceMembers,
          groupUUIDS: await this.extractUsers(store.state.user.uuid),
        };
        await this.updateGroup(tmpObj);
      } catch (err) {
        console.warn("Error updating group", err);
        dispatchErrorAlert("Error updating group");
      }
    },
    async changeGroupCombo(evt) {
      if (typeof evt == "object") {
        if (evt && evt.groupId) {
          await this.resetForm();
          this.conferenceName = evt;
          this.conferenceDescription = evt.groupDescription;
          this.selectGroup(evt.groupId);
          this.getInitSnapShot();
          this.ignoreDirty = false;
          this.isDuplicating = true;
          this.isInserting = true;
        }
      } else {
        if (this.isEditingTemplate) {
          this.updateTemplate();
        }
      }
    },
    checkCodeEntered(ev) {
      const charCode = ev.which ? ev.which : ev.keyCode;
      if (charCode !== 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        ev.preventDefault();
        ev.stopPropagation();
      }
    },
    checkFrequencyValue(ev) {
      if (this.confFrequency < 0 || this.confFrequency > this.maxFrequency) {
        this.confFrequency = this.maxFrequency;
      }
    },
    confFrequencyUpdated() {
      switch (this.scheduledInterval?.value || this.scheduledInterval) {
        case "d":
          this.maxFrequency = 365;
          break;
        case "w":
          this.maxFrequency = 52;
          break;
        case "m":
          this.maxFrequency = 12;
          break;
        default:
          this.maxFrequency = 0;
          break;
      }
      // check current value against max
      if (this.confFrequency > 0 && this.confFrequency > this.maxFrequency) {
        this.confFrequency = 0;
      }
    },
    removeScheduledConferences(expandReocurring) {
      this.confFrequency = 0;
      this.maxFrequency = 0;
      this.scheduledInterval = "";
      const indexOf = Array.isArray(expandReocurring) && expandReocurring.indexOf('reocurringOpened');
      if (indexOf !== false) {
        if (indexOf !== -1) {
          expandReocurring.splice(indexOf, 1);
        } else {
          expandReocurring.push('reocurringOpened');
        }
      }
      return expandReocurring;
    },
    openCloseCroppie(value = undefined) {
      this.showCroppie = value ? value : !this.showCroppie;
    },
    getTypeOfParticipant(type) {
      if (type === "Visitors") {
        return this.$t("components.addConferenceModal.visitor");
      } else if (type === "Users") {
        return this.$t("components.addConferenceModal.users");
      }
    },
    autocompleteMinutesEnd(hour) {
      let finalHour = hour;
      if (hour < 10) finalHour = '0' + hour;
      this.timeEnd = finalHour + ":00";
    },
    handleMinuteClickEnd(minutes) {
      const hour = this.timeEnd.slice(0,2);
      let newtimeEnd = hour + ':';
      if (minutes > 9) {
        newtimeEnd += minutes;
      } else {
        newtimeEnd += '0' + minutes;
      }
      if (newtimeEnd) {
        this.timeEnd = newtimeEnd;
        this.endTimeMenu = false;
      }
    },
    autocompleteMinutes(hour) {
      let finalHour = hour;
      if (hour < 10) finalHour = '0' + hour;
      this.timeStart = finalHour + ":00";
    },
    handleMinuteClick(minutes) {
      const hour = this.timeStart.slice(0,2);
      let newTimeStart = hour + ':';
      const newTimeEnd = hour + ':' + (minutes + 10 <= 59 ? minutes + 10 : '59'); //Set TimeEnd 10 mins later for reference
      if (minutes > 9) {
        newTimeStart += minutes;
      } else {
        newTimeStart += '0' + minutes;
      }
      if (newTimeStart) {
        this.timeStart = newTimeStart;
        this.timeEnd = newTimeEnd;
        this.startTimeMenu = false;
      }
    },
    async conferenceRSVP() {
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conferenceId
      );

      if (timelineEv) {
        await dispatchRsvpTimelineEntry([
          timelineEv.uuid,
          this.rsvpValue == true ? true : false,
        ]);
      }
    },
    checkPin() {
      if (this.complexPinActive) {
        return this.complexPinRegex.test(this.pinCode);
      } else if (this.pinCode) {
        return /^\d{6}$/.test(this.pinCode);
      } else {
        return true;
      }
    },
    async reminderConference(conf) {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conference = {
        personalizedGreeting: conf.confPersonalizedGreet,
        confStartDate: conf.confStartDate,
        confStartTime: conf.confStartTime,
        inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
        confName: conf.confName,
        isUnlimited: conf.isUnlimited,
        durationMin: conf.confDuration,
        confDescription: conf.confDescription,
      };
      const text = await getInviteTextForRem(tZone, conference, false);
      let subject = this.$t("components.invitePreview.inviteRemSubject");
      let mssgType = "conf-reminder";
      const noInviteLink = false;
      const dates = {
        startEvent: conf.confStartDate,
        timeStart: conf.confStartTime,
        timezone: tZone,
      };
      const evName = conference.confName;
      const sendMsg = true;

      this.justSendMailToAll(
        conf.confUUIDS,
        subject,
        text,
        mssgType,
        dates,
        evName,
        sendMsg,
        noInviteLink
      );
    },
    duplicateConference(id) {
      const original = this.myConferenceDetails.find((e) => e.confId == id);
      if (original && original.confId) {
        this.isDuplicating = true;
        this.isInserting = true;
        this.conferenceToEdit = undefined;
        this.isEditingConference = false;
        this.viewingConferences = false;
        this.conferenceName = original.confName;
        this.conferenceId = uuidv4();
        this.conferenceDescription = original.confDescription;
        this.conferenceImage = original.confAvatar;
        this.conferenceMembers = JSON.parse(JSON.stringify(original.members || []));
        this.startEvent = new Date();
        this.timeEnd = null;
        this.timeStart = null;
        this.durationMin = null;
        this.pinCode = "";
        this.isUnlimited = original.isUnlimited;
        // this.syncSelector();
      }
    },
    doSendMessageToAll(confId) {
      const uuidArrays = [];
      this.conferenceToEdit.confUUIDS.forEach((uuid) => {
        if (uuid !== this.state.ownUUID) {
          uuidArrays.push(uuid);
        }
      });
      this.setShowModalSendMsg(uuidArrays, confId);
    },
    doResendInviteToAll() {
      if (!this.conferenceToEdit) return false;
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conferenceToEdit.confId
      );
      if (!timelineEv) return false;
      this.createInvitations(this.conferenceToEdit, timelineEv.uuid);
    },
    setShowModalSendMsg(members, confId) {
      setSendMessageModalEvent({
        show: true,
        users: members,
        type: "conferenceMsg",
        confId: confId,
      });
    },
    loadConference(confId) {
      const original = this.myConferenceDetails.find((e) => e.confId == confId);
      if (original) {
        this.editConference(original);
        this.ignoreDirty = true;
        this.$router.push({ path: "/newconference/" });
      }
    },
    checkUsers(members) {
      const users = [];
      if (members && members.length > 0) {
        members.forEach((member) => {
          if (member.uuid && this.state.group[member.uuid]) {
            users.push(member);
          }
        });
        this.conferenceMembers = JSON.parse(JSON.stringify(users));
      } else {
        this.conferenceMembers = JSON.parse(JSON.stringify(members || []));
      }
    },
    loadExistingConference() {
      this.ignoreDirty = false;
      this.isUnlimited = this.conferenceToEdit.isUnlimited;
      this.conferenceName = this.conferenceToEdit.confName;
      this.conferenceId = this.conferenceToEdit.confId;
      this.recording = this.conferenceToEdit.recording;
      this.transcript = this.conferenceToEdit.transcript;
      this.privateTranscript = this.conferenceToEdit.privateTranscript;
      this.conferenceDescription = this.conferenceToEdit.confDescription;
      this.personalizedGreeting =
        this.conferenceToEdit.confPersonalizedGreet || "";
      this.conferenceImage = this.conferenceToEdit.confAvatar;
      this.checkUsers(this.conferenceToEdit.members);
      (this.scheduledInterval = this.conferenceToEdit.confIsReoInterval || ""),
        (this.confFrequency = +this.conferenceToEdit.confIsReoFrequency || 0),
        // (this.startEvent = this.conferenceToEdit.confStartDate);
      this.timeEnd = this.isUnlimited
        ? null
        : this.conferenceToEdit.confEndTime;
      this.timeStart = this.conferenceToEdit.confStartTime;
      // const conferenceISOStartDate = new Date(this.conferenceToEdit.confStartDate);
      // const conferenceISOEndDate = new Date(this.conferenceToEdit.confEndDate);
      // conferenceISOStartDate.setHours(, X, 0, 0);
      // conferenceISOEndDate.setHours(X, X, 0, 0);
      const _startEvent = this.conferenceToEdit.confStartDate;
      const conferenceISOStartDate = new Date(
        _startEvent +
          "T" +
          this.timeStart
            .split(":")
            .map((e) => e.padStart(2, "0"))
            .join(":") +
          ":00" +
          (this.conferenceToEdit.confTimezone || getTimezone(_startEvent, this.timeStart))
      );
      const conferenceISOEndDate = this.isUnlimited
        ? null
        : new Date(
            _startEvent +
              "T" +
              this.timeEnd
                .split(":")
                .map((e) => e.padStart(2, "0"))
                .join(":") +
              ":00" +
              (this.conferenceToEdit.confTimezone || getTimezone(_startEvent, this.timeEnd))
          );
      // this.startEvent = this.moment(conferenceISOStartDate).format(
      //   "YYYY-MM-DD"
      // );
      // this.startEvent = conferenceISOStartDate;
      const dateCounter = new Date(_startEvent);
      this.startEvent = dateCounter;
      this.timeEnd = this.isUnlimited
        ? null
        : this.moment(conferenceISOEndDate).format("HH:mm");
      this.timeStart = this.moment(conferenceISOStartDate).format("HH:mm");
      this.durationMin = this.isUnlimited
        ? null
        : this.conferenceToEdit.confDuration;
      this.isUnlimited = this.conferenceToEdit.isUnlimited;
      this.pinCode = this.conferenceToEdit.confPin || "";
      this.rsvpValue = this.extractRSVP();
      this.expandReocurring = this.conferenceToEdit.confIsReocurring ? ['reocurringOpened'] : [];
      this.syncSelector();
      dispatchFetchMyItemsForDateEvent(dateCounter.toISOString());
    },
    syncSelector() {
      // removes allready selected users groups or teams from dropDown
      if (!this.conferenceMembers) {
        this.goBackToGrid();
        return;
      }
      this.conferenceMembers.forEach((member) => {
        if (member.isTeam) {
          const extant = this.allTeams[member.name];
          if (extant) {
            this.allTeams[member.name] = undefined;
          }
        } else if (member.isGroup) {
          const extant = this.allGroups.find((e) => e.groupId === member.uuid);
          if (extant) {
            this.allGroups = this.allGroups.filter(
              (group) => group.groupId !== member.uuid
            );
          }
        } else {
          // users and guests
          this.allUsers[member.uuid] = undefined;
          delete this.allUsers[member.uuid];
        }
      });
    },
    handleScroll() {
      EventBus.$emit("closeMenu");
    },
    checkSendMessage(confMembers, confId) {
      const uuidArrays = [];
      confMembers.forEach((uuid) => {
        if (uuid !== this.state.ownUUID) {
          uuidArrays.push(uuid);
        }
      });
      this.setShowModalSendMsg(uuidArrays, confId);
    },
    async deletedMessageConf(extantEv, isTempConf, conf) {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conference = {
        personalizedGreeting: conf.confPersonalizedGreet,
        confStartDate: conf.confStartDate,
        confStartTime: conf.confStartTime,
        inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
        confName: conf.confName,
        isUnlimited: conf.isUnlimited,
        durationMin: conf.confDuration,
      };
      const text = await getInviteTextForCancellation(tZone, conference, false);
      let subject = this.$t("components.invitePreview.inviteCancelSubject");
      const startEvent = this.moment(extantEv.start).format("YYYY-MM-DD");
      const timeStart = this.moment(extantEv.start).format("HH:mm");
      const mssgType = "conf-delete";
      const noInviteLink = true;
      const evName = conf.confName;
      const dates = {
        startEvent: startEvent,
        timeStart: timeStart,
      };

      let sendMsg = !conf.confIsTemp;
      this.justSendMailToAll(
        conf.confUUIDS,
        subject,
        text,
        mssgType,
        dates,
        evName,
        sendMsg,
        noInviteLink
      );
    },
    async deleteConferenceSafely(event, confId) {
      event.stopPropagation();
      event.preventDefault();
      let isTempConf = false;
      try {
        if (!this.deleteActive) {
          this.deleteActive = true;
          setTimeout(() => {
            this.deleteActive = false;
          }, 1500);
        } else {
          this.deleteActive = false;
          const extantEv = this.timelineEvents.find((e) => e.confId === confId);
          const extantConf = this.myConferenceDetails.find(
            (e) => e.confId === confId
          );
          if (extantConf) {
            isTempConf = extantConf.confIsTemp;
          }
          if (extantEv && extantEv.uuid) {
            await deleteTimelineItemEvent(extantEv.uuid);
          }
          if (confId) {
            await this.deleteConferenceDocumentStore(confId);
            await dispatchDeleteGroupConferenceEvent(confId);
          }
          dispatchSuccessAlert(
            this.$t("components.conferences.conferenceDeleted")
          );
          // only notify if conference has not occurred
          if (extantEv && extantEv.end) {
            const orgEnd = moment(new Date(extantEv.end));
            const elapsedMins = moment().diff(orgEnd, "minutes");
            if (elapsedMins < 3) {
              const tobeNotified = extantEv.users.filter(
                (item) => item != store.state.ownUUID
              );
              if (tobeNotified && tobeNotified.length) {
                await this.deletedMessageConf(extantEv, isTempConf, extantConf);
              }
            }
          }
          await this.resetSnapShots();
          this.goBackToGrid();
        }
      } catch (err) {
        this.deleteActive = false;
        console.warn("Error deleting group", err);
        dispatchErrorAlert("Error deleting group");
      }
    },
    async deleteConference(confId) {
      const extantEv = this.timelineEvents.find((e) => e.confId === confId);
      const extantConf = this.myConferenceDetails.find(
        (e) => e.confId == confId
      );
      if (extantEv && extantEv.uuid) {
        await deleteTimelineItemEvent(extantEv.uuid);
      }
      if (confId) {
        await this.deleteConferenceDocumentStore(confId);
        await dispatchDeleteGroupConferenceEvent(confId);
      }
      if (extantEv && extantEv.end) {
        const orgEnd = moment(new Date(extantEv.end));
        const elapsedMins = moment().diff(orgEnd, "minutes");
        if (elapsedMins < 3) {
          const tobeNotified = extantEv.users.filter(
            (item) => item != store.state.ownUUID
          );
          if (tobeNotified && tobeNotified.length) {
            await this.deletedMessageConf(
              extantEv,
              extantConf.isTemp,
              extantConf
            );
          }
        }
      }
      await this.resetSnapShots();
      this.goBackToGrid();
    },
    async deleteConferenceDocumentStore(confId) {
      const foundStore = this.myDocuments.find((e) => e.confId === confId);
      if (foundStore && foundStore.docStoreUUID) {
        await dispatchDeleteCompanyDocumentEvent(foundStore.docStoreUUID);
      } else {
        console.warn("Store not found for deletion");
      }
    },
    editConference(conference) {
      this.viewingConferences = false;
      this.isEditingConference = true;
      this.$nextTick(() => (this.conferenceToEdit = conference));
    },
    infiniteHandler($state) {
      if (this.sliceIndex < this.myConferences.length) {
        this.sliceIndex += 6;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    selectGroup(groupId) {
      let extant = store.state.user.groups.find((e) => e.groupId == groupId);
      if (extant) {
        extant.groupMembers.forEach((member) => {
          this.addMember(member);
        });
      }
    },
    updateDurationMin(givenStart, givenEnd) {
      if (!givenStart || !givenEnd) return;
      const start = new Date(givenStart).getTime();
      const end = new Date(givenEnd).getTime();
      const diffMs = end - start;
      let diffMins = Math.floor(diffMs / 60000);
      if (diffMins < 0) {
        diffMins = 0;
      }
      this.durationMin = diffMins;
    },
    startChanged() {
      if (!this.startEvent || !this.timeStart) return;
      this.formattedStartDate = this.format_Date(this.startEvent);
      this.startMenu = false;
      if (this.timeEnd && this.timeStart) {
        const cStart = parseInt(this.timeStart.replace(":", ""));
        const cEnd = parseInt(this.timeEnd.replace(":", ""));
        if (cStart < cEnd) {
          this.endChanged();
        } else {
          this.timeEnd = null;
          this.durationMin = null;
        }
      }
    },
    endChanged() {
      if (!this.startEvent || !this.timeEnd) return;
      // this.endEvent = this.startEvent;
      // const dateTimeStart =
      //   this.startEvent + "T" + this.zeroFill(this.timeStart);
      // const dateTimeEnd = this.endEvent + "T" + this.zeroFill(this.timeEnd);
      // this.updateDurationMin(dateTimeStart, dateTimeEnd);
      const timeArray = this.timeEnd.split(':');
      const startDateTime = this.createStartDateTime();
      const endDateTime = new Date(this.createStartDateTime().setHours(timeArray[0], timeArray[1]))
      if(endDateTime.toString() !== this.endEvent.toString()){
        this.endEvent = endDateTime;
      }
      if (!this.timeStart) return;
      this.updateDurationMin(startDateTime.toString(), endDateTime.toString());
    },
    recalcEnd(recalc = true) {
  // Exit early if startEvent or timeStart are not valid
      if (!this.startEvent || !this.timeStart || !this.timeEnd) return;

      // If recalc flag is true, perform any additional operations with the calculated difference
      if (recalc) {
      //OLD functionality to set a duration and calculate timeEnd instead of setting endDate+timeEnd
      //   // Construct the starting date-time object from the event's starting date and time
      //   const startDateTime = this.createStartDateTime();
        
      //   if (this.durationMin) {
      //     // Calculate the ending time by adding duration in minutes to the starting time
      //     const endDate = new Date(startDateTime.getTime() + this.durationMin * 60000);
        
      //     // Format the ending date and time
      //     const formattedEndDate = this.formatDate(endDate);
      //     const formattedEndTime = this.formatTime(endDate);
        
      //     // Update the endEvent and timeEnd properties if recalc is true
      //       this.endEvent = formattedEndDate;
      //       this.timeEnd = formattedEndTime;
      //   }
      }

      // Return?
    },

    // Assuming this.startEvent is a Date object and this.timeStart is a string in "HH:MM" format
    createStartDateTime() {
      // Clone the startEvent date object to prevent mutating the original date
      const startDateTime = new Date(this.startEvent);

      if (this.timeStart.split(':').length > 1) {
        // Extract hours and minutes from this.timeStart
        const [hours, minutes] = this.timeStart.split(':').map(Number);
  
        // Set the hours and minutes to match this.timeStart
        startDateTime.setHours(hours, minutes, 0, 0); // Sets the seconds and milliseconds to zero
      }
      return startDateTime;
    },

    // Assumes dateObj is a JavaScript Date object
    formatDate(dateObj) {
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
      const day = dateObj.getDate().toString().padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    },

    // Assumes dateObj is a JavaScript Date object
    formatTime(dateObj) {
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');

      return `${hours}:${minutes}`;
    },

    hasUserRejected(uuid){
       const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conferenceToEdit.confId
      );
      if (!timelineEv || !timelineEv.rsvp) return false;
      const extant = timelineEv.rsvp[uuid] || {};
      if (extant.status) {
        return (extant.status == "rejected");
      }
      return false;
    },
    extractRSVP() {
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conferenceToEdit.confId
      );
      if (!timelineEv) return null;
      const extant = timelineEv.rsvp[store.state.ownUUID] || {};
      if (extant == {}) return null;
      if (extant.status) {
        if (extant.status == "rejected") {
          return false;
        } else if (extant.status == "accepted") {
          return true;
        } else if (extant.status == "pending") {
          return null;
        } else {
          return null;
        }
      }
    },
    // format_Date(date) {
    //   if (!(date instanceof Date)) throw new Error('Input is not a Date object');

    //   const day = ('0' + date.getDate()).slice(-2); // Add leading zero and take the last two characters
    //   const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0-indexed in JavaScript
    //   const year = date.getFullYear();

    //   return `${day}.${month}.${year}`;
    // },
    format_Date(date) {
      if (!date) return null;
      return this.moment(date).format("YYYY-MM-DD")
      // const [year, month, day] = date.split("-");
      // return `${day}.${month}.${year}`;
    },
    cleanDate() {
      if (
        // this.isMyConference ||
        this.conferenceModerator == store.state.ownUUID
      ) {
        this.startEvent = new Date();
        this.formatedStart;
        this.timeEnd = null;
        this.timeStart = null;
        this.durationMin = null;
        document.getElementById("startDateMenu").blur();
      }
    },
    toggleModerator(user) {
      if (user.isModerator) {
        const extant = this.conferenceMembers.find((e) => e.uuid === user.uuid);
        extant.isModerator = false;
      } else {
        const extant = this.conferenceMembers.find((e) => e.uuid === user.uuid);
        extant.isModerator = true;
      }
    },
    closeGuestModal() {
      this.showAddGuestModal = false;
    },
    addMember(memberToAdd) {
      if (!this.isConferenceFull && this.state.group[memberToAdd.uuid]) {
        if (!this.conferenceMembers.find((e) => e.uuid === memberToAdd.uuid)) {
          this.conferenceMembers.push(memberToAdd);
          if (this.isEditingTemplate) {
            this.updateTemplate();
          }
        }
      }
    },
    async createDocStore(confObject) {
      if (this.tempDocumentStore && this.tempDocumentStore.documents.length) {
        this.tempDocumentStore.docMembersUUIDS = confObject.confUUIDS;
        this.tempDocumentStore.moderators = confObject.confModerators;
        this.tempDocumentStore.confId = confObject.confId;
        await newCompanyDocumentEvent(this.tempDocumentStore);
        this.tempDocumentStore = null;
      }
    },
    async saveConference(isTempConf = false) {
      // save conference
      await this.resetSnapShots();
      let transResults = false;
      let confObject = {};
      if (this.conferenceIsValid) {
        if (!this.timeEnd && (!this.durationMin || !this.durationMin == 0)) {
          this.isUnlimited = true;
        } else {
          this.isUnlimited = false;
        }
        try {
          //const startEventAsDate = new Date(this.startEvent);
          //const startEventAsNumber = startEventAsDate - (startEventAsDate.getTimezoneOffset() * 60000);
          //const startEventAsDay = new Date(startEventAsNumber).toISOString().slice(0, 10);
          confObject = {
            confId: this.conferenceId,
            confIsTemp: isTempConf,
            confName:
              typeof this.conferenceName == "string"
                ? this.conferenceName
                : this.conferenceName.groupName,
            confDescription: this.conferenceDescription,
            confOwner: store.state.user.uuid,
            confPersonalizedGreet: this.personalizedGreeting,
            confModerators: await this.getModerators(),
            confPin: this.pinCode,
            confStartDate: moment(this.startEvent).toISOString(true).slice(0, 10),
            confStartTime: this.timeStart,
            confEndDate: this.isUnlimited ? moment(this.startEvent).toISOString(true).slice(0, 10) : moment(this.endEvent).toISOString(true).slice(0, 10),
            confEndTime: !this.timeEnd ? this.timeStart : this.timeEnd,
            confTimezone: moment(this.startEvent).set("hour", +this.timeStart.split(':')[0]).set("minute", +this.timeStart.split(':')[1]).format('Z'),
            confDuration: this.isUnlimited ? 0 : this.durationMin,
            isUnlimited: this.isUnlimited,
            confIsReocurring: this.isReOcurring,
            confIsReoInterval: this.scheduledInterval || "",
            confIsReoFrequency: +this.confFrequency || 0,
            members: this.conferenceMembers,
            confUUIDS: await this.extractUsers(this.uuidToRepresentStore || store.state.user.uuid),
            confRealUsers: await this.extractOnlyUsers(this.uuidToRepresentStore || store.state.user.uuid),
            creationServerTs: Date.now(),
            updatedServerTs: Date.now(),
            confAvatar: this.conferenceImage,
            timelineEventId: undefined,
            recording: this.recording,
            transcript: this.transcript,
            privateTranscript: this.privateTranscript,
          };
          await newGroupConferenceEvent(confObject);
          transResults = true;
          if (!isTempConf) {
            this.isChanging = true;
            this.goBackToGrid();
            dispatchSuccessAlert(this.$t("components.groups.templateCreated"));
          }
        } catch (err) {
          console.warn("Error creating conference", err);
          return dispatchErrorAlert(
            this.$t("components.conferences.errCreateConference")
          );
        }
      } else {
        return dispatchErrorAlert(
          this.$t("components.conferences.errCreateConference")
        );
      }

      // CREATE DOCUMENT STORE
      await this.createDocStore(confObject);
      // CREATE TIMELINE EVENT TO ALL USERS
      if (transResults) {
        transResults = false;
        confObject.rsvp = true;

        const event = await this.createTimelineEntry(confObject);
        if (event) {
          this.createInvitations(confObject, event.uuid);
          try {
            transResults = true;
          } catch (error) {
            console.warn("Error sending conf email", error);
            return false;
          }
          return event;
        }
      }
    },
    zeroFill(time) {
      return ("0" + time).slice(-5);
    },
    getDurationPhrase(num) {
      let hours = num / 60;
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);

      if (rhours === 1 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHourMinute", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours === 1 && rminutes > 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHourMinutes", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours > 1 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHoursMinute", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours > 1 && rminutes > 1)
        return (
          this.$t(
            "components.invitePreview.inviteAllLineDurationHoursMinutes",
            [rhours, rminutes]
          ) + "."
        );
      if (rhours === 0 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationMinute", [
            rminutes,
          ]) + "."
        );
      if (rhours === 0 && rminutes > 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDuration", [
            rminutes,
          ]) + "."
        );
      if (rhours === 1 && rminutes === 0)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHour", [
            rhours,
          ]) + "."
        );
      if (rhours > 1 && rminutes === 0)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHours", [
            rhours,
          ]) + "."
        );
    },
    async createTimelineEntry(data) {
      const dateTimeStart =
        data.confStartDate + "T" + this.zeroFill(data.confStartTime);
      const dateTimeEnd =
        data.confEndDate + "T" + this.zeroFill(data.confEndTime);
      const tmpData = {
        confId: data.confId,
        name: data.confName,
        details: data.confDescription,
        isTemp: data.confIsTemp,
        users: data.confUUIDS,
        start: new Date(dateTimeStart),
        end: new Date(dateTimeEnd),
        color: "orange",
        type: "videoCall",
        host: data.confOwner,
        title: data.confName.trim(),
        eventType: "bridgeCall",
        isUnlimited: data.isUnlimited,
        isReOcurring: data.confIsReocurring || false,
        confIsReoInterval: data.confIsReoInterval || "",
        confIsReoFrequency: +data.confIsReoFrequency || 0,
        viewed: {},
        rsvp: true,
      };
      if (data.confUUIDS && data.confUUIDS.length) {
        tmpData.aiUuid = data.confUUIDS.find(uuid => store.state.group[uuid]?.user?.aiUser) || "";
      }
      const meeting = await createTimelineEntryEffect(tmpData);
      return meeting;
    },
    async doUpdateConference() {
      if (this.conferenceIsValid) {
        let refConference = await this.myConferenceDetails.find(
          (e) => e.confId === this.conferenceId
        );
        let guestEmailTextForChange = "";
        let userEmailTextForChange = "";
        let conf = {};
        if (!this.timeEnd && !this.durationMin) {
          this.isUnlimited = true;
        } else {
          this.isUnlimited = false;
        }
        try {
          const confObject = {
            confId: this.conferenceId,
            confName: this.conferenceName,
            confDescription: this.conferenceDescription,
            confOwner: this.conferenceToEdit.confOwner,
            confPersonalizedGreet: this.personalizedGreeting,
            confModerators: await this.getModerators(),
            confPin: this.pinCode,
            confStartDate: moment(this.startEvent).toISOString(true).slice(0, 10),
            confStartTime: this.timeStart,
            confEndDate: this.isUnlimited ? moment(this.startEvent).toISOString(true).slice(0, 10) : moment(this.endEvent).toISOString(true).slice(0, 10),
            confEndTime: !this.timeEnd ? this.timeStart : this.timeEnd,
            confTimezone: moment(this.startEvent).set("hour", +this.timeStart.split(':')[0]).set("minute", +this.timeStart.split(':')[1]).format('Z'),
            confDuration: this.isUnlimited ? 0 : this.durationMin,
            confIsReocurring: this.isReOcurring,
            confIsReoInterval: this.scheduledInterval || "",
            confIsReoFrequency: +this.confFrequency || 0,
            isUnlimited: this.isUnlimited,
            members: this.conferenceMembers,
            confUUIDS: await this.extractUsers(this.conferenceToEdit.confOwner),
            confRealUsers: await this.extractOnlyUsers(
              this.conferenceToEdit.confOwner
            ),
            creationServerTs: this.conferenceToEdit.creationServerTs,
            updatedServerTs: Date.now(),
            confAvatar: this.conferenceImage,
            timelineEventId: undefined,
            recording: this.recording,
            transcript: this.transcript,
            privateTranscript: this.privateTranscript,
          };
          const extantEv = this.timelineEvents.find(
            (e) => e.confId === confObject.confId
          );
          if (extantEv && extantEv.uuid) {
            let isMeaningful = false;
            const newUsers = confObject.confUUIDS.filter(
              (e) => this.conferenceToEdit.confUUIDS.indexOf(e) === -1
            );
            await updateGroupConferenceEvent(confObject);

            // UPDATE DOCUMENT STORE
            await this.doUpdateDocumentStore(confObject);
            // NOTIFICATIONS
            if (refConference && refConference.confId) {
              const tZone =
                store.state.namespaceSettings.timezone || "Europe/Berlin";
              const oldConfStartDate = refConference.confStartDate;
              const oldConfStartTime = refConference.confStartTime;
              const asHtml = false;
              conf = {
                personalizedGreeting: this.personalizedGreeting,
                confStartDate: moment(this.startEvent).toISOString(true).slice(0, 10),
                confStartTime: this.timeStart,
                inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
                confName: this.conferenceName,
                isUnlimited: this.timeEnd ? false : true,
                durationMin: this.durationMin,
                confDescription: this.conferenceDescription,
              };
              guestEmailTextForChange = await getInviteTextGuestChange(
                tZone,
                conf,
                oldConfStartDate,
                oldConfStartTime,
                asHtml
              );
              userEmailTextForChange = await getInviteTextUserChange(
                tZone,
                conf,
                oldConfStartDate,
                oldConfStartTime,
                asHtml
              );
            }
            // get waitingRoomAssistants for send notification
            const waitingRoomAssistants = (this.state.user.waitingRoomAssistants || []).filter(uuid=>{
              return !confObject.confUUIDS.includes(uuid)
            })
            const dateTimeStart =
              confObject.confStartDate +
              "T" +
              this.zeroFill(confObject.confStartTime);
            const dateTimeEnd =
              confObject.confEndDate +
              "T" +
            this.zeroFill(confObject.confEndTime);
            const tmpData = {
              confId: confObject.confId,
              name: confObject.confName,
              details: confObject.confDescription,
              users: confObject.confUUIDS,
              start: new Date(dateTimeStart),
              end: new Date(dateTimeEnd),
              uuid: extantEv.uuid,
              isUnlimited: confObject.isUnlimited,
              isReOcurring: confObject.confIsReocurring || false,
              confIsReoInterval: confObject.confIsReoInterval || "",
              confIsReoFrequency: +confObject.confIsReoFrequency || 0,
              color: "orange",
              type: "videoCall",
              title: confObject.confName.trim(),
              eventType: "bridgeCall",
              waitingRoomAssistants: waitingRoomAssistants,
            };
            if (confObject.confUUIDS && confObject.confUUIDS.length) {
              tmpData.aiUuid = confObject.confUUIDS.find(uuid => store.state.group[uuid]?.user?.aiUser) || "";
            }
            const timezone =
              store.state.namespaceSettings.timezone || "Europe/Berlin";
            const confStartMoment = this.moment(tmpData.start).tz(timezone);
            const confEndMoment = this.moment(tmpData.end).tz(timezone);

            const body = confObject.isUnlimited
              ? this.$t(
                  "components.notificationsModal.eventChangesTimezoneNoDuration",
                  [
                    confObject.confName.trim(),
                    confStartMoment.format("DD.MM.YYYY"),
                    confStartMoment.format("HH:mm"),
                    timezone,
                  ]
                )
              : this.$t("components.notificationsModal.eventChangesTimezone1", [
                  confObject.confName.trim(),
                  confStartMoment.format("DD.MM.YYYY"),
                  confStartMoment.format("HH:mm"),
                  timezone,
                  await this.getDurationPhrase(this.getDurationMin),
                  confEndMoment.format("HH:mm"),
                ]);
            const mssg = {
              body: body,
              header: {
                translateMessage: {
                  translateKey: confObject.isUnlimited
                    ? this.$t(
                        "components.notificationsModal.eventChangesTimezoneNoDuration",
                        [
                          confObject.confName.trim(),
                          confStartMoment.format("DD.MM.YYYY"),
                          confStartMoment.format("HH:mm"),
                          timezone,
                        ]
                      )
                    : this.$t(
                        "components.notificationsModal.eventChangesTimezone1",
                        [
                          confObject.confName.trim(),
                          confStartMoment.format("DD.MM.YYYY"),
                          confStartMoment.format("HH:mm"),
                          timezone,
                          await this.getDurationPhrase(this.getDurationMin),
                          confEndMoment.format("HH:mm"),
                        ]
                      ),
                },
              },
            };

            if (
              new Date(extantEv.start).getDate() !==
                new Date(tmpData.start).getDate() ||
              new Date(extantEv.start).getTime() !==
                new Date(tmpData.start).getTime() ||
              new Date(extantEv.end).getDate() !==
                new Date(tmpData.end).getDate() ||
              new Date(extantEv.end).getTime() !==
                new Date(tmpData.end).getTime()
            ) {
              isMeaningful = true;
            }
            await updateTimelineItemEvent([extantEv.uuid, tmpData, mssg]);
            if (isMeaningful) {
              setTimeout(() => {
                this.SendChangeEmail(
                  confObject,
                  extantEv.uuid,
                  guestEmailTextForChange,
                  userEmailTextForChange
                );
              }, 1000);
            }

            if (newUsers && newUsers.length) {
              newUsers.forEach((uuid) => {
                const person = store.state.group[uuid];
                if (person) {
                  if (person.permissions == 10) {
                    this.createGuestInvite(
                      person.user,
                      extantEv.uuid,
                      confObject
                    );
                  } else {
                    this.createUserInvite(
                      person.user,
                      extantEv.uuid,
                      confObject
                    );
                  }
                }
              });
            }
          }
          this.isChanging = true;
          this.deleteUserNotification();
          this.goBackToGrid();
          dispatchSuccessAlert(
            this.$t("components.conferences.conferenceUpdated")
          );
          if(this.uuidToRepresentStore) resetAndRefetchDatesEvent(); //get new data from conference
        } catch (err) {
          console.warn("Error creating conference", err);
          dispatchErrorAlert("Error updating conference");
        }
      } else {
        dispatchErrorAlert("Error updating conference");
      }
    },
    async SendChangeEmail(
      confObject,
      eventUUID,
      guestEmailTextForChange,
      userEmailTextForChange
    ) {
      if (confObject && confObject.confUUIDS && confObject.confUUIDS.length) {
        let gdata = {};
        let udata = {};
        const uuids = confObject.confUUIDS.filter(
          (e) => e !== store.state.ownUUID
        );
        await parallel(1, [...uuids], async (uuid) => {
          if (store.state.group[uuid].user.visitor) {
            gdata = {
              visitorId: uuid,
              name: store.state.group[uuid].user.name,
              email: store.state.group[uuid].user.email || "",
              mobilePhone: store.state.group[uuid].user.mobilePhone || "",
              inviteText: guestEmailTextForChange,
              subject: this.$t("components.invitePreview.inviteChangeSubject"),
              subjectDisplay: this.$t(
                "components.invitePreview.inviteChangeSubject"
              ),
            };
            await sendVisitorEmail(gdata, { eventUUID: eventUUID });
          } else {
            const user = store.state.group[uuid].user;
            udata = {
              inviteText: userEmailTextForChange,
              subject: this.$t("components.invitePreview.inviteChangeSubject"),
              subjectDisplay: this.$t(
                "components.invitePreview.inviteChangeSubject"
              ),
            };
            udata.variables = {};
            await sendUserEmail(user, udata, { eventUUID: eventUUID });
          }
        });
      }
    },
    getModerators() {
      let mods = [];
      this.conferenceMembers.forEach((member) => {
        if (member.isModerator === true) {
          mods.push(member.uuid);
        }
      });
      return mods;
    },
    extractOnlyUsers(owner) {
      const allUsers = this.extractUsers(owner);
      let onlyUsers = [];
      if (allUsers && allUsers.length) {
        onlyUsers = allUsers.filter(
          (e) => (store.state.group[e] || {}).permissions !== 10
        );
      }
      return onlyUsers;
    },
    extractUsers(owner) {
      let users = [];
      this.conferenceMembers.forEach((member) => {
        if (member.isTeam) {
          const extant =
            this.state.namespaceSettings.processedOrganisation.teams[
              member.name
            ];
          const tempMembers = extant.members;
          extant.supervisors.forEach((element) => {
            if (tempMembers.indexOf(element.uuid) == -1) {
              if (this.state.group[element.uuid]) {
                tempMembers.push(element.uuid);
              }
            }
          });
          tempMembers.forEach((id) => {
            if (users.indexOf(id) == -1) {
              if (this.state.group[id]) {
                users.push(id);
              }
            }
          });
        } else if (member.isGroup) {
          if (this.state.group[member.uuid]) {
            const extant = store.state.group[owner].user.groups.find(
              (e) => e.groupId == member.uuid
            );
            const extantMmembers = extant.groupMembers;
            const members = extantMmembers.map((member) => member.uuid);
            members.forEach((el) => {
              if (users.indexOf(el) == -1) {
                users.push(el);
              }
            });
          }
        } else if (member.uuid) {
          if (this.state.group[member.uuid]) {
            if (users.indexOf(member.uuid) == -1) {
              users.push(member.uuid);
            }
          }
        }
      });
      return users;
    },
    showMeDaMoney(a, b) {
      return Object.keys(a).filter((k) => a[k] !== b[k]);
    },
    closeAutocomplete(focusBack=false) {
      // Access the component reference and close the menu
      if (this.$refs.autocompleteAddUsersGuests?.blur) {
        this.$refs.autocompleteAddUsersGuests.blur();
      }
      // Return focus to input after closing autocomplete menu (if required)
      if (focusBack){
        setTimeout(() => {
          this.$refs.autocompleteAddUsersGuests?.focus();
        }, 50);
      }
    },
    handleSelection(event) {
      this.searchInput = '';
      if (this.isConferenceFull) {
        this.closeAutocomplete();
        event.preventDefault();
        event.stopPropagation();
      }
      let name = "";
      const idx = event[event.length - 1];
      const user = this.allUsers[idx];
      if(idx && this.tobeNotifiedDeleteUser.indexOf(idx)!==-1){
        this.tobeNotifiedDeleteUser.splice(this.tobeNotifiedDeleteUser.indexOf(idx), 1)
      }
      if (user && user.user) {
        const uuid = idx;
        if (user.user.visitor && user.user.visitorData.visitorId) {
          // is visitor
          const dataObj = {
            isGuest: true,
            isModerator: false,
            name: user.user.name,
            userFunction: user.user.userFunction,
            uuid: user.user.visitorData.visitorId,
            isGroup: false,
            isTeam: false,
          };
          name = this.$t("components.conferenceForm.guestAdded", [
            user.user.name,
          ]);
          this.addMember(dataObj);
          this.allUsers[idx] = undefined;
          delete this.allUsers[idx];
          this.selectedDropDowItem = [];
        } else if (user.user && uuid) {
          // is user
          const dataObj = {
            isGuest: false,
            isModerator: false,
            name: user.user.name,
            userFunction: user.user.userFunction,
            uuid: uuid,
            isGroup: false,
            isTeam: false,
          };
          name = this.$t("components.conferenceForm.userAdded", [
            user.user.name,
          ]);
          this.addMember(dataObj);
          this.allUsers[idx] = undefined;
          delete this.allUsers[idx];
          this.selectedDropDowItem = [];
        }
      } else if (this.allGroups.findIndex((e) => e.groupName === idx) !== -1) {
        // is group
        const extant = this.allGroups.find((e) => e.groupName === idx);
        if (extant && extant.groupMembers) {
          extant.groupMembers.forEach((member) => {
            this.addMember(member);
          });
          name = this.$t("components.conferenceForm.groupAdded", [
            extant.groupName,
          ]);
          const newArray = this.allGroups.filter((e) => e.groupName !== idx);
          this.allGroups = newArray;
          this.selectedDropDowItem = [];
        }
        // else if (this.allTeams[idx] != undefined)
      } else if (this.allTeams.find(e=>e.name === idx)) {
        // is team
        const extant = this.allTeams.find(e=>e.name === idx);
        if (extant && extant.members) {
          extant.members.forEach((uuid) => {
            if (
              (((store.state.group[uuid] || {}).user || {}).name || false) ===
              false
            ) {
              // skip no data in user
            } else {
              const user = store.state.group[uuid].user;
              const dataObj = {
                isGuest: false,
                isModerator: false,
                name: user.name,
                userFunction: user.userFunction || "",
                uuid: uuid,
                isGroup: false,
                isTeam: false,
              };
              this.addMember(dataObj);
            }
          });
          if (extant && extant.supervisors) {
            const superv = extant.supervisors.map((e) => e.uuid);
            if (superv && superv.length) {
              superv.forEach((uuid) => {
                if (
                  (((store.state.group[uuid] || {}).user || {}).name ||
                    false) === false
                ) {
                  // skip no data in user
                } else {
                  const user = store.state.group[uuid].user;
                  const dataObj = {
                    isGuest: false,
                    isModerator: false,
                    name: user.name,
                    userFunction: user.userFunction || "",
                    uuid: uuid,
                    isGroup: false,
                    isTeam: false,
                  };
                  this.addMember(dataObj);
                }
              });
            }
          }
          name = this.$t("components.conferenceForm.teamAdded", [idx]);
          // remove from array and clear entry
          // this.allTeams = this.allTeams.filter(e=>e.name !== idx)
          // delete this.allTeams[idx];
          this.selectedDropDowItem = [];
        }
      }
      // Add a slight delay to reset the input for better UX
      // https://gitlab.ra-micro.de/devcups/voffice/-/issues/490#note_28475
      this.$nextTick(() => {
        if (this.$refs.autocompleteAddUsersGuests) {
          this.$refs.autocompleteAddUsersGuests.inputValue = ''; // Clear internal input
          setTimeout(() => {
            this.closeAutocomplete(true); // true close menu and give focus
          }, 100);
        }
      });
      this.totalParticipants = this.getTotalInvited; // Trigger for getTotalInvited computed
      dispatchSuccessAlert(name);
    },
    doUpdateDocumentStore(conf) {
      const foundStore = this.myDocuments.find((e) => e.confId === conf.confId);
      if (foundStore && foundStore.confId) {
        foundStore.docMembersUUIDS = conf.confUUIDS;
        foundStore.moderators = conf.confModerators;
        updateCompanyDocumentEvent(foundStore);
      } else {
        this.createDocStore(conf);
      }
    },
    async deleteUser(user) {
      if (!user) return false; // missing param
      if (!user.isGroup && !user.isTeam) {
        if (!user.visitor) {
          const extant = store.state.group[user.uuid] || false;
          if (!extant) return false;
          this.conferenceMembers = this.conferenceMembers.filter(
            (e) => e.uuid !== user.uuid
          );
          this.allUsers = { ...this.allUsers, [user.uuid]: extant };
          if (this.tobeNotifiedDeleteUser.indexOf(user.uuid) === -1) {
            this.tobeNotifiedDeleteUser.push(user.uuid);
          }
        } else {
          const extant = store.state.group[user.visitorData.visitorId] || false;
          if (!extant) return false;
          this.conferenceMembers = this.conferenceMembers.filter(
            (e) => e.uuid !== user.visitorData.visitorIduuid
          );
          this.allUsers = { ...this.allUsers, [user.uuid]: extant };
          if (this.tobeNotifiedDeleteUser.indexOf(user.uuid) === -1) {
            this.tobeNotifiedDeleteUser.push(user.uuid);
          }
        }
        this.totalMembers--;
      } else if (user.isGroup) {
        const extant = store.state.user.groups.find(
          (e) => e.groupId === user.uuid
        );
        if (extant) {
          this.allGroups.push(extant);

          const idx = this.conferenceMembers.findIndex(
            (e) => e.uuid === user.uuid
          );
          if (idx !== -1) {
            this.conferenceMembers.splice(idx, 1);
          }
          const groupM = extant.groupMembers.map((e) => e.uuid);
          groupM.forEach((uuid) => {
            if (this.tobeNotifiedDeleteUser.indexOf(uuid) === -1) {
              this.tobeNotifiedDeleteUser.push(user.visitorData.visitorId);
            }
          });
        }
      } else if (user.isTeam) {
        const extant =
          this.state.namespaceSettings.processedOrganisation.teams[user.name];
        if (extant) {
          this.allTeams[user.name] = extant;

          const idx = this.conferenceMembers.findIndex(
            (e) => e.name === user.name
          );
          if (idx !== -1) {
            this.conferenceMembers.splice(idx, 1);
          }
          let tempUsers = extant.members || [];
          if (extant.supervisors && extant.supervisors.length) {
            extant.supervisors.forEach((element) => {
              if (tempUsers.indexOf(element.uuid) == -1) {
                tempUsers.push(element.uuid);
              }
            });
          }
          tempUsers.forEach((uuid) => {
            if (this.tobeNotifiedDeleteUser.indexOf(uuid) === -1) {
              this.tobeNotifiedDeleteUser.push(user.visitorData.visitorId);
            }
          });
        }
      }
    },
    async deleteUserNotification() {
      if (this.tobeNotifiedDeleteUser && this.tobeNotifiedDeleteUser.length && this.conferenceToEdit) {
        const tobeNotifiedDeleteUser = this.tobeNotifiedDeleteUser;
        this.tobeNotifiedDeleteUser = [];
        const conferenceToEdit = this.conferenceToEdit;
        const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
        const conference = {
          personalizedGreeting: conferenceToEdit.confPersonalizedGreet,
          confStartDate: conferenceToEdit.confStartDate,
          confStartTime: conferenceToEdit.confStartTime,
          inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
          confName: conferenceToEdit.confName,
          isUnlimited: conferenceToEdit.isUnlimited,
          durationMin: conferenceToEdit.confDuration,
          confDescription: conferenceToEdit.confDescription,
        };
        const text = await getInviteTextForCancellation(
          tZone,
          conference,
          false
        );
        let subject = this.$t("components.invitePreview.inviteCancelSubject");
        const mssgType = "conf-delete";
        const evName = conferenceToEdit.confName;
        const dates = {
          startEvent: conferenceToEdit.confStartDate,
          timeStart: conferenceToEdit.confStartTime,
        };
        let sendMsg = !conferenceToEdit.confIsTemp;
        this.sendMailToAll(
          tobeNotifiedDeleteUser,
          subject,
          text,
          mssgType,
          evName,
          dates,
          sendMsg
        );
      }
    },
    async resetForm() {
      this.forceExit= false;
      this.tobeNotifiedDeleteUser = [];
      this.ignoreDirty = false;
      this.showConfirmation = false;
      this.isUnlimited = true;
      this.isEditingConference = false;
      this.conferenceName = "";
      this.conferenceId = uuidv4();
      this.conferenceDescription = "";
      this.conferenceImage = this.state.namespaceSettings.companyLogo
        ? this.state.namespaceSettings.companyLogo
        : "/img/icon.png";
      this.durationMin = null;
      this.startEvent = new Date(); // Today as default
      this.endEvent = "";
      this.timeStart = null;
      this.timeEnd = null;
      this.conferenceMembers = [];
      this.selectedDropDowItem = [];
      this.expandReocurring = [];
      this.scheduledInterval = "";
      this.confFrequency = 0;
      this.pinCode = "";
      this.isEditingTemplate = false;
      this.personalizedGreeting = "";
      this.allUsers = JSON.parse(JSON.stringify(this.state.group || {}));
      // this.allTeams = JSON.parse(
      //   JSON.stringify(
      //     this.state.namespaceSettings.processedOrganisation.teams || {}
      //   )
      // );
      this.allTeams = JSON.parse(
        JSON.stringify(
          this.getOrganigramTeams
        )
      );
      this.allGroups = JSON.parse(JSON.stringify(this.state.user.groups || []));
      await this.populateDefaultModerator();
      await this.resetSnapShots();
    },
    populateDefaultModerator() {
      let extant = false;
      this.conferenceMembers.forEach((member) => {
        if (member.uuid === store.state.ownUUID) {
          extant = true;
        }
      });
      if (extant) return;
      const tmpObj = {
        name: this.uuidToRepresentStore ? this.getNameForUuid(this.uuidToRepresentStore) : store.state.user.name,
        uuid: this.uuidToRepresentStore ? this.uuidToRepresentStore : store.state.ownUUID,
        isModerator: true,
        isGuest: false,
        userFunction: this.state.group[this.uuidToRepresentStore ? this.uuidToRepresentStore : store.state.ownUUID].user.position || "",
        isGroup: false,
        isTeam: false,
      };
      this.conferenceMembers.push(tmpObj);
    },
    clickOnAddCard(date = null) {
      if (
       this.$route.path !== "/newconference" &&
       this.$route.path !== "/newconference/"
      ) {
        this.$router.push({ path: "/newconference/" });
      }
      this.isInserting = true;
      this.ignoreDirty = false;
      this.viewingConferences = false;
      this.conferenceToEdit = null;
      this.isEditingConference = false;
      this.getInitSnapShot();
      if(date) {
        // prefix initial hour and day
        // const splittedDay = date[1]
        // const initialHour = `${String(new Date(parseInt(splittedDay)).getHours()).padStart(2, '0')}:00`;
        // const to get the current day and not get the conflict with parameter
        // Assumes "date" is a valid date string or timestamp that can be parsed by `new Date()`
        const splittedDay = new Date(date).getTime();
        const currentDay = new Date().getTime();

        if (currentDay - 60000 > splittedDay) {
          // If the current day and time is greater than 'date' plus one minute,
          // set startEvent to the current date without a specific hour.
          this.startEvent = new Date();
        } else {
          // Else, set startEvent to the exact date and hour from 'date'
          this.startEvent = new Date(date);

          // Format the initial hour correctly as HH:00. No need to pad here since hours will always be double digits.
          const initialHour = `${this.startEvent.getHours().toString().padStart(2, '0')}:00`;
          this.timeStart = initialHour;
        }

        // Assign the startEvent to initSnapShot in one place to avoid repetition
        this.initSnapShot.startEvent = this.startEvent;

        // If timeStart has been set, we assign it, otherwise, don’t.
        if (this.timeStart) {
          this.initSnapShot.timeStart = this.timeStart;
        }

      } else {
        // set actual day without initial hour
        const fdate = new Date();
        this.startEvent = fdate;
        this.initSnapShot.startEvent = this.startEvent;
      }
    },
    getInitSnapShot() {
      this.initSnapShot = {
        name: this.conferenceName == null ? "" : this.conferenceName,
        greet: this.personalizedGreeting,
        members: this.conferenceMembers.length || 0,
        start: this.startEvent,
        startTime: this.timeStart,
        end: this.timeEnd,
        desc: this.conferenceDescription,
        img: this.conferenceImage,
        pin: this.pinCode,
        docsTemp: this.tempDocumentStore,
      };
    },
    async getPostSnapShot() {
      this.postSnapShot = {
        name: this.conferenceName == null ? "" : this.conferenceName,
        greet: this.personalizedGreeting,
        members: this.conferenceMembers.length || 0,
        start: this.startEvent,
        startTime: this.timeStart,
        end: this.timeEnd,
        desc: this.conferenceDescription,
        img: this.conferenceImage,
        pin: this.pinCode,
        docsTemp: this.tempDocumentStore,
      };
    },
    saveConferenceImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files || !files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.temporalImage = e.target.result;
        this.showCroppie = true;
      };
      reader.readAsDataURL(files[0]);
    },
    async uploadConferenceImage(id, img) {
      var location = await new Promise((resolve, reject) => {
        uploadConferenceImage(id, img).then(resolve).catch(reject);
      });
      var url = "/" + location + "?_=" + Date.now();
      this.conferenceImage = url;
      this.resetConferenceImageInput();
    },
    resetConferenceImageInput() {
      this.$refs.conferenceImageInput.value = "";
    },
    setConferenceImage() {
      this.$refs.conferenceImageInput.click();
    },
    async callNow() {
      // create temp object
      let tempConf;
      if (!this.conferenceToEdit) {
        const isTemp = true;
        this.isUnlimited = true;
        this.conferenceId = uuidv4();
        this.conferenceDescription = this.conferenceName + "-Temp";
        this.conferenceImage = this.state.namespaceSettings.companyLogo
          ? this.state.namespaceSettings.companyLogo
          : "/img/icon.png";
        const conferenceISOStartDate = new Date();
        this.startEvent = conferenceISOStartDate;
        this.endEvent = this.startEvent;
        this.timeStart = this.formatTime(conferenceISOStartDate);
        this.timeEnd = this.timeStart;
        this.durationMin = null;
        tempConf = await this.saveConference(isTemp);
      } else {
        tempConf = this.conferenceToEdit;
      }
      if (tempConf && tempConf.confId) {
        const myConference = this.myConferenceDetails.find(
          (e) => e.confId == tempConf.confId
        );
        if (myConference && myConference.confId){
          this.callGroup(myConference, this.conferenceMembers);
          // send email to offline participants
          const timelineEv = this.timelineEvents.find(
            (e) => e.confId === myConference.confId
          );
          if (timelineEv){
            const optionsSendMailCoferenceCall = {
              eventUUID : timelineEv.uuid
            }
            let usersUuid = this.conferenceMembers.map((user)=>user.uuid)
            this.sendMailConferenceCallOfflineUser(usersUuid, optionsSendMailCoferenceCall)
          }
        }
      }
    },
    callEveryone(waitingRoomMode = false, conference = null) {
      // await this.doUpdateConference();
      this.resetSnapShots();
      if (conference && conference.confId) {
        this.conferenceToEdit = conference;
      }
      // const extantEvent = this.timelineEvents.find(
      //   (e) => e.confId === this.conferenceToEdit.confId
      // );
      if (this.conferenceToEdit.confDuration) {
        const duration = Math.floor(
          this.conferenceToEdit.confDuration * 60 * 1000
        );
        this.setdurationMeeting(duration);
      } else {
        const duration = Math.floor(1440 * 60 * 1000);
        this.setdurationMeeting(duration);
      }

      if (this.conferenceToEdit.confUUIDS) {
        let usersToCall = [];
        const usersFunction = [];
        const regUsers = [];
        const mobUsers = [];
        let doCall = true;
        const usersToHideName = [];
        this.$router.push({ path: "/home" });
        this.conferenceToEdit.confUUIDS.forEach((uuid) => {
          if (uuid !== this.state.ownUUID) {
            const isConnected =
              (store.state.group[uuid] || {}).connected || false;
            const person = store.state.group[uuid];
            if (
              person &&
              person.user &&
              !person.user.rtcCallStatus &&
              !person.user.inBridgeCall
            ) {
              if (!this.amInACall) {
                if (
                  isConnected &&
                  (person.user.activity || false) !== "Holidays" &&
                  (person.user.activity || false) !== "Out of Office" &&
                  (person.user.activity || false) !== "No Calls" &&
                  (person.user.activity || false) !== "Break" &&
                  this.hasUserRejected(uuid) == false
                ) {
                  if (person.user.isMobile) {
                    mobUsers.push(uuid);
                  } else {
                    regUsers.push(uuid);
                  }
                  if (!waitingRoomMode) {
                    usersToCall.push(uuid);
                  }
                  if (!usersFunction[uuid]) {
                    if (
                      this.state.group[uuid].user.position ||
                      this.state.group[uuid].user.visitorData?.function
                    ) {
                      usersFunction[uuid] =
                        this.state.group[uuid].user.position ||
                        this.state.group[uuid].user.visitorData.function;
                    } else {
                      usersFunction[uuid] = "";
                    }
                  }
                }
              }
            }
            if (person && person.user && person.user.inBridgeCall && userInStaticRoom(uuid)) {
              usersToCall.push(uuid);
            }
          } else {
            if (!usersFunction[uuid]) {
              if (
                this.state.group[uuid].user.position ||
                this.state.group[uuid].user.visitorData?.function
              ) {
                usersFunction[uuid] =
                  this.state.group[uuid].user.position ||
                  this.state.group[uuid].user.visitorData.function;
              } else {
                usersFunction[uuid] = "";
              }
            }
          }
        });
        this.conferenceMembers.forEach((member) => {
          if (member.showUserName == false) {
            usersToHideName.push(member.uuid);
          }
        });
        /*
        if (mobUsers.length && regUsers.length) {
          // SHOW POPUP MOBILES CANT BE CALLED
          dispatchErrorAlert(this.$t("components.conferenceForm.mobAdvice"));
          if (usersToCall.length) usersToCall = regUsers;
        } else if (!regUsers.length && mobUsers.length > 1) {
          // SHOW POPUP MOBILES CANT BE CALLED
          dispatchErrorAlert(this.$t("components.conferenceForm.mobAdvice"));
          if (usersToCall.length) doCall = false;
        }
        */
        if (doCall) {
            const id = uid();
            const info = {
              callUUID: id,
              calling: usersToCall,
              isNormalMode:
                this.conferenceToEdit.confUUIDS.length > 0 ? true : false,
              initiator: this.state.ownUUID,
              callStartTs: Date.now(),
              callDurationMs: this.conferenceToEdit.isUnlimited
                ? 24 * 3600 * 1000
                : Math.floor(this.conferenceToEdit.confDuration * 60000) ||
                  60000 * 20,
              infiniteCall: false,
              conferenceDocuments: [], // this.documentsPdf || [],//newGroupDate.pdfDocuments || [],
              userConferencePosition: {}, //usersFunction || {},
              excludedFromCalling: [], //usersToExclude || [],
              conferenceAttendees: this.getAttendeesObject(
                this.conferenceToEdit.confUUIDS || []
              ),
              confModerators: this.conferenceToEdit.confModerators || [],
              conferenceSettings: {}, //newGroupDate.settings || {},
              usersToHideName: usersToHideName || [],
              isConference: true,
              confId: this.conferenceToEdit.confId,
              isConferenceCall: this.conferenceToEdit.confId || undefined,
              recording: this.recording,
              transcript: this.transcript,
              privateTranscript: this.privateTranscript,
            };
            store.addRemoteBridgeStream(id, info);
            store.setCurrentContentVisile("", false, this.$router);
            const interval = setInterval(() => {
              if (store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[id] && store.state.remoteBridgeStreams[id].roomId) {
                clearInterval(interval);
                usersToCall.forEach((user, idx) => {
                  const infoStartBridgeCall = {
                    callUUID: id,
                    isNormalMode:
                      this.conferenceToEdit.confUUIDS.length > 0 ? true : false,
                    initiator: this.state.ownUUID,
                    callStartTs: Date.now(),
                    callDurationMs: this.conferenceToEdit.isUnlimited
                      ? 24 * 3600 * 1000
                      : Math.floor(this.conferenceToEdit.confDuration * 60000) ||
                        60000 * 20,
                    infiniteCall: false,
                    conferenceDocuments: [], // this.documentsPdf || [],//newGroupDate.pdfDocuments || [],
                    userConferencePosition: {}, //usersFunction || {},
                    excludedFromCalling: [], //usersToExclude || [],
                    conferenceAttendees: this.getAttendeesObject(
                      this.conferenceToEdit.confUUIDS || []
                    ),
                    confModerators: this.conferenceToEdit.confModerators || [],
                    conferenceSettings: {}, //newGroupDate.settings || {},
                    usersToHideName: usersToHideName || [],
                    isConference: true,
                    confId: this.conferenceToEdit.confId,
                    isConferenceCall: this.conferenceToEdit.confId || undefined,
                    isPinProtected: this.pinCode.length > 0,
                    pinCode: this.pinCode.length > 0 ? this.pinCode : false,
                    roomGuid: store.state.remoteBridgeStreams[id].roomGuid || undefined,
                    roomId: store.state.remoteBridgeStreams[id].roomId || undefined,
                    recording: this.recording,
                    transcript: this.transcript,
                    privateTranscript: this.privateTranscript,
                  };
                  if (!hasPrivilege(user)) {
                    markAsCallWaitingRoomUserEvent(user);
                  }
                  wsCallStartBridgeCall(
                    user,
                    this.state.ownUUID,
                    infoStartBridgeCall,
                    idx > 0
                  );
                });
              } else if (!store.state.remoteBridgeStreams[id]) {
                clearInterval(interval);
              }
            }, 200);
            // send email to offline participants
            const timelineEv = this.timelineEvents.find(
              (e) => e.confId === this.conferenceToEdit.confId
            );
            if (timelineEv){
              const optionsSendMailCoferenceCall = {
                eventUUID : timelineEv.uuid
              }
              this.sendMailConferenceCallOfflineUser(this.conferenceToEdit.confUUIDS, optionsSendMailCoferenceCall)
            }
          }
        }
    },
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach((uuid) => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true,
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    setdurationMeeting(duration) {
      return store.setdurationMeeting(duration);
    },
    ///////////////////////////////////
    // CALLS SECTION  END
    //////////////////////////////////

    ///////////////////////////////////
    // EMAIL SECTION  START
    //////////////////////////////////
    async createInvitations(confObject = null, eventId) {
      if (confObject) {
        this.conferenceToEdit = confObject;
      }
      const filteredGuests = this.conferenceToEdit.confUUIDS.filter(
        (e) => store.state.group[e].user.visitorData
      );
      const realUsers = this.conferenceToEdit.confUUIDS.filter(
        (e) => !store.state.group[e].user.visitorData
      );
      // invite guests by mail
      for (const item of filteredGuests) {
        let tmpUserObj = {};
        let user = (store.state.group[item] || {}).user || false;
        if (user) {
          tmpUserObj = {
            name: user.name,
            uuid: user.uuid,
            email: store.state.group[user.uuid].user.email || "",
            mobilePhone: store.state.group[user.uuid].user.mobilePhone || "",
          };
          await setRemoteUser(user.uuid, {
            visitorData: {
              ...store.state.group[user.uuid].user.visitorData,
              linkDateFrom: this.formattedStartDate,
              linkTimeFrom: this.timeStart,
              linkDateTo: this.formattedStartDate,
              linkTimeTo: this.timeEnd,
            },
          });
          await this.createGuestInvite(tmpUserObj, eventId, confObject);
        }
      }
      // invite users by mail
      for (const item of realUsers) {
        let tmpUserObj = {};
        let user = (store.state.group[item] || {}).user || false;
        // if (user && user.uuid !== store.state.ownUUID) {
        if (user && user.uuid) {
          tmpUserObj = {
            name: user.name,
            uuid: user.uuid,
            email: store.state.group[user.uuid].user.email || "",
            mobilePhone: store.state.group[user.uuid].user.mobilePhone || "",
          };
          await this.createUserInvite(tmpUserObj, eventId, confObject);
        }
      }
      // send user invite by message
      if (realUsers && realUsers.length > 0) {
        const tmpUsers = [];
        realUsers.forEach(async (user) => {
          if (user !== this.state.ownUUID) {
            tmpUsers.push(user);
          }
          if (
            ((((store.state.group[user] || {}).user || {}).userSettings || {})
              .showVideoCallPlanner || true) == false && user !== this.state.ownUUID
          ) {
            await setRemoteUser(user, {
              userSettings: { showVideoCallPlanner: true },
            });
          }
        });
        if (tmpUsers.length > 0 && !this.conferenceToEdit.confIsTemp) {
          const usersUuid = tmpUsers.filter(
            (uuid) => uuid !== this.state.ownUUID
          );
          this.sendUserMessage(
            usersUuid,
            "conf-invite",
            confObject.confName,
            eventId
          );
        }
        if (
          confObject.confUUIDS &&
          confObject.confUUIDS.length &&
          confObject.confUUIDS.length > 1
        ) {
          dispatchSuccessAlert(
            this.$t("components.conferenceForm.invitationsSent")
          );
        }
      }
    },
    doGetInviteTextForUser(asHtml = false) {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting: this.personalizedGreeting,
        confStartDate: moment(this.startEvent).toISOString(true).slice(0, 10),
        confStartTime: this.timeStart,
        inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
        confName: (this.isTemplate && this.conferenceName.groupName) ?  this.conferenceName.groupName : this.conferenceName,
        isUnlimited: this.timeEnd ? false : true,
        durationMin: this.durationMin,
        confDescription: this.conferenceDescription,
      };
      getInviteTextForUser(tZone, conf, asHtml).then((res) => {
        this.uInviteText = res;
        return res;
      });
    },
    doGetInviteTextForGuest(asHtml = false) {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting: this.personalizedGreeting,
        confStartDate: moment(this.startEvent).toISOString(true).slice(0, 10),
        confStartTime: this.timeStart,
        inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
        confName: (this.isTemplate && this.conferenceName.groupName) ?  this.conferenceName.groupName : this.conferenceName,
        isUnlimited: this.timeEnd ? false : true,
        durationMin: this.durationMin,
        confDescription: this.conferenceDescription,
      };
      return getInviteTextForGuest(tZone, conf, asHtml).then((res) => {
        this.gInviteText = res;
        return res;
      });
    },
    // user reminder invite
    async createUserReminderInvite(user, eventId, confObject) {
      const subject = this.$t("components.invitePreview.inviteRemSubject");
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting:
          confObject.confPersonalizedGreet || this.personalizedGreeting,
        confStartDate: confObject.confStartDate,
        confStartTime: confObject.confStartTime,
        inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
        confName: confObject.confName || this.conferenceName,
        isUnlimited: confObject.isUnlimited,
        durationMin: confObject.confDuration,
        confDescription: confObject.confDescription,
      };
      getInviteTextForRem(tZone, conf, false).then((res) => {
        const odata = {
          inviteText: res,
          subject: subject,
          subjectDisplay: subject,
        };
        odata.variables = {};
        return sendUserEmail(user, odata, { eventUUID: eventId });
      });
    },
    // user invite mail
    async createUserInvite(user, eventId, confObject) {
      const subject = this.$t(
        "components.inviteVisitor.emailConferenceSubject"
      );
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting:
        confObject.confPersonalizedGreet || this.personalizedGreeting,
        confStartDate: confObject.confStartDate,
        confStartTime: confObject.confStartTime,
        inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
        confName: confObject.confName || this.conferenceName,
        isUnlimited: confObject.isUnlimited,
        durationMin: confObject.confDuration,
        confDescription: confObject.confDescription,
      };
      getInviteTextForUser(tZone, conf, false).then((res) => {
        const odata = {
          inviteText: res,
          subject: subject,
          subjectDisplay: subject,
        };
        odata.variables = {};
        return sendUserEmail(user, odata, { eventUUID: eventId });
      });
    },
    // guest invite mail
    async createGuestInvite(guestObject, meetingId, confObject) {
      const subject = this.$t(
        "components.inviteVisitor.emailConferenceSubject"
      );
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting:
          confObject.confPersonalizedGreet || this.personalizedGreeting,
        confStartDate: confObject.confStartDate,
        confStartTime: confObject.confStartTime,
        inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
        confName: confObject.confName || this.conferenceName,
        isUnlimited: confObject.isUnlimited,
        durationMin: confObject.confDuration,
        confDescription: confObject.confDescription,
      };
      getInviteTextForGuest(tZone, conf, false).then((res) => {
        const odata = {
          visitorId: guestObject.uuid,
          name: guestObject.name,
          email: guestObject.email || "",
          mobilePhone: guestObject.mobilePhone || "",
          inviteText: res,
          subject: subject,
          subjectDisplay: subject,
        };

        if (!guestObject.email && guestObject.mobilePhone) {
          odata.inviteText =
            this.state.user.name + " " + this.virtualOfficePrefix + "\n";
          odata.inviteText +=
            this.state.namespaceSettings.customVisitorWhatsAppInviteText ||
            this.$tm("components.inviteVisitor.whatsappText").join("\n");
        }
        odata.variables = {};
        return sendVisitorEmail(odata, { eventUUID: meetingId });
      });
    },
    // guest invite reminder
    async createGuestReminderInvite(guestObject, meetingId, confObject) {
      const subject = this.$t("components.invitePreview.inviteRemSubject");
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting:
          confObject.confPersonalizedGreet || this.personalizedGreeting,
        confStartDate: confObject.confStartDate,
        confStartTime: confObject.confStartTime,
        inviter: this.uuidToRepresentStore ? `${this.getTitelForUuid(this.uuidToRepresentStore)} ${this.getNameForUuid(this.uuidToRepresentStore)} ${this.$t("components.conferenceForm.perProcuration")}` : `${this.getTitelForUuid(store.state.ownUUID)} ${this.getNameForUuid(store.state.ownUUID)}`,
        confName: confObject.confName || this.conferenceName,
        isUnlimited: confObject.isUnlimited,
        durationMin: confObject.confDuration,
        confDescription: confObject.confDescription,
      };
      getInviteTextForRem(tZone, conf, false).then((res) => {
        const odata = {
          visitorId: guestObject.uuid,
          name: guestObject.name,
          email: guestObject.email || "",
          mobilePhone: guestObject.mobilePhone || "",
          inviteText: res,
          subject: subject,
          subjectDisplay: subject,
        };

        if (!guestObject.email && guestObject.mobilePhone) {
          odata.inviteText =
            this.state.user.name + " " + this.virtualOfficePrefix + "\n";
          odata.inviteText +=
            this.state.namespaceSettings.customVisitorWhatsAppInviteText ||
            this.$tm("components.inviteVisitor.whatsappText").join("\n");
        }
        odata.variables = {};
        return sendVisitorEmail(odata, { eventUUID: meetingId });
      });
    },
    ///////////////////////////////////
    // EMAIL SECTION  END
    //////////////////////////////////
    async sendUserMessage(uuids, type, title, eventId) {
      const timezone =
        store.state.namespaceSettings.timezone || "Europe/Berlin";
      const confDate =
        this.startEvent && this.timeStart
          ? new Date(
            new Date(this.startEvent).toISOString().slice(0, 10) +
                "T" +
                this.timeStart
                  .split(":")
                  .map((e) => e.padStart(2, "0"))
                  .join(":") +
                ":00" +
                moment(this.startEvent).set("hour", +this.timeStart.split(':')[0]).set("minute", +this.timeStart.split(':')[1]).format('Z')
            )
          : new Date();
      const confMoment = this.moment(confDate).tz(timezone);

      if (uuids.length > 0) {
        let notificationMssg = "";
        let header = "";
        if (type == "conf-invite") {
          const dateParam =
            `${confMoment.format("DD.MM.YY, HH:mm")}` +
            " " +
            this.$t("components.meetingLineModal.hours") +
            " " +
            `(${timezone})`;
          notificationMssg = this.$t(
            "components.addConferenceModal.headerConferenceInvite",
            [title, store.state.group[store.state.ownUUID].user.name, dateParam]
          );
          header = {
            meetingId: eventId,
            typeMsg: "newConference",
            translateMessage: {
              translateKey:
                "components.addConferenceModal.headerConferenceInvite",
              params: [
                title,
                store.state.group[store.state.ownUUID].user.name,
                dateParam,
              ],
            },
          };
        }
        if (uuids && uuids.length) {
          uuids = uuids.filter((uuid) => uuid !== store.state.ownUUID);
          await parallel(1, [...uuids], async (user) => {
            const dataMsg = prepadeDataToSendMessageEffector(
              user,
              notificationMssg +
                " " +
                this.$t("components.meetingLineModal.hours"),
              header,
              "plannerEvent",
              true
            );
            newMessageEvent(dataMsg);
          });
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.colorWhite{
  color: white !important;
}
.text-bold {
  font-weight: bold !important;
}
.participantName, .participantCategory {
  font-size: 13px !important;
}
.hover-gray:hover{
  color: white;
  background-color: rgb(117, 117, 117);
}
.participantsButMobile {
  background-color: white;
  height: 36px;
  padding-left: 12px;
}
.participantsBut {
  background-color: white;
  position: absolute;
  top: -62px;
  right: 2px;
  height: 36px;
  width: 365px;
}
.joinConferenceClass{
  position: fixed;
  background-color: rgb(255, 165, 0);
  right: 50px;
}
.totalParticipantsClass{
  position: fixed;
  right: 275px;
}
.totalParticipantsClassRight
{
  position: fixed;
  right: 50px;
}
.v-theme--dark .participantsButMobile, .v-theme--dark .participantsBut {
  background-color: #313131;
}
.center {
  margin: 0 auto;
}
.rowUsersConference {
  flex-direction: row;
  flex-wrap: wrap;
}
.row.justifyRight {
  justify-content: flex-end;
  height: 100%;
}
.maxWidth35 {
  // flex: 0 0 35% !important;
  // max-width: 35% !important;
  height: 270px;
}
.maxWidth33 {
  flex: 0 0 33% !important;
  max-width: 33% !important;
}
.maxWidth25 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}
.maxWidth20 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}
.uploadGroupImage {
  position: absolute;
  bottom: 8px;
  left: 12px;
}
.borderRadius-4 {
  border-radius: 4px;
}
.v-theme--light.v-btn.list {
  color: rgb(117, 117, 117);
}
.list {
  text-transform: none;
  font-weight: 300;
  padding-left: 5px !important;
  letter-spacing: 0;
  font-size: 16px !important;
}
.no-bg {
  background-color: transparent !important;
}
.deleteBasketButtonNormal {
  color: rgb(117, 117, 117) !important;
}
.deleteBasketButtonActive {
  color: red !important;
  font-weight: 600 !important;
}
.justStart {
  justify-content: flex-start;
}
.confImage {
  max-width: 160px;
  max-height: 125px;
}
.v-select__selection {
  display: none;
}
.w-100 {
  width: 100%;
}
.heightScroll {
  min-height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  overflow: auto;
}
.userList {
  overflow: auto;
  max-height: 100%;
  height: calc(100vh - 290px);
  position: relative;
}
.titleUserDiv {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
@media (max-width: 959.9px) {
  .userDiv {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .userDiv {
    width: 50%;
  }
}
.userDiv {
  display: inline-block;
  text-align: left;
  padding: 12px;
}

.userAvatar {
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: middle;
}

.userName {
  vertical-align: middle;
}

.hoverDiv {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  display: table;
  text-align: center;
  z-index: 1;
  background: #78787854;

  & div {
    height: 100%;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    svg {
      font-size: 30px;
      color: white;
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

.buttonAdd {
  font-size: 49px;
  color: var(--v-primary-base);
}

.divAdd {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.subTitleCard {
  color: rgb(255, 255, 255) !important;
  margin-top: -6px !important;
}

.v-theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}

.v-theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}

.gradientBg {
  // min-height: 45px;
  // max-height: 45px;
  position: relative;
}

.buttonFlip {
  font-size: 20px;
  width: 1.25em;
}

.customFontSize {
  font-size: 13px !important;
  word-break: break-word;
  max-width: 85%;
}

.colorWhite {
  color: white !important;
  opacity: 1!important;
}

/*.v-theme--dark.v-btn.v-btn--disabled {
  color: #00000042 !important;
}*/

.buttonCallFrontCard {
  background: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  // padding: 2px !important;
  font-size: 0px !important;
  // position: relative !important;
  // right: -2px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.divIconUserOnline {
  text-align: center;
  height: 25px;
  width: 25px;
  background: white;
  border-radius: 50%;
  display: table;

  & .frontOnline {
    width: 17px;
    vertical-align: middle;
    margin-top: 4px !important;
  }
}

.iconCardsContainerBlack {
  position: absolute;
  top: 0;
  right: 0;
  // background: #00000054;
  background: unset;
  height: 120px;
}

.iconCardsContainer {
  position: absolute;
  right: 0;
  top: 0;
  height: 28px;
}
.contentGridWrapper {
  min-height: calc(100vh - 210px);
  overflow-y: visible;
}

.custom6cols {
  width: 16%;
  flex-basis: 16%;
}

.cardWidth {
  max-width: 235px;
  box-shadow: none !important;
}

@media (max-width: 765px) {
  .contentGridWrapper .custom6cols {
    width: 50%;
    min-width: 50%;
  }
}

// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}

@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .contentGridWrapper .custom6cols {
    width: 20% !important;
    flex-basis: 20% !important;
  }
}

@media (max-width: 890px) {
  .contentGridWrapper .custom6cols {
    width: 25% !important;
    flex-basis: 25% !important;
  }
}

@media (min-width: 765px) and (max-width: 1024px) {
  .cardWidth {
    max-width: 175px !important;
    min-width: 175px !important;
  }
}

.footerCard {
  background: white;
  padding: 0 !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.v-card__subtitle {
  color: black !important;
}

.v-card__title {
  font-size: 15px !important;
}

.opactity0 {
  opacity: 0;
}

.flipButton {
  padding: 0 5px;
  position: absolute;
  right: 5px;
  bottom: 13px;
  color: black;
  cursor: pointer;
  font-size: 22px;
}
</style>
<style lang="scss">
.v-picker-title{
  display: none;
}
.v-combobox {
.v-input__details {
  padding-top: 0px !important;
    margin-top: -15px !important;
    margin-bottom: 15px;
  }
}
.height-40{
  .v-input__control {
    .v-field {
      height: 50px !important;
    }
  }
}
.deleteBasketPosition {
  position: absolute;
  right: 10px;
}
.timePicker input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    pointer-events: none;
}
.v-list-item__content {
  padding: 0px !important;
}
.frequencyDenominator {
  max-width: 70px !important;
}
.frequencyInput {
  max-width: 28px !important;
}
.frequencyButton {
  max-width: 30px;
}
.scheculedConf {
  .v-list-group__header {
    padding: 0px;
    min-height: unset;
  }
  .v-list-item--link:before {
    background-color: transparent !important;
  }
}
.selectorBack {
  width: 95%;
  max-width: 95%;
  overflow-x: hidden;
  background-color: #e4f4f6;
  margin: 3px;
  padding-left: 7px;
  cursor: pointer;
}
.v-theme--dark .selectorBack {
  background-color: #e4f4f61a;
}
.borderError {
  fieldset {
    border-color: red !important;
  }
}
.w-90 {
  width: 90%;
}
.logoAvatar {
  position: relative;
  top: 10px;
}
.v-theme--dark .deleteBasketButtonNormal {
  color: white !important;
}
</style>