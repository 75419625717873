 <template>
  <span v-if="person && person.user && person.user.uuid">
    <template v-if="organisationData && organisationData.name">
      <span>{{ organisationData.name }}</span>
      <br />
    </template>
    <!-- <template v-if="organisationData && organisationData.position">
      <span>{{ organisationData.position }}</span>
      <br />
    </template> -->
    <template v-if="person && person.user && person.user.uuid">
      <span
        >{{ assistant ? "Assistant" : getUserTitel(person.user.uuid) }}
        {{ person.user.name }}</span
      >
      <br
        v-if="hasRepresentative(person.user.uuid)"
      />
      <span v-if="hasRepresentative(person.user.uuid)">{{ getUserRepresentative(person.user.uuid) }}</span>
      <br
        v-if="
          person.user.position ||
          (person.user.visitorData &&
            person.user.visitorData.noteForContact &&
            person.user.visitorData.noteForContact.length)
        "
      />
    </template>
    <span v-if="hasPrivilege(person.user.uuid) && person.user.position">{{
      person.user.position
    }}</span>
    <span
      v-if="
        !hasPrivilege(person.user.uuid) &&
        person.user.visitorData &&
        person.user.visitorData.noteForContact
      "
      >{{ person.user.visitorData.noteForContact }}</span
    >
    <template
      v-if="
        hasPrivilege(person.user.uuid) && getLocationTranslated(person.user)
      "
    >
      <br />
      <span>{{ getLocationTranslated(person.user) }}</span>
    </template>
    <template v-if="state.namespaceSettings.displayUserStatus">
      <template v-if="!isGuest && userInStaticRoom(person.user.uuid)">
        <br/>
        <span class="bold">
          {{getStaticRoomName(person.user.uuid)}}
        </span>
      </template>
      <br v-if="getUserActivity(person) && !isGuest" />
      <span v-if="!isGuest">
        <font-awesome-icon
          :icon="['fal', 'video']"
          v-if="getCallText(person) && person.user.activity !== 'No status'"
        />
        {{
          getCallText(person) && person.user.activity !== "No status"
            ? getCallText(person)
            : checkStatusLabel
        }}
      </span>
      <!-- https://gitlab.ra-micro.de/devcups/voffice/-/issues/461 -->
      <br v-if="userInStaticRoom(person.user.uuid) && person.user.activity !== 'No status' && person.user.activity !== 'Available' && getCallText(person)" />
      <span v-if="userInStaticRoom(person.user.uuid) && person.user.activity !== 'No status' && person.user.activity !== 'Available' && getCallText(person)">{{ checkStatusLabel }}</span>
      <!-- end https://gitlab.ra-micro.de/devcups/voffice/-/issues/461 -->
      <br
        v-if="
          (!getCallText(person) && getPersonInHolidays && !isGuest) ||
          (getCallText(person) && getPersonInHolidays) ||
          (!getCallText(person) && getPersonInIllness && !isGuest) ||
          (getCallText(person) && getPersonInIllness)
        "
      />
      <span v-if="getPersonInHolidays && !isGuest"
        >{{ moment(new Date(person.user.startHolidays)).format("DD.MM.YY") }}
        -
        {{ moment(new Date(person.user.endHolidays)).format("DD.MM.YY") }}</span
      >
      <span v-if="getPersonInIllness && !isGuest"
        >{{ moment(new Date(person.user.startIllness)).format("DD.MM.YY") }}
        -
        {{ moment(new Date(person.user.endIllness)).format("DD.MM.YY") }}</span
      >
      <template
        v-if="person.user.activity == 'Break' && person.user.breakTimeEnd"
      >
        <br />
        <span> {{ breakTimeEnd }} {{ $t("generics.hours") }} </span>
      </template>
      <br />
      <span v-if="getAdditionalStatus" :style="`white-space: pre-line;`">
        {{ getAdditionalStatus }}
      </span>
    </template>
  </span>
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { userInCallText, userInStaticRoom, getStaticRoomName } from "../../utils/calls";
import {
  locationTranslated,
  userHasHolidaysSetted,
  userHasIllnessSetted,
  getUserActivityFunction,
} from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges.js";
import { hasRepresentative } from "../../utils/representatives";
export default {
  props: ["person", "assistant", "organisationData"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      moment: moment,
    };
  },
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getLocationTranslated(person) {
      return locationTranslated(person);
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getUserRepresentative(uuid) {
      return store.getUserRepresentativeName(uuid);
    },
    getCallText(person) {
      return userInCallText(person);
    },
    userInStaticRoom(uuid) {
      return userInStaticRoom(uuid);
    },
    getStaticRoomName(uuid) {
      return getStaticRoomName(uuid);
    },
    getUserActivity(person) {
      return getUserActivityFunction(person);
    },
    hasRepresentative(uuid) {
      return hasRepresentative(uuid);
    }
  },
  computed: {
    getAdditionalStatus() {
      const noCallsAdditionalInfo =
        this.person?.user?.noCallsAdditionalInfo === 'components.conferenceForm.externalConference'
          ? this.$t(this.person.user.noCallsAdditionalInfo)
          : this.person?.user?.noCallsAdditionalInfo;
      return ((noCallsAdditionalInfo || "") + "\n" + (this.person?.user?.additionalStatus || "")).trim();
    },
    breakTimeEnd() {
      if ((this.person.user.breakTimeEnd || '').length > 5 && Date.parse(this.person.user.breakTimeEnd)) {
        return moment(this.person.user.breakTimeEnd).format("HH:mm");
      }
      return this.person.user.breakTimeEnd;
    },
    checkStatusLabel() {
      if (
        this.person.user.activity === "Break" &&
        this.person.user.breakTimeEnd
      ) {
        return this.$t("generics.expectedReturn");
      } else {
        if (this.getPersonInHolidays) {
          return this.$t('status.Holidays')
        } else if (this.getPersonInIllness) {
          return this.$t('status.Ill')
        } else {
          return this.getUserActivity(this.person);
        }
      }
    },
    getPersonInHolidays() {
      return userHasHolidaysSetted(this.person.user.uuid);
    },
    getPersonInIllness() {
      return userHasIllnessSetted(this.person.user.uuid)
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>