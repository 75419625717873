<template>
<div :class="`alertContainer ${isMobile ? 'alertMobile' : ''}`">
  <AlertItem class="alertItem" v-for="item of alerts" :key="item.id" :item="item" />
</div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import AlertItem from "./alertItem.vue";
import { isMobile } from "../../lib/mobileUtil";
import { alertsArrayStore } from '../../effector/alerts';

export default {
  components: {
    AlertItem,
  },
  data() {
    const effector = {
      alerts: alertsArrayStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return { 
      isMobile: isMobile(),
      // Effector
      ...effector,
    };
  },
};
</script>

<style lang="scss" scoped>
.alertMobile{
  left: 0px !important;
  width: 100% !important;
  transform: none !important;
}
.alertContainer {
    position: fixed;
    bottom: 25px;
    left: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
.alertItem {
  max-width: 100%;
}
</style>
