<template>
  <div>
    <v-dialog
      :model-value="showAdminSettings" @update:model-value="showAdminSettings = $event"
      persistent
      max-width="950"
      :fullscreen="isMobile"
      @keydown.esc="toggleModalAdminSettings"
    >
      <v-card
        :class="{ height494: showCroppieLogo, heightAuto: !showCroppieLogo }"
        flat
      >
        <HeaderModal
          :titleModal="$t('generics.adminSettings')"
          :closeModalFunction="closeModalFunction"
        />
        <v-row
          class=""
          v-show="showCroppieLogo && !showRelayText && !showAliasText"
        >
          <v-col cols="12" class="mt-4">
            <div class="mx-3">
            <cropper
              :stencil-size="stencilSize"
              :default-size="defaultSize"
              ref="cropper"
              class="twitter-cropper"
              background-class="twitter-cropper__background"
              foreground-class="twitter-cropper__foreground"
              image-restriction="stencil"
              :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 1,
                previewClass: 'twitter-cropper__stencil',
              }"
              :transitions="false"
              :canvas="true"
              :debounce="false"
              :min-width="150"
              :min-height="150"
              :src="cropSrc"
              @change="onChange"
            />
            <v-col cols="4">
              <v-slider :model-value="zoomValue" @update:model-value="zoomValue = $event; onZoom(zoomValue)"></v-slider>
            </v-col> 
            </div>
            <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
              <v-row
                class="px-2 btns"
                flex
                :style="{ width: '100%', margin: 0 }"
              >
                <v-col cols="4" class="px-0 py-0">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon density="comfortable" variant="text" color="white" @click="rotate(-90)">
                        <font-awesome-icon
                          :icon="['fal', 'undo']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("components.navbar.rotateLeft") }}</span>
                  </v-tooltip>
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon density="comfortable" variant="text" color="white" @click="rotate(90)">
                        <font-awesome-icon
                          :icon="['fal', 'undo']"
                          :style="{ fontSize: '20px' }"
                          class="fa-flip-horizontal"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("components.navbar.rotateRight") }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="8" class="px-0 py-0" align="right">
                  <v-btn color="primary" @click="cancelCrop" flat>{{
                    $t("generics.cancel")
                  }}</v-btn>
                  <v-btn color="primary" @click="crop" flat>{{
                    $t("generics.save")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
        <v-row
          class="mx-0 mb-4"
          v-if="!showCroppieLogo && !showRelayText && !showAliasText"
          :class="{ rowSettingsMobile: isMobile, rowSettings: !isMobile }"
        >
          <v-col
            v-for="n in 2"
            :key="n"
            :lg="cols[n - 1]"
            :md="cols[n - 1]"
            :sm="cols[n - 1]"
            :xl="cols[n - 1]"
            :id="n == 2 ? 'accordionsSection' : 'upperSection'"
            class="text-center py-0"
          >
            <div v-if="n == 1">
              <v-row class="mx-0">
                <v-col
                  v-for="n in 2"
                  :key="n"
                  :lg="colsLogo[n - 1]"
                  :md="colsLogo[n - 1]"
                  :sm="colsLogo[n - 1]"
                  :xl="colsLogo[n - 1]"
                  class="text-center col-12"
                >
                  <!-- START LOGO -->
                  <div v-if="n == 1">
                    <div class="py-0 my-0">
                      <p class="text-left mb-1 text-subtitle-2">
                        {{ $t("components.adminSettingsModal.vOfficeLogo") }}
                      </p>
                      <p class="text-left mb-0 text-caption">
                        {{
                          $t("components.adminSettingsModal.vOfficeLogoText")
                        }}
                      </p>
                    </div>
                    <v-avatar
                      tile
                      size="150"
                      class="borderRadius10"
                      :class="{ 'mr-0': isMobile }"
                    >
                      <v-divider></v-divider>
                      <div class="w100">
                        <v-row flex :style="{ width: '100%', margin: 0 }">
                          <input
                            type="file"
                            ref="companyLogoInput"
                            class="d-none"
                            @change="
                              showLogoPreviewModal($event, 'companyLogo')
                            "
                          />
                          <v-col>
                            <div class="w100">
                              <v-avatar
                                tile
                                size="130"
                                class="borderCircle"
                                :class="{ 'mr-0': isMobile }"
                              >
                                <img
                                  id="logoModal"
                                  class="borderCircle"
                                  max-height="130"
                                  max-width="130"
                                  height="130"
                                  width="130"
                                  contain
                                  v-bind:src="
                                    state.namespaceSettings.companyLogo ||
                                    state.namespaceSettings.companyLogo !== ''
                                      ? state.namespaceSettings.companyLogo
                                      : ''
                                  "
                                  lazy-src="assets/icon.png"
                                />
                                <div
                                  flex
                                  class="uploadAvatarButtons p-4 hasImage"
                                  :style="
                                    state.namespaceSettings.companyLogo === ''
                                      ? 'display:block!important'
                                      : ''
                                  "
                                >
                                  <v-tooltip location="top">
                                    <template v-slot:activator="{ props }">
                                      <v-btn
                                        v-bind="props"
                                        color="primary"
                                        class="mb-2"
                                        isSmall="true"
                                        v-on:click="uploadCompanyLogo"
                                      >
                                        <font-awesome-icon
                                          :icon="['fal', 'upload']"
                                          :style="{ fontSize: '20px' }"
                                        />
                                      </v-btn>
                                    </template>
                                    <span>{{
                                      $t("components.navbar.uploadPicture")
                                    }}</span>
                                  </v-tooltip>
                                </div>
                                <div
                                  flex
                                  class="removeAvatarButtons p-4 hasImage"
                                  :style="
                                    state.namespaceSettings.companyLogo === ''
                                      ? 'display:block!important'
                                      : ''
                                  "
                                >
                                  <v-tooltip location="top">
                                    <template v-slot:activator="{ props }">
                                      <v-btn
                                        v-bind="props"
                                        color="primary"
                                        class="mb-2"
                                        isSmall="true"
                                        v-on:click="removeCompanyLogo"
                                      >
                                        <DeleteBasket
                                          indexData=""
                                          :delFunction="removeCompanyLogo"
                                          extraClasses="deleteBasketWhite"
                                          fontSize="20"
                                          prevent="false"
                                        />
                                      </v-btn>
                                    </template>
                                    <span>{{ $t("generics.remove") }}</span>
                                  </v-tooltip>
                                </div>
                              </v-avatar>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <v-spacer></v-spacer>
                    </v-avatar>
                  </div>
                  <!-- END LOGO -->
                  <!-- START SCREENSAVER -->
                  <div v-else>
                    <div class="justify-center align-baseline">
                      <div class="py-0 my-0">
                        <p class="text-left mb-1 text-subtitle-2">
                          {{
                            $t("components.adminSettingsModal.screenSaverLogo")
                          }}
                        </p>
                        <p class="text-left mb-0 text-caption">
                          {{
                            $t("components.adminSettingsModal.screenSaverText")
                          }}
                        </p>
                      </div>
                      <v-row class="mx-0">
                        <v-col cols="12" class="pl-0">
                          <v-text-field
                            variant="outlined"
                            density="compact"
                            :model-value="companyHomePage" @update:model-value="companyHomePage = $event"
                            class="mt-2"
                            label="URL"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0 pl-0 my-0">
                          <p class="text-left mb-1 text-subtitle-2">
                            {{ $t("components.adminSettingsModal.setAlias") }}
                          </p>
                          <v-form :model-value="aliasFormValid" @update:model-value="aliasFormValid = $event" v-on:submit.prevent>
                            <div class="d-flex">
                              <v-text-field
                                variant="outlined"
                                density="compact"
                                :model-value="officeName" @update:model-value="officeName = $event"
                                class="my-0"
                                style="height: 50px"
                              ></v-text-field>
                              <v-btn
                                :disabled="
                                  !officeName || officeName.trim().length <= 0
                                "
                                @click="saveOfficeName"
                                flat
                                class="ml-2 mt-0"
                                color="primary"
                                >{{ $t("generics.save") }}</v-btn
                              >
                            </div>
                          </v-form>
                        </v-col>
                        <v-col cols="12" class="py-0 pl-0">
                          <p class="text-left mb-1 text-subtitle-2">
                            {{
                              $t(
                                "components.adminSettingsModal.companyEmailInfo"
                              )
                            }}
                          </p>
                          <div class="d-flex">
                            <v-text-field
                              variant="outlined"
                              density="compact"
                              :model-value="companyEmail" @update:model-value="companyEmail = $event"
                              class="mt-2"
                              style="height: 40px"
                              :label="
                                $t('components.adminSettingsModal.companyEmail')
                              "
                              :rules="companyEmailRules"
                            ></v-text-field>
                            <v-btn
                              :disabled="
                                !companyEmail ||
                                !companyEmail.length ||
                                companyEmail.length < 5 ||
                                !companyEmailRegex.test(companyEmail)
                              "
                              @click="setCompanyEmail()"
                              flat
                              class="ml-2 mt-2"
                              color="primary"
                              >{{ $t("generics.save") }}</v-btn
                            >
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="mx-0">
                        <v-col
                          cols="12"
                          class="pl-0 pt-2 pb-0 mt-1 d-flex align-center justify-space-between"
                        >
                          <span class="">
                            <v-radio-group
                            inline
                              :model-value="basicView" @update:model-value="basicView = $event;changeBasicView()"
                            >
                              <v-radio
                                :label="
                                  $t(
                                    'components.adminSettingsModal.viewReduced'
                                  )
                                "
                                :value="true"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t(
                                    'components.adminSettingsModal.viewExtended'
                                  )
                                "
                                :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </span>
                          <v-btn flat
                            color="primary"
                            @click="opencloseInfoCompact()"
                            >{{ $t("generics.info") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row class="mx-0" justify="center" v-if="1 === 2">
                        <v-col cols="6" class="py-0 px-0">
                          <v-switch
                            :model-value="activateScreenSaver" @update:model-value="activateScreenSaver = $event"
                            :label="$t('generics.yes')"
                            width="100%"
                            disabled
                          ></v-switch>
                        </v-col>
                        <v-col cols="6" class="py-0 px-0">
                          <v-radio-group
                            :model-value="radioGroup" @update:model-value="radioGroup = $event"
                            v-if="activateScreenSaver"
                            class="mt-1 radioGroup"
                          >
                            <v-radio :label="'1 h'" :value="1"></v-radio>
                            <v-radio :label="'2 h'" :value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <!-- END SCREENSAVER -->
                </v-col>
              </v-row>
            </div>
            <div  v-if="n == 2">
              <v-expansion-panels
              clas
                :accordion="false"
                :popout="false"
                :inset="false"
                :multiple="false"
                :focusable="false"
                :disabled="false"
                :readonly="false"
                :flat="false"
                :hover="false"
                :tile="false"
                :model-value="panel" @update:model-value="panel = $event"
              >
                <v-expansion-panel
                  v-for="(item, i) in getExpansionPanels"
                  :key="i"
                  :style="[
                    ((item.index === 1 || item.index === 2) && isBasicView) ||
                    (item.index === 4 && !isPayAvailable) ||
                    (item.index === 5 && !isDevelopment) ||
                    (item.index === 9 && !featureFileTranfer) ||
                    (item.index === 14 && !showWorkingTime)
                      ? { display: 'none' }
                      : { display: 'block' },
                  ]"
                >
                  <v-expansion-panel-title class="px-3">
                    <v-list-item-title class="text-subtitle-2 font-weight-medium">
                      {{ item.name }}
                          <!-- item.index == 8 ||
                          item.index == 9 || -->
                      <v-tooltip
                        top
                        v-if="
                          item.index == 6 ||
                          item.index == 7 ||
                          item.index === 10||
                          item.index == 16
                        "
                        content-class="videoButtonTT"
                      >
                        <template v-slot:activator="{ props }">
                          <font-awesome-icon
                            v-bind="props"
                            color="#2a3133"
                            :icon="['fal', 'info-circle']"
                            :style="{
                              fontSize: '17px',
                              position: 'relative',
                              top: '2px',
                            }"
                          />
                        </template>
                        <span>
                              <!-- : item.index == 8
                              ? $t(
                                  "components.adminSettingsModal.e2eeInfoButton"
                                )
                              : item.index == 9
                              ? $t(
                                  "components.adminSettingsModal.ICE_serversInfo"
                                ) -->
                          {{
                            item.index == 6
                              ? $t("components.adminSettingsModal.beaconInfoTT")
                              : item.index == 7
                              ? $t(
                                  "components.adminSettingsModal.messageForAllTT"
                                )
                              : item.index == 10
                              ? $t(
                                  "components.adminSettingsModal.editEmailsTextsTT"
                                )
                              : item.index == 16
                              ? $t(
                                  "components.adminSettingsModal.searchGuestTT"
                                )
                              : ""
                          }}
                        </span>
                      </v-tooltip>
                      <br />
                    </v-list-item-title>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text v-if="item.index == 0">
                    <div class="w100">
                      <!-- <template> -->
                        <!-- Login daily -->
                        <v-list-item v-if="amIAdmin" class="py-0 px-0 switchWrapper">
                          <div class="py-0 my-0 pl-3">
                            <div class="d-flex align-baseline">
                              <v-switch
                                class="my-0 py-3 mr-2"
                                :model-value="userLoginDaily" @update:model-value="userLoginDaily = $event; changeShowUserLoginDaily()"
                                hide-details
                                :disabled="isBasicView"
                                :label="
                                  $t(
                                    'components.adminSettingsModal.saveFirstLoginDaily'
                                  )
                                "
                              >
                              </v-switch>
                              <v-tooltip location="top" content-class="videoButtonTT">
                                <template v-slot:activator="{ props }">
                                  <font-awesome-icon
                                    v-bind="props"
                                    color="#2a3133"
                                    :icon="['fal', 'info-circle']"
                                    :style="{
                                      fontSize: '17px',
                                      position: 'relative',
                                      bottom: '3px'
                                    }"
                                  />
                                </template>
                                <span>
                                  {{
                                    $t(
                                      "components.adminSettingsModal.loginDailyTT"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </div>
                          </div>
                        </v-list-item>
                        <!-- end Login daily -->
                        <!-- User status -->
                        <v-list-item v-if="amIAdmin" class="py-0 px-0 switchWrapper">
                          <div class="py-0 my-0 pl-3">
                            <v-switch
                              class="my-0 py-3"
                               hide-details
                              :label="
                                $t(
                                  'components.adminSettingsModal.statusDisplay'
                                )
                              "
                              :model-value="userStatusDisplay" @update:model-value="userStatusDisplay = $event; changeUserStatusDisplay()"
                            ></v-switch>
                          </div>
                        </v-list-item>
                        <!-- end User status -->
                        <!-- START show NO STATUS -->
                        <v-list-item v-if="amIAdmin" class="py-0 px-0 switchWrapper">
                          <div class="py-0 my-0 pl-3">
                            <v-switch
                             hide-details
                              class="my-0 py-3"
                              :label="
                                $t(
                                  'components.adminSettingsModal.showNoStatus'
                                )
                              "
                              :model-value="showNoStatus" @update:model-value="showNoStatus = $event; changeShowNoStatus()"
                            ></v-switch>
                          </div>
                        </v-list-item>
                        <!-- END show NO STATUS -->
                        <!-- START show coffee break -->
                        <v-list-item v-if="amIAdmin" class="py-0 px-0 switchWrapper">
                          <div class="py-0 my-0 pl-3">
                            <v-switch
                             hide-details
                              class="my-0 py-3"
                              :label="
                                $t(
                                  'components.adminSettingsModal.coffeeBreakDisplay'
                                )
                              "
                              :model-value="showCoffeeBreak" @update:model-value="showCoffeeBreak = $event; changeShowCoffeeBreak()"
                            ></v-switch>
                          </div>
                        </v-list-item>
                        <!-- END show coffee break -->
                        <!-- START show workingtime -->
                        <v-list-item v-if="amIAdmin" class="py-0 px-0 switchWrapper">
                          <div class="py-0 my-0 pl-3">
                            <div class="d-flex align-baseline">
                              <v-switch
                                hide-details
                                class="my-0 py-3 mr-2"
                                :label="
                                $t('components.workingTime.workingTime')
                                "
                                :model-value="showWorkingTime" @update:model-value="showWorkingTime = $event;changeShowWorkingTime()"
                              ></v-switch>
                              <!-- <v-tooltip location="top" content-class="videoButtonTT">
                                <template v-slot:activator="{ on }">
                                  <font-awesome-icon
                                    v-on="on"
                                    color="#2a3133"
                                    :icon="['fal', 'info-circle']"
                                    :style="{
                                      fontSize: '17px',
                                      position: 'relative',
                                    }"
                                  />
                                </template>
                                <span>
                                  {{
                                    $t(
                                      "generics.inProgress"
                                    )
                                  }}
                                </span>
                              </v-tooltip> -->
                            </div>
                          </div>
                        </v-list-item>
                        <!-- END show workingtime -->
                        <!-- timezone starts -->
                        <!-- <template> -->
                          <v-list-item
                            v-if="amIAdmin"
                            class="py-0 px-0 switchWrapper mt-4"
                          >
                            <div class="py-0 my-0 w100">
                              <v-list-item-subtitle
                                class="text-subtitle-2 d-flex ml-0"
                              >
                                <v-tooltip location="top">
                                  <template v-slot:activator="{ props }">
                                    <span v-bind="props">
                                      {{
                                        $t(
                                          "components.adminSettingsModal.timezone"
                                        )
                                      }}
                                    </span>
                                  </template>
                                  <span>{{
                                    $t(
                                      "components.adminSettingsModal.ttTimezone"
                                    )
                                  }}</span>
                                </v-tooltip>
                              </v-list-item-subtitle>
                              <div id="autocomplete_timezone" class="pt-2">
                                <v-autocomplete
                                  attach="#autocomplete_timezone"
                                  ref="section"
                                  :model-value="timezone" @update:model-value="timezone = $event; changeCompanyTimeZone()"
                                  :items="getTimeZones"
                                  item-title="name"
                                  item-value="tz"
                                  :auto-select-first="false"
                                  hide-no-data
                                  width="50%"
                                />
                              </div>
                            </div>
                          </v-list-item>
                        <!-- </template> -->
                        <!-- timezone ends -->
                        <!-- User video calls -->
                        <v-list-item v-if="amIAdmin" class="py-0 px-0 switchWrapper">
                          <div class="py-0 my-0 pl-3">
                            <v-list-item-subtitle
                              class="text-subtitle-2 d-flex ml-0"
                            >
                              <span class="mr-2">
                                {{
                                  $t(
                                    "components.adminSettingsModal.userVideoCall"
                                  )
                                }}
                              </span>
                              <v-tooltip location="top" content-class="videoButtonTT">
                                <template v-slot:activator="{ props }">
                                  <font-awesome-icon
                                    v-bind="props"
                                    color="#2a3133"
                                    :icon="['fal', 'info-circle']"
                                    :style="{
                                      fontSize: '17px',
                                      position: 'relative',
                                    }"
                                  />
                                </template>
                                <span>
                                  {{
                                    $t(
                                      "components.adminSettingsModal.loginDailyTT"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </v-list-item-subtitle>
                            <div class="d-flex flex-row flex-nowrap"> 
                              <v-switch
                                :disabled="isBasicView ? true : null"
                                class="my-0 py-3 mr-6"
                                 hide-details
                                :label="
                                  $t('components.adminSettingsModal.forAllUser')
                                "
                                :model-value="videoCallAllUser" @update:model-value="videoCallAllUser = $event;changeDisplayUserVideoCall('user')"
                              ></v-switch>
  
                              <v-switch
                                :disabled="!featureOrganization || isBasicView"
                                class="my-0 py-3"
                                 hide-details
                                :label="
                                  $t(
                                    'components.adminSettingsModal.forManagement'
                                  )
                                "
                                :model-value="videoCallManagement" @update:model-value="videoCallManagement = $event; changeDisplayUserVideoCall('management')"
                              ></v-switch>
                            </div>
                          </div>
                        </v-list-item>
                        <!--End  User video calls -->
                        <v-list-item class="px-0">
                          <div class="py-0 my-0  pl-3 d-flex">
                            <v-switch
                             hide-details
                              class="my-0 py-3 d-flex"
                              :model-value="displayAllUsers" @update:model-value="displayAllUsers = $event; changeDisplayUserVideoCall('allUsers')"
                              :label="
                                $t(
                                  'components.adminSettingsModal.displayAllUsers'
                                )
                              "
                            ></v-switch>
                          </div>
                        </v-list-item>
                        <!-- file tranfer -->
                        <v-list-item class="px-0">
                          <div class="py-0 my-0  pl-3 d-flex">
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-switch
                                 hide-details
                                  v-bind="props"
                                  class="my-0 py-3 d-flex"
                                  :model-value="featureFileTranfer" @update:model-value="featureFileTranfer = $event;changeFeatureFiletranfer()"
                                  :label="
                                    $t(
                                      'components.adminSettingsModal.featureFileTranfer'
                                    )
                                  "
                                ></v-switch>
                              </template>
                              <span>{{
                                $t(
                                  "components.adminSettingsModal.userDataTransfer"
                                )
                              }}</span>
                            </v-tooltip>
                          </div>
                        </v-list-item>
                        <!-- end file tranfer -->
                        <!-- START TIMELINE SETTING -->
                        <v-list-item class="px-0">
                          <div class="pl-3 py-0">
                            <!-- <v-row style="height: 45px">
                              <v-col class="py-0"> -->
                                <v-switch
                                 hide-details
                                  :model-value="useFeatureTimeline" @update:model-value="useFeatureTimeline = $event;changeUseFeatureTimeline()"
                                  :label="
                                    $t(
                                      'components.adminSettingsModal.useFeatureTimeline'
                                    )
                                  "
                                  width="100%"
                                  class="py-3"
                                ></v-switch>
                              <!-- </v-col>
                            </v-row>
                            <v-col>
                              <v-row class="d-flex flex-row flex-nowrap"> -->
                                <v-radio-group
                                  :row="true"
                                  :model-value="timelineSelection" @update:model-value="timelineSelection = $event; changeTimelineSelection()"
                                  :disabled="!useFeatureTimeline"
                                  inline
                                >
                                  <v-radio
                                    class="ml-3"
                                    key="1"
                                    :label="
                                      $t(
                                        'components.adminSettingsModal.timelineObligatory'
                                      )
                                    "
                                    value="timelineObligatory"
                                  ></v-radio>
                                  <v-radio
                                    class="ml-3"
                                    key="2"
                                    :label="
                                      $t(
                                        'components.adminSettingsModal.timelineUserSelection'
                                      )
                                    "
                                    value="timelineUserSelection"
                                  ></v-radio>
                                </v-radio-group>
                              <!-- </v-row>
                            </v-col> -->
                          </div>
                        </v-list-item>
                        <!-- END TIMELINE SETTING -->
                        <!-- START RATING SETTING -->
                        <v-list-item
                          v-if="amIAdmin"
                          class="py-0 px-0 switchWrapper"
                        >
                          <div class="py-0  pl-3 my-0">
                            <v-list-item-subtitle
                              class="text-subtitle-2 d-flex ml-0"
                              >{{
                                $t(
                                  "components.adminSettingsModal.qualityLogging"
                                )
                              }}</v-list-item-subtitle
                            >
                            <v-radio-group
                              :model-value="qualityLogging" @update:model-value="qualityLogging = $event; changequalityLogging"
                              inline
                            >
                              <v-radio
                                class="ml-3"
                                :label="`${$t(
                                  'components.adminSettingsModal.activate'
                                )}`"
                                :value="true"
                              ></v-radio>
                              <v-radio
                                class="ml-3"
                                :label="`${$t(
                                  'components.adminSettingsModal.desactivate'
                                )}`"
                                :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-list-item>
                        <!-- END RATING SETTING -->
                      <!-- </template> -->
                      <!-- end activate deactivate logging for subdomain -->
                      <v-list-item class="px-0">
                        <div class="pl-3">
                          <v-list-item-subtitle
                            class="text-subtitle-2 d-flex ml-0"
                            >{{
                              $t("components.adminSettingsModal.reviews")
                            }}</v-list-item-subtitle
                          >
                          <v-btn flat
                            class="mt-3"
                            color="primary"
                            style="max-width: 350px"
                            @click="opencloseStats()"
                          >
                            {{
                              $t(
                                "components.adminSettingsModal.showRatingStats"
                              )
                            }}
                          </v-btn>
                        </div>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <div class=" pl-3">
                          <v-list-item-subtitle
                            class="text-subtitle-2 d-flex ml-0 mt-4"
                            >{{
                              $t("components.adminSettingsModal.quality")
                            }}</v-list-item-subtitle
                          >
                          <div class="d-flex">
                            <v-switch
                             hide-details
                              :model-value="showCountQualityStatistics" @update:model-value="showCountQualityStatistics = $event; changeShowCountQualityStatistics"
                              :label="
                                $t('components.adminSettingsModal.capture')
                              "
                              width="100%"
                              class="py-3"
                            ></v-switch>
                          </div>
                        </div>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <div class=" pl-3">
                          <v-list-item-subtitle class="text-subtitle-2 d-flex ml-0">
                            {{
                              $t("components.adminSettingsModal.mailSettings")
                            }}
                          </v-list-item-subtitle>
                          <v-btn flat
                            class="mt-3"
                            color="primary"
                            max-width="350px"
                            @click="openMailFooterModal()"
                          >
                            <!-- width="230px" -->
                            {{ $t("components.adminSettingsModal.mailFooter") }}
                          </v-btn>
                        </div>
                      </v-list-item>
                    </div>
                  </v-expansion-panel-text>
                  <v-expansion-panel-text v-if="item.index == 1">
                    <div class="d-flex">
                      <v-list-item v-if="amIAdmin" class="py-0 px-0 switchWrapper w100">
                        <div class="py-0 pl-3 my-0">
                          <div class="d-flex">
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-btn flat
                                  v-bind="props"
                                  color="primary"
                                  class="mt-1 cursorPointer"
                                  @click="openClose2faModal()"
                                >
                                  {{ $t("generics.info") }}
                                </v-btn>
                              </template>
                              <span>{{
                                $t("components.adminSettingsModal.twoFAMainTT")
                              }}</span>
                            </v-tooltip>
                          </div>
                          <div class="w100 mt-2">
                            <v-radio-group
                              inline
                              :model-value="activate2FAType" @update:model-value="activate2FAType = $event; change2FASetting('2fa')"
                            >
                              <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                  <v-radio
                                    v-bind="props"
                                    class="ml-0 mx-0"
                                    key="1"
                                    value="allUsers"
                                  ></v-radio>
                                </template>
                                <span>{{
                                  $t(
                                    "components.adminSettingsModal.twoFAAllUsersTT"
                                  )
                                }}</span>
                              </v-tooltip>
                              <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                  <span v-bind="props" class="py-2">{{
                                    $t(
                                      "components.adminSettingsModal.2faTypeAllUsers"
                                    )
                                  }}</span>
                                </template>
                                <span>{{
                                  $t(
                                    "components.adminSettingsModal.twoFAAllUsersTT"
                                  )
                                }}</span>
                              </v-tooltip>
                              <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                  <v-radio
                                    v-bind="props"
                                    class="ml-2 mr-0"
                                    key="3"
                                    value="default"
                                  ></v-radio>
                                </template>
                                <span>{{
                                  $t(
                                    "components.adminSettingsModal.twoFAUserSettingTT"
                                  )
                                }}</span>
                              </v-tooltip>
                              <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                  <span v-bind="props" class="py-2">{{
                                    $t(
                                      "components.adminSettingsModal.2faTypeDefault"
                                    )
                                  }}</span>
                                </template>
                                <span>{{
                                  $t(
                                    "components.adminSettingsModal.2faTypeDefaultTT"
                                  )
                                }}</span>
                              </v-tooltip>
                            </v-radio-group>
                          </div>
                          <v-row>
                            <div
                              v-if="
                                activateSMS ||
                                !(activatePIN || activatePassword)
                              "
                            >
                              <v-tooltip location="top" max-width="400">
                                <template v-slot:activator="{ props }">
                                  <v-switch
                                    v-bind="props"
                                    class="my-0 py-2 pl-3"
                                    :model-value="activateSMS" @update:model-value="activateSMS = $event; change2FASetting('sms')"
                                    :disabled="
                                      activateSMS &&
                                      !activatePIN &&
                                      !activatePassword
                                    "
                                    :label="
                                      $t(
                                        'components.adminSettingsModal.2faActivateSMS'
                                      )
                                    "
                                  ></v-switch>
                                </template>
                                <span>{{
                                  $t(
                                    "components.adminSettingsModal.2faActivateSMSTT"
                                  )
                                }}</span>
                              </v-tooltip>
                            </div>
                            <div class="">
                              <v-tooltip location="top" max-width="400">
                                <template v-slot:activator="{ props }">
                                  <v-switch
                                    v-bind="props"
                                    class="my-0 py-2 pl-3"
                                    :model-value="activatePassword" @update:model-value="activatePassword = $event; change2FASetting('password')"
                                    :disabled="activatePassword && !activatePIN"
                                    :label="
                                      $t(
                                        'components.adminSettingsModal.2faActivatePassword'
                                      )
                                    "
                                  ></v-switch>
                                </template>
                                <span>{{
                                  $t(
                                    "components.adminSettingsModal.2faActivatePasswordTT"
                                  )
                                }}</span>
                              </v-tooltip>
                            </div>
                            <div class="">
                              <v-tooltip location="top" max-width="400">
                                <template v-slot:activator="{ props }">
                                  <v-switch
                                    v-bind="props"
                                    class="my-0 py-2 pl-3 inlineflex"
                                    :model-value="activatePIN" @update:model-value="activatePIN = $event; change2FASetting('pin')"
                                    :disabled="activatePIN && !activatePassword"
                                    :label="
                                      $t(
                                        'components.adminSettingsModal.2faActivatePin'
                                      )
                                    "
                                  ></v-switch>
                                </template>
                                <span>{{
                                  $t(
                                    "components.adminSettingsModal.2faActivatePinTT"
                                  )
                                }}</span>
                              </v-tooltip>
                              <v-radio-group
                                v-if="activatePIN"
                                inline
                                :model-value="pinProtectionType" @update:model-value="pinProtectionType = $event; change2FASetting('pinProtectionType')"
                                class="mt-1 radioGroup inlineflex"
                              >
                              </v-radio-group>
                            </div>
                          </v-row>
                          <p class="text-left text-subtitle-1" align="left">
                            {{
                              $t(
                                "components.adminSettingsModal.twoFaLifetimeHours"
                              )
                            }}
                          </p>
                          <div id="autocomplete_2faHours">
                            <!-- <v-overflow-btn
                              attach="#autocomplete_2faHours"
                              class="my-2"
                              :items="twoFaLifetimeHours"
                              :model-value="selectedTwoFaLifetimeHours" @update:model-value="selectedTwoFaLifetimeHours = $event"
                              @change="setTwoFaLifetimeHours"
                            ></v-overflow-btn> -->
                            <v-select
                              hide-details
                              density="compact"
                              variant="outlined"
                              attach="#autocomplete_2faHours"
                              class="my-2"
                              :items="twoFaLifetimeHours"
                              :model-value="selectedTwoFaLifetimeHours" @update:model-value="selectedTwoFaLifetimeHours = $event;setTwoFaLifetimeHours()"
                            ></v-select>
                          </div>
                          <div class="w100">
                            <v-switch
                              class="pb-4 pl-0 pt-2"
                              :model-value="userSetTime" @update:model-value="userSetTime = $event; change2FASetting('time')"
                              :label="
                                $t('components.adminSettingsModal.2faOwnTime')
                              "
                            ></v-switch>
                          </div>
                          <div class="containerRecovery">
                            <div class="absoluteDropdown">
                              <TwoFactorAdminRecoveryEntry />
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </div>
                  </v-expansion-panel-text>
                  <v-expansion-panel-text v-if="item.index == 2">
                    <v-list-item v-if="amIAdmin" class="py-0 px-0 switchWrapper">
                      <div class="my-0 pt-1 pb-3">
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <v-switch
                              class="my-0 pl-3 py-2 pt-4"
                              :label="
                                $t(
                                  'components.adminSettingsModal.featureOrganization'
                                )
                              "
                              :model-value="featureOrganization" @update:model-value="featureOrganization = $event; changefeatureOrganization"
                              hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" class="py-2 pb-3">
                            <div id="combobox_organigram">
                              <v-combobox
                                attach="#combobox_organigram"
                                :disabled="!featureOrganization"
                                @blur="blurShowOrganigrammFor()"
                                :model-value="showOrganigrammFor" @update:model-value="showOrganigrammFor = $event; updateShowOrganigrammFor()"
                                :items="dataComboboxShowOrganigrammFor"
                                select
                                :label="
                                  $t(
                                    'components.adminSettingsModal.showOrganigrammFor'
                                  )
                                "
                                multiple
                                class="mx-1"
                                density="compact"
                                item-title="value"
                                item-value="text"
                                :return-object="true"
                                counter
                              >
                              </v-combobox>
                            </div>
                          </v-col>
                          <v-row  v-if="featureOrganization">
                            <v-col cols="12" class="py-2 d-flex align-center">
                              <v-switch 
                                class="my-0 pl-6 py-2 pb-3"
                                :label="
                                  $t(
                                    'components.adminSettingsModal.showHeadline'
                                  )
                                "
                                :model-value="showHeadlineOrganization" @update:model-value="showHeadlineOrganization = $event; changeShowHeadlineOrganization()"
                                hide-details
                              ></v-switch>
                              <v-tooltip location="top" content-class="videoButtonTT">
                              <template v-slot:activator="{ props }">
                                <font-awesome-icon
                                  class="ml-4"
                                  v-bind="props"
                                  color="#2a3133"
                                  :icon="['fal', 'info-circle']"
                                  :style="{
                                    fontSize: '17px',
                                  }"
                                />
                              </template>
                              <span>
                                {{$t( "components.adminSettingsModal.headlineTT" )}}
                              </span>
                            </v-tooltip>
                            </v-col>
                            <v-row v-if="showHeadlineOrganization">
                              <v-col cols="12" class="py-2 px-7">
                                <div class="d-flex px-3 align-center">
                                  <v-text-field
                                    hide-details
                                    variant="outlined"
                                    density="compact"
                                    :model-value="headlineOrganigramDe" @update:model-value="headlineOrganigramDe = $event"
                                    :label="`
                                     ${ $t(
                                        'components.adminSettingsModal.headline'
                                      )} DE`
                                    "
                                    ></v-text-field>
                                    <v-text-field
                                    hide-details
                                    class="ml-2"
                                    variant="outlined"
                                    density="compact"
                                    :model-value="headlineOrganigramEn" @update:model-value="headlineOrganigramEn = $event"
                                    :label="`
                                     ${ $t(
                                        'components.adminSettingsModal.headline'
                                      )} EN`
                                    "
                                    ></v-text-field>
                                    <v-text-field
                                    class="ml-2"
                                    hide-details
                                    variant="outlined"
                                    density="compact"
                                    :model-value="headlineOrganigramEs" @update:model-value="headlineOrganigramEs = $event"
                                    :label="`
                                     ${ $t(
                                        'components.adminSettingsModal.headline'
                                      )} ES`
                                    "
                                    ></v-text-field>
                                    <v-btn @click="addHeadlineOrganigram" :disabled="!headlineOrganigramDe.length && !headlineOrganigramEs.length && !headlineOrganigramEn.length" class="ml-2" max-width="150px" flat color="primary">{{
                                      $t( 'generics.add')}}
                                    </v-btn>
                                </div>
                              </v-col>
                              <v-col cols="12" class="py-2 px-7 headlineOrganigramList">
                                <div class="px-3 py-2 d-flex justify-space-between align-center">
                                  <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[0].de}}</div>
                                  <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[0].en}}</div>
                                  <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[0].es}}</div>
                                  <div style="min-width: 131px;" class="text-right  d-flex justify-end">
                                    <v-btn class="ml-2" density="comfortable" variant="text" color="primary" icon @click="editHeadlineOrganigram(headlineOrganigram[0].id)"><font-awesome-icon
                                        :icon="['fal', 'pen']"
                                        :style="{ fontSize: '16px' }"
                                      /></v-btn>
                                      <div style="width: 36px;height: 36px;"></div>
                                  </div>
                                </div>
                                <draggable  :model-value="headlineOrganigram" @update:model-value="headlineOrganigram = $event" @change="headlineOrderChanged">
                                   <template #item="{ element, index }">
                                    <div  v-if="element.id !== 0 && element.id !== 9999" class="px-3  py-2 d-flex justify-space-between align-center">
                                      <!-- <div class="list-group-item"> -->
                                      <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[index].de}}</div>
                                        <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[index].en}}</div>
                                        <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[index].es}}</div>
                                        <div style="min-width: 131px;" class=" text-right">
                                          <DeleteBasket :indexData='element.id ' :delFunction="removeHeadlineOrganigram"  fontSize="16"/>
                                          <v-btn class="mb-1" color="primary"  density="comfortable" variant="text" icon @click="editHeadlineOrganigram(element.id)">
                                            <font-awesome-icon
                                              :icon="['fal', 'pen']"
                                              :style="{ fontSize: '16px' }"
                                            />
                                          </v-btn>
                                          <v-btn class="mb-1" color="primary" icon density="comfortable" variant="text">
                                            <font-awesome-icon
                                              :icon="['fal', 'bars']"
                                              :style="{ fontSize: '16px' }"
                                            />
                                          </v-btn>
                                        <!-- </div> -->
                                      </div>
                                    </div>
                                  </template>
                                <!-- <transition-group name="card">
                                  <template v-for="(headline, index) in headlineOrganigram">
                                    <div :key="index" v-if="headline.id !== 0 && headline.id !== 9999" class="px-3  py-2 d-flex justify-space-between align-center">
                                      <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[index].de}}</div>
                                      <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[index].en}}</div>
                                      <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[index].es}}</div>
                                      <div style="min-width: 131px;" class=" text-right">
                                        <DeleteBasket :indexData='headline.id ' :delFunction="removeHeadlineOrganigram"  fontSize="16"/>
                                        <v-btn class="mb-1" color="primary" icon @click="editHeadlineOrganigram(headline.id)">
                                          <font-awesome-icon
                                            :icon="['fal', 'pen']"
                                            :style="{ fontSize: '16px' }"
                                          />
                                        </v-btn>
                                        <v-btn class="mb-1" color="primary" icon>
                                          <font-awesome-icon
                                            :icon="['fal', 'bars']"
                                            :style="{ fontSize: '16px' }"
                                          />
                                        </v-btn>
                                      </div>
                                    </div>
                                  </template>
                                </transition-group> -->
                                </draggable>
                                <div class="px-3  py-2 d-flex justify-space-between align-center">
                                  <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[(headlineOrganigram.length -1)].de}}</div>
                                  <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[(headlineOrganigram.length -1)].en}}</div>
                                  <div style="min-width: 232px;" class=" text-left">{{headlineOrganigram[(headlineOrganigram.length -1)].es}}</div>
                                  <div style="min-width: 131px;" class="text-right   d-flex justify-end">
                                    <v-btn class="ml-2" density="comfortable" variant="text" color="primary" icon @click="editHeadlineOrganigram(headlineOrganigram[(headlineOrganigram.length -1)].id)"><font-awesome-icon
                                        :icon="['fal', 'pen']"
                                        :style="{ fontSize: '16px' }"
                                      /></v-btn>
                                    <div style="width: 36px;height: 36px;"></div>
                                  </div>
                                </div>
                              </v-col>
                              </v-row>
                          </v-row>
                        </v-row>
                      </div>
                    </v-list-item>
                    <!-- <p class="text-left subtitle-1">
                      {{ $t("components.adminSettingsModal.homeViewAllUsers") }}
                    </p> 
                    <v-list-item class="py-0 switchWrapper">
                      <v-row class="mx-0">
                        <v-col cols="12" class="py-0 px-0">
                          <v-radio-group
                            row
                            :model-value="organisationFavorite" @update:model-value="organisationFavorite = $event"
                            class="mt-1 radioGroup"
                            @change="changeOrganisationFavorite()"
                          >
                            <v-radio
                              class="pr-2"
                              :label="
                                $t('components.adminSettingsModal.favorites')
                              "
                              value="favorites"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('components.adminSettingsModal.multiSelect')
                              "
                              value="multiSections"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item>
                      <v-row class="mx-0">
                        <v-col cols="12">
                          <div id="combobox_organigram_sections">
                            <v-combobox
                              attach="#combobox_organigram_sections"
                              :disabled="organisationFavorite == 'favorites'"
                              @change="updateFavoriteSections"
                              :model-value="myFavoriteSections" @update:model-value="myFavoriteSections = $event"
                              :items="dataCombobox"
                              select
                              item-title="name"
                              :label="$t('generics.sections')"
                              multiple
                              class="mx-1"
                              density="compact"
                              counter
                            ></v-combobox>
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item> -->
                  </v-expansion-panel-text>
                  <v-expansion-panel-text v-if="item.index == 3">
                    <!-- START WAITING ROOM LINKS -->
                    <v-row>
                      <v-col class="py-0">
                        <v-form :model-value="waitingRoomFormValid" @update:model-value="waitingRoomFormValid = $event">
                          <div class>
                            <v-row>
                              <div v-if="getCustomInviteTexts.length > 0">
                                <v-col class="pb-0">
                                  <v-tooltip location="top">
                                    <template v-slot:activator="{ props }">
                                      <div id="autocomplete_templates">
                                        <v-autocomplete
                                          attach="#autocomplete_templates"
                                          v-bind="props"
                                          :search-input.sync="templateSearch"
                                          :model-value="customTemplateWaitingRoomSelected" @update:model-value="customTemplateWaitingRoomSelected = $event"
                                          :items="getCustomInviteTexts"
                                          variant="outlined"
                                          item-title="title"
                                          item-value="title"
                                          hide-details="auto"
                                          :label="$t('generics.templates')"
                                          density="compact"
                                          :menu-props="{ closeOnContentClick: true }"
                                        >
                                          <template slot="item" slot-scope="data">
                                            <v-list-item
                                              @click="
                                                saveCustomTemplateWaitingRoomSelected(
                                                  data
                                                )
                                              "
                                              style="
                                                position: relative;
                                                min-height: 25px;
                                              "
                                            >
                                              <div class="w100">
                                                <div class="listTitle mb-1">
                                                  {{ data.item.title }}
                                                </div>
                                              </div>
                                            </v-list-item>
                                          </template>
                                        </v-autocomplete>
                                      </div>
                                      </template>
                                  <span>{{
                                    $t(
                                      "components.adminSettingsModal.waitingroomTemplate"
                                    )
                                  }}</span>
                                </v-tooltip>
                                  </v-col
                                >
                                <v-col class="">
                                  <v-btn flat
                                    :disabled="
                                      !state.namespaceSettings
                                        .customTemplateWaitingRoomSelected
                                    "
                                    @click="
                                      resetCustomTemplateWaitingRoomSelected()
                                    "
                                    color="primary"
                                  >
                                    {{
                                      $t(
                                        "components.adminSettingsModal.resetTemplateWaitingRoom"
                                      )
                                    }}
                                  </v-btn>
                                </v-col>
                              </div>
                              <v-col cols="12" class="">
                                <v-tooltip location="top">
                                  <template v-slot:activator="{ props }">
                                    <v-text-field
                                      hide-details
                                      variant="outlined"
                                      density="compact"
                                      v-bind="props"
                                      :model-value="waitingRoomAlias" @update:model-value="waitingRoomAlias = $event"
                                      :label="
                                        $t(
                                          'components.adminSettingsModal.waitingRoomAlias'
                                        )
                                      "
                                    ></v-text-field>
                                  </template>
                                  <span>{{
                                    $t(
                                      "components.adminSettingsModal.displayDescriptionTT"
                                    )
                                  }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </div>
                          <div class="w100">
                            <v-alert
                              v-if="aliasTakenError"
                              density="compact"
                              type="error"
                              >{{
                                $t("components.adminSettingsModal.aliasTaken")
                              }}</v-alert
                            >
                            <v-alert v-if="aliasSuccess" density="compact" type="success">{{
                              aliasSuccess
                            }}</v-alert>
                          </div>
                        </v-form>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12 d-flex align-center">
                       <v-textarea
                         hide-details
                         variant="outlined"
                         density="compact"
                         v-model="waitingRoomWelcomeActualLanguageModel"
                         :label="`${$t('components.waitingRoom.welcomeMsg')} ${getLanguage}`"
                       ></v-textarea>
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn v-on="props" class="ml-1" icon @click="showModelWaitingRoomWelcome=!showModelWaitingRoomWelcome" >
                              <font-awesome-icon
                                  v-if="!showModelWaitingRoomWelcome"
                                  :icon="['fal', 'chevron-down']"
                                  :style="{ fontSize: '16px' }"
                                  color="primary"
                                />
                                <font-awesome-icon
                                  v-else
                                  :icon="['fal', 'chevron-up']"
                                  :style="{ fontSize: '16px' }"
                                  color="primary"
                                />
                            </v-btn>
                            </template>
                            <span>{{!showModelWaitingRoomWelcome? $t(
                                      "components.adminSettingsModal.moreLanguages"
                                    ) : $t(
                                      "components.adminSettingsModal.hideMoreLangauges"
                                    )}}
                              </span>
                          </v-tooltip>
                      </v-col>
                      <!-- <v-col cols="12 py-0">
                        <v-btn @click="showModeWaitingRoomWelcome=!showModeWaitingRoomWelcome" text>Show more</v-btn>
                      </v-col> -->
                      <div  v-if="showModelWaitingRoomWelcome" class="w100">
                        <v-col class="pr52" cols="12" v-for="(modelsData, index) in waitingRoomWelcomeOtherLanguageModel" :key="`modelData-${modelsData.lang}-${index}`" >
                          <v-textarea
                            hide-details
                            variant="outlined"
                            density="compact"
                            :value="modelsData.value"
                            @update:model-value="waitingRoomWelcomeOtherLanguageModel = { lang: modelsData.lang, value:$event };"
                            :label="`${$t('components.waitingRoom.welcomeMsg')} ${modelsData.lang}`"
                          ></v-textarea>
                        </v-col>
                      </div>
                      <!-- <v-col cols="12">
                        <v-text-field
                          hide-details
                          outlined
                          dense
                          v-model="waitingRoomWelcomeTextES"
                          :label="`${$t('components.waitingRoom.welcomeMsg')} ES`"
                        ></v-text-field>
                      </v-col> -->
                      <!-- <v-col cols="12">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-bind="props"
                              hide-details
                              type="number"
                              variant="outlined"
                              density="compact"
                              @change="waitingRoomExpectedTimeChange"
                              :model-value="waitingRoomExpectedTime" @update:model-value="waitingRoomExpectedTime = $event"
                              :label="$t('components.waitingRoom.expectedTime')"
                            ></v-text-field>
                          </template>
                          <span>{{
                            $t(
                              "components.adminSettingsModal.waitingRoomTimeTT"
                            )
                          }}</span>
                        </v-tooltip>
                      </v-col> -->
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div
                          class="containerResetBgWaitingroom"
                          @mouseover="showResetBgWaitingRoom = true"
                          @mouseleave="showResetBgWaitingRoom = false"
                        >
                          <v-img
                            @click="setCustomBackground"
                            v-if="!isPdfBg"
                            class="bg-grey-darken-4"
                            :src="actualWaitingRoomBg"
                            max-height="150"
                            contain
                            lazy-src="img/default_profile_picture.png"
                          ></v-img>
                          <v-btn flat
                            v-if="showResetBgWaitingRoom && hasCustomBg"
                            fab
                            x-small
                            class="btnResetBgWaitingroom"
                            color="#2a3133"
                            @click="resetBgWaitingRoom()"
                          >
                            <font-awesome-icon
                              :icon="['fal', 'times']"
                              :style="{ fontSize: '16px' }"
                              color="white"
                            />
                          </v-btn>
                        </div>
                        <span class="ml-2 d-flex">{{
                          $t("components.waitingRoom.currentBackground")
                        }}</span>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex"
                        style="flex-direction: column"
                      >
                        <v-col>
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <div id="combobox_templates">
                                <v-combobox
                                  attach="#combobox_templates"
                                  v-bind="props"
                                  :model-value="selectedTemplate" 
                                  @update:model-value="selectedTemplate = $event; updateTemplateBg($event)"
                                  :items="getWaitingRoomBgs"
                                  :label="
                                    $t('components.adminSettingsModal.templates')
                                  "
                                  variant="outlined"
                                  density="compact"
                                  return-object
                                  item-title="imageName"
                                  item-value="imagePath"
                                  hide-details
                                >
                                  <template v-slot:item="{ item, index, props}">
                                      <div class="d-flex align-center" @click="selectedTemplate=item.raw; updateTemplateBg()">
                                      <v-img
                                        class="my-1"
                                        style="max-width: 145px"
                                        :src="item.value"
                                        lazy-src="img/default_profile_picture.png"
                                      ></v-img>
                                      <span class="ml-2">{{ item.title }}</span>
                                    </div>
                                  </template>
                                </v-combobox>
                              </div>
                            </template>
                            <span>{{
                              $t("components.adminSettingsModal.templatesTT")
                            }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col>
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <v-btn
                                v-bind="props"
                                @click="setCustomBackground"
                                flat
                                class="ml-0 w-100"
                                color="primary"
                                >{{
                                  $t("components.waitingRoom.uploadBG")
                                }}</v-btn
                              >
                            </template>
                            <span>{{
                              $t("components.waitingRoom.customBgTT")
                            }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          variant="outlined"
                          density="compact"
                          :model-value="facebookLink" @update:model-value="facebookLink = $event"
                          label="Facebook"
                          prepend-inner-icon="mdi-facebook"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          variant="outlined"
                          density="compact"
                          :model-value="instagramLink" @update:model-value="instagramLink = $event"
                          label="Instagram"
                          prepend-inner-icon="mdi-instagram"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          variant="outlined"
                          density="compact"
                          :model-value="twitterLink" @update:model-value="twitterLink = $event"
                          label="Twitter"
                          prepend-inner-icon="mdi-twitter"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          variant="outlined"
                          density="compact"
                          :model-value="youtubeLink" @update:model-value="youtubeLink = $event"
                          label="YouTube"
                          prepend-inner-icon="mdi-youtube"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          variant="outlined"
                          density="compact"
                          :model-value="linkedinLink" @update:model-value="linkedinLink = $event"
                          label="LinkedIn"
                          prepend-inner-icon="mdi-linkedin"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          variant="outlined"
                          density="compact"
                          :model-value="webLink" @update:model-value="webLink = $event"
                          label="Web"
                          prepend-inner-icon="mdi-link"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-right">
                        <v-btn flat
                          :disabled="!waitingRoomFormValid"
                          @click="saveWaitingRoomSettings"
                          class="ml-2"
                          color="primary"
                          >{{ $t("components.calendarContainer.save") }}</v-btn
                        >
                        <v-btn 
                          @click="cancelWaitingRoomSettings"
                          flat
                          class="ml-2"
                          color="primary"
                          >{{ $t("generics.cancel") }}</v-btn
                        >
                      </v-col>
                    </v-row>
                    <!-- END WAITING ROOM LINKS -->
                  </v-expansion-panel-text>
                  <v-expansion-panel-text v-if="item.index == 4">
                    <v-row class="mx-0">
                      <v-col cols="5">
                        <v-col cols="12" class="px-0">
                          <span class="text-left text-subtitle-2 d-flex mr-2 align-center">
                            <v-switch
                              hide-details
                              class="py-0 my-0 pr-2"
                              :model-value="paySetting" @update:model-value="paySetting = $event; changePaySetting()"
                            ></v-switch>
                            {{ $t("components.adminSettingsModal.paySetting") }}
                          </span>
                        </v-col>
                        <v-col cols="4" class="px-0">
                          <div id="select_currency">
                            <v-select
                              attach="#select_currency"
                              :model-value="selectCurrency" @update:model-value="selectCurrency = $event; setCurrency"
                              :items="currencies"
                              :label="
                                $t('components.adminSettingsModal.currency')
                              "
                            ></v-select>
                          </div>
                        </v-col>
                      </v-col>
                      <v-col cols="7">
                        <v-col cols="12" class="px-0">
                          <v-btn flat
                            :color="paypalSettingsFilled ? 'primary' : 'orange'"
                            class="btnvOfficePay mx-1"
                            @click="openClosePayPalSettings"
                          >
                            {{
                              $t("components.adminSettingsModal.paypalSettings")
                            }}
                          </v-btn>
                          <v-btn flat
                            color="primary"
                            class="mx-1"
                            @click="openInfoPaypalSettings"
                          >
                            {{ $t("generics.info") }}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" class="px-0">
                          <v-btn flat
                            color="primary"
                            class="btnvOfficePay mx-1"
                            @click="openCloseModalProducts"
                          >
                            {{
                              $t("components.adminSettingsModal.paypalProducts")
                            }}
                          </v-btn>
                          <v-btn flat
                            color="primary"
                            class="mx-1"
                            @click="openInfoPaypalProducts"
                          >
                            {{ $t("generics.info") }}
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row class="w-100 mx-0 my-0"> </v-row>
                  </v-expansion-panel-text>
                  <!-- <v-expansion-panel-text
                    v-if="item && item.index == 5 && amIAdmin"
                  >
                    <template>
                      <v-list-item
                        v-if="amIAdmin"
                        class="py-0 px-0 switchWrapper"
                      >
                        <div class="py-0 pl-3 my-0">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ on }">
                              <v-list-item-subtitle
                                v-on="on"
                                class="subtitle-2 d-flex ml-0"
                                >{{
                                  $t(
                                    "components.adminSettingsModal.deletionMessages"
                                  )
                                }}</v-list-item-subtitle
                              >
                            </template>
                            <span>{{
                              $t("components.adminSettingsModal.deletionTT")
                            }}</span>
                          </v-tooltip>
                          <v-radio-group
                            row
                            @change="changeDeletionMessages()"
                            :model-value="deletionMessages" @update:model-value="deletionMessages = $event"
                          >
                            <v-radio
                              :label="$t('components.adminSettingsModal.1day')"
                              value="1day"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.2day')"
                              :value="false"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.1week')"
                              value="1week"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('components.adminSettingsModal.1month')
                              "
                              value="1month"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('components.adminSettingsModal.3month')
                              "
                              value="3months"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.never')"
                              value="never"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </v-list-item>
                    </template>
                    <template>
                      <v-list-item
                        v-if="amIAdmin"
                        class="py-0 px-0 switchWrapper"
                      >
                        <div class="py-0 pl-3 my-0">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ on }">
                              <v-list-item-subtitle
                                v-on="on"
                                class="subtitle-2 d-flex ml-0"
                                >{{
                                  $t(
                                    "components.adminSettingsModal.deletionMissedCalls"
                                  )
                                }}</v-list-item-subtitle
                              >
                            </template>
                            <span>{{
                              $t("components.adminSettingsModal.deletionTT")
                            }}</span>
                          </v-tooltip>
                          <v-radio-group
                            row
                            @change="changeDeletionMissedCalls()"
                            :model-value="deletionCalls" @update:model-value="deletionCalls = $event"
                          >
                            <v-radio
                              :label="$t('components.adminSettingsModal.1day')"
                              value="1day"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.2day')"
                              :value="false"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.1week')"
                              value="1week"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('components.adminSettingsModal.1month')
                              "
                              value="1month"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('components.adminSettingsModal.3month')
                              "
                              value="3months"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.never')"
                              value="never"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </v-list-item>
                    </template>
                    <template>
                      <v-list-item
                        v-if="amIAdmin"
                        class="py-0 px-0 switchWrapper"
                      >
                        <div class="py-0 pl-3 my-0">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ on }">
                              <v-list-item-subtitle
                                v-on="on"
                                class="subtitle-2 d-flex ml-0"
                                >{{
                                  $t(
                                    "components.adminSettingsModal.deletionInvites"
                                  )
                                }}</v-list-item-subtitle
                              >
                            </template>
                            <span>{{
                              $t("components.adminSettingsModal.deletionTT")
                            }}</span>
                          </v-tooltip>
                          <v-radio-group
                            row
                            @change="changeDeletionInvites()"
                            :model-value="deletionInvites" @update:model-value="deletionInvites = $event"
                          >
                            <v-radio
                              :label="$t('components.adminSettingsModal.1day')"
                              value="1day"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.2day')"
                              :value="false"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.1week')"
                              value="1week"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('components.adminSettingsModal.1month')
                              "
                              value="1month"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('components.adminSettingsModal.3month')
                              "
                              value="3months"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.never')"
                              value="never"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </v-list-item>
                    </template>
                    <template>
                      <v-list-item
                        v-if="amIAdmin"
                        class="py-0 px-0 switchWrapper"
                      >
                        <div class="py-0 pl-3 my-0">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ on }">
                              <v-list-item-subtitle
                                v-on="on"
                                class="subtitle-2 d-flex ml-0"
                                >{{
                                  $t(
                                    "components.adminSettingsModal.autoDeleteFrequency"
                                  )
                                }}</v-list-item-subtitle
                              >
                            </template>
                            <span>{{
                              $t("components.adminSettingsModal.deletionTT")
                            }}</span>
                          </v-tooltip>
                          <v-radio-group
                            @change="changeAutoDeleteConferences"
                            :model-value="autoDeleteConferences" @update:model-value="autoDeleteConferences = $event"
                            row
                          >
                            <v-radio
                              :label="$t('components.adminSettingsModal.1day')"
                              value="1"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.2day')"
                              value="2"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.adminSettingsModal.1week')"
                              value="7"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('components.adminSettingsModal.1month')
                              "
                              value="30"
                            ></v-radio>
                             <v-radio
                              :label="
                                $t('components.adminSettingsModal.3month')
                              "
                              value="90"
                            ></v-radio>
                            <v-radio
                              :label="$t('components.addConferenceModal.never')"
                              value="0"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </v-list-item>
                    </template>
                  </v-expansion-panel-text> -->
                  <!-- START BEACON SETTINGS -->
                  <v-expansion-panel-text v-if="item.index == 6">
                    <v-row class="mx-0">
                      <v-col cols="8" class="py-0">
                        <!-- domain whitelist -->
                        <v-list-item>
                          <div class="w100">
                            <v-list-item-subtitle
                              class="text-subtitle-2 d-flex ml-0"
                            >
                              {{
                                $t("components.adminSettingsModal.beaconDomainWhitelist")
                              }}
                            </v-list-item-subtitle>
                            <div class="text-left my-3">
                            <v-btn  color="primary" @click="openBeaconDomainWhitelist()" flat> {{
                                $t("components.adminSettingsModal.beaconDomainWhitelist")
                              }}</v-btn>
                            </div>
                          </div>
                        </v-list-item>
                        <!-- end domain whitelist -->
                        <v-list-item>
                          <div class="w100">
                            <v-list-item-subtitle
                              class="text-subtitle-2 d-flex ml-0"
                            >
                              {{
                                $t("components.adminSettingsModal.beaconTitle")
                              }} {{  getLanguage}}
                            </v-list-item-subtitle>
                            <div class="d-flex align-center">
                            <v-text-field
                              variant="outlined"
                              density="compact"
                              :model-value="beaconTitleActualLanguageModel" @update:model-value="beaconTitleActualLanguageModel = $event"
                              maxLength="25"
                              counter
                              class="mt-2"
                              hide-details="auto"
                              @change="setBeaconTitle()"
                            ></v-text-field>
                            <v-tooltip top>
                              <template v-slot:activator="{ props }">
                              <v-btn v-bind="props" class="ml-1" style="margin-top: -15px" icon @click="showModelBeaconTitle=!showModelBeaconTitle" >
                                  <font-awesome-icon
                                      v-if="!showModelBeaconTitle"
                                      :icon="['fal', 'chevron-down']"
                                      :style="{ fontSize: '16px' }"
                                      color="primary"
                                    />
                                    <font-awesome-icon
                                      v-else
                                      :icon="['fal', 'chevron-up']"
                                      :style="{ fontSize: '16px' }"
                                      color="primary"
                                    />
                              </v-btn>
                              </template>
                              <span>{{!showModelBeaconTitle? $t(
                                      "components.adminSettingsModal.moreLanguages"
                                    ) : $t(
                                      "components.adminSettingsModal.hideMoreLangauges"
                                    )}}
                              </span>
                              </v-tooltip>
                          </div>
                          <div class="pr40" v-if="showModelBeaconTitle" cols="12" v-for="(modelsData, index) in beaconTitleOtherLanguageModel" :key="`modelData-${modelsData.lang}-${index}`" >
                            <v-list-item-subtitle
                              class="text-subtitle-2 d-flex ml-0"
                            >
                              {{
                                $t("components.adminSettingsModal.beaconTitle")
                              }} {{ modelsData.lang }}
                            </v-list-item-subtitle>
                            <v-text-field
                              variant="outlined"
                              density="compact"
                              :value="modelsData.value"
                              @update:model-value="beaconTitleOtherLanguageModel = { lang: modelsData.lang, value:$event };"
                              maxLength="25"
                              counter
                              class="mt-2"
                              hide-details="auto"
                              @change="setBeaconTitle()"
                            ></v-text-field>

                          </div>
                            <!-- <v-list-item-subtitle
                              class="subtitle-2 d-flex ml-0"
                            >
                              {{
                                $t("components.adminSettingsModal.beaconTitle")
                              }} ES
                            </v-list-item-subtitle>
                            <v-text-field
                              outlined
                              dense
                              v-model="beaconTitleES"
                              maxLength="25"
                              counter
                              class="mt-2"
                              hide-details="auto"
                              @change="setBeaconTitle()"
                            ></v-text-field>
                          </v-list-item-content> -->
                          </div>
                        </v-list-item>
                        <v-list-item>
                          <div class="w100">
                            <v-tooltip location="top" max-width="450">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  v-bind="props"
                                  class="text-subtitle-2 d-flex ml-0"
                                >
                                  {{
                                    $t(
                                      "components.adminSettingsModal.beaconNoUserTitle"
                                    )
                                  }} {{ getLanguage }}
                                </v-list-item-subtitle>
                              </template>
                              <span>
                                {{
                                  $t(
                                    "components.adminSettingsModal.beaconNoUserTT"
                                  )
                                }}
                              </span>
                            </v-tooltip>
                            <div class="d-flex align-center">
                              <v-textarea
                                variant="outlined"
                                density="compact"
                                v-model="noUserMessageActualLanguageModel"
                                maxLength="500"
                                counter
                                hide-details="auto"
                                @change="setNoUserMsg()"
                                rows="3"
                                no-resize
                              ></v-textarea>
                              <v-tooltip top>
                                <template v-slot:activator="{ props }">
                                  <v-btn v-bind="props" class="ml-1" style="margin-top: -15px" icon @click="showModelnoUserMessage=!showModelnoUserMessage" >
                                      <font-awesome-icon
                                          v-if="!showModelnoUserMessage"
                                          :icon="['fal', 'chevron-down']"
                                          :style="{ fontSize: '16px' }"
                                          color="primary"
                                        />
                                        <font-awesome-icon
                                          v-else
                                          :icon="['fal', 'chevron-up']"
                                          :style="{ fontSize: '16px' }"
                                          color="primary"
                                        />
                                    </v-btn>
                                  </template>
                                  <span>{{!showModelnoUserMessage? $t(
                                        "components.adminSettingsModal.moreLanguages"
                                      ) : $t(
                                        "components.adminSettingsModal.hideMoreLangauges"
                                      )}}</span>
                              </v-tooltip>
                            </div>
                            <div class="pr40" v-if="showModelnoUserMessage" cols="12" v-for="(modelsData, index) in noUserMessageOtherLanguageModel" :key="`modelData-${modelsData.lang}-${index}`" >
                              <v-tooltip top max-width="450">
                                <template v-slot:activator="{ props }">
                                  <v-list-item-subtitle
                                    v-bind="props"
                                    class="text-subtitle-2 d-flex ml-0"
                                  >
                                    {{
                                      $t(
                                        "components.adminSettingsModal.beaconNoUserTitle"
                                      )
                                    }} {{ modelsData.lang }}
                                  </v-list-item-subtitle>
                                </template>
                                <span>
                                  {{
                                    $t(
                                      "components.adminSettingsModal.beaconNoUserTT"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                              <v-textarea
                                variant="outlined"
                                density="compact"
                                :model-value="modelsData.value"
                                @update:model-value="noUserMessageOtherLanguageModel = { lang: modelsData.lang, value:$event };"
                                maxLength="500"
                                counter
                                hide-details="auto"
                                @change="setNoUserMsg()"
                                rows="3"
                                no-resize
                              ></v-textarea>
                            </div>
                            <!-- <v-tooltip top max-width="450">
                              <template v-slot:activator="{ on }">
                                <v-list-item-subtitle
                                  v-on="on"
                                  class="subtitle-2 d-flex ml-0"
                                >
                                  {{
                                    $t(
                                      "components.adminSettingsModal.beaconNoUserTitle"
                                    )
                                  }} ES
                                </v-list-item-subtitle>
                              </template>
                              <span>
                                {{
                                  $t(
                                    "components.adminSettingsModal.beaconNoUserTT"
                                  )
                                }}
                              </span>
                            </v-tooltip>
                            <v-textarea
                              outlined
                              dense
                              v-model="noUserMessageES"
                              maxLength="500"
                              counter
                              hide-details="auto"
                              @change="setNoUserMsg()"
                              rows="3"
                               no-resize
                            ></v-textarea>
                            </v-list-item-content> -->
                          </div>
                      </v-list-item>
                        <v-list-item class=px-0>
                          <div class="pl-3 w100">
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  class="text-subtitle-2 pl-2 py-2 d-flex ml-0"
                                  v-bind="props"
                                >
                                  <v-checkbox
                                    class="mt-0"
                                    hide-details
                                    :model-value="showTeamsInBeacon" @update:model-value="showTeamsInBeacon = $event; setShowTeamsInBeacon()"
                                    :label="
                                      $t(
                                        'components.adminSettingsModal.showTeamsInBeacon'
                                      )
                                    "
                                  ></v-checkbox>
                                </v-list-item-subtitle>
                              </template>
                              <span>{{
                                $t(
                                  "components.adminSettingsModal.showTeamsInBeaconTT"
                                )
                              }}</span>
                            </v-tooltip>
                            <div id="combobox_beaconTeams">
                              <v-combobox
                                attach="#combobox_beaconTeams"
                                :disabled="!showTeamsInBeacon"
                                :model-value="teamsShownInBeacon" @update:model-value="teamsShownInBeacon = $event; setTeamsShownInBeacon()"
                                :items="nameSpaceTeams"
                                select
                                :label="
                                  $t('components.organisationCarousel.teams')
                                "
                                multiple
                                variant="outlined"
                                class="mt-3"
                                density="compact"
                                hide-details
                                item-value="team"
                                item-title="team"
                                :return-object="true"
                              >
                              </v-combobox>
                            </div>
                          </div>
                        </v-list-item>
                        <!-- #397 - Guest description -->
                        <v-list-item class=px-0>
                          <div class="pl-3 w100">
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  class="text-subtitle-2 pl-2 py-2 d-flex ml-0"
                                  v-bind="props"
                                >
                                  <v-checkbox
                                    class="mt-0"
                                    hide-details
                                    :model-value="showDescriptionInBeacon" @update:model-value="showDescriptionInBeacon = $event; setShowDescriptionInBeacon()"
                                    :label="
                                      $t(
                                        'components.adminSettingsModal.showDescriptionInBeacon'
                                      )
                                    "
                                  ></v-checkbox>
                                </v-list-item-subtitle>
                              </template>
                              <span>{{
                                $t(
                                  "components.adminSettingsModal.showDescriptionInBeaconTT"
                                )
                              }}</span>
                            </v-tooltip>
                            <div id="combobox_beaconDescription">
                              <v-combobox
                                attach="#combobox_beaconDescription"
                                :disabled="!showDescriptionInBeacon"
                                :model-value="descriptionShownInBeacon" @update:model-value="descriptionShownInBeacon = $event; setDescriptionShownInBeacon()"
                                :items="nameSpaceDescriptions"
                                select
                                :label="
                                  $t('components.adminSettingsModal.description')
                                "
                                multiple
                                variant="outlined"
                                class="mt-3"
                                density="compact"
                                hide-details
                                item-value="description"
                                item-title="description"
                                :return-object="true"
                              >
                              </v-combobox>
                            </div>
                          </div>
                        </v-list-item>
                        <!-- END - Guest description -->
                        <v-list-item class="mt-6 px-0">
                          <div class="pl-3">
                            <v-tooltip location="top" max-width="200px">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  v-bind="props"
                                  class="text-subtitle-2 d-flex ml-0"
                                >
                                  {{
                                    $t(
                                      "components.adminSettingsModal.beaconUsersInCall"
                                    )
                                  }}
                                </v-list-item-subtitle>
                              </template>
                              <span>
                                {{
                                  $t(
                                    "components.adminSettingsModal.beaconUsersInCallTT"
                                  )
                                }}
                              </span>
                            </v-tooltip>
                            <v-radio-group
                              inline
                              :model-value="beaconUsersIcall" @update:model-value="beaconUsersIcall = $event; changeBeaconUsersInCall()"
                            >
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.green')
                                "
                                :value="'green'"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.yellow')
                                "
                                :value="'yellow'"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-list-item>
                        <v-list-item class="px-0">
                          <div class="pl-3">
                            <v-tooltip location="top" max-width="200px">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  v-bind="props"
                                  class="text-subtitle-2 d-flex ml-0"
                                >
                                  {{
                                    $t(
                                      "components.adminSettingsModal.beaconInactiveUsers"
                                    )
                                  }}
                                </v-list-item-subtitle>
                              </template>
                              <span>
                                {{
                                  $t(
                                    "components.adminSettingsModal.beaconInactiveUsersTT"
                                  )
                                }}
                              </span>
                            </v-tooltip>
                            <v-radio-group
                              inline
                              :model-value="beaconInactiveUsers" @update:model-value="beaconInactiveUsers = $event; changeBeaconInactiveUsers()"
                            >
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.gray')
                                "
                                :value="'gray'"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.hidden')
                                "
                                :value="'hidden'"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-list-item>
                        <v-list-item class="px-0">
                          <div class="pl-3">
                            <v-tooltip location="top" max-width="200px">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  v-bind="props"
                                  class="text-subtitle-2 d-flex ml-0"
                                >
                                  {{
                                    $t(
                                      "generics.language"
                                    )
                                  }}
                                </v-list-item-subtitle>
                              </template>
                              <span>
                                {{
                                  $t(
                                    "components.adminSettingsModal.beaconUsersInCallTT"
                                  )
                                }}
                              </span>
                            </v-tooltip>
                            <v-radio-group
                              inline
                              v-model="beaconLanguage"
                              @update:model-value="changeBeaconLanguage()"
                            >
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.browserLanguage')
                                "
                                :value="'default'"
                              ></v-radio>
                               <v-radio
                                label="
                                  DE
                                "
                                :value="'de'"
                              ></v-radio>
                               <v-radio
                                label="
                                  EN
                                "
                                :value="'en'"
                              ></v-radio>
                              <v-radio
                                label="
                                 ES
                                "
                                :value="'es'"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-list-item>
                        <v-col cols="12" class="py-0 px-0">
                          <div id="containerCopy"></div>
                          <v-list-item class="pt-3">
                            <div class="w100">
                              <v-tooltip location="top" max-width="200px">
                                <template v-slot:activator="{ props }">
                                  <v-list-item-subtitle
                                    v-bind="props"
                                    class="text-subtitle-2 d-flex ml-0"
                                  >
                                    {{
                                      $t(
                                        "components.adminSettingsModal.beaconCode"
                                      )
                                    }}
                                  </v-list-item-subtitle>
                                </template>
                                <span>
                                  {{
                                    $t(
                                      "components.adminSettingsModal.beaconCodeTT"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                              <v-list-item-subtitle
                                class="text-subtitle-2 d-flex ml-0 mb-0 mt-3"
                              >
                                {{
                                  $t(
                                    "components.adminSettingsModal.beaconCopyHead"
                                  )
                                }}
                              </v-list-item-subtitle>
                              <div class="containerCode">
                                <font-awesome-icon
                                  @click="copyContentHead"
                                  class="iconCopy"
                                  :icon="['fal', 'copy']"
                                  :style="{
                                    fontSize: '20px',
                                    color: colorIconHead,
                                  }"
                                />
                                <div class="codeViewer" ref="codeViewer">
                                  &lt;script src="{{
                                    getHost()
                                  }}/widget/voffice-widget.js"&gt;&lt;/script&gt;
                                </div>
                              </div>
                              <v-list-item-subtitle
                                class="text-subtitle-2 d-flex ml-0 mb-0 mt-3"
                              >
                                {{
                                  $t(
                                    "components.adminSettingsModal.beaconCopyBody"
                                  )
                                }}
                              </v-list-item-subtitle>
                              <div class="containerCode">
                                <font-awesome-icon
                                  @click="copyContentBody"
                                  class="iconCopy"
                                  :icon="['fal', 'copy']"
                                  :style="{
                                    fontSize: '20px',
                                    color: colorIconBody,
                                  }"
                                />
                                <div class="codeViewer" ref="codeViewerBody">
                                  &lt;voffice-widget namespace="{{ getHost() }}"
                                  title="{{ beaconTitleDE }}" color="{{
                                    beaconColor
                                  }}" position='{{state.namespaceSettings.beaconPosition}}'
                                   icon="{{
                                    state.namespaceSettings.companyLogo
                                  }}" {{
                                    state.namespaceSettings.beaconLanguage && state.namespaceSettings.beaconLanguage !== 'default' ? `language="${beaconLanguage}"`: ''
                                  }}&gt;&lt;/voffice-widget&gt;
                                </div>
                              </div>
                            </div>
                          </v-list-item>
                        </v-col>
                      </v-col>
                      <v-col cols="4" class="py-0 px-0">
                        <v-list-item class="px-0 mb-3">
                          <div class="w100">
                            <v-list-item-subtitle
                              class="text-subtitle-2 d-flex ml-0"
                            >
                              Icon
                            </v-list-item-subtitle>
                            <input
                              type="file"
                              ref="beaconLogoInput"
                              class="d-none"
                              @change="
                                showLogoPreviewModal($event, 'beaconLogo')
                              "
                            />
                            <v-avatar tile size="130">
                              <v-img
                                id="logoModal"
                                class="borderCircle"
                                max-height="130"
                                max-width="130"
                                height="130"
                                width="130"
                                contain
                                v-bind:src="
                                  state.namespaceSettings.beaconLogo ||
                                  'assets/icon.png'
                                "
                                lazy-src="assets/icon.png"
                              />
                              <div flex class="uploadBeaconButton p-4">
                                <v-tooltip location="top">
                                  <template v-slot:activator="{ props }">
                                    <v-btn
                                      v-bind="props"
                                      color="primary"
                                      class="mb-2"
                                      isSmall="true"
                                      v-on:click="uploadBeaconLogo"
                                    >
                                      <font-awesome-icon
                                        :icon="['fal', 'upload']"
                                        :style="{ fontSize: '20px' }"
                                      />
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $t("components.navbar.uploadPicture")
                                  }}</span>
                                </v-tooltip>
                              </div>
                            </v-avatar>
                          </div>
                        </v-list-item>
                        <v-list-item class="px-0">
                          <div class="w100">
                            <v-list-item-subtitle
                              class="text-subtitle-2 d-flex ml-0"
                            >
                              {{
                                $t("components.adminSettingsModal.beaconColor")
                              }}
                            </v-list-item-subtitle>
                            <v-color-picker
                              :mode.sync="mode"
                              :model-value="beaconColor" @update:model-value="beaconColor = $event"
                              dot-size="20"
                              swatches-max-height="200"
                              hide-mode-switch
                              class="pt-2"
                              width="280"
                            ></v-color-picker>
                            <div class="w100">
                            <v-list-item-subtitle
                              class="text-subtitle-2 mt-2 d-flex ml-0"
                            >
                              {{$t("generics.position")}}
                            </v-list-item-subtitle>
                            <div class="w100">
                              <v-radio-group @update:model-value="beaconPositionBottom = 0; beaconPositionRight=0; beaconPositionLeft= 0" hide-details class="px-2" inline v-model="beaconPositionSide">
                                <v-radio
                                  :label="$t('generics.right')"
                                  value="right"
                                ></v-radio>
                                  <v-radio
                                  :label="$t('generics.left')"
                                  value="left"
                                ></v-radio>
                              </v-radio-group>
                              <div class="d-flex mt-2">
                                <v-text-field
                                  class="px-2"
                                  variant="underlined"
                                  v-model="beaconPositionBottom"
                                  label="Bottom"
                                  :min="0"
                                  type="number"
                                ></v-text-field>
                                <v-text-field
                                v-if="beaconPositionSide === 'right'"
                                  class="px-2"
                                  v-model="beaconPositionRight"
                                  :label="beaconPositionSide"
                                  :min="0"
                                  type="number"
                                  variant="underlined"
                                ></v-text-field>
                                <v-text-field
                                  v-else
                                  class="px-2"
                                  v-model="beaconPositionLeft"
                                  :label="beaconPositionSide"
                                  :min="0"
                                  type="number"
                                  variant="underlined"
                                ></v-text-field>
                              </div>
                            </div>
                          </div>
                          </div>

                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                  <!-- END BEACON SETTINGS -->
                  <!-- MESSAGES TO ALL -->
                  <v-expansion-panel-text v-if="item.index == 7">
                    <v-row class="mx-0">
                      <v-col cols="8">
                        <v-textarea
                          variant="outlined"
                          counter
                          contenteditable="true"
                          :label="$t('components.sendMessageModal.message')"
                          :rules="systemMessageRules"
                          :model-value="systemMessagetext" @update:model-value="systemMessagetext = $event"
                          :no-resize="true"
                          maxlength="250"
                          autofocus
                          id="messageTextarea"
                        ></v-textarea>
                        <!-- EXPIRE DATE AND TIME PICKERS -->
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-checkbox
                              v-bind="props"
                              :model-value="messageForAllExpires" @update:model-value="messageForAllExpires = $event"
                              class="mt-0 mb-2 messageForAllExpires"
                              hide-details
                              :label="$t('components.adminSettingsModal.expiredTime')"
                            ></v-checkbox>
                          </template>
                          <span>{{$t('components.adminSettingsModal.expiredTimeTT')}}</span>
                        </v-tooltip>
                        <div v-if="messageForAllExpires">
                          <v-row>
                            <!-- DATE PICKER -->
                            <v-col cols="6">
                              <v-menu
                                :model-value="messageForAllDateMenu" @update:model-value="messageForAllDateMenu = $event"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-text-field
                                    :model-value="messageForAllDate" @update:model-value="messageForAllDate = $event"
                                    class="pt-0"
                                    hide-details
                                    readonly
                                   
                                    v-bind="props"
                                  >
                                    <font-awesome-icon
                                      :icon="['fal', 'calendar']"
                                      :style="{ fontSize: '20px', color:'#757575' } "
                                      slot="prepend"
                                    />
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)"
                                  :locale="$locale.current()"
                                  :model-value="messageForAllDate" @update:model-value="messageForAllDate = $event"
                                  no-title
                                  @input="messageForAllDateMenu = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <!-- END DATE PICKER -->
                            <!-- TIME PICKER -->
                            <v-col cols="6">
                              <v-menu
                                ref="messageForAllTimeMenu"
                                :model-value="messageForAllTimeMenu" @update:model-value="messageForAllTimeMenu = $event"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                :return-value.sync="messageForAllTime"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-text-field
                                    class="pt-0"
                                    :model-value="messageForAllTime" @update:model-value="messageForAllTime = $event"
                                    readonly
                                    hide-details
                                   
                                    v-bind="props"
                                  >
                                    <font-awesome-icon
                                      :icon="['fal', 'clock']"
                                      :style="{ fontSize: '20px', color:'#757575' } "
                                      slot="prepend"
                                    />
                                  </v-text-field>
                                </template>
                                <v-time-picker
                                  v-if="messageForAllTimeMenu"
                                  :model-value="messageForAllTime" @update:model-value="messageForAllTime = $event"
                                  full-width
                                  format="24hr"
                                  :min="minMessageForAllTime"
                                  @click:minute="$refs.messageForAllTimeMenu[0].save(messageForAllTime)"
                                ></v-time-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <!-- END TIME PICKER -->
                        </div>
                        <!-- END EXPIRE DATE AND TIME PICKERS -->
                      </v-col>
                      <v-col cols="4">
                        <v-radio-group
                          :model-value="systemMessageColor" @update:model-value="systemMessageColor = $event"
                          column
                          hide-details
                        >
                          <v-radio value="red">
                            <template v-slot:label>
                              <div class="w100 ml-1 bg-red text-center">
                                <span class="text-white">{{$t('generics.sampleText')}}</span>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="green">
                            <template v-slot:label>
                              <div class="w100 ml-1 bg-green text-center">
                                <span class="text-white">{{$t('generics.sampleText')}}</span>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="yellow">
                            <template v-slot:label>
                              <div class="w100 ml-1 bg-yellow text-center">
                                <span class="text-black">{{$t('generics.sampleText')}}</span>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="teal">
                            <template v-slot:label>
                              <div class="w100 ml-1 bg-teal text-center">
                                <span class="text-white">{{$t('generics.sampleText')}}</span>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="white">
                            <template v-slot:label>
                              <div class="w100 ml-1 bg-white text-center">
                                <span class="text-black">{{$t('generics.sampleText')}}</span>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="black">
                            <template v-slot:label>
                              <div class="w100 ml-1 bg-black text-center">
                                <span class="text-white">{{$t('generics.sampleText')}}</span>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class="mx-0">
                      <v-btn flat
                        color="primary"
                        @click="sendSystemMessage()"
                        :disabled="disabledSystemMessageBtn"
                        >{{
                          $t("components.adminSettingsModal.sendMessageToAll")
                        }}</v-btn
                      >
                    </v-row>
                  </v-expansion-panel-text>
                  <!-- END MESSAGES TO ALL -->
                  <!-- E2E -->
                  <!-- <v-expansion-panel-text v-if="item.index == 8">
                    <v-row class="mx-0">
                      <v-col cols="12" class="d-flex">
                        <v-switch
                          :model-value="activateE2E" @update:model-value="activateE2E = $event"
                          width="100%"
                          class="mt-0"
                          hide-details
                          density="compact"
                          @change="setE2E"
                        ></v-switch>
                        <v-tooltip location="top">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="py-2 lighten-4">
                              {{
                                $t("components.adminSettingsModal.activateE2E")
                              }}</span
                            >
                          </template>
                          <span>{{
                            !activateE2E
                              ? $t(
                                  "components.adminSettingsModal.activateE2EEOnTT"
                                )
                              : $t(
                                  "components.adminSettingsModal.activateE2EEOffTT"
                                )
                          }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" class="d-flex">
                        <v-switch
                          :model-value="strictRule" @update:model-value="strictRule = $event"
                          width="100%"
                          class="mt-0"
                          hide-details
                          density="compact"
                          @change="setStrictE2E"
                          :disabled="!activateE2E"
                        ></v-switch>
                        <v-tooltip location="top" :disabled="!activateE2E">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="py-2 lighten-4">
                              {{
                                $t("components.adminSettingsModal.strictE2E")
                              }}</span
                            >
                          </template>
                          <span>{{
                            strictRule
                              ? $t("components.adminSettingsModal.ttRuleOn")
                              : $t("components.adminSettingsModal.ttRuleOff")
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text> -->
                  <!-- END E2E -->
                  <!-- ICE Server -->
                  <!-- <v-expansion-panel-text v-if="item.index == 9">
                    <v-row class="mx-0">
                      <v-col cols="12 text-left">
                        <select
                          ref="selectIceServers"
                          size="4"
                          class="selectIceServers"
                        >
                          <option
                            v-for="(server, index) in getIceServers"
                            :value="server"
                            :key="index"
                            @dblclick="selectServer(server)"
                          >
                            {{ server.urls }}
                            <span v-if="server.credential && server.username"
                              >[{{ server.username }}:{{
                                server.credential
                              }}]</span
                            >
                          </option>
                        </select>
                        <v-text-field
                          class="mt-2"
                          variant="outlined"
                          density="compact"
                          hide-details
                          :model-value="STUN_TURN_URI" @update:model-value="STUN_TURN_URI = $event"
                          :label="
                            $t('components.adminSettingsModal.TURN_STUN_URI')
                          "
                        ></v-text-field>
                        <v-text-field
                          variant="outlined"
                          class="mt-2"
                          density="compact"
                          hide-details
                          :model-value="TURN_username" @update:model-value="TURN_username = $event"
                          :label="
                            $t('components.adminSettingsModal.TURN_username')
                          "
                        ></v-text-field>
                        <v-text-field
                          class="mt-2"
                          variant="outlined"
                          density="compact"
                          hide-details
                          :model-value="TURN_password" @update:model-value="TURN_password = $event"
                          type="password"
                          :label="
                            $t('components.adminSettingsModal.TURN_password')
                          "
                        ></v-text-field>
                      </v-col>
                      <v-row class="mx-0">
                        <v-col cols="3" class="px-0">
                          <v-btn
                            color="primary"
                            :disabled="
                              !STUN_TURN_URI || STUN_TURN_URI.length == 0
                            "
                            @click.stop.prevent="addServer"
                            :style="'text-transform:none!important'"
                            >{{
                              $t("components.adminSettingsModal.AddServer")
                            }}</v-btn
                          >
                        </v-col>

                        <v-col cols="4" class="px-0">
                          <v-btn
                            color="primary"
                            @click.stop.prevent="openCloseModalRemoveICEServer"
                            :style="'text-transform:none!important'"
                            >{{
                              $t("components.adminSettingsModal.RemoveServer")
                            }}</v-btn
                          >
                        </v-col>

                        <v-col cols="5" class="px-0">
                          <v-btn
                            color="primary"
                            @click.stop.prevent="resetDefault"
                            :style="'text-transform:none!important'"
                            >{{
                              $t("components.adminSettingsModal.ResetDefaults")
                            }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-expansion-panel-text> -->
                  <v-expansion-panel-text v-if="item.index == 10">
                    <v-row class="text-left">
                      <v-col cols="12" class="py-0"> </v-col>
                      <v-col cols="12" class="py-0">
                        <v-textarea
                          variant="outlined"
                          :label="
                            $t('components.adminSettingsModal.userEmailText')
                          "
                          :contenteditable="true"
                          :model-value="userInviteEmailText" @update:model-value="userInviteEmailText = $event"
                          :no-resize="true"
                          class="paddingTextArea"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-btn 
                          flat
                          class="mr-2"
                          color="primary"
                          @click="resetUserInviteEmailText()"
                          :loading="savingInviteEmailText"
                          :disabled="savingInviteEmailText"
                        >
                          {{
                            $t(
                              "components.inviteGuestPanel.resetCustomInviteText"
                            )
                          }}
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <font-awesome-icon
                                :icon="['fal', 'spinner-third']"
                                :style="{ fontSize: '15px', color: '#2a3133' }"
                              />
                            </span>
                          </template>
                        </v-btn>
                        <v-btn flat
                          class="mr-2"
                          color="primary"
                          :disabled="
                            !userInviteEmailText || savingInviteEmailText
                          "
                          :loading="savingInviteEmailText"
                          @click="saveUserInviteEmailText()"
                        >
                          {{ $t("components.inviteGuestPanel.saveText") }}
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <font-awesome-icon
                                :icon="['fal', 'spinner-third']"
                                :style="{ fontSize: '15px', color: '#2a3133' }"
                              />
                            </span>
                          </template>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="text-left">
                      <v-col cols="12" class="py-0"> </v-col>
                      <v-col cols="12" class="pb-0">
                        <v-textarea
                        variant="outlined"
                          :label="
                            $t('components.adminSettingsModal.accessLinkText')
                          "
                          :contenteditable="true"
                          :model-value="accessLinkEmailText" @update:model-value="accessLinkEmailText = $event"
                          :no-resize="true"
                          class="paddingTextArea"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-btn flat
                          class="mr-2"
                          color="primary"
                          @click="resetAccessLinkEmailText()"
                          :loading="savingAccessLinkEmailText"
                          :disabled="savingAccessLinkEmailText"
                        >
                          {{
                            $t(
                              "components.inviteGuestPanel.resetCustomInviteText"
                            )
                          }}
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <font-awesome-icon
                                :icon="['fal', 'spinner-third']"
                                :style="{ fontSize: '15px', color: '#2a3133' }"
                              />
                            </span>
                          </template>
                        </v-btn>
                        <v-btn flat
                          class="mr-2"
                          color="primary"
                          :loading="savingAccessLinkEmailText"
                          :disabled="
                            !accessLinkEmailText || savingAccessLinkEmailText
                          "
                          @click="saveAccessLinkEmailText()"
                        >
                          {{ $t("components.inviteGuestPanel.saveText") }}
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <font-awesome-icon
                                :icon="['fal', 'spinner-third']"
                                :style="{ fontSize: '15px', color: '#2a3133' }"
                              />
                            </span>
                          </template>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                  <!-- END ICE SERVER -->
                  <!-- CONFERENCE HALL -->
                  <v-expansion-panel-text v-if="item.index == 11">
                    <v-row class="text-left">
                     <v-col cols="12" class="px-0">
                          <span class="text-left text-subtitle-2 d-flex align-center mr-2">
                            <v-switch
                              hide-details
                              class="py-0 my-0 pr-2"
                              :model-value="showConferencehall" @update:model-value="showConferencehall = $event; changeShowConferencehall()"
                            ></v-switch>
                            {{ $t("components.adminSettingsModal.showEvents") }}
                          </span>
                        </v-col>
                    </v-row>
                    <!-- <template v-if="!isPkg">
                      <v-row class="text-left">
                        <v-col cols="12" class="px-0">
                            <span class="text-left text-subtitle-2 d-flex mr-2">
                              <v-text-field
                                variant="outlined"
                                density="compact"
                                :model-value="eventApiKey" @update:model-value="eventApiKey = $event"
                                class="mt-2"
                                style="height: 40px"
                                :label="$t('components.adminSettingsModal.eventApiKey')"
                              ></v-text-field>
                            </span>
                          </v-col>
                      </v-row>
                      <v-row class="text-left">
                        <v-col cols="12" class="px-0">
                          <span class="text-left text-subtitle-2 d-flex mr-2">
                            <v-text-field
                              variant="outlined"
                              density="compact"
                              :model-value="eventClientId" @update:model-value="eventClientId = $event"
                              class="mt-2"
                              style="height: 40px"
                              :label="$t('components.adminSettingsModal.eventsClientId')"
                            ></v-text-field>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="text-right">
                        <v-col cols="12">
                          <v-btn flat
                            color="primary"
                            @click="setEventsApiSettings()"
                            >{{ $t("generics.save") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </template> -->
                  </v-expansion-panel-text>
                  <!-- END CONFERENCE HALL -->
                  <!-- DAILY REPORT -->
                  <v-expansion-panel-text v-if="item.index == 12">
                    <v-row class="text-left">
                      <v-col cols="12" class="px-0 py-0">
                        <span class="text-left text-subtitle-2 align-center
                         d-flex mr-2 align-center">
                          <v-switch
                            hide-details
                            class="py-0 my-0 pr-2"
                            :model-value="showDailyReport" @update:model-value="showDailyReport = $event; changeShowDailyReport()"
                          ></v-switch>
                          {{ $t("components.adminSettingsModal.showDailyReport") }}
                        </span>
                      </v-col>
                      <v-col cols="12" class="px-0 pt-0">
                        <v-radio-group inline :model-value="dailyReportValue" @update:model-value="dailyReportValue = $event; changeDailyReportValue()" :disabled="!showDailyReport">
                          <v-radio :label="$t('generics.none')" :value="'perUser'"></v-radio>
                          <v-radio :label="$t('components.organisationCarousel.team')" :value="'team'"></v-radio>
                          <v-radio :label="$t('components.organisationCarousel.department')" :value="'department'"></v-radio>
                          <v-radio :label="$t('components.organisationCarousel.section')" :value="'section'"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                  <!-- END DAILY REPORT -->
                  <!-- PRIVACY -->
                  <v-expansion-panel-text v-if="item.index == 13">
                    <!-- TIMEFRAMES FOR DATA DELETION -->
                    <div v-if="amIAdmin && isDevelopment">
                      <v-list-item v-if="amIAdmin && isDevelopment" class="py-0 px-0 switchWrapper">
                        <div class="py-0 pl-3 my-0">
                          <v-list-item-subtitle class="text-subtitle-2 d-flex ml-0">
                            {{$t("components.adminSettingsModal.timeframeDeletion")}}
                          </v-list-item-subtitle>
                        </div>
                      </v-list-item>
                      <!-- deletion of messages -->
                      <!-- <template> -->
                        <v-list-item
                          v-if="amIAdmin && isDevelopment"
                          class="py-0 px-0 switchWrapper"
                        >
                          <div class="py-0 pl-3 my-0">
                            
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  v-bind="props"
                                  class="text-subtitle-2 d-flex ml-0"
                                  >{{
                                    $t(
                                      "components.adminSettingsModal.deletionMessages"
                                    )
                                  }}</v-list-item-subtitle
                                >
                              </template>
                              <span>{{
                                $t("components.adminSettingsModal.deletionTT")
                              }}</span>
                            </v-tooltip>
                            <v-radio-group
                              inline
                              :model-value="deletionMessages" @update:model-value="deletionMessages = $event;changeDeletionMessages()"
                            >
                              <v-radio
                                :label="$t('components.adminSettingsModal.1day')"
                                value="1day"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.2day')"
                                :value="false"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.1week')"
                                value="1week"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.1month')
                                "
                                value="1month"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.3month')
                                "
                                value="3months"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.never')"
                                value="never"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-list-item>
                      <!-- </template> -->
                      <!-- deletion of missed calls -->
                      <!-- <template> -->
                        <v-list-item
                          v-if="amIAdmin && isDevelopment"
                          class="py-0 px-0 switchWrapper"
                        >
                          <div class="py-0 pl-3 my-0">
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  v-bind="props"
                                  class="text-subtitle-2 d-flex ml-0"
                                  >{{
                                    $t(
                                      "components.adminSettingsModal.deletionMissedCalls"
                                    )
                                  }}</v-list-item-subtitle
                                >
                              </template>
                              <span>{{
                                $t("components.adminSettingsModal.deletionTT")
                              }}</span>
                            </v-tooltip>
                            <v-radio-group
                              inline
                              :model-value="deletionCalls" @update:model-value="deletionCalls = $event; changeDeletionMissedCalls()"
                            >
                              <v-radio
                                :label="$t('components.adminSettingsModal.1day')"
                                value="1day"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.2day')"
                                :value="false"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.1week')"
                                value="1week"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.1month')
                                "
                                value="1month"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.3month')
                                "
                                value="3months"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.never')"
                                value="never"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-list-item>
                      <!-- </template> -->
                      <!-- deletion of invites -->
                      <!-- <template> -->
                        <v-list-item
                          v-if="amIAdmin && isDevelopment"
                          class="py-0 px-0 switchWrapper"
                        >
                          <div class="py-0 pl-3 my-0">
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  v-bind="props"
                                  class="text-subtitle-2 d-flex ml-0"
                                  >{{
                                    $t(
                                      "components.adminSettingsModal.deletionInvites"
                                    )
                                  }}</v-list-item-subtitle
                                >
                              </template>
                              <span>{{
                                $t("components.adminSettingsModal.deletionTT")
                              }}</span>
                            </v-tooltip>
                            <v-radio-group
                              inline
                              :model-value="deletionInvites" @update:model-value="deletionInvites = $event; changeDeletionInvites()"
                            >
                              <v-radio
                                :label="$t('components.adminSettingsModal.1day')"
                                value="1day"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.2day')"
                                :value="false"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.1week')"
                                value="1week"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.1month')
                                "
                                value="1month"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.3month')
                                "
                                value="3months"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.never')"
                                value="never"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-list-item>
                      <!-- </template> -->
                      <!-- deletion of user conferences -->
                      <!-- <template> -->
                        <v-list-item
                          v-if="amIAdmin && isDevelopment"
                          class="py-0 px-0 switchWrapper"
                        >
                          <div class="py-0 pl-3 my-0">
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-list-item-subtitle
                                  v-bind="props"
                                  class="text-subtitle-2 d-flex ml-0"
                                  >{{
                                    $t(
                                      "components.adminSettingsModal.autoDeleteFrequency"
                                    )
                                  }}</v-list-item-subtitle
                                >
                              </template>
                              <span>{{
                                $t("components.adminSettingsModal.deletionTT")
                              }}</span>
                            </v-tooltip>
                            <v-radio-group
                              :model-value="autoDeleteConferences" @update:model-value="autoDeleteConferences = $event; changeAutoDeleteConferences"
                              inline
                            >
                              <v-radio
                                :label="$t('components.adminSettingsModal.1day')"
                                value="1"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.2day')"
                                value="2"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.adminSettingsModal.1week')"
                                value="7"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.1month')
                                "
                                value="30"
                              ></v-radio>
                              <v-radio
                                :label="
                                  $t('components.adminSettingsModal.3month')
                                "
                                value="90"
                              ></v-radio>
                              <v-radio
                                :label="$t('components.addConferenceModal.never')"
                                value="0"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-list-item>
                      <!-- </template> -->
                    </div>
                    <!-- END TIMEFRAMES FOR DATA DELETION -->
                    <!-- E2E ENCRYPTION -->
                    <!-- <template>
                      <v-list-item class="py-0 px-0 switchWrapper">
                        <div class="py-0 pl-3 my-0">
                          <v-list-item-subtitle class="text-subtitle-2 d-flex ml-0 align-baseline">
                            <span class="mr-4">
                            {{$t("components.adminSettingsModal.e2eTitle")}}
                            </span>
                             <v-tooltip location="top" content-class="videoButtonTT">
                              <template v-slot:activator="{ props }">
                                <font-awesome-icon
                                  v-bind="props"
                                  color="#2a3133"
                                  :icon="['fal', 'info-circle']"
                                  :style="{
                                    fontSize: '17px',
                                    position: 'relative',
                                    top: '2px',
                                  }"
                                />
                              </template>
                              <span>
                                {{$t( "components.adminSettingsModal.e2eeInfoButton" )}}
                              </span>
                            </v-tooltip>
                          </v-list-item-subtitle>
                        </div>
                      </v-list-item>
                      <v-row class="mx-0">
                        <v-col cols="12" class="d-flex">
                          <v-switch
                            :model-value="activateE2E" @update:model-value="activateE2E = $event; setE2E"
                            width="100%"
                            class="mt-0"
                            hide-details
                            density="compact"
                          ></v-switch>
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <span v-bind="props" class="py-2">
                                {{
                                  $t("components.adminSettingsModal.activateE2E")
                                }}</span
                              >
                            </template>
                            <span>{{
                              !activateE2E
                                ? $t(
                                    "components.adminSettingsModal.activateE2EEOnTT"
                                  )
                                : $t(
                                    "components.adminSettingsModal.activateE2EEOffTT"
                                  )
                            }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <v-switch
                            :model-value="strictRule" @update:model-value="strictRule = $event; setStrictE2E"
                            width="100%"
                            class="mt-0"
                            hide-details
                            density="compact"
                            :disabled="!activateE2E"
                          ></v-switch>
                          <v-tooltip location="top" :disabled="!activateE2E">
                            <template v-slot:activator="{ props }">
                              <span v-bind="props" class="py-2">
                                {{
                                  $t("components.adminSettingsModal.strictE2E")
                                }}</span
                              >
                            </template>
                            <span>{{
                              strictRule
                                ? $t("components.adminSettingsModal.ttRuleOn")
                                : $t("components.adminSettingsModal.ttRuleOff")
                            }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </template> -->
                    <!-- END E2E ENCRYPTION -->
                    <!-- ICE SERVER -->
                    <!-- <template> -->
                      <v-list-item class="py-0 px-0 switchWrapper">
                        <div class="py-0 pl-3 my-0">
                          <v-list-item-subtitle class="text-subtitle-2 d-flex ml-0 align-baseline">
                            <span class="mr-4">
                            {{$t("components.adminSettingsModal.ICE_servers")}}
                            </span>
                             <v-tooltip location="top" content-class="videoButtonTT">
                              <template v-slot:activator="{ props }">
                                <font-awesome-icon
                                  v-bind="props"
                                  color="#2a3133"
                                  :icon="['fal', 'info-circle']"
                                  :style="{
                                    fontSize: '17px',
                                    position: 'relative',
                                    top: '2px',
                                  }"
                                />
                              </template>
                              <span>
                                {{$t( "components.adminSettingsModal.ICE_serversInfo" )}}
                              </span>
                            </v-tooltip>
                          </v-list-item-subtitle>
                        </div>
                      </v-list-item>
                      <v-row class="mx-0">
                        <v-col cols="12 text-left">
                          <select
                            ref="selectIceServers"
                            size="4"
                            class="selectIceServers"
                          >
                            <option
                              v-for="(server, index) in getIceServers"
                              :value="server"
                              :key="index"
                              @dblclick="selectServer(server)"
                            >
                              {{ server.urls }}
                              <span v-if="server.credential && server.username"
                                >[{{ server.username }}:{{
                                  server.credential
                                }}]</span
                              >
                            </option>
                          </select>
                          <v-text-field
                            class="mt-2"
                            variant="outlined"
                            density="compact"
                            hide-details
                            :model-value="STUN_TURN_URI" @update:model-value="STUN_TURN_URI = $event"
                            :label="
                              $t('components.adminSettingsModal.TURN_STUN_URI')
                            "
                          ></v-text-field>
                          <v-text-field
                            variant="outlined"
                            class="mt-2"
                            density="compact"
                            hide-details
                            :model-value="TURN_username" @update:model-value="TURN_username = $event"
                            :label="
                              $t('components.adminSettingsModal.TURN_username')
                            "
                          ></v-text-field>
                          <v-text-field
                            class="mt-2"
                            variant="outlined"
                            density="compact"
                            hide-details
                            :model-value="TURN_password" @update:model-value="TURN_password = $event"
                            type="password"
                            :label="
                              $t('components.adminSettingsModal.TURN_password')
                            "
                          ></v-text-field>
                        </v-col>
                        <v-row class="mx-0">
                          <v-col cols="3" class="px-0">
                            <v-btn flat
                              color="primary"
                              :disabled="
                                !STUN_TURN_URI || STUN_TURN_URI.length == 0
                              "
                              @click.stop.prevent="addServer"
                              :style="'text-transform:none!important'"
                              >{{
                                $t("components.adminSettingsModal.AddServer")
                              }}</v-btn
                            >
                          </v-col>

                          <v-col cols="4" class="px-0">
                            <v-btn flat
                              color="primary"
                              @click.stop.prevent="openCloseModalRemoveICEServer"
                              :style="'text-transform:none!important'"
                              >{{
                                $t("components.adminSettingsModal.RemoveServer")
                              }}</v-btn
                            >
                          </v-col>

                          <v-col cols="5" class="px-0">
                            <v-btn flat
                              color="primary"
                              @click.stop.prevent="resetDefault"
                              :style="'text-transform:none!important'"
                              >{{
                                $t("components.adminSettingsModal.ResetDefaults")
                              }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-row>
                    <!-- </template> -->
                    <!-- END ICE SERVER -->
                  </v-expansion-panel-text>
                  <!-- END PRIVACY -->
                  <!-- WORKINGTIME TEMPLATES -->
                  <v-expansion-panel-text v-if="item.index == 14">
                    <div v-if="amIAdmin">
                      <v-row class="text-left">
                        <v-col cols="5">
                          <div class="w100">
                            <v-combobox :model-value="selectedWorkingTimeTemplate" @update:model-value="selectedWorkingTimeTemplate = $event" density="compact" variant="outlined" :items="getTemplatesWorkingTime"  item-title="name" item-value="_id"></v-combobox>
                             <v-row class="text-right">
                              <v-col>
                                <v-btn class="mr-4" :disabled="!selectedWorkingTimeTemplate" @click="openCloseModalAddEditWorkingTimeTemplate(true, selectedWorkingTimeTemplate)" color="primary" flat>
                                 {{$t("components.workingTime.editTemplate")}}
                                </v-btn>
                                <v-btn class="mr-4" color="primary" @click="openCloseModalAddEditWorkingTimeTemplate(true, undefined)" flat>
                                   {{$t("components.workingTime.addTemplate")}}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <div v-if="selectedWorkingTimeTemplate">
                            <div class="text-center"> {{$t("components.workingTime.template")}}: {{selectedWorkingTimeTemplate.name}}</div>
                            <v-table fixed-header density="compact">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      {{$t("components.workingTime.day")}}
                                    </th>
                                    <th class="text-left">
                                      {{$t("components.workingTime.workTime")}}
                                    </th>
                                    <th class="text-left">
                                     {{$t("components.workingTime.pause")}}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(data, index) in getSelectedWorkingTimeData" :key="index">
                                    <td>{{$t("components.workingTime."+ days[index].toLowerCase() + "")}}</td>
                                    <td>{{toHoursAndMinutes(data.work)}}</td>
                                    <td>{{toHoursAndMinutes(data.pause)}}</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-text>
                  <!-- END WORKINGTIME TEMPLATES -->
                  <v-expansion-panel-text v-if="item.index == 15">
                    <v-row class="text-left">
                     <v-col cols="12" class="px-0">
                          <span class="text-left text-subtitle-2 align-center d-flex mr-2">
                            <v-switch
                              hide-details
                              class="py-0 my-0 pr-2"
                              :model-value="showGPT4BOT" @update:model-value="showGPT4BOT = $event; changeShowGPT4BOT()"
                            ></v-switch>
                            {{ $t("components.adminSettingsModal.showIa") }}
                          </span>
                        </v-col>
                    </v-row>
                    <!-- <template> -->
                      <v-row class="text-left">
                        <v-col cols="12" class="px-0">
                            <span class="text-left text-subtitle-2 d-flex mr-2">
                              <v-text-field
                                variant="outlined"
                                density="compact"
                                :model-value="openAiApiKey" @update:model-value="openAiApiKey = $event"
                                class="mt-2"
                                style="height: 40px"
                                :label="$t('components.adminSettingsModal.apiKeyOpenAi')"
                              ></v-text-field>
                            </span>
                          </v-col>
                      </v-row>
                      <v-row class="text-left">
                        <v-col cols="12" class="px-0">
                          <span class="text-left text-subtitle-2 d-flex mr-2">
                            <v-text-field
                              variant="outlined"
                              density="compact"
                              :model-value="humanPictureApiKey" @update:model-value="humanPictureApiKey = $event"
                              class="mt-2"
                              style="height: 40px"
                              :label="$t('components.adminSettingsModal.apiKeyHumanPicture')"
                            ></v-text-field>
                          </span>
                        </v-col>
                      </v-row>
                      <!-- confidentialAiEndpoint -->
                      <v-row class="text-left">
                        <v-col cols="12" class="px-0">
                          <span class="text-left text-subtitle-2 d-flex mr-2">
                            <v-text-field
                              variant="outlined"
                              density="compact"
                              :model-value="confidentialAiEndpoint" @update:model-value="confidentialAiEndpoint = $event"
                              class="mt-2"
                              style="height: 40px"
                              :label="$t('components.adminSettingsModal.apiConfidentialUrl')"
                            ></v-text-field>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="text-right">
                        <v-col cols="12">
                          <v-btn flat
                            color="primary"
                            @click="setGpt4bot()"
                            >{{ $t("generics.save") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    <!-- </template> -->
                  </v-expansion-panel-text>
                  <!-- SEARCH GUEST -->
                  <v-expansion-panel-text v-if="item.index == 16">
                    <v-row class="text-left">
                     <v-col cols="12" class="px-0">
                      <v-text-field
                        variant="outlined"
                        density="compact"
                        v-model="searchGuest"
                        class="mt-2"
                        style="height: 40px"
                        :label="$t('literals.Email address')"
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        @click:clear="searchGuest = ''"
                      ></v-text-field>
                      </v-col>
                      <v-col>
                        <div class="d-flex"  v-for="guest in guestEmailList" :key="guest.user.uuid">
                         <v-col cols="11">{{ guest.user.email }}</v-col>
                         <v-col cols="1" class="text-right pr-0">
                              <DeleteBasket
                                :indexData="guest.user.uuid"
                                :delFunction="removeGuestEmail"
                                extraClasses=""
                                fontSize="20"
                                prevent="false"
                              />
                         </v-col>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                  <!-- SOCIAL MEDIA -->
                  <v-expansion-panel-text v-if="item.index == 17">
                    <v-row class="text-left">
                      <v-col cols="12" class="px-0">
                          <span class="text-left text-subtitle-2 align-center d-flex mr-2">
                            <v-switch
                              hide-details
                              class="py-0 my-0 pr-2"
                              :model-value="showSocialMedia" @update:model-value="showSocialMedia = $event; changeShowSocialMedia()"
                            ></v-switch>
                            {{ $t("components.adminSettingsModal.showSocialMedia") }}
                          </span>
                        </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                  <!-- MARRIAGE PROJECT -->
                  <v-expansion-panel-text v-if="item.index == 18">
                    <v-row class="text-left align-end">
                     <v-col cols="5" class="px-0">
                      <v-text-field
                        variant="outlined"
                        density="compact"
                        v-model="marriageName"
                        class="mt-2"
                        style="height: 40px"
                        :label="$t('components.contact.name')"
                      ></v-text-field>
                      </v-col>
                      <v-col cols="5" class="">
                      <v-text-field
                        variant="outlined"
                        density="compact"
                        v-model="marriageDomain"
                        class="mt-2"
                        style="height: 40px"
                        :label="$t('components.payment.domain')"
                      ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-btn :disabled="!marriageName.length || !marriageDomain.length" color="primary" @click="saveExternalDomain()" flat>{{
                          $t("generics.save")
                        }}</v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="text-left" v-for="(project, i) in marriageProject" :key="i">
                      <v-col cols="10" class="px-0">
                        <span class="font-weight-bold">{{project.name}}:</span> {{ project.domain }}
                      </v-col>
                      <v-col cols="2">
                        <DeleteBasket :indexData='{domain:project.domain, name:project.name}' :delFunction="removeExternalDomain"  fontSize="16"/>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-col>
        </v-row>
        <v-row class="mx-0" v-if="showRelayText">
          <v-list-item class="py-0 switchWrapper">
            <div class="w100">
              <v-list-item-title
                >{{
                  $t("components.adminSettingsModal.relayServerInfo0")
                }}{{}}</v-list-item-title
              >
              <div class="w100">
                <p>
                  {{ $t("components.adminSettingsModal.relayServerInfo1") }}
                </p>
                <p>
                  {{ $t("components.adminSettingsModal.relayServerInfo2") }}
                </p>
              </div>
            </div>
          </v-list-item>
        </v-row>
        <v-row class="mx-0" v-if="showAliasText">
          <v-list-item class="py-0 switchWrapper">
            <div class="w100">
              <v-list-item-title>{{
                $t("components.adminSettingsModal.setAlias")
              }}</v-list-item-title>
              <div class="w100">
                <p>{{ $t("components.adminSettingsModal.setAliasInfo") }}</p>
              </div>
            </div>
          </v-list-item>
        </v-row>
        <FooterModal
          v-if="!showCroppieLogo"
          :closeModalFunction="closeModalFunction"
        />
      </v-card>
    </v-dialog>
    <input
      type="file"
      accept="image/x-png,image/gif,image/jpeg"
      ref="backgroundWaitingRoomInput"
      class="d-none"
      @change="saveBackgroundWaitingRoom($event)"
    />
    <input
      type="file"
      accept="application/pdf"
      ref="pdfWaitingRoomInput"
      class="d-none"
      @change="savePdfWaitingRoom($event)"
    />
    <div v-if="openLocationModal">
      <AddLocationModal
        :showModal="openLocationModal"
        :closeModal="setCustomLocation"
      />
    </div>
    <div v-if="showFtpModal">
      <ConfirmFtpSettingModal
        :showModal="showFtpModal"
        :closeModal="openCloseConfirmationFtp"
        :cancelFtp="changeFtp"
      />
    </div>
    <div v-if="openInfoCompact">
      <CompactViewInfoModal
        :showModal="openInfoCompact"
        :closeModal="opencloseInfoCompact"
      />
    </div>
    <div v-if="showPayModal">
      <PayModal :showModal="showPayModal" :closeModal="openClosePayModal" />
    </div>
    <div>
      <TwoFAInfoModal
        :showModal="show2faInfoModal"
        :closeModal="openClose2faModal"
      />
    </div>
    <div v-if="showShortRelayModal">
      <RelyShortHelpModal
        :showModal="showShortRelayModal"
        :closeModal="openCloseShortRelyModal"
      />
    </div>
    <div v-if="showRatingStats">
      <CallsRatingModal
        :showModal="showRatingStats"
        :closeModal="opencloseStats"
      />
    </div>
    <div v-if="showPayPalSettings">
      <PayPalSettingsModal
        :showModal="showPayPalSettings"
        :closeModal="openClosePayPalSettings"
      />
    </div>
    <div v-if="showModalProducts">
      <ProductsModal
        :showModal="showModalProducts"
        :closeModal="openCloseModalProducts"
      />
    </div>
    <div v-if="showModalRemoveICEServer">
      <RemoveIceServerModal
        :showModal="showModalRemoveICEServer"
        :closeModal="openCloseModalRemoveICEServer"
        :removeICEServer="removeServer"
      />
    </div>
    <div v-if="showModalAddEditWorkingTimeTemplate">
      <AddEditWorkingTimeTemplate  :showModal="showModalAddEditWorkingTimeTemplate" :editTemplate="editTemplatesWorkingTime" :closeModal="openCloseModalAddEditWorkingTimeTemplate"/>
    </div>
  </div>
</template>

<script>
import moment from "../../../sharedsrc/moment";
import { v4 as uuidv4 } from "uuid";
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { getIceServers, setIceServers } from "../../lib/rtcSettings";
import {
  getPayPalSettings,
  setNamespaceAlias,
  setNamespaceSetting,
} from "../../lib/wsMsg";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import { removeUser } from "../../utils/waitingRoom";
import { getTimezone } from "../../utils/basicFunctions";
import { basicView, hasPrivilege } from "../../utils/privileges";
import TwoFaAdminConfirmation from "../2fa/modals/2faAdminConfirmation.vue";
import AddLocationModal from "./addLocationModal.vue";
import CompactViewInfoModal from "./compactViewInfoModal.vue";
import RelyShortHelpModal from "./relyShortHelpModal.vue";
import ConfirmFtpSettingModal from "./confirmFtpSettingModal.vue";
import PayModal from "./payModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { setMailFooterEvent, setBeaconDomainWhitelistEvent } from "../../effector/modals";
import TwoFAInfoModal from "./twoFAInfoModal.vue";
import CallsRatingModal from "./callsRatingModal.vue";
import TwoFactorAdminRecoveryEntry from "../2fa/admin/recoveryEntry.vue";
import { dispatchSuccessAlert } from "../../effector/alerts";
import PayPalSettingsModal from "../modal/paypal/payPalSettings.vue";
import ProductsModal from "../modal/paypal/products.vue";
import RemoveIceServerModal from "../modal/removeIceServerModal.vue";
import AddEditWorkingTimeTemplate from "../modal/addEditWorkingTimeTemplate.vue";
import {
  prepadeDataToSendMessageEffector,
  newMessageEvent,
} from "../../effector/message";
import { cloneDeep } from "lodash-es";
import { YOUTUBE_API_KEY_DEFAULT, YOUTUBE_CLIENT_ID_DEFAULT } from '../../../sharedsrc/constants'
import draggable from "vuedraggable";

export default {
  data() {
    return {
      APIUser: "",
      APIPassword: "",
      APISignature: "",
      show2FaModal: true,
      state: store.state,
      companyHomePage: store.state.namespaceSettings.companyHomePage,
      alias: store.state.namespaceSettings.alias,
      aliasFormValid: true,
      aliasTakenError: false,
      aliasSuccess: "",
      showCroppieLogo: false,
      showPreview: false,
      setCurrentContentVisile: store.setCurrentContentVisile,
      showRelayText: false,
      showAliasText: false,
      forceRelay: store.state.namespaceSettings.forceRelay,
      activateScreenSaver: false,
      isMobile: isMobile(),
      STUN_TURN_URI: "",
      TURN_username: "",
      TURN_password: "",
      ICE_servers: [],
      setBlurEffect: store.state.namespaceSettings.showBlurEffectOption,
      companyEmail: store.state.namespaceSettings.companyInfo.email || "",
      waitingRoomUrl: store.state.namespaceSettings.waitingRoomUrl,
      waitingRoomAlias: store.state.namespaceSettings.waitingRoomAlias,
      waitingRoomWelcomeTextDE: store.state.namespaceSettings.waitingRoomWelcomeTextDE, 
      waitingRoomWelcomeTextEN: store.state.namespaceSettings.waitingRoomWelcomeTextEN, 
      waitingRoomWelcomeTextES: store.state.namespaceSettings.waitingRoomWelcomeTextES, 
      sectionAlias: store.state.namespaceSettings.sectionAlias,
      departmentAlias: store.state.namespaceSettings.departmentAlias,
      teamAlias: store.state.namespaceSettings.teamAlias,
      originalSectionAlias: store.state.namespaceSettings.sectionAlias,
      originalDepartmentAlias: store.state.namespaceSettings.departmentAlias,
      originalTeamAlias: store.state.namespaceSettings.teamAlias,
      originalWaitingRoomUrl: store.state.namespaceSettings.waitingRoomUrl,
      originalWaitingRoomAlias: store.state.namespaceSettings.waitingRoomAlias,
      waitingRoomFormValid: true,
      featureInfoBoard: store.state.namespaceSettings.featureInfoBoard,
      featureFileTranfer: store.state.namespaceSettings.featureFileTranfer,
      companyEmailRegex: /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/,
      companyEmailRules: [
        (v) =>
          /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||
          this.$t("components.firstModal.emailMustValid"),
      ],
      twoFaLifetimeHours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      selectedTwoFaLifetimeHours:
        store.state.namespaceSettings.twoFaLifetimeHours,
      panel: [],
      featureOrganization: store.state.namespaceSettings.featureOrganization,
      showOrganigrammFor: store.state.namespaceSettings.showOrganigrammFor,
      dataComboboxShowOrganigrammFor: [
        {
          text: this.$t("components.adminSettingsModal.2faTypeAllUsers"),
          value: "all",
        },
        {
          text: this.$t("components.adminSettingsModal.2faTypeAdmins"),
          value: "admin",
        },
         {
          text: this.$t("components.adminSettingsModal.manager"),
          value: "manager",
        },
        // {
        //   text: this.$t("components.adminSettingsModal.sectionManager"),
        //   value: "sectionManager",
        // },
        // {
        //   text: this.$t("components.adminSettingsModal.departmentManager"),
        //   value: "departmentManager",
        // },
        // {
        //   text: this.$t("components.adminSettingsModal.teamManager"),
        //   value: "teamManager",
        // },
      ],
      showCountQualityStatistics:
        store.state.namespaceSettings.showCountQualityStatistics,
      locationSetting: store.state.namespaceSettings.locationPrivacity,
      userLoginDaily: store.state.namespaceSettings.loginDaily,
      userStatusDisplay: store.state.namespaceSettings.displayUserStatus,
      videoCallAllUser:
        store.state.namespaceSettings.displayCurrentVideocallForUser,
      displayAllUsers: store.state.namespaceSettings.displayAllUsers,
      videoCallManagement:
        store.state.namespaceSettings.displayCurrentVideocallForManagement,
      enableLocation: store.state.namespaceSettings.enableLocation,
      timezone: store.state.namespaceSettings.timezone || "Europe/Berlin",
      autoDeleteConferences:
        store.state.namespaceSettings.autoDeleteConferences || "0",
      activate2FAType:
        store.state.namespaceSettings.activate2FAType || "default",
      activateSMS: store.state.namespaceSettings.activateSMS,
      activatePIN: store.state.namespaceSettings.activatePIN,
      pinProtectionType:
        store.state.namespaceSettings.pinProtectionType || "simple",
      activatePassword: store.state.namespaceSettings.activatePassword,
      userSetTime: store.state.namespaceSettings.userSetTime,
      userSetTimeNumber: store.state.namespaceSettings.userSetTimeNumber,
      organisationFavorite: store.state.namespaceSettings.organisationFavorite,
      myFavoriteSections: store.state.namespaceSettings.favoriteSections,
      officeName:
        store.state.namespaceSettings.companyInfo.virtualOfficePrefix || "",
      fullView: !store.state.namespaceSettings.basicView,
      basicView: store.state.namespaceSettings.basicView,
      dynamicSidePanel: store.state.namespaceSettings.dynamicSidePanel,
      // dingDongTimeForRepeat:
      //   store.state.namespaceSettings.dingDongTimeForRepeat || "180000",
      openLocationModal: false,
      enableRemoteStorage: store.state.namespaceSettings.enableRemoteStorage,
      url: store.state.namespaceSettings.remoteStorageData.url,
      username: store.state.namespaceSettings.remoteStorageData.username,
      password: store.state.namespaceSettings.remoteStorageData.password,
      port: store.state.namespaceSettings.remoteStorageData.port,
      syncIntervale:
        store.state.namespaceSettings.remoteStorageData.syncIntervale,
      qualityLogging: store.state.namespaceSettings.qualityLogging,
      activateFtp: store.state.namespaceSettings.activateFtp,
      showFtpModal: false,
      pictureSize: store.state.namespaceSettings.pictureSize,
      timelineSelection: store.state.namespaceSettings.timelineSelection,
      useFeatureTimeline: store.state.namespaceSettings.useFeatureTimeline,
      basicVersion: true,
      proVersion: false,
      paySetting: store.state.namespaceSettings.paySetting,
      ftpItems: ["FTP", "SFTP"],
      selectFtp:
        store.state.namespaceSettings.remoteStorageData.remoteStorage || "FTP",
      openInfoCompact: false,
      showPasswordFTP: false,
      disableConnectFtpButton: false,
      showPayModal: false,
      payHours: store.state.namespaceSettings.payHours,
      payVat: 0,
      selectedVat: "",
      show2faInfoModal: false,
      showRatingStats: false,
      selectCurrency: store.state.namespaceSettings.currency || "EUR",
      currencies: ["EUR", "GBP", "CHF"],
      showRelayLabel: store.state.namespaceSettings.showRelayLabel,
      showShortRelayModal: false,
      showPayPalSettings: false,
      showModalProducts: false,
      facebookLink:
        store.state.namespaceSettings.waitingRoomSocialMedia.facebookLink,
      instagramLink:
        store.state.namespaceSettings.waitingRoomSocialMedia.instagramLink,
      twitterLink:
        store.state.namespaceSettings.waitingRoomSocialMedia.twitterLink,
      youtubeLink:
        store.state.namespaceSettings.waitingRoomSocialMedia.youtubeLink,
      linkedinLink:
        store.state.namespaceSettings.waitingRoomSocialMedia.linkedinLink,
      webLink: store.state.namespaceSettings.waitingRoomSocialMedia.webLink,
      waitingRoomGreeting:
        store.state.namespaceSettings.waitingRoomGreeting ||
        this.$t("components.waitingRoom.greetingText"),
      selectedTemplate:
        store.state.namespaceSettings.waitingRoomSocialMedia.templateBg,
      waitingRoomExpectedTime:
        store.state.namespaceSettings.waitingRoomExpectedTime,
      showResetBgWaitingRoom: false,
      deletionMessages: store.state.namespaceSettings.deletionMessages,
      deletionCalls: store.state.namespaceSettings.deletionCalls,
      deletionInvites: store.state.namespaceSettings.deletionInvites,
      beaconTitleDE: store.state.namespaceSettings.beaconTitle || "",
      beaconTitleEN: store.state.namespaceSettings.beaconTitleEN || "",
      beaconTitleES: store.state.namespaceSettings.beaconTitleES || "",
      noUserMessageDE: store.state.namespaceSettings.noUserMessage || "",
      noUserMessageEN: store.state.namespaceSettings.noUserMessageEN || "",
      noUserMessageES: store.state.namespaceSettings.noUserMessageES || "",
      beaconColor: store.state.namespaceSettings.beaconColor || "#a51916",
      beaconPositionSide: store.state.namespaceSettings.beaconPosition.position || 'right',
      beaconPositionBottom: store.state.namespaceSettings.beaconPosition.bottomPx || 0,
      beaconPositionRight: store.state.namespaceSettings.beaconPosition.rightPx || 0,
      beaconPositionLeft: store.state.namespaceSettings.beaconPosition.leftPx || 0,
      beaconUsersIcall:
        store.state.namespaceSettings.beaconUsersIcall || "green",
      beaconInactiveUsers:
        store.state.namespaceSettings.beaconInactiveUsers || "gray",
      beaconLanguage: store.state.namespaceSettings.beaconLanguage || "default",
      mode: "hexa",
      showDescriptionInBeacon:  store.state.namespaceSettings.showDescriptionInBeacon || false,
      descriptionShownInBeacon:
        store.state.namespaceSettings.descriptionShownInBeacon || null,
      showTeamsInBeacon:
        store.state.namespaceSettings.showTeamsInBeacon || false,
      teamsShownInBeacon:
        store.state.namespaceSettings.teamsShownInBeacon || null,
      beaconCode: store.state.namespaceSettings.beaconCode || "",
      systemMessagetext: "",
      systemMessageColor: "red",
      systemMessageRules: [
        (v) => !!v || "",
        (v) => !!v.trim() || "Message can not be blank",
      ],
      activateE2E: store.state.namespaceSettings.activateE2E || false,
      strictRule: store.state.namespaceSettings.strictRule || false,
      colorIconHead: "#ffffff",
      colorIconBody: "#ffffff",
      uploadType: null,
      templateSearch: "",
      userInviteEmailText:
        store.state.namespaceSettings.customInviteMessage ||
        this.$t("components.invitesText.defaultUserText"),
      accessLinkEmailText:
        store.state.namespaceSettings.customAccessLinkMessage ||
        this.$t("backend.firstSetup.defaultUserText"),
      savingInviteEmailText: false,
      savingAccessLinkEmailText: false,
      customTemplateWaitingRoomSelected:
        store.state.namespaceSettings.customTemplateWaitingRoomSelected,
      showModalRemoveICEServer: false,
      showNoStatus: store.state.namespaceSettings.showNoStatus,
      showCoffeeBreak: store.state.namespaceSettings.showCoffeeBreak,
      showWorkingTime: store.state.namespaceSettings.showWorkingTime,
      showConferencehall: store.state.namespaceSettings.showConferencehall,
      showGPT4BOT: store.state.namespaceSettings.showGPT4BOT,
      showSocialMedia: store.state.namespaceSettings.showSocialMedia,
      showDailyReport: store.state.namespaceSettings.showDailyReport,
      dailyReportValue: store.state.namespaceSettings.dailyReportValue,
      eventApiKey: store.state.namespaceSettings.eventApiKey || YOUTUBE_API_KEY_DEFAULT,
      eventClientId: store.state.namespaceSettings.eventClientId || YOUTUBE_CLIENT_ID_DEFAULT,
      openAiApiKey: store.state.namespaceSettings.openAiApiKey,
      humanPictureApiKey: store.state.namespaceSettings.humanPictureApiKey,
      confidentialAiEndpoint: store.state.namespaceSettings.confidentialAiEndpoint,
      showModalAddEditWorkingTimeTemplate: false,
      selectedWorkingTimeTemplate: '',
      templatesWorkingTime: store.state.namespaceSettings.weeklyTimecountingTemplates || [],
      editTemplatesWorkingTime: false,
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      messageForAllDateMenu: false,
      messageForAllDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10),
      messageForAllTimeMenu: false,
      messageForAllTime: null,
      messageForAllExpires: false,
      showHeadlineOrganization: store.state.namespaceSettings.showHeadlineOrganization,
      headlineOrganigram: store.state.namespaceSettings.headlineOrganigram,
      headlineOrganigramDe: '',
      headlineOrganigramEn: '',
      headlineOrganigramEs: '',
      editingHeadlineOrganigram: null,
      zoomValue: 0,
      cropSrc: '',
      uuid: uuidv4(),
      showModelWaitingRoomWelcome: false,
      showModelBeaconTitle: false,
      showModelnoUserMessage: false,
      searchGuest: '',
      guestEmailList: [],
      marriageName: '',
      marriageDomain: '',
      marriageProject: store.state.namespaceSettings.marriageProject,
    };
  },
  components: {
    CallsRatingModal,
    PayModal,
    HeaderModal,
    FooterModal,
    TwoFaAdminConfirmation,
    AddLocationModal,
    ConfirmFtpSettingModal,
    CompactViewInfoModal,
    TwoFAInfoModal,
    RelyShortHelpModal,
    TwoFactorAdminRecoveryEntry,
    PayPalSettingsModal,
    ProductsModal,
    DeleteBasket,
    RemoveIceServerModal,
    AddEditWorkingTimeTemplate,
    draggable
  },
  props: ["showAdminSettings", "toggleModalAdminSettings"],
  watch: {
    beaconColor: {
      immediate: true,
      handler: function (value) {
        if (value && value !== store.state.namespaceSettings.beaconColor) {
          this.setBeaconColor();
        }
      },
    },
    beaconPositionSide: {
      immediate: true,
      handler: function (value) {
        if (value && value !== store.state.namespaceSettings.beaconPosition.position) {
          this.setBeaconPosition();
        }
      },
    },
    beaconPositionBottom: {
      immediate: true,
      handler: function (value) {
        if (value && value !== store.state.namespaceSettings.beaconPosition.bottomPx) {
          this.setBeaconPosition();
        }
      },
    },
    beaconPositionRight: {
      immediate: true,
      handler: function (value) {
        if (value && value !== store.state.namespaceSettings.beaconPosition.rightPx) {
          this.setBeaconPosition();
        }
      },
    },
    beaconPositionLeft: {
      immediate: true,
      handler: function (value) {
        if (value && value !== store.state.namespaceSettings.beaconPosition.leftPx) {
          this.setBeaconPosition();
        }
      },
    },
    panel: {
      immediate: true,
      handler: function (value) {
        if (document.getElementById("accordionsSection")) {
          setTimeout(() => {
            document.getElementById("accordionsSection").scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }, 250);
        }
      },
    },
    showAdminSettings: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.loadData();
        } else {
          this.APIUser = "";
          this.APIPassword = "";
          this.APISignature = "";
        }
      },
    },
    isBasicView: {
      immediate: true,
      handler: function (compactView) {
        if (compactView) {
          this.disableSettingsInCompactView();
        }
      },
    },
    getCustomTemplateWaitingRoomSelected: {
      immediate: true,
      handler: function (value) {
        this.customTemplateWaitingRoomSelected = value;
      },
    },
    messageForAllExpires: {
      handler: function () {
        this.cleanExpiredData();
      },
    },
    searchGuest: {
      handler: function() {
        this.searchGuestMail();
      },
    }
  },
  methods: {
    saveExternalDomain(){
      const data = {
        name: this.marriageName,
        domain: this.marriageDomain,
      };
      // Check if either the name or the domain already exists in the array
      const domainExists = this.marriageProject.some(item => item.domain === data.domain || item.name === data.name);
      // If the domain does not exist, push the new data to the array
      if (!domainExists) {
        this.marriageProject.push(data);
        setNamespaceSetting("marriageProject", this.marriageProject);
      } else {
        console.log('Domain already exists');
        // Optionally, you can handle this case by showing an alert or other UI feedback
      }
      this.marriageName = '';
      this.marriageDomain = '';
    },
    removeExternalDomain(data){
      // Find the index of the item that matches both the domain and name
      const index = this.marriageProject.findIndex(item => item.domain === data.domain && item.name === data.name);
      // If found, remove the item from the array
      if (index !== -1) {
        this.marriageProject.splice(index, 1);
        setNamespaceSetting("marriageProject", this.marriageProject);
      } else {
        console.log('Item not found');
      }
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    onZoom(result) {
      const value = result / 100
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 10,
        height: Math.min(boundaries.height, boundaries.width) - 10,
      };
    },
    resizeImage(imagePath, newWidth, newHeight) {
      const originalImage = new Image();
      originalImage.src = imagePath;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      return new Promise((resolve, reject) => {
        originalImage.addEventListener('error', (err) => reject(err));
        originalImage.addEventListener('load', () => {
          const originalWidth = originalImage.naturalWidth;
          const originalHeight = originalImage.naturalHeight;
          const aspectRatio = originalWidth/originalHeight;
          if (newHeight === undefined) {
            newHeight = newWidth/aspectRatio;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(originalImage, 0, 0, newWidth, newHeight);
          const dataUrl = canvas.toDataURL("image/webp", 0.9);
          return resolve(dataUrl);
        });
      });
    },
    async removeGuestEmail(uuid) {
      if ((store.state.group[uuid] || {}).user && !this.hasPrivilege(uuid)) {
        await removeUser(uuid);
        if (this.searchGuest?.length) this.searchGuestMail();
        if (!this.guestEmailList?.length) this.searchGuest = "";
      }
    },
    searchGuestMail() {
      if (this.searchGuest?.length > 3) {
        this.guestEmailList = JSON.parse(JSON.stringify(
          Object.values(this.state.group)
            .filter(data => data.user?.uuid && !this.hasPrivilege(data.user.uuid) && data.user.email?.includes(this.searchGuest))
          ));
      } else {
        this.guestEmailList = [];
      }
    },
    toHoursAndMinutes(totalMinutes) {
      const minutes = totalMinutes % 60;
      const hours = Math.floor(totalMinutes / 60);
      return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}`;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    openCloseModalAddEditWorkingTimeTemplate(openClose, edit) {
      this.selectedWorkingTimeTemplate = '';
      this.editTemplatesWorkingTime = edit;
      this.showModalAddEditWorkingTimeTemplate = openClose
    },
    cleanExpiredData() {
      this.messageForAllDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
      this.messageForAllTime = null;
    },
    resetUserInviteEmailText() {
      this.userInviteEmailText = this.$t(
        "components.invitesText.defaultUserText"
      );
      this.saveUserInviteEmailText();
    },
    changeShowConferencehall() {
      setNamespaceSetting("showConferencehall", this.showConferencehall);
    },
    changeShowGPT4BOT() {
      setNamespaceSetting("showGPT4BOT", this.showGPT4BOT);
    },
    changeShowSocialMedia() {
      setNamespaceSetting("showSocialMedia", this.showSocialMedia);
    },
    changeShowDailyReport() {
      setNamespaceSetting("showDailyReport", this.showDailyReport);
    },
    saveUserInviteEmailText() {
      this.savingInviteEmailText = true;
      setNamespaceSetting("customInviteMessage", this.userInviteEmailText);
      // add toaster saved
      dispatchSuccessAlert(this.$t("generics.emailSaved"));
      setTimeout(() => {
        this.savingInviteEmailText = false;
      }, 1500);
    },
    resetAccessLinkEmailText() {
      this.accessLinkEmailText = this.$t("backend.firstSetup.defaultUserText");
      this.saveAccessLinkEmailText();
    },
    saveAccessLinkEmailText() {
      this.savingAccessLinkEmailText = true;
      setNamespaceSetting("customAccessLinkMessage", this.accessLinkEmailText);
      // add toaster saved
      dispatchSuccessAlert(this.$t("generics.emailSaved"));
      setTimeout(() => {
        this.savingAccessLinkEmailText = false;
      }, 1500);
    },
    openCloseModalRemoveICEServer() {
      this.showModalRemoveICEServer = !this.showModalRemoveICEServer;
    },
    selectServer(server) {
      this.STUN_TURN_URI = server.urls;
      if (server.credential && server.username) {
        this.TURN_username = server.username;
        this.TURN_password = server.credential;
      } else {
        this.TURN_username = "";
        this.TURN_password = "";
      }
    },
    addServer() {
      if (this.STUN_TURN_URI !== "") {
        const obj = {
          urls: this.STUN_TURN_URI,
          credential: this.TURN_password || undefined,
          username: this.TURN_username || undefined,
        };
        this.ICE_servers.push(obj);
        this.STUN_TURN_URI = "";
        this.TURN_username = "";
        this.TURN_password = "";
        return setIceServers(this.ICE_servers);
      }
    },
    removeServer() {
      const index = this.$refs.selectIceServers[0].options.selectedIndex;
      this.ICE_servers.splice(index, 1);
      return setIceServers(this.ICE_servers);
    },
    resetDefault() {
      return setIceServers([]);
    },
    userRelay() {
      setNamespaceSetting("forceRelay", this.forceRelay);
    },
    resetCustomTemplateWaitingRoomSelected() {
      this.customTemplateWaitingRoomSelected = undefined;
      setNamespaceSetting("customTemplateWaitingRoomSelected", undefined);
    },
    saveCustomTemplateWaitingRoomSelected(data) {
      this.customTemplateWaitingRoomSelected = data.item.title;
      const tmpData = {
        title: data.item.title,
        text: data.item.text,
      };
      setNamespaceSetting("customTemplateWaitingRoomSelected", tmpData);
    },
    openBeaconDomainWhitelist(){
      setBeaconDomainWhitelistEvent(true)
    },
    copyContentHead(){
      this.copyCode(this.$refs.codeViewer[0].innerText);
      this.colorIconHead = "green";
      setTimeout(() => {
        this.colorIconHead = "#ffffff";
      }, 500);
    },
    copyContentBody() {
      this.copyCode(this.$refs.codeViewerBody[0].innerText);
      this.colorIconBody = "green";
      setTimeout(() => {
        this.colorIconBody = "#ffffff";
      }, 500);
    },
    copyCode(text) {
      var selBox2 = document.createElement("textarea");
      selBox2.style.position = "fixed";
      selBox2.style.left = "0";
      selBox2.style.top = "0";
      selBox2.style.opacity = "0";
      selBox2.value = text;
      document.getElementById("containerCopy").appendChild(selBox2);
      selBox2.focus();
      selBox2.select();
      document.execCommand("copy");
      document.getElementById("containerCopy").removeChild(selBox2);
    },
    setEventsApiSettings() {
      setNamespaceSetting("eventApiKey", this.eventApiKey);
      setNamespaceSetting("eventClientId", this.eventClientId);
    },
    setGpt4bot(){
      setNamespaceSetting("openAiApiKey", this.openAiApiKey);
      setNamespaceSetting("humanPictureApiKey", this.humanPictureApiKey);
      setNamespaceSetting("confidentialAiEndpoint", this.confidentialAiEndpoint);
    },
    changeBeaconUsersInCall() {
      setNamespaceSetting("beaconUsersIcall", this.beaconUsersIcall);
    },
    changeBeaconInactiveUsers() {
      setNamespaceSetting("beaconInactiveUsers", this.beaconInactiveUsers);
    },
    changeBeaconLanguage() {
      setNamespaceSetting("beaconLanguage", this.beaconLanguage);
    },
    setE2E() {
      setNamespaceSetting("activateE2E", this.activateE2E);
    },
    setStrictE2E() {
      setNamespaceSetting("strictRule", this.strictRule);
    },
    sendSystemMessage() {
      const users = Object.keys(this.state.group);
      const expiryTs = this.messageForAllExpires && this.messageForAllDate && this.messageForAllTime
        ? new Date(
            this.messageForAllDate +
              "T" +
              this.messageForAllTime
                .split(":")
                .map((e) => e.padStart(2, "0"))
                .join(":") +
              ":00" +
              getTimezone(this.messageForAllDate, this.messageForAllTime)
          ).getTime()
        : undefined;
      users.forEach((userUUID) => {
        if (this.hasPrivilege(userUUID)) {
          const dataMsg = prepadeDataToSendMessageEffector(
            userUUID,
            this.systemMessagetext,
            { color: this.systemMessageColor, type: "system Message" },
            "systemMessage",
            false,
            expiryTs
          );
          newMessageEvent(dataMsg);
        }
      });
      this.systemMessagetext = "";
      this.systemMessageColor = "red";
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getHost() {
      return `${document.location.protocol}//${document.location.host}`;
    },
    copyContent(reference) {
      var selBox2 = document.createElement("textarea");
      selBox2.style.position = "fixed";
      selBox2.style.left = "0";
      selBox2.style.top = "0";
      selBox2.style.opacity = "0";
      selBox2.value = this.$refs[`${reference}`].textContent;
      document.body.appendChild(selBox2);
      selBox2.focus();
      selBox2.select();
      document.execCommand("copy");
      document.body.removeChild(selBox2);
    },
    setNoUserMsg() {
      if (
        this.noUserMessage !==
        this.$t("components.adminSettingsModal.beaconNoUsersDefault")
      ) {
        setNamespaceSetting("noUserMessage", this.noUserMessageDE);
        setNamespaceSetting("noUserMessageEN", this.noUserMessageEN);
        setNamespaceSetting("noUserMessageES", this.noUserMessageES);
      } else {
        setNamespaceSetting("noUserMessage", "");
        setNamespaceSetting("noUserMessageEN", "");
        setNamespaceSetting("noUserMessageES", "");
      }
    },
    setBeaconTitle() {
      setNamespaceSetting("beaconTitle", this.beaconTitleDE);
      setNamespaceSetting("beaconTitleEN", this.beaconTitleEN);
      setNamespaceSetting("beaconTitleES", this.beaconTitleES);
    },
    setBeaconColor() {
      setNamespaceSetting("beaconColor", this.beaconColor);
    },
    setBeaconPosition() {
      const obj = {
        'position': this.beaconPositionSide,
        'bottomPx': this.beaconPositionBottom,
        'rightPx': this.beaconPositionRight,
        'leftPx': this.beaconPositionLeft
      }
      setNamespaceSetting("beaconPosition", obj);
    },
    setDescriptionShownInBeacon() {
      setNamespaceSetting("descriptionShownInBeacon", this.descriptionShownInBeacon);
    },
    setTeamsShownInBeacon() {
      setNamespaceSetting("teamsShownInBeacon", this.teamsShownInBeacon);
    },
    setShowDescriptionInBeacon() {
      setNamespaceSetting("showDescriptionInBeacon", this.showDescriptionInBeacon);
    },
    setShowTeamsInBeacon() {
      setNamespaceSetting("showTeamsInBeacon", this.showTeamsInBeacon);
    },
    changeDeletionInvites() {
      setNamespaceSetting("deletionInvites", this.deletionInvites);
    },
    changeDeletionMissedCalls() {
      setNamespaceSetting("deletionCalls", this.deletionCalls);
    },
    changeDeletionMessages() {
      setNamespaceSetting("deletionMessages", this.deletionMessages);
    },
    resetSelectedBgTemplate() {
      this.selectedTemplate = "";
    },
    resetBgWaitingRoom() {
      const firstBg = this.getWaitingRoomBgs[0];
      const socialMedia = {
        facebookLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.facebookLink,
        instagramLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.instagramLink,
        twitterLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.twitterLink,
        youtubeLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.youtubeLink,
        linkedinLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.linkedinLink,
        webLink: this.state.namespaceSettings.waitingRoomSocialMedia.webLink,
        templateBg: {
          imagePath: firstBg.imagePath,
          imageName: firstBg.imageName,
          imageClass: firstBg.imageClass,
        },
      };
      this.selectedTemplate = {
        imagePath: firstBg.imagePath,
        imageName: firstBg.imageName,
        imageClass: firstBg.imageClass,
      };
      setNamespaceSetting("waitingRoomSocialMedia", socialMedia);
      setNamespaceSetting("waitingRoomBackground", undefined);
    },
    openInfoPaypalProducts() {
      const data = {
        show: true,
        header: this.$t("generics.info"),
        body: this.$t("components.paypal.infoProducts"),
      };
      return store.setinfoModal(data);
    },
    openInfoPaypalSettings() {
      const data = {
        show: true,
        header: this.$t("components.paypal.title"),
        body: `${this.$t("components.paypal.text1")}`,
      };
      return store.setinfoModal(data);
    },
    waitingRoomExpectedTimeChange() {
      if (this.waitingRoomExpectedTime < 0) {
        this.waitingRoomExpectedTime = undefined;
      }
    },
    getTranslatedOrganigramCombo() {
      let temporal = [];
      for (const key in this.showOrganigrammFor) {
        if (Object.hasOwnProperty.call(this.showOrganigrammFor, key)) {
          const element = this.showOrganigrammFor[key];
          switch (element) {
            case "all":
              temporal.push({
                text: this.$t("components.adminSettingsModal.2faTypeAllUsers"),
                value: "all",
              });
              break;
            case "admin":
              temporal.push({
                text: this.$t("components.adminSettingsModal.2faTypeAdmins"),
                value: "admin",
              });
              break;
            case "manager":
              temporal.push({
                text: this.$t("components.adminSettingsModal.manager"),
                value: "manager",
              });
              break;
            // case "sectionManager":
            //   temporal.push({
            //     text: this.$t("components.adminSettingsModal.sectionManager"),
            //     value: "sectionManager",
            //   });
            //   break;
            // case "departmentManager":
            //   temporal.push({
            //     text: this.$t(
            //       "components.adminSettingsModal.departmentManager"
            //     ),
            //     value: "departmentManager",
            //   });
            //   break;
            // case "teamManager":
            //   temporal.push({
            //     text: this.$t("components.adminSettingsModal.teamManager"),
            //     value: "teamManager",
            //   });
            //   break;
            default:
              break;
          }
        }
        this.showOrganigrammFor = temporal;
      }
    },
    updateTemplateBg() {
      const socialMedia = {
        facebookLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.facebookLink,
        instagramLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.instagramLink,
        twitterLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.twitterLink,
        youtubeLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.youtubeLink,
        linkedinLink:
          this.state.namespaceSettings.waitingRoomSocialMedia.linkedinLink,
        webLink: this.state.namespaceSettings.waitingRoomSocialMedia.webLink,
        templateBg: {
          imagePath: this.selectedTemplate.imagePath,
          imageName: this.selectedTemplate.imageName,
          imageClass: this.selectedTemplate.imageClass,
        },
      };
      setNamespaceSetting("waitingRoomSocialMedia", socialMedia);
      setNamespaceSetting("waitingRoomBackground", undefined);
    },
    savePdfWaitingRoom(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files || !files.length) return;
      var reader = new FileReader();
      // this.showPreview = true;
      reader.onload = (e) => {
        store.setWaitingRoomBackgroundPdf(e.target.result);
      };
      reader.readAsDataURL(files[0]);
      this.resetInputPdfWaitingRoom();
    },
    resetInputPdfWaitingRoom() {
      this.$refs.pdfWaitingRoomInput.value = "";
    },
    setCustomBackground() {
      this.$refs.backgroundWaitingRoomInput.click();
    },
    saveBackgroundWaitingRoom(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files || !files.length) return;
      var reader = new FileReader();
      // this.showPreview = true;
      reader.onload = (e) => {
        store.setWaitingRoomBackground(e.target.result);
      };
      reader.readAsDataURL(files[0]);
      this.resetInputBackgroundWaitingRoom();
      this.resetSelectedBgTemplate();
    },
    resetInputBackgroundWaitingRoom() {
      this.$refs.backgroundWaitingRoomInput.value = "";
    },
    async loadData() {
      let settings = await getPayPalSettings();
      if (!settings) settings = {};
      this.APIUser = settings.APIUser || "";
      this.APIPassword = settings.APIPassword || "";
      this.APISignature = settings.APISignature || "";
    },
    openClosePayPalSettings() {
      this.showPayPalSettings = !this.showPayPalSettings;
    },
    openCloseModalProducts() {
      this.showModalProducts = !this.showModalProducts;
    },
    openMailFooterModal() {
      setMailFooterEvent(true);
    },
    setCurrency(currency) {
      setNamespaceSetting("currency", currency);
    },
    opencloseStats() {
      this.showRatingStats = !this.showRatingStats;
    },
    openClose2faModal() {
      this.show2faInfoModal = !this.show2faInfoModal;
    },
    openCloseShortRelyModal() {
      this.showShortRelayModal = !this.showShortRelayModal;
    },
    openClosePayModal(cancelFunction = null) {
      this.showPayModal = !this.showPayModal;
      if (this.showPayModal === false && cancelFunction) {
        this.paySetting = false;
      }
    },
    opencloseInfoCompact() {
      this.openInfoCompact = !this.openInfoCompact;
    },
    closeModalFunction() {
      return this.showRelayText
        ? (this.showRelayText = false)
        : this.showAliasText
        ? (this.showAliasText = false)
        : this.closeModal();
    },
    changePaySetting() {
      if (this.paySetting) {
        this.openClosePayModal();
      } else {
        setNamespaceSetting("paySetting", false);
      }
    },
    changeUseFeatureTimeline() {
      setNamespaceSetting("useFeatureTimeline", this.useFeatureTimeline);
    },
    changeTimelineSelection() {
      setNamespaceSetting("timelineSelection", this.timelineSelection);
    },
    changeFeatureFiletranfer() {
      setNamespaceSetting("featureFileTranfer", this.featureFileTranfer);
    },
    changequalityLogging() {
      setNamespaceSetting("qualityLogging", this.qualityLogging);
    },
    setCustomLocation() {
      this.openLocationModal = !this.openLocationModal;
    },
    // changedingDongTimeForRepeat() {
    //   setNamespaceSetting("dingDongTimeForRepeat", this.dingDongTimeForRepeat);
    // },
    openCloseConfirmationFtp() {
      this.showFtpModal = !this.showFtpModal;
    },
    changeFtp() {
      this.activateFtp = false;
      setNamespaceSetting("activateFtp", false);
    },
    changeBasicView() {
      setNamespaceSetting("basicView", this.basicView);
    },
    changeDailyReportValue() {
      setNamespaceSetting("dailyReportValue", this.dailyReportValue);
    },
    disableSettingsInCompactView() {
      if (this.userLoginDaily) {
        this.userLoginDaily = false;
        this.changeShowUserLoginDaily();
      }
      if (this.videoCallManagement) {
        this.videoCallManagement = false;
        this.changeDisplayUserVideoCall("management");
      }
      if (this.featureInfoBoard) {
        this.featureInfoBoard = false;
        this.changeDisplayUserVideoCall("featureInfoBoard");
      }
      if (this.videoCallAllUser) {
        this.videoCallAllUser = false;
        this.changeDisplayUserVideoCall("videoCallAllUser");
      }
      if (this.showCountQualityStatistics) {
        this.showCountQualityStatistics = false;
        this.changeShowCountQualityStatistics();
      }
    },
    saveOfficeName() {
      store.state.namespaceSettings.companyInfo.virtualOfficePrefix =
        this.officeName;
      setNamespaceSetting(
        "companyInfo",
        store.state.namespaceSettings.companyInfo
      );
      this.panel = [];
    },
    changeOrganisationFavorite() {
      if (this.organisationFavorite === "favorites") {
        this.myFavoriteSections = [];
      } else {
        this.myFavoriteSections =
          store.state.namespaceSettings.favoriteSections;
      }
      setNamespaceSetting("organisationFavorite", this.organisationFavorite);
    },
    change2FASetting(setting) {
      switch (setting) {
        case "2fa":
          setNamespaceSetting("activate2FAType", this.activate2FAType);
          break;
        case "sms":
          setNamespaceSetting("activateSMS", this.activateSMS);
          break;
        case "pin":
          setNamespaceSetting("activatePIN", this.activatePIN);
          break;
        case "pinProtectionType":
          setNamespaceSetting("pinProtectionType", this.pinProtectionType);
          break;
        case "password":
          setNamespaceSetting("activatePassword", this.activatePassword);
          break;
        case "time":
          setNamespaceSetting("userSetTime", this.userSetTime);
          break;
        case "timeNumber":
          setNamespaceSetting("userSetTimeNumber", this.userSetTimeNumber);
          break;
      }
    },
    changeDisplayUserVideoCall(who) {
      if (who === "featureInfoBoard") {
        setNamespaceSetting("featureInfoBoard", this.featureInfoBoard);
      }
      if (who === "allUsers") {
        setNamespaceSetting("displayAllUsers", this.displayAllUsers);
      }
      if (who === "user") {
        setNamespaceSetting(
          "displayCurrentVideocallForUser",
          this.videoCallAllUser
        );
      }
      if (who === "management") {
        setNamespaceSetting(
          "displayCurrentVideocallForManagement",
          this.videoCallManagement
        );
      }
      if (who === "location") {
        setNamespaceSetting("enableLocation", this.enableLocation);
      }
    },
    changeShowUserLoginDaily() {
      setNamespaceSetting("loginDaily", this.userLoginDaily);
    },
    changeUserStatusDisplay() {
      setNamespaceSetting("displayUserStatus", this.userStatusDisplay);
    },
    changeShowNoStatus() {
      setNamespaceSetting("showNoStatus", this.showNoStatus);
    },
    changeShowCoffeeBreak() {
      setNamespaceSetting("showCoffeeBreak", this.showCoffeeBreak);
    },
    changeShowWorkingTime() {
      setNamespaceSetting("showWorkingTime", this.showWorkingTime);
    },
    changeAutoDeleteConferences() {
      setNamespaceSetting("autoDeleteConferences", this.autoDeleteConferences);
    },
    changeCompanyTimeZone() {
      if (this.timezone) setNamespaceSetting("timezone", this.timezone);
    },
    setWaitingRoomSocialMedia() {
      const socialMedia = {
        facebookLink: "",
        instagramLink: "",
        twitterLink: "",
        youtubeLink: "",
        linkedinLink: "",
        webLink: "",
        templateBg:
          this.state.namespaceSettings.waitingRoomSocialMedia.templateBg,
      };
      if (this.facebookLink && this.isValidURL(this.facebookLink)) {
        socialMedia.facebookLink = this.facebookLink;
      }
      if (this.instagramLink && this.isValidURL(this.instagramLink)) {
        socialMedia.instagramLink = this.instagramLink;
      }
      if (this.twitterLink && this.isValidURL(this.twitterLink)) {
        socialMedia.twitterLink = this.twitterLink;
      }
      if (this.youtubeLink && this.isValidURL(this.youtubeLink)) {
        socialMedia.youtubeLink = this.youtubeLink;
      }
      if (this.linkedinLink && this.isValidURL(this.linkedinLink)) {
        socialMedia.linkedinLink = this.linkedinLink;
      }
      if (this.webLink && this.isValidURL(this.webLink)) {
        socialMedia.webLink = this.webLink;
      }
      setNamespaceSetting("waitingRoomSocialMedia", socialMedia);
    },
    isValidURL(s) {
      return s.match(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
      );
    },
    saveWaitingRoomSettings() {
      this.setwaitingRoomUrl();
      this.setWaitingRoomAlias();
      this.setwaitingRoomWelcomeText();
      this.setWaitingRoomSocialMedia();
      this.setWaitingRoomExpectedTime();
      dispatchSuccessAlert(this.$t("components.adminSettingsModal.saved"));
    },
    cancelWaitingRoomSettings() {
      this.waitingRoomUrl = this.originalWaitingRoomUrl;
      this.waitingRoomAlias = this.originalWaitingRoomAlias;
      dispatchSuccessAlert(this.$t("components.adminSettingsModal.cancelled"));
    },
    setTwoFaLifetimeHours() {
      setNamespaceSetting(
        "twoFaLifetimeHours",
        this.selectedTwoFaLifetimeHours
      );
    },
    setCompanyEmail() {
      const companyInfo = store.state.namespaceSettings.companyInfo;
      companyInfo.email = this.companyEmail.trim().toLowerCase();
      setNamespaceSetting("companyInfo", companyInfo);
      this.panel = [];
    },
    setwaitingRoomUrl() {
      let url = this.waitingRoomUrl.trim();
      if (
        url &&
        url.indexOf("https://") === -1 &&
        url.indexOf("http://") === -1
      ) {
        url = "https://" + url;
      }
      setNamespaceSetting("waitingRoomUrl", url);
      this.panel = [];
    },
    save(time) {
      this.$refs.dialog.save(time);
    },
    updateFavoriteSections() {
      if (this.myFavoriteSections.length > 0) {
        setNamespaceSetting("favoriteSections", this.myFavoriteSections);
      }
    },
    blurShowOrganigrammFor() {
      // if we do not have selected anything from the list we deactivate the switch
      if (Object.keys(this.showOrganigrammFor).length == 0) {
        this.featureOrganization = false;
        this.changefeatureOrganization();
      }
    },
    updateShowOrganigrammFor() {
      let dataToSave = [];
      this.showOrganigrammFor.forEach((element) => {
        dataToSave.push(element.value);
      });
      this.state.namespaceSettings.showOrganigrammFor = dataToSave;
      setNamespaceSetting(
        "showOrganigrammFor",
        store.state.namespaceSettings.showOrganigrammFor
      );
    },
    changeShowHeadlineOrganization() {
      this.state.namespaceSettings.showHeadlineOrganization = this.showHeadlineOrganization;
      setNamespaceSetting(
        "showHeadlineOrganization",
        store.state.namespaceSettings.showHeadlineOrganization
      );
    },
    removeHeadlineOrganigram(id){
      const indexToRemove = this.headlineOrganigram.findIndex(e=>e.id === id)
      if(indexToRemove !== -1){
        this.headlineOrganigram.splice(indexToRemove, 1);
        this.state.namespaceSettings.headlineOrganigram =
          this.headlineOrganigram;
        setNamespaceSetting(
          "headlineOrganigram",
          store.state.namespaceSettings.headlineOrganigram
        );
         dispatchSuccessAlert(
          `${this.$t("components.adminSettingsModal.sectionRemoved")}`
        );
      }
    },
    headlineOrderChanged() {
      this.state.namespaceSettings.headlineOrganigram = this.headlineOrganigram;
      setNamespaceSetting(
        "headlineOrganigram",
        store.state.namespaceSettings.headlineOrganigram
      );
    },
    editHeadlineOrganigram(id) {
      // console.log(this.headlineOrganigram);
      // console.log(id);
      this.editingHeadlineOrganigram = id;
      const indexToEdit= this.headlineOrganigram.findIndex(e=>e.id === id)
      // console.log('indexToEdit', indexToEdit)
      if(indexToEdit !== -1){
        this.headlineOrganigramDe =this.headlineOrganigram[indexToEdit].de
        this.headlineOrganigramEn =this.headlineOrganigram[indexToEdit].en
        this.headlineOrganigramEs =this.headlineOrganigram[indexToEdit].es
      }
    },
    cleanHeadlineOrganigram() {
      this.headlineOrganigramDe = '';
      this.headlineOrganigramEn = '';
      this.headlineOrganigramEs = '';
      this.editingHeadlineOrganigram = null;
    },
    addHeadlineOrganigram() {
       const generateUniqueId = ()=>{
          let potentialId;
          let idExists;

          do {
              potentialId = uuidv4();
              // Check if the generated potentialId exists in the data array
              idExists = this.headlineOrganigram.some(item => item.id === potentialId);
          } while (idExists); // If the idExists, loop again to generate a new ID

          return potentialId;
      }
      if(this.headlineOrganigramDe.length ||  this.headlineOrganigramEn.length ||  this.headlineOrganigramEs.length){

        const data = {
          id: generateUniqueId(),
          de:this.headlineOrganigramDe,
          en:this.headlineOrganigramEn,
          es:this.headlineOrganigramEs
        }
        if(!this.editingHeadlineOrganigram?.toString().length){
          const positionBeforeEnd = this.headlineOrganigram.length - 1;
          this.headlineOrganigram.splice(positionBeforeEnd, 0, data);
        }else{
          const indexToEdit= this.headlineOrganigram.findIndex(e=>e.id === this.editingHeadlineOrganigram)
          if(indexToEdit !== -1){
            const data = {
              id: this.headlineOrganigram[indexToEdit].id,
              de:this.headlineOrganigramDe,
              en:this.headlineOrganigramEn,
              es:this.headlineOrganigramEs
            }
            this.headlineOrganigram[indexToEdit] = data;
          }
        }
        this.state.namespaceSettings.headlineOrganigram = this.headlineOrganigram;
        setNamespaceSetting(
          "headlineOrganigram",
          store.state.namespaceSettings.headlineOrganigram
        );
      }
      this.cleanHeadlineOrganigram();
    },
    changefeatureOrganization() {
      this.state.namespaceSettings.featureOrganization =
        this.featureOrganization;
      setNamespaceSetting(
        "featureOrganization",
        store.state.namespaceSettings.featureOrganization
      );
      if (!store.state.namespaceSettings.featureOrganization) {
        this.videoCallManagement = false;
        setNamespaceSetting("displayCurrentVideocallForManagement", false);
      }
      // if we activate the switch we mark an option from the list
      if (this.featureOrganization) {
        this.showOrganigrammFor = [
          {
            text: this.$t("components.adminSettingsModal.2faTypeAllUsers"),
            value: "all",
          },
        ];
        this.state.namespaceSettings.showOrganigrammFor = [
          this.showOrganigrammFor[0].value,
        ];
      } else {
        // if we deactivate it we clean the list
        this.showOrganigrammFor = [];
        this.state.namespaceSettings.showOrganigrammFor =
          this.showOrganigrammFor;
      }
      // update the list in the store
      setNamespaceSetting(
        "showOrganigrammFor",
        store.state.namespaceSettings.showOrganigrammFor
      );
    },
    changeShowCountQualityStatistics() {
      this.state.namespaceSettings.showCountQualityStatistics =
        this.showCountQualityStatistics;
      setNamespaceSetting(
        "showCountQualityStatistics",
        store.state.namespaceSettings.showCountQualityStatistics
      );
    },
    setWaitingRoomAlias() {
      setNamespaceSetting(
        "waitingRoomAlias",
        (this.waitingRoomAlias || "").trim()
      );
    },
    setwaitingRoomWelcomeText() {
      setNamespaceSetting(
        "waitingRoomWelcomeTextDE",
        (this.waitingRoomWelcomeTextDE || "")
      );
      setNamespaceSetting(
        "waitingRoomWelcomeTextEN",
        (this.waitingRoomWelcomeTextEN || "")
      );
      setNamespaceSetting(
        "waitingRoomWelcomeTextES",
        (this.waitingRoomWelcomeTextES || "")
      );
    },
    setWaitingRoomExpectedTime() {
      if (this.waitingRoomExpectedTime !== undefined) {
        setNamespaceSetting(
          "waitingRoomExpectedTime",
          this.waitingRoomExpectedTime
        );
      }
    },
    async setAlias() {
      const result = await setNamespaceAlias("set", this.alias);
      if (result && !this.alias) {
        this.aliasSuccess = this.$t(
          "components.adminSettingsModal.aliasRemoved"
        );
      } else if (result && this.alias) {
        this.aliasSuccess = this.$t("components.adminSettingsModal.aliasSet");
      } else {
        this.aliasSuccess = "";
      }
      this.aliasTakenError = !result;
    },
    removeCompanyLogo() {
      // add logic to remove company logo
      setNamespaceSetting("companyLogo", "");
    },
    uploadCompanyLogo() {
      this.$refs.companyLogoInput[0].click();
    },
    uploadBeaconLogo() {
      this.$refs.beaconLogoInput[0].click();
    },
    showLogoPreviewModal(e, type) {
      this.uploadType = type;
      var files = e.target.files || e.dataTransfer.files;
      if (!files || !files.length) return;
      var reader = new FileReader();
      this.showPreview = true;
      reader.onload = (e) => {
        this.cropSrc = e.target.result
        this.showCroppieLogo = true;
      };
      reader.readAsDataURL(files[0]);
      // if (type == "companyLogo") {
      //   this.resetInputAvatar();
      // } else {
      //   this.resetBeaconInputLogo();
      // }
    },
    async crop() {
      const canvas = this.$refs.cropper.getResult().canvas;
      if (canvas) {
        const base64Image = canvas.toDataURL("image/png");
        const resizedImage = await this.resizeImage(base64Image, 256);
        if (this.uploadType == "companyLogo") {
          store.setCompanyLogo(resizedImage);
          this.resetInputAvatar();
        } else {
          store.setBeaconLogo(resizedImage);
          this.resetBeaconInputLogo();
        }
        this.showCroppieLogo = false;
      }
    },
    cancelCrop() {
      this.showCroppieLogo = false;
      if (this.uploadType == "companyLogo") {
        this.resetInputAvatar();
      } else {
        this.resetBeaconInputLogo();
      }
    },
    resetInputAvatar() {
      if (this.$refs.companyLogoInput) {
        this.$refs.companyLogoInput.value = "";
      }
      this.uploadType = null;
    },
    resetBeaconInputLogo() {
      if (this.$refs.beaconLogoInput) {
        this.$refs.beaconLogoInput.value = "";
      }
      this.uploadType = null;
    },
    rotate(rotationAngle) {
      this.$refs.cropper.rotate(rotationAngle);
    },
    closeModal() {
      setNamespaceSetting("companyHomePage", this.companyHomePage);
      this.checkIfWaitingRommIsEmpty();
      this.toggleModalAdminSettings();
    },
    checkIfWaitingRommIsEmpty() {
      if (
        store.state.namespaceSettings.waitingRoomUrl === "" &&
        this.companyHomePage !== ""
      ) {
        this.waitingRoomUrl = this.companyHomePage;
        this.setwaitingRoomUrl();
      }
    },
  },
  computed: {
    noUserMessageActualLanguageModel: {
      get() {
        switch (this.getLanguage) {
          case 'DE':
            return this.noUserMessageDE;
          case 'EN':
            return this.noUserMessageEN;
          case 'ES':
            return this.noUserMessageES;
          default:
            return ''; // or any default value
        }
      },
      set(newValue) {
          switch (this.getLanguage) {
          case 'DE':
            this.noUserMessageDE = newValue;
            break;
          case 'EN':
            this.noUserMessageEN = newValue;
            break;
          case 'ES':
            this.noUserMessageES = newValue;
            break;
          // Handle other cases if necessary
        }
      }
    },
    noUserMessageOtherLanguageModel: {
      get() {
        switch (this.getLanguage) {
          case 'DE':
          return [{lang: 'EN',value: this.noUserMessageEN}, {lang: 'ES',value: this.noUserMessageES}];
          case 'EN':
          return [{lang: 'DE',value: this.noUserMessageDE}, {lang: 'ES',value: this.noUserMessageES}];
            case 'ES':
            return [{lang: 'DE',value: this.noUserMessageDE}, {lang: 'EN',value: this.noUserMessageEN}];
          default:
            break;
        }
      },
      set(newValue) {
          if (newValue && newValue.lang && newValue.value !== undefined) {
            switch (newValue.lang) {
              case 'DE':
                this.noUserMessageDE = newValue.value;
                break;
              case 'EN':
                this.noUserMessageEN = newValue.value;
                break;
              case 'ES':
                this.noUserMessageES = newValue.value;
                break;
              // Handle other cases if necessary
            }
        }
      }
    },
    beaconTitleActualLanguageModel: {
      get() {
        switch (this.getLanguage) {
          case 'DE':
            return this.beaconTitleDE;
          case 'EN':
            return this.beaconTitleEN;
          case 'ES':
            return this.beaconTitleES;
          default:
            return ''; // or any default value
        }
      },
      set(newValue) {
          switch (this.getLanguage) {
          case 'DE':
            this.beaconTitleDE = newValue;
            break;
          case 'EN':
            this.beaconTitleEN = newValue;
            break;
          case 'ES':
            this.beaconTitleES = newValue;
            break;
          // Handle other cases if necessary
        }
      }
    },
    beaconTitleOtherLanguageModel: {
      get() {
        switch (this.getLanguage) {
          case 'DE':
          return [{lang: 'EN',value: this.beaconTitleEN}, {lang: 'ES',value: this.beaconTitleES}];
          case 'EN':
          return [{lang: 'DE',value: this.beaconTitleDE}, {lang: 'ES',value: this.beaconTitleES}];
            case 'ES':
            return [{lang: 'DE',value: this.beaconTitleDE}, {lang: 'EN',value: this.beaconTitleEN}];
          default:
            break;
        }
      },
      set(newValue) {
          if (newValue && newValue.lang && newValue.value !== undefined) {
            switch (newValue.lang) {
              case 'DE':
                this.beaconTitleDE = newValue.value;
                break;
              case 'EN':
                this.beaconTitleEN = newValue.value;
                break;
              case 'ES':
                this.beaconTitleES = newValue.value;
                break;
              // Handle other cases if necessary
            }
        }
      }
    },
    waitingRoomWelcomeActualLanguageModel: {
      get() {
        switch (this.getLanguage) {
          case 'DE':
            return this.waitingRoomWelcomeTextDE;
          case 'EN':
            return this.waitingRoomWelcomeTextEN;
          case 'ES':
            return this.waitingRoomWelcomeTextES;
          default:
            return ''; // or any default value
        }
      },
      set(newValue) {
        switch (this.getLanguage) {
        case 'DE':
          this.waitingRoomWelcomeTextDE = newValue;
          break;
        case 'EN':
          this.waitingRoomWelcomeTextEN = newValue;
          break;
        case 'ES':
          this.waitingRoomWelcomeTextES = newValue;
          break;
        // Handle other cases if necessary
      }
      }
    },
    waitingRoomWelcomeOtherLanguageModel: {
      get() {
        switch (this.getLanguage) {
          case 'DE':
          return [{lang: 'EN',value: this.waitingRoomWelcomeTextEN}, {lang: 'ES',value: this.waitingRoomWelcomeTextES}];
          case 'EN':
          return [{lang: 'DE',value: this.waitingRoomWelcomeTextDE}, {lang: 'ES',value: this.waitingRoomWelcomeTextES}];
            case 'ES':
            return [{lang: 'DE',value: this.waitingRoomWelcomeTextDE}, {lang: 'EN',value: this.waitingRoomWelcomeTextEN}];
          default:
            break;
        }
      },
      set(newValue) {
          if (newValue && newValue.lang && newValue.value !== undefined) {
            switch (newValue.lang) {
              case 'DE':
                this.waitingRoomWelcomeTextDE = newValue.value;
                break;
              case 'EN':
                this.waitingRoomWelcomeTextEN = newValue.value;
                break;
              case 'ES':
                this.waitingRoomWelcomeTextES = newValue.value;
                break;
              // Handle other cases if necessary
            }
        }
      }
    },
    getLanguage() {
      return this.state.user.language.toUpperCase();
    },
    getSelectedWorkingTimeData(){
      const sortData = JSON.parse(JSON.stringify(this.selectedWorkingTimeTemplate.data));
      sortData.push(sortData.shift());
      return sortData
    },
    getTemplatesWorkingTime(){
      return store.state.namespaceSettings.weeklyTimecountingTemplates
    },
    minMessageForAllTime() {
      const today = new Date();
      const someDate = new Date(this.messageForAllDate);
      if (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      ) {
        return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5);
      }
      return '00:00';
    },
    disabledSystemMessageBtn() {
      if (!this.systemMessagetext || this.systemMessagetext.length == 0) return true;
      if (this.messageForAllExpires && (!this.messageForAllDate || !this.messageForAllTime)) return true;
    },
    isPkg() {
      return webLicensedBaseFeatures.isPkg;
    },
    getCustomTemplateWaitingRoomSelected() {
      return this.state.namespaceSettings.customTemplateWaitingRoomSelected;
    },
    getCustomInviteTexts() {
      const customTexts = this.state.namespaceSettings.inviteGuestCustomTexts;
      return customTexts;
    },
    nameSpaceDescriptions() { // not in use, left for future implementation
      return [];
    },
    nameSpaceTeams() {
      const organisation = cloneDeep(this.state.namespaceSettings.organisation);
      const namespaceTeams = organisation.filter((e) => e.team) || [];
      return namespaceTeams;
    },
    getTimeZones() {
      const sortByZone = (a, b) => {
        const [ahh, amm] = a.name.split("(UTC")[1].split(")")[0].split(":");
        const [bhh, bmm] = b.name.split("(UTC")[1].split(")")[0].split(":");
        return +ahh * 60 + amm - (+bhh * 60 + bmm);
      };
      const timezones = moment.tz
        .names()
        .filter(
          (e) =>
            // List of regions: https://www.php.net/manual/en/timezones.php
            ["Africa", "America", "Antarctica", "Arctic", "Asia", "Atlantic", "Australia", "Europe", "Indian", "Pacific"].includes(e.split("/")[0]) &&
            // Exclude deprecated timezones: https://www.php.net/manual/en/timezones.others.php
            ![
              "Africa/Asmera",
              "Africa/Timbuktu",
              "America/Argentina/ComodRivadavia",
              "America/Atka",
              "America/Buenos_Aires",
              "America/Catamarca",
              "America/Coral_Harbour",
              "America/Cordoba",
              "America/Ensenada",
              "America/Fort_Wayne",
              "America/Godthab",
              "America/Indianapolis",
              "America/Jujuy",
              "America/Knox_IN",
              "America/Louisville",
              "America/Mendoza",
              "America/Montreal",
              "America/Porto_Acre",
              "America/Rosario",
              "America/Santa_Isabel",
              "America/Shiprock",
              "America/Virgin",
              "Antarctica/South_Pole",
              "Asia/Ashkhabad",
              "Asia/Calcutta",
              "Asia/Chongqing",
              "Asia/Chungking",
              "Asia/Dacca",
              "Asia/Harbin",
              "Asia/Istanbul",
              "Asia/Kashgar",
              "Asia/Katmandu",
              "Asia/Macao",
              "Asia/Rangoon",
              "Asia/Saigon",
              "Asia/Tel_Aviv",
              "Asia/Thimbu",
              "Asia/Ujung_Pandang",
              "Asia/Ulan_Bator",
              "Atlantic/Faeroe",
              "Atlantic/Jan_Mayen",
              "Australia/ACT",
              "Australia/Canberra",
              "Australia/Currie",
              "Australia/LHI",
              "Australia/North",
              "Australia/NSW",
              "Australia/Queensland",
              "Australia/South",
              "Australia/Tasmania",
              "Australia/Victoria",
              "Australia/West",
              "Australia/Yancowinna",
              "Brazil/Acre",
              "Brazil/DeNoronha",
              "Brazil/East",
              "Brazil/West",
              "Canada/Atlantic",
              "Canada/Central",
              "Canada/Eastern",
              "Canada/Mountain",
              "Canada/Newfoundland",
              "Canada/Pacific",
              "Canada/Saskatchewan",
              "Canada/Yukon",
              "CET",
              "Chile/Continental",
              "Chile/EasterIsland",
              "CST6CDT",
              "Cuba",
              "EET",
              "Egypt",
              "Eire",
              "EST",
              "EST5EDT",
              "Etc/GMT",
              "Etc/GMT+0",
              "Etc/GMT+1",
              "Etc/GMT+10",
              "Etc/GMT+11",
              "Etc/GMT+12",
              "Etc/GMT+2",
              "Etc/GMT+3",
              "Etc/GMT+4",
              "Etc/GMT+5",
              "Etc/GMT+6",
              "Etc/GMT+7",
              "Etc/GMT+8",
              "Etc/GMT+9",
              "Etc/GMT-0",
              "Etc/GMT-1",
              "Etc/GMT-10",
              "Etc/GMT-11",
              "Etc/GMT-12",
              "Etc/GMT-13",
              "Etc/GMT-14",
              "Etc/GMT-2",
              "Etc/GMT-3",
              "Etc/GMT-4",
              "Etc/GMT-5",
              "Etc/GMT-6",
              "Etc/GMT-7",
              "Etc/GMT-8",
              "Etc/GMT-9",
              "Etc/GMT0",
              "Etc/Greenwich",
              "Etc/UCT",
              "Etc/Universal",
              "Etc/UTC",
              "Etc/Zulu",
              "Europe/Belfast",
              "Europe/Nicosia",
              "Europe/Tiraspol",
              "Factory",
              "GB",
              "GB-Eire",
              "GMT",
              "GMT+0",
              "GMT-0",
              "GMT0",
              "Greenwich",
              "Hongkong",
              "HST",
              "Iceland",
              "Iran",
              "Israel",
              "Jamaica",
              "Japan",
              "Kwajalein",
              "Libya",
              "MET",
              "Mexico/BajaNorte",
              "Mexico/BajaSur",
              "Mexico/General",
              "MST",
              "MST7MDT",
              "Navajo",
              "NZ",
              "NZ-CHAT",
              "Pacific/Enderbury",
              "Pacific/Johnston",
              "Pacific/Ponape",
              "Pacific/Samoa",
              "Pacific/Truk",
              "Pacific/Yap",
              "Poland",
              "Portugal",
              "PRC",
              "PST8PDT",
              "ROC",
              "ROK",
              "Singapore",
              "Turkey",
              "UCT",
              "Universal",
              "US/Alaska",
              "US/Aleutian",
              "US/Arizona",
              "US/Central",
              "US/East-Indiana",
              "US/Eastern",
              "US/Hawaii",
              "US/Indiana-Starke",
              "US/Michigan",
              "US/Mountain",
              "US/Pacific",
              "US/Samoa",
              "UTC",
              "W-SU",
              "WET",
              "Zulu"
            ].includes(e)
        );
      return timezones
        .map((element) => {
          const [region, ...rest] = element.split('/');
          const city = rest.join('/');
          const regionKey = 'timezones.' + region;
          const cityKey = 'timezones.' + city;
          let regionTrans = this.$t(regionKey) || regionKey;
          if (regionTrans === regionKey) regionTrans = region;
          let cityTrans = this.$t(cityKey) || cityKey;
          if (cityTrans === cityKey) cityTrans = city;
          return {
            name:
              regionTrans + " / " + cityTrans +
              " (UTC " +
              moment("2013-11-18").tz(element).format("Z") +
              ")",
            tz: element,
          };
        })
        .sort(sortByZone);
    },
    getExpansionPanels() {
      return [
        {
          index: 0,
          name: this.$t("components.adminSettingsModal.baseSettings"),
        },
        {
          index: 1,
          name: this.$t("components.2faModal.twoFactorAuthenticationTitle"),
        },
        { index: 2, name: this.$t("generics.organisation") },
        {
          index: 3,
          name: this.$t("components.adminSettingsModal.waitingroomTitle"),
        },
        { index: 4, name: "vOffice Pay" },
        // {
        //   index: 5,
        //   name: this.$t("components.adminSettingsModal.timeframeDeletion"),
        // },
        {
          index: 6,
          name: this.$t("components.adminSettingsModal.beacon"),
        },
        {
          index: 7,
          name: this.$t("components.adminSettingsModal.messageToAll"),
        },
        // {
        //   index: 8,
        //   name: this.$t("components.adminSettingsModal.e2eTitle"),
        // },
        // {
        //   index: 9,
        //   name: this.$t("components.adminSettingsModal.ICE_servers"),
        // },
        {
          index: 10,
          name: this.$t("components.adminSettingsModal.editEmailTexts"),
        },
        {
          index: 11,
          name: this.$t("components.adminSettingsModal.events"),
        },
        {
          index: 12,
          name: this.$t("components.adminSettingsModal.dailyReport"),
        },
        {
          index: 13,
          name: this.$t("components.adminSettingsModal.privacy"),
        },
        {
          index: 14,
          name: this.$t("components.workingTime.workingTime"),
        },
        {
          index: 15,
          name: this.$t("components.adminSettingsModal.iaTitle"),
        },
        {
          index: 16,
          name: this.$t("components.adminSettingsModal.searchGuest"),
        },
        {
          index: 17,
          name: this.$t("components.adminSettingsModal.socialMedia"),
        },
        {
          index: 18,
          name: this.$t("components.adminSettingsModal.marriageProject"),
        },
      ];
    },
    actualWaitingRoomBg() {
      if (this.isPdfBg) {
        return store.state.namespaceSettings.waitingRoomBackground;
      }
      if (store.state.namespaceSettings.waitingRoomBackground) {
        return store.state.namespaceSettings.waitingRoomBackground;
      } else {
        return store.state.namespaceSettings.waitingRoomSocialMedia.templateBg
          .imagePath;
      }
    },
    hasCustomBg() {
      return store.state.namespaceSettings.waitingRoomBackground;
    },
    isPdfBg() {
      if (
        store.state.namespaceSettings.waitingRoomBackground &&
        store.state.namespaceSettings.waitingRoomBackground.indexOf(".pdf") !==
          -1
      ) {
        return true;
      } else if (
        store.state.namespaceSettings.waitingRoomBackground &&
        store.state.namespaceSettings.waitingRoomBackground.indexOf(".pdf") ==
          -1
      ) {
        return false;
      }
      return false;
    },
    getWaitingRoomBgs() {
      const tempArray = [
        "img/templates/wr_back_01.png",
        "img/templates/wr_back_02.png",
        "img/templates/wr_back_03.png",
        "img/templates/wr_back_04.png",
        "img/templates/wr_back_05.png",
        "img/templates/wr_back_06.png",
        "img/templates/wr_back_07.png",
        "img/templates/wr_back_08.png",
        "img/templates/wr_back_09.png",
        "img/templates/wr_back_10.png",
      ];
      let finalResult = [];
      for (let i = 0; i < tempArray.length; i++) {
        const imagePath = tempArray[i];
        const imageName = imagePath.split("/")[2].split(".")[0];
        const tempObject = {
          imagePath,
          imageName,
          imageClass: "imageClass" + (i + 1),
        };
        finalResult.push(tempObject);
      }
      return finalResult;
    },
    paypalSettingsFilled() {
      return this.APIUser != "" && this.APIPassword != "";
    },
    isDevelopment() {
      return webLicensedBaseFeatures.isDev;
    },
    isPayAvailable() {
      // See also websrc/components/content/calls/remoteButtonsBar.vue isPayAvailable
      return webLicensedBaseFeatures.isVisitorPayAvailable;
    },
    isBasicView() {
      return basicView();
    },
    getIceServers() {
      this.ICE_servers = getIceServers();
      return this.ICE_servers;
    },
    dataCombobox: function (data) {
      const result = [];
      const sections =
        this.state.namespaceSettings.processedOrganisation.sections || {};
      for (const key in sections) {
        result.push(key);
      }
      return result;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    showModal() {
      return store.state.showModalMeetingTime !== undefined;
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.display;
      return lg
        ? [12, 12]
        : sm
        ? [12, 12]
        : md
        ? [12, 12]
        : xl
        ? [12, 12]
        : [12, 12];
    },
    colsLogo() {
      const { lg, sm, md, xl } = this.$vuetify.display;
      return lg ? [6, 6] : sm ? [6, 6] : md ? [6, 6] : xl ? [6, 6] : [12, 12];
    },
  },
  mounted() {
    this.getTranslatedOrganigramCombo();
    if (this.setBlurEffect) {
      setNamespaceSetting("showBlurEffectOption", false);
    }
    this.checkIfWaitingRommIsEmpty();
    if (this.hasCustomBg) {
      this.selectedTemplate = "";
    }
  },
  beforeUnmount() {
    if (this.uploadType) {
      if (this.uploadType == "companyLogo") {
        this.resetInputAvatar();
      } else {
        this.resetBeaconInputLogo();
      }
    }
  },
};
</script>
<style scoped lang="scss">
.v-switch .v-selection-control{ 
  min-height: unset !important;
  height: auto !important;
}
.twitter-cropper {
  height: 256px;
  width: 256px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
.headlineOrganigramList{
  max-height: 300px;
  overflow: auto;
  min-height: 180px;
}
.mw25{
  min-width: 25%;
}
.messageForAllExpires {
  width: 350px;
}
.w100 {
  width: 100%;
}
.selectIceServers {
  outline: none;
  border: 1px solid #9e9e9e;
  padding: 5px;
  min-width: 250px;
  width: 100%;
}

.videoButtonTT {
  max-width: 250px;
  background: rgb(97, 97, 97);
  opacity: 1 !important;
}
.iconCopy {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.containerCode {
  position: relative;
  max-width: 100%;
  .iconCopy {
    position: absolute;
    top: 19px;
    right: 13px;
    cursor: pointer;
  }
  .codeViewer {
    text-align: left;
    display: block;
    padding: 15px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    background-color: #333;
    font-family: "Multi", sans-serif;
    color: #a87007;
    font-weight: bold;
    margin-top: 10px;
    overflow-x: auto;
  }
}
.containerResetBgWaitingroom {
  position: relative;
}
.btnResetBgWaitingroom {
  position: absolute;
  top: 5px;
  right: 5px;
}
.btnvOfficePay {
  color: #ffffff !important;
  width: 65%;
}
.containerRecovery {
  margin-top: 100px;
}
.absoluteDropdown {
  position: absolute;
  bottom: 0;
}
.cursorPointer {
  cursor: pointer;
}
.inlineflex {
  display: inline-flex !important;
}
.height494 {
  height: 494px;
  max-height: 494px;
}
.heightAuto {
  height: auto;
}
.rowSettingsMobile {
  max-height: calc(100% - 133px);
  overflow: auto;
}
@media (max-width: 800px) {
  .rowSettings {
    max-height: 350px !important;
  }
}
.rowSettings {
  max-height: 645px;
  overflow: auto;
}
.pointer {
  cursor: pointer;
}
.w-100 {
  width: 100%;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.pl-0 {
  padding-left: 0px;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
.uploadAvatarButtons {
  position: absolute;
  border-radius: 10px;
  text-align: center;
  top: 20px;

  &:hover {
    display: block;
  }
}

.uploadBeaconButton {
  position: absolute;
  border-radius: 10px;
  text-align: center;
  bottom: 0px;

  &:hover {
    display: block;
  }
}
.uploadAvatarButtons:hover {
  display: block;
}
.removeAvatarButtons {
  position: absolute;
  border-radius: 10px;
  text-align: center;
  bottom: 10px;

  &:hover {
    display: block;
  }
}
.removeAvatarButtons:hover {
  display: block;
}
.borderCircle {
  border: 1px solid #2a3133;
  border-radius: 50% !important;
  margin: unset !important;
}
.custom-loader {
  animation: loader 1s infinite;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss">
.vue-advanced-cropper__background, .vue-advanced-cropper__foreground{
  background: none !important;
}
/*
  Vue2 to Vue3 admin settings accordion styles correction
*/
#accordionsSection{
  .v-switch .v-selection-control,
  .v-selection-control__wrapper,
  .v-label { 
    min-height: unset !important;
    height: auto !important;
  }
}
/*
  END - Vue2 to Vue3 admin settings accordion styles correction
*/
.pr52{
  padding-right: 52px!important;
}
.pr40 {
  padding-right: 40px!important;
}
#autocomplete_templates {
  .v-menu__content {
    top: 80px !important;
    left: 22px !important;
  }  
}
#combobox_beaconTeams {
  .v-menu__content {
    top: 90px !important;
    left: 10px !important;
  }  
}
#select_currency {
  .v-menu__content {
    top: 150px !important;
    left: 35px !important;
  }  
}
#combobox_templates {
  .v-menu__content {
    top: 260px !important;
    left: unset !important;
    right: 37px !important;
    max-height: 50% !important;
    min-width: 43% !important;
    max-width: 54% !important;
  }  
}

#combobox_organigram_sections {
  .v-menu__content {
    margin-top: 20px;
    margin-left: 30px;
  }  
}
#combobox_organigram {
  .v-menu__content {
    margin-top: 50px;
  }  
}
#autocomplete_2faHours {
  .v-menu__content {
    margin-top: 213px;
    margin-left: 12px;
  }  
}
#autocomplete_timezone {
  .v-menu__content {
    margin-top: 25px !important;
  }
}
.paddingTextArea  textarea{
  padding-top: 8px!important;
}
.radioGroup {
  & .v-input__slot {
    margin-bottom: 0px !important;
  }
}
</style>
