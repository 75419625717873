<template>
  <v-dialog :model-value="dailyReportModal" @update:model-value="dailyReportModal = $event" persistent max-width="720">
    <v-card>
      <HeaderModal
        :titleModal="`${$t('components.adminSettingsModal.dailyReport')} ${getDailyReportDate.getUTCDate()}.${getDailyReportDate.getUTCMonth() + 1}.${getDailyReportDate.getFullYear()}`"
        :closeModalFunction="closeModal"
      />
      <v-card-text class="mx-auto py-4">
        <v-textarea
          :disabled="disabledDailyReport"
          variant="outlined"
          density="compact"
          rows="8"
          :model-value="dailyReportText" @update:model-value="dailyReportText = $event"
          :counter="1000"
          maxlength="1000"
          :label="$t('components.adminSettingsModal.addDailyReportTT')"
          no-resize
        />
        <span class="text-red" v-if="disabledDailyReport">{{
          $t("components.dailyReport.reportPerDay")
        }}</span>
      </v-card-text>
      <FooterModal :closeModalFunction="closeModal">
        <v-btn
          color="primary"
          class="ml-2"
          @click="sendReport"
          :disabled="disabledSave || disabledDailyReport"
          >{{ $t("generics.save") }}</v-btn
        >
      </FooterModal>
    </v-card>
  </v-dialog>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { sendMailDailyReport } from "../../lib/wsMsg";
import {
  resetDailyReportModalEvent,
  dailyReportModalStore,
} from "../../effector/modals";
import { isMobile } from "../../lib/mobileUtil";
export default {
  components: { HeaderModal, FooterModal },
  props: [],
  data() {
    const effector = {
      dailyReportModal: dailyReportModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      isMobile: isMobile(),
      state: store.state,
      dailyReportText: "",
      // Effector
      ...effector,
    };
  },
  methods: {
    async sendReport() {
      const dateToSet = this.getDailyReportDate
      // send email
      const emailList =
        store.state.namespaceSettings.dailyReportSettings.members[
          this.state.ownUUID
        ];
      if (emailList?.length) {
        const dailyReportDate = `${dateToSet.getUTCDate()}.${dateToSet.getUTCMonth() + 1}.${dateToSet.getFullYear()}`
        const subjectDailyReport = this.$t("components.dailyReport.subjectDailyReport", [
          dailyReportDate,
          this.getNameForUuid(this.state.ownUUID)
        ])
        const bodyDailyReport = this.$t("components.dailyReport.bodyDailyReport", [
          this.getNameForUuid(this.state.ownUUID),
          dailyReportDate,
          this.dailyReportText
        ])
        await sendMailDailyReport({
          subject: subjectDailyReport,
          customMessage: bodyDailyReport,
        });
      }
      store.setDailyReportDay(dateToSet);
      this.closeModal();
    },
    isToday(someDate) {
      const date = new Date(store.state.currentTS);
      if (typeof someDate === "string") {
        const today = date.toISOString().slice(0, 10);
        return someDate.startsWith(today);
      } else if (someDate instanceof Date && !isNaN(someDate)) {
        const today = date;
        return (
          someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
        );
      }
      return false;
    },
    getNameForUuid(uuid){
      return store.getNameForUuid(uuid)
    },
    closeModal() {
      resetDailyReportModalEvent(false);
    },
  },
  computed: {
    getDailyReportDate(){
      const today = new Date();
      let dateToSet = today;
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const dailyReportDay =  new Date(this.state.persisted.dailyReportDay);
      yesterday.setUTCHours(0,0,0,0);
      dailyReportDay.setUTCHours(0,0,0,0);
      today.setUTCHours(0,0,0,0);
      // check if today is monday, and if the user set the daily report from friday
      if(today.getDay() == 1){
        const friday = new Date();
        friday.setDate(friday.getDate() - 3);
        friday.setUTCHours(0,0,0,0);
        if(dailyReportDay.getTime() < friday.getTime()){
          dateToSet = friday;
        }
      }
      // check if the user has not set the daily report from 2 days ago
      else if (dailyReportDay.getTime() < yesterday.getTime()) {
        dateToSet = yesterday;
      }
      // check if the user is setting the daily report from today
      else {
        dateToSet = today;
      }
      return dateToSet
    },
    disabledDailyReport() {
      return this.isToday(this.state.persisted.dailyReportDay);
    },
    disabledSave() {
      if (this.dailyReportText.length < 1) return true;
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>