<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <div>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="$t('components.modalProducts.title')" :closeModalFunction="closeModal" />
        <v-container v-if="!newProduct && !productToEdit">
          <v-row>
            <v-col cols="12">
              <p class="text-body-1">{{$t('components.modalProducts.titleBody')}}</p>
            </v-col>
          </v-row>

          <v-row class="mx-0" v-if="getProducts.length > 0">
            <v-col cols="12" class="py-0">
              <v-row class="mx-0">
                <v-col cols="6" class="px-0 py-0">
                  <span class="font-weight-bold">{{$t('components.modalProducts.productName')}}</span>
                </v-col>
                <v-col cols="3" class="px-0 py-0">
                  <span class="font-weight-bold">{{$t('components.modalProducts.productHour')}}</span>
                </v-col>
                <v-col cols="3" class="px-0 py-0">
                  
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0" v-for="product in getProducts" :key="product.productId">
              <v-row class="mx-0">
                <v-col cols="6" class="px-0 py-0">
                  {{product.productName}}
                </v-col>
                <v-col cols="3" class="px-0 py-0">
                  {{product.productPrice}}
                  <font-awesome-icon :icon="['fal', 'euro-sign']" :style="{ fontSize: '15px' }" v-if="state.namespaceSettings.currency === 'EUR'"/>
                    <font-awesome-icon :icon="['far', 'pound-sign']" :style="{ fontSize: '15px' }" v-if="state.namespaceSettings.currency === 'GBP'"/>
                    <v-img
                    contain
                    height="20"
                    v-if="state.namespaceSettings.currency === 'CHF'"
                    src="img/paymentIcons/swiss-franc-black.png"
                    lazy-src="img/paymentIcons/swiss-franc.png"
                  ></v-img>
                </v-col>
                <v-col cols="3" class="px-0 py-0 text-center">
                  <font-awesome-icon :icon="['fal', 'pen']" :style="{ fontSize: '15px', cursor: 'pointer' }" @click="setProductToEdit(product)"/>
                  <DeleteBasket
                    :indexData="product"
                    :delFunction="deleteProduct"
                    extraClasses="cursorPointer ml-3"
                    fontSize="15"
                    prevent="true"
                  />
                </v-col>
              </v-row>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn color="primary" @click="showNewProduct">
                <font-awesome-icon :icon="['fal', 'plus']" :style="{ fontSize: '16px' }" />
                <span class="pl-1">{{$t('components.modalProducts.addNewProducts')}}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-if="newProduct || productToEdit">
          <v-row>
            <v-col cols="8">
              <v-text-field 
                variant="outlined"
                density="compact"
                :model-value="productName" @update:model-value="productName = $event"
                maxLength="15"
                :label="$t('components.modalProducts.productName')"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field 
                variant="outlined"
                density="compact"
                :model-value="pricePerHour" @update:model-value="pricePerHour = $event"
                type="number"
                max="10000"
                @change="checkAmountValue"
                @keyup="checkAmountValue"
                :rules="amountRules"
                :label="$t('components.modalProducts.productHour')"
              >
                <template v-slot:append>
                  <font-awesome-icon :icon="['fal', 'euro-sign']" :style="{ fontSize: '15px' }" v-if="state.namespaceSettings.currency === 'EUR'"/>
                  <font-awesome-icon :icon="['far', 'pound-sign']" :style="{ fontSize: '15px' }" v-if="state.namespaceSettings.currency === 'GBP'"/>
                  <v-img
                    contain
                    height="20"
                    v-if="state.namespaceSettings.currency === 'CHF'"
                    src="img/paymentIcons/swiss-franc-black.png"
                    lazy-src="img/paymentIcons/swiss-franc.png"
                  ></v-img>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn color="primary" @click="!productToEdit ? saveNewProduct() : editProduct()" :disabled="!checkNewProduct">
                {{$t('generics.save')}}
              </v-btn>
              <v-btn color="primary" @click="showNewProduct" class="ml-2">
                {{$t('generics.cancel')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <FooterModal :closeModalFunction="closeModal">
          
        </FooterModal>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { v4 as uuidv4 } from "uuid";
import { setNamespaceSetting } from "../../../lib/wsMsg";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import DeleteBasket from "../../ui/deleteBasket.vue";
export default {
  props: ["showModal","closeModal"],
  components: {
    HeaderModal,
    FooterModal,
    DeleteBasket
  },
  data: () => ({
    state: store.state,
    newProduct: false,
    productName: '',
    pricePerHour: null,
    amountRules: [(v) => !v || /^\d+([.,]\d{2}|)$/.test(v) || ""],
    productToEdit: false,
  }),
  mounted() {},
  unmounted() {},

  watch: {},
  methods: {
    setProductToEdit(product){
      this.productToEdit = product.productId;
      this.productName = product.productName;
      this.pricePerHour = parseFloat(product.productPrice.replace(',', '')).toLocaleString('en', { minimumFractionDigits: 2 }).replace(',', '');
    },
    deleteProduct(product){
      const products = store.state.namespaceSettings.adminProducts;
      const indexToDelete = products.findIndex(e => e.productId === product.productId);
      if ( indexToDelete !== -1 ){
        products.splice(indexToDelete, 1);
        setNamespaceSetting('adminProducts', products);
      }
    },
    editProduct(){
      if ( this.checkNewProduct ){
        store.state.namespaceSettings.adminProducts.filter(e => e.productId == this.productToEdit).forEach((product) => {
          product.productName = this.productName;
          product.productPrice = this.pricePerHour;
        });
        setNamespaceSetting('adminProducts', store.state.namespaceSettings.adminProducts);
        this.productName = '';
        this.pricePerHour = null;
        this.productToEdit = false;
      }
    },
    saveNewProduct(){
      if ( this.checkNewProduct ){
        const productObj = {
          productId: uuidv4(),
          productName: this.productName,
          productPrice: this.pricePerHour,
        }
        const existingProducts = store.state.namespaceSettings.adminProducts;
        existingProducts.push(productObj);
        setNamespaceSetting('adminProducts', existingProducts);
        this.clearForm();
      }
    },
    clearForm(){
      this.productName = '';
      this.pricePerHour = null;
      this.showNewProduct();
    },
    showNewProduct(){
      this.newProduct = !this.newProduct;
    },
    checkAmountValue(){
      if (this.pricePerHour <= 0) {
        this.pricePerHour = "";
      }else if (this.pricePerHour > 10000){
        this.pricePerHour = 10000;
      }
    },
  },
  computed:{

    checkNewProduct(){
      return this.productName !== '' && this.pricePerHour !== null;
    },
    getProducts(){
      return (store.state.namespaceSettings.adminProducts || [])
        .map(product => ({
          ...product,
          productPrice: parseFloat(product.productPrice).toLocaleString(this.$locale.current(), { minimumFractionDigits: 2 })
        }));
    },
  }
};
</script>

<style scoped lang="scss">
.cursorPointer{
  cursor: pointer;
}
</style>
