<template>
  <div :class="isMobile ? 'InviteFormContainerMobile mt-4' : isAssistsInviteForm ? 'InviteFormContainer' : 'InviteFormContainer mt-18'">
    <v-card :class="`${!showTimeline ? 'pt-1' : 'pt-5'} pl-10 pr-10`">
      <div class="cols-12 textCenterd">{{ getHeaderTitle() }}</div>
      <v-tabs
        :vertical="isMobile ? true : false "
        :centered="isMobile ? false : true"
        class="tabInvite mb-5"
        :model-value="tab" @update:model-value="tab = $event"
      >
        <v-tab>
          {{ $t("components.inviteVisitor.title") }}
        </v-tab>
        <v-tab>
          {{ $t("components.inviteGuestPanel.inviteList") }}
        </v-tab>
      </v-tabs>
      <v-window :model-value="tab" @update:model-value="tab = $event">
        <!--TAB 1-->
        <v-window-item>
          <v-card flat>
            <v-row>
              <!--column 1-->
              <v-col :cols="isMobile ? 12 : 5">
                <!--contacts dropdown-->
                <div class="d-flex">
                  <div class="contactStyle pt-2">
                    <v-combobox
                      :model-value="contactSelected" @update:model-value="contactSelected = $event"
                      :search-input.sync="contactSearch"
                      :items="filteredInvitations"
                      class="pb-6"
                      variant="outlined"
                      item-title="name"
                      item-value="email"
                      hide-details="auto"
                      :label="$t('components.inviteVisitor.contacts')"
                      select
                      density="compact"
                      :menu-props="{ closeOnContentClick: true }"
                    >
                      <template #item="{ item }">
                        <v-list-item
                          @click="handleSelectEmail(item.raw)"
                          style="position: relative; min-height: 25px"
                           class="d-flex justify-space-between align-center w-100"
                        >
                          <div class="d-flex flex-wrap w-100">
                            <v-list-item-title class="w-100">
                              {{ item.raw.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="w-100 mt-2">
                              {{ item.raw.email }}
                            </v-list-item-subtitle>
                          </div>
                          <DeleteBasket
                            :indexData="item.raw"
                            :delFunction="removeUserFromList"
                            extraClasses="deleteContactBasket cursorPointer"
                            fontSize="20"
                            prevent="true"
                          />
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </div>
                </div>
                <!--name-->
                <v-text-field
                  class="pb-4"
                  :class="{ borderError: name == '' }"
                  @blur="applyRules = true"
                  :model-value="name" @update:model-value="name = $event"
                  counter
                  maxlength="60"
                  required
                  variant="outlined"
                  :rules="applyRules ? userNameRules : noRules"
                  :label="$t('components.inviteVisitor.nameGuest')"
                  density="compact"
                >
                </v-text-field>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <!--email-->
                    <v-text-field
                      :model-value="email" @update:model-value="email = $event; checkEmailPreSend();"
                      class="pb-2"
                      :class="{ borderError: isEmailInvalid }"
                      :label="$t('components.inviteVisitor.invitationEmail')"
                      required
                      variant="outlined"
                      density="compact"
                      :rules="emailRules"
                      @keyup="changeEmail"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <template v-if="!reducedMode">
                  <v-row>
                    <v-col cols="12" class="pt-0 positionRelative pb-6">
                      <vue-tel-input
                        :model-value="telNumberModel" 
                        @update:model-value="telNumberModel = $event"
                        defaultCountry="DE"
                        enabledCountryCode
                        @validate="onTelInput"
                        name="number"
                        id="numDisplay"
                        class="
                          focusStyle
                          form-control
                          text-center
                          input-sm
                          inputPhone2FA
                        "
                        value
                        :inputOptions="{placeholder: $t('components.inviteGuestPanel.telOptional')}"
                        autocomplete="off"
                        :class="{ borderError: !phoneInputValid }"
                      >
                      </vue-tel-input>
                      <label
                        :class="isDarkMode ? 'bgBlack' : 'bgWhite'"
                        class="customLabelTlf"
                        >{{
                          $t("components.inviteGuestPanel.telOptional")
                        }}</label
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <div class="d-flex">
                        <v-checkbox
                          :disabled="getCheckboxDisabled"
                          class="mr-3"
                          style="height: 60px !important;"
                          :model-value="typeInvitation" @update:model-value="typeInvitation = $event; changeTypeInvitation()"
                          :label="$t('components.inviteVisitor.asap')"
                          value="asap"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                        :disabled="getCheckboxDisabled"
                          class="mr-3"
                          style="height: 60px !important;"
                          :model-value="typeInvitation" @update:model-value="typeInvitation = $event; changeTypeInvitation()"
                          :label="$t('components.inviteVisitor.soon')"
                          value="soon"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                        :disabled="getCheckboxDisabled"
                          class="mr-3"
                          style="height: 60px !important; margin-top: 5px;"
                          :model-value="typeInvitation" @update:model-value="typeInvitation = $event;changeTypeInvitation()"
                          :label="
                            $t('components.inviteVisitor.appointmentshort')
                          "
                          value="appointment"
                        ></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="d-flex w100">
                    <div class="pt-0  w100">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        :model-value="startLinkDateTo" @update:model-value="startLinkDateTo = $event"
                        v-if="fixedDate"
                      >
                        <template v-slot:activator="{ props }">
                          <v-text-field
                            :model-value="linkDateTo" @update:model-value="linkDateTo = $event"
                            v-bind="props"
                            variant="outlined"
                            density="compact"
                            append-inner-icon="mdi-calendar"
                            readonly
                            :label="
                              $t('components.inviteGuestPanel.selectDate')
                            "
                            :disabled="fixedDate === false"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          :model-value="dateTo"
                           @update:model-value="dateTo = $event"
                          @input="tixdate = false"
                          :pickTime="true"
                          :locale="this.$locale.current()"
                          :disabled="fixedDate === false"
                          first-day-of-week="1"
                          :allowed-dates="allowedDates"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="pt-0 w100 ml-2">
                      <v-menu
                        ref="menutTo"
                        :model-value="menutTo" @update:model-value="menutTo = $event"
                        :close-on-content-click="false"
                        :nudge-left="150"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        v-if="fixedDate"
                      >
                        <template v-slot:activator="{ props }">
                          <v-text-field
                            :model-value="linkTimeTo" @update:model-value="linkTimeTo = $event"
                            readonly
                             variant="outlined"
                            density="compact"
                            append-inner-icon="mdi-clock"
                            v-bind="props"
                            :disabled="fixedDate === false"
                            :label="
                              $t('components.conferenceForm.from')
                            "
                          >
                          </v-text-field>
                        </template>
                        <v-time-picker
                          format="24hr"
                          v-if="menutTo"
                          :model-value="linkTimeTo" @update:model-value="linkTimeTo = $event"
                          @click:minute="$refs.menutTo.save(linkTimeTo)"
                          @click:hour="autocompleteMinutes"
                          :disabled="fixedDate === false"
                          min="00:00"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                    <div class="ml-2 pt-0 w100">
                      <v-menu
                        ref="endTime"
                        :model-value="endTime" @update:model-value="endTime = $event"
                        :close-on-content-click="false"
                        :nudge-left="150"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        v-if="fixedDate"
                        :disabled="!linkTimeTo"
                      >
                        <template v-slot:activator="{ props }">
                          <v-text-field
                            :model-value="durationMin" @update:model-value="durationMin = $event"
                            readonly
                             variant="outlined"
                            density="compact"
                            append-inner-icon="mdi-clock"
                            v-bind="props"
                            :disabled="fixedDate === false || !linkTimeTo"
                            :label="
                              $t('components.conferenceForm.to')
                            "
                          >
                          </v-text-field>
                        </template>
                        <v-time-picker
                          format="24hr"
                          @click:minute="$refs.endTime.save(durationMin);"
                          v-if="endTime"
                          :model-value="durationMin" @update:model-value="durationMin = $event"
                          :disabled="fixedDate === false || !linkTimeTo"
                          :min="linkTimeTo"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                  </div>
                  <v-row>
                    <!-- CC -->
                    <v-col cols="12" md="9" lg="8" class="py-0">
                      <v-checkbox
                        class="pt-0 mt-0"
                        :model-value="carboncopy" @update:model-value="carboncopy = $event"
                        :label="$t('components.inviteGuestPanel.ccBenutzer', [`${getTitelForUuid(state.ownUUID)} ${getNameForUuid(state.ownUUID)}`])"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0" v-if="isPayAvailable">
                      <div class="d-flex">
                        <v-text-field
                          :model-value="amountPayLink" @update:model-value="amountPayLink = $event"
                          type="number"
                          variant="outlined"
                          min="1"
                          max="10000"
                          @change="checkValuePayLink"
                          @keyup="checkValuePayLink"
                          hide-details
                          density="compact"
                          :rules="amountRules"
                          class="mr-2 maxwidth150"
                        >
                          <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
                          <template style="margin-top: 12px" v-slot:append-inner>
                            <font-awesome-icon
                              :icon="['fal', 'euro-sign']"
                              class="mt-1"
                              :style="{ fontSize: '15px' }"
                              v-if="state.namespaceSettings.currency === 'EUR'"
                            />
                            <font-awesome-icon
                              :icon="['far', 'pound-sign']"
                              class="mt-1"
                              :style="{ fontSize: '15px' }"
                              v-if="state.namespaceSettings.currency === 'GBP'"
                            />
                            <v-img
                              contain
                              height="20"
                              v-if="state.namespaceSettings.currency === 'CHF'"
                              src="img/paymentIcons/swiss-franc-black.png"
                              lazy-src="img/paymentIcons/swiss-franc.png"
                            ></v-img>
                          </template>
                        </v-text-field>
                        <p class="pt-2">
                          {{ $t("components.inviteVisitor.paylinkPayment") }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- pin -->
                  <v-row>
                    <v-col class="pt-0">
                      <div class="d-flex">
                        <v-text-field
                          class="mr-2 mt-0 pt-0 maxwidth150"
                          autocomplete="off"
                          :model-value="pinCode" @update:model-value="pinCode = $event"
                          density="compact"
                          variant="outlined"
                          @keydown.space.prevent
                          @blur="checkPin"
                          :maxlength="6"
                          :rules="[
                            (v) => !!v || '',
                            (v) => (v && v.length === 6) || '',
                            (v) =>
                              /^\d{6}$/.test(v) ||
                              this.$t('components.2faPin.sixDigitsRequired'),
                          ]"
                        ></v-text-field>
                        <p class="pt-2">
                          {{ $t("components.inviteVisitor.pinAccess") }}
                        </p>
                      </div>
                      <p class="text-body-2 noteStyle pt-2" v-if="pinCode">
                        {{ $t("components.inviteGuestPanel.pinGuestSenden") }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-textarea
                        :model-value="noteForContact" @update:model-value="noteForContact = $event"
                        name="subjectinput"
                        :no-resize="true"
                        variant="outlined"
                        density="compact"
                        :rows="1"
                        class=""
                        maxlength="80"
                        auto-grow
                        :label="$t('components.inviteVisitor.notiz')"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-col>
              <!--column 2-->
              <template v-if="!reducedMode">
                <v-col :cols="isMobile ? 12 : 7" class=" pt-5" @click.stop.prevent>
                  <v-textarea
                    class="inviteTextAreaClass"
                    spellcheck="false"
                    :model-value="inviteText" @update:model-value="inviteText = $event"
                    variant="outlined"
                    :no-resize="true"
                    name="noteinput"
                    auto-grow
                    rows="1"
                    @click.stop.prevent
                    :label="
                      $t('components.inviteVisitor.invitationTextHeadline')
                    "
                    maxlength="2000"
                    counter
                  ></v-textarea>
                  <div style="display: none">Value: {{ inviteText }}</div>
                  <div>
                    <v-autocomplete
                      v-if="getCustomInviteTexts.length > 0"
                      :search-input.sync="templateSearch"
                      :model-value="customTextSelected" @update:model-value="customTextSelected = $event"
                      :items="getCustomInviteTexts"
                      class="pb-6"
                      variant="outlined"
                      item-title="title"
                      item-value="title"
                      hide-details="auto"
                      :label="$t('generics.templates')"
                      density="compact"
                      clearable
                      @click:clear="resetCustomMessage()"
                      :menu-props="{ closeOnContentClick: true }"
                    >
                      <template slot="item" slot-scope="data">
                        <v-list-item
                          @click="selectText(data)"
                          style="position: relative; min-height: 25px"
                        >
                          <!-- <v-list-item-content>
                            <div class="listTitle mb-1">
                              {{ data.item.title }}
                            </div>
                          </v-list-item-content> -->
                          <v-list-item-title>
                            {{ data.item.title }}
                            </v-list-item-title>
                          <DeleteBasket
                            v-if="amIAdmin"
                            :indexData="data.item"
                            :delFunction="removeCustomTextFromList"
                            extraClasses="deleteContactBasket  cursorPointer"
                            fontSize="20"
                            prevent="true"
                          />
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <div
                      align="right"
                      class="buttonsTextarea pt-6 px-0"
                      v-if="updateText"
                    >
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <v-btn
                            class="mb-2"
                            align="right"
                            color="primary"
                            variant="outlined"
                            v-bind="props"
                            density="compact"
                            @click="resetCustomMessage(true)"
                          >
                            <font-awesome-icon
                              :icon="['fal', 'undo']"
                              :style="{ fontSize: '18px' }"
                              :class="hideElements ? null : `mr-3`"
                            />
                            {{
                              hideElements
                                ? null
                                : $t(
                                    "components.inviteGuestPanel.resetCustomInviteText"
                                  )
                            }}
                          </v-btn>
                        </template>
                        <span>{{
                          $t(
                            "components.inviteGuestPanel.resetCustomInviteText"
                          )
                        }}</span>
                      </v-tooltip>
                      <template v-if="amIAdmin">
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              v-bind="props"
                              class="mb-2"
                              align="right"
                              color="primary"
                              variant="outlined"
                              density="compact"
                              @click.stop.prevent="saveText()"
                              :disabled="disabledSaveText"
                            >
                              <font-awesome-icon
                                :icon="['fal', 'save']"
                                :style="{ fontSize: '18px' }"
                                :class="hideElements ? null : `mr-3`"
                              />
                              {{
                                hideElements
                                  ? null
                                  : $t("components.inviteGuestPanel.saveText")
                              }}
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.inviteGuestPanel.saveText")
                          }}</span>
                        </v-tooltip>
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              :disabled="
                                getCustomInviteTexts.length >= 20 || isTextEmpty
                              "
                              align="right"
                              color="primary"
                              class="mb-2"
                              variant="outlined"
                              v-bind="props"
                              density="compact"
                              @click="saveTextAsModal()"
                            >
                              <font-awesome-icon
                                :icon="['fal', 'save']"
                                :style="{ fontSize: '18px' }"
                                :class="hideElements ? null : `mr-3`"
                              />
                              {{
                                hideElements
                                  ? null
                                  : $t("components.inviteVisitor.saveTextAs")
                              }}
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.inviteVisitor.saveTextAs")
                          }}</span>
                        </v-tooltip>
                      </template>
                    </div>
                  </div>
                </v-col>
              </template>
            </v-row>
            <v-row>
              <v-col :cols="isMobile ? 12 : 5" class="pt-0 d-flex justify-end">
                <v-btn
                  color="primary"
                  variant="outlined"
                  @click="reducedMode = !reducedMode"
                >
                  <span>
                    {{
                      reducedMode
                        ? $t("generics.extend")
                        : $t("generics.reduced")
                    }}
                  </span>
                  <font-awesome-icon
                    :icon="['fal', 'arrow-down']"
                    :style="{ fontSize: '16px' }"
                    class="ml-1"
                    v-if="reducedMode"
                  />
                  <font-awesome-icon
                    v-else
                    :icon="['fal', 'arrow-up']"
                    :style="{ fontSize: '16px' }"
                    class="ml-1"
                  />
                </v-btn>
              </v-col>
            </v-row>
            <!-- /////// Error text ///////// -->
            <v-row class="mx-0 py-2" style="width: 100%" v-if="errMssg">
              <div>
                <span align="left" style="color: red">
                  {{
                    emailExists
                      ? $t("components.inviteGuestPanel.sameEmail")
                      : ""
                  }}</span
                >
              </div>
            </v-row>
            <v-row class="mx-auto footerRow">
              <v-col cols="12" class="px-0 pt-2">
                <v-divider></v-divider>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 4"
                :md="isMobile ? 12 : 6"
                align="right"
                class="px-0 pb-0 align-self-center"
                v-if="!hideNoteInviteGuest"
              >
                <p
                  class="mb-0 text-body-2 noteStyle"
                  align="left"
                >
                  <span class="font-weight-medium">{{
                    $t("components.inviteGuestPanel.note")
                  }}</span
                  >{{
                    $t("components.inviteGuestPanel.guestWaitingroom", [
                      `${getTitelForUuid(
                        isAssistsInviteForm
                          ? isAssistsInviteForm
                          : state.ownUUID
                      )} ${getNameForUuid(
                        isAssistsInviteForm
                          ? isAssistsInviteForm
                          : state.ownUUID
                      )}`,
                    ])
                  }}
                </p>
              <v-col class="px-0 pb-4 pt-1" cols="12" v-if="!hideNoteInviteGuest">
                <v-checkbox
                  hide-details
                  :model-value="hideNoteInviteGuest" @update:model-value="hideNoteInviteGuest = $event; setHideNoteInviteGuest(hideNoteInviteGuest)"
                  :label="$t('components.meetingTime.dontShowAgain')"
                >
                  <template slot="label">
                    <p class="mb-0 font-italic">
                      {{ $t("components.meetingTime.dontShowAgain") }}
                    </p>
                  </template>
                </v-checkbox>
              </v-col>
              </v-col>
              <v-col
                :cols="hideNoteInviteGuest || isMobile ? 12 : 8"
                :md="hideNoteInviteGuest || isMobile ? 12 : 6"
                :align="isMobile ? 'center' : 'right'"
                class="px-0 pt-0 mb-3"
              >
                <!--Save contact-->
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      align="right"
                      :class="`${isMobile ? 'mb-2' : null} cursorPointer`"
                      color="primary"
                      variant="outlined"
                      v-bind="props"
                      @click.stop.prevent="addUserToList()"
                      :disabled="!name || name.length == 0 || isEmailInvalid"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'save']"
                        :style="{ fontSize: '18px' }"
                        class="mr-3"
                      />
                      {{ $t("components.inviteGuestPanel.saveContact") }}
                    </v-btn>
                  </template>
                  <span>{{
                    $t("components.inviteVisitor.ttSaveDiskette")
                  }}</span>
                </v-tooltip>
                <v-btn
                  :disabled="disableSendButton"
                  color="primary"
                  class="ml-3"
                  @click.stop.prevent="precheckNewVisitor"
                  >{{ $t("components.inviteGuestPanel.sendInvitation") }}
                </v-btn>
                <v-btn
                  style="border-radius: 4px!important; min-width: 45px"
                  icon
                  tile
                  variant="outlined"
                  class="ml-3 mr-2"
                  color="primary"
                  density="comfortable"
                  @click="closeInvitationForm()"
                >
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
        <!-- </v-tab-item> -->
        <!--Tab Content 2-->
        <v-window-item>
          <div class="visitorListTable" v-if="getVisitors.length > 0">
          <div class="d-flex Table" v-if="getVisitors.length > 0">
            <!--image-->
            <div class="ListAvatar">
            </div>
            <!--send-mail-->
            <div class="ListMessage">
            </div>
            <!--name-->
            <div class="ListName ">
            </div>
            <!--email-->
            <div class="ListEmail">
            </div>
            <div class="listDate">
            </div>
            <div class="ListPayLink"></div>
            <div class="ListResend"></div>
            <div class="ListResend"></div>
            <div class="ListCopy"></div>
            <div class="ListDelete">
            <v-btn density="comfortable" variant="text" icon small :disabled="!selectedUser.length || removingVisitor">
              <DeleteBasket
                :delFunction="deleteAllVisitor"
                extraClasses="deleteUserFromList"
                fontSize="20"
              />
            </v-btn>
            </div>
            <div class="ListCheckbox mt-2">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-checkbox
                  density="compact"
                  class=""
                  v-bind="props"
                  hide-details
                  :model-value="selectAll" @update:model-value="selectAll = $event; selectAllUser()"
                ></v-checkbox>
                </template>
                <span>{{$t("generics.selectAll")}}</span>
              </v-tooltip>
            </div>
          </div>
            <div v-for="(invitation, index) in getVisitors" :key="invitation.uuid || index">
              <div class="d-flex Table">
                <!--image-->
                <div class="ListAvatar">
                  <div class="AvatarImage">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <v-img
                          v-bind="props"
                          :src="
                            getAvatarForUuid(invitation.uuid) ||
                            'img/default_profile_picture.png'
                          "
                          lazy-src="img/default_profile_picture.png"
                        ></v-img>
                      </template>
                      <span>{{ invitation.noteForContact }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <!--send-mail-->
                <div class="ListMessage">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="mt-1 mx-2"
                        icon
                        density="comfortable"
                        variant="text"
                        @click="setShowModalSenMsg(invitation.uuid)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'paper-plane']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("components.groups.sendMessage") }}</span>
                  </v-tooltip>
                </div>
                <!--name-->
                <div class="ListName pl-2">
                  <template v-if="editingName && editingName == invitation.uuid">
                    <v-text-field
                      :model-value="newVisitorName" @update:model-value="newVisitorName = $event"
                      density="compact"
                      clearable
                      class="pr-5"
                      type="text"
                      hide-details
                      maxlength="60"
                      :disabled="savingVisitorName"
                      @click:clear="editNameGuest(invitation)"
                    >
                    <template v-slot:append>
                       <v-btn
                        class="pt-1"
                        icon
                        density="comfortable"
                        variant="text"
                        :loading="savingVisitorName"
                        :disabled="!newVisitorName.trim()"
                        @click="saveNewVisitorName(invitation)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'save']"
                          :style="{ fontSize: '18px' }"
                        />
                      </v-btn>
                    </template>
                    </v-text-field>
                  </template>
                  <template v-else>
                  <p class="mt-2" >
                    {{ getTitelForUuid(invitation.uuid) }} {{ invitation.name }}
                  </p>
                  </template>
                </div>
                <!--email-->
                <div class="ListEmail">
                  <p class="mt-2">
                    {{ invitation.email }}
                    <font-awesome-icon
                      v-if="invitation.uuid"
                      v-show="hasUuidBeenOnPage(invitation.uuid)"
                      :icon="['fal', 'check']"
                      color="primary"
                      class="ml-2"
                    />
                  </p>
                </div>
                <!--Date-->
                <div class="listDate">
                  <p class="mt-3">
                    {{
                      $t("generics.sentOn") +
                      " " +
                      formatDateToShow(invitation.created) +
                      " " +
                      $t("generics.hours")
                    }}
                  </p>
                </div>
                <!--Date-->
                <!--TYPE-->
                <div class="listDate" v-if="getInviteType(invitation) && getInviteType(invitation).trim()">
                  <p class="mt-3">
                    {{ getInviteType(invitation) }}
                  </p>
                </div>
                <!--TYPE-->
                <!--if paylinf-->
                <div class="ListPayLink">
                  <img
                    v-if="
                      totalPaid[invitation.uuid] &&
                      totalPaid[invitation.uuid] != ''
                    "
                    class="facepayImg mr-2"
                    src="img/icons/facepay.svg"
                    lazy-src="img/icons/facepay.svg"
                  />
                  <span class="mt-1">
                    {{ totalPaid[invitation.uuid] || "" }}
                  </span>
                </div>
                  <!--edit name-->
                <div class="ListResend">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="mt-1"
                        icon
                        density="comfortable"
                        variant="text"
                        :disabled="savingVisitorName"
                        @click="editNameGuest(invitation)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'pen']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      $t("generics.edit")
                    }}</span>
                  </v-tooltip>
                </div>
                <!--resend-->
                <div class="ListResend">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="mt-1"
                        icon
                        density="comfortable"
                        variant="text"
                        @click="SendLinkToVisitor(invitation.uuid, invitation)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'redo']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.inviteVisitor.resendInvLink")
                    }}</span>
                  </v-tooltip>
                </div>
                <!--copy link-->
                <div class="ListCopy">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="mt-1"
                        icon
                        density="comfortable"
                        variant="text"
                        @click="copyLink($event, invitation)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'copy']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.inviteGuestPanel.copyLink")
                    }}</span>
                  </v-tooltip>
                </div>
                <!--delete-->
                <div class="ListDelete">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn class="mt-1" icon density="comfortable" variant="text" v-bind="props">
                        <DeleteBasket
                          :indexData="invitation.uuid"
                          :delFunction="deleteVisitor"
                          extraClasses="deleteUserFromList"
                          fontSize="20"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("generics.delete") }}</span>
                  </v-tooltip>
                </div>
                <!--copy link-->
                <div class="ListCheckbox" style="margin-top: 11px!important">
                  <!-- <v-tooltip location="top">
                    <template v-slot:activator="{ on }"> -->
                        <!-- v-on="on" -->
                      <v-checkbox
                        :id="invitation.uuid"
                        :key="invitation.uuid"
                        density="compact"
                        hide-details
                        :model-value="selectedUser" @update:model-value="selectedUser = $event"
                        :value="invitation.uuid"
                      ></v-checkbox>
                    <!-- </template>
                    <span>{{
                      $t("components.inviteGuestPanel.copyLink")
                    }}</span>
                  </v-tooltip> -->
                </div>
              </div>
              <v-divider class="mt-2 mb-2"></v-divider>
            </div>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
    <template v-if="showInvitationModal">
      <SendInvitationModal
        :closeShowInviteInfo="closeShowInviteInfo"
        :showInviteInfo="showInvitationModal"
      />
    </template>
    <template v-if="showSaveTextModal">
      <SaveCustomTextModal
        :showModal="showSaveTextModal"
        :closeModal="openCloseSaveTextModal"
        :saveTextAs="saveTextAs"
      />
    </template>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import Vue from "@vue/compat";
import moment from "../../../sharedsrc/moment";
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store, { EventBus } from "../../store";
import { isWaitingRoomUser } from "../../utils/privileges";
import { v4 as uuidv4 } from "uuid";
import { createVisitor, sendVisitorEmail } from "../../lib/inviteHelpers";
import { deleteVisitor } from "../../utils/waitingRoom";
import DeleteBasket from "../ui/deleteBasket.vue";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import SaveCustomTextModal from "../modal/saveCustomTextModal.vue";
import { isMobile } from "../../lib/mobileUtil";
import {
  getPayPalSettings,
  getUserInviteLink,
  setNamespaceSetting,
  isEmailRegistered,
  sendInviteMailCustom,
  sendInviteWhatsApp,
  transferVisitor,
  setRemoteUser,
  getPaymentsForGuest,
} from "../../lib/wsMsg";
import { parallel } from "../../lib/asyncUtil";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../effector/alerts";
import {
  createTimelineEntryEffect,
  timelineEvents,
  updateTimelineItemEffect
} from "../../effector/timeline";
import { newConferenceEvent } from "../../effector/conference";
import {
  foundExistingEmailState,
  findUserEmailEvent,
  foundExistingUUIDByEmailState,
} from "../../effector/users";
import InfoModalInviteVisitorsMenu from "../modal/infoModalInviteVisitorsMenu.vue";
import DirectLinkInvitationMultiUser from "../modal/directLinkInviteMultiUser.vue";
import {
  setHistoryMessagesModalEvent,
  setDeleteVisitorPaylinkModalEvent,
} from "../../effector/modals";

// import VueScreenSize from "vue-screen-size";

export default {
  // mixins: [VueScreenSize.VueScreenSizeMixin], //for VueScreenSize
  components: {
    InfoModalInviteVisitorsMenu,
    DirectLinkInvitationMultiUser,
    SendInvitationModal,
    DeleteBasket,
    SaveCustomTextModal,
  },
  data() {
    const effector = {
      foundExistingEmail: foundExistingEmailState,
      foundExistingUUID: foundExistingUUIDByEmailState,
      timelineEvents: timelineEvents,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      isMobile: isMobile(),
      tab: null,
      userSaveLimit: false,
      showInfoModal: false,
      createVisitorBusy: false,
      state: store.state,
      ownUUID: store.state.ownUUID,
      name: "",
      email: null,
      inviteText:
        (store.state.namespaceSettings.customTemplateWaitingRoomSelected || {})
          .text || "",
      appmtOptionselect: null,
      contactSelected: null,
      items: {},
      subject: "",
      instalink: "",
      created: "",
      addToPlanner: false,
      internalnote: "",
      linkDateFrom: "",
      date: "",
      linkTimeFrom: null,
      linkDateTo: "",
      dateTo: new Date(),
      linkTimeTo: null,
      menutTo: false,
      endTime: false,
      time: null,
      emailValid: false,
      durationMin: "",
      errMssg: false,
      // successText: "",
      userNameRules: [
        (v) => (!!v && !!v.trim().length) || this.$t("generics.nameRequired"),
      ],
      emailRules: [
        (v) => (!!v && !!v.trim().length) || this.$t("generics.emailRequired"),
        (v) => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v),
        (v) => !!v && !v.trim().includes(","),
        (v) => !!v && !v.trim().includes(";"),
      ],
      noRules: [],
      userList: [],
      inviteList: [],
      invitateLink: undefined,
      startLinkDateFrom: false,
      startLinkDateTo: false,
      price: "",
      lastEmail: "",
      lastUuid: "",
      complexPinActive: false,
      noPin: false,
      pinCode: null,
      isWaitingRoom: true,
      isSupportCall: false,
      isOneTime: false,
      isforMail: false,
      callPeriod: false,
      fixedDate: false,
      carboncopy: false,
      complexPinRegex:
        /^(?=.*[0-9])(?=.*[a-zñöäü])(?=.*[A-ZÖÄÜÑ])(?=.*[*.!@$%^&(){}[\]\/:;<>,.?~_\+\-\=|]).{12,}$/,
      multiUser: false,
      showModalDirectLink: false,
      forceSingleUse: false,
      conferenceTitle: "",
      conferenceId: null,
      row: null,
      moment: moment,
      deleting: false,
      applyRules: true,
      visFunction: "",
      phoneNumber: "",
      phoneNumberInput: "",
      phoneInputValid: true,
      invitationSend: "email",
      setCurrentContentVisile: store.setCurrentContentVisile,
      showInvitationModal: false,
      link: "",
      telNumberModel: "",
      phoneNumberRegister: "",
      emailExists: false,
      typeInvitation: "",
      noteForContact: "",
      paypalSettings: undefined,
      amountPayLink: null,
      amountRules: [(v) => !v || /^\d+([.,]\d{2}|)$/.test(v) || ""],
      paypalError: false,
      hideNoteInviteGuest: store.state.persisted.hideNoteInviteGuest,
      contactSearch: null,
      originalInviteText: "",
      showSaveTextModal: false,
      customTextSelected:
        (store.state.namespaceSettings.customTemplateWaitingRoomSelected || {})
          .title || null,
      templateSearch: null,
      totalPaid: {},
      reducedMode: true,
      editingName:false,
      newVisitorName: '',
      savingVisitorName: false,
      selectedUser: [],
      selectAll: null,
      removingVisitor: false,
      // Effector
      ...effector,
    };
  },
  watch: {
    getSelectedTitle: {
      handler: function (value) {
        if (value && value.trim().length === 0) {
          this.resetCustomMessage();
        }
      },
    },
    selectedUser: {
      handler: function (value) {
        const visitorLength = this.getVisitors.length
        const selectedUserLength = value.length;
        if(selectedUserLength<visitorLength){
          this.selectAll = false;
        } else if(selectedUserLength===visitorLength){
          this.selectAll = true;
        }
      },
    },
    inviteList() {
      this.inviteList.length > 0
        ? (this.multiUser = true)
        : (this.multiUser = false);
    },
    dateTo(val) {
      this.linkDateTo = this.formatDate(this.dateTo);
      this.startLinkDateTo = false;
    },
    date(val) {
      this.linkDateFrom = this.formatDate(this.date);
      this.startLinkDateFrom = false;
    },
    "state.showInviteInstaLink": function (value) {
      this.callPeriod = false;
      this.fixedDate = false;
      this.isWaitingRoom = true;
      this.carboncopy = false;
      this.inviteText = "";
      this.complexPinActive = false;
      this.pinCode = null;
      this.multiUser = false;
      this.conferenceTitle = "";
      this.userList = [];
      if (value === true) {
        this.appmtOptionselect = null;
      }
    },
  },
  mounted() {
    this.loadActualMessage();
    EventBus.$on("openTabInviteGuestList", this.openListTab);
    this.linkDateTo = this.formatDate(new Date())
  },
  unmounted(){
    EventBus.$off("openTabInviteGuestList", this.openListTab);
  },
  methods: {
    async deleteAllVisitor() {
      this.removingVisitor = true;
      const visitorToRemove =JSON.parse(JSON.stringify(this.selectedUser));
      for (let index = 0; index < visitorToRemove.length; index++) {
        const uuid = visitorToRemove[index];
        await this.deleteVisitor(uuid)
      }
      this.removingVisitor = false;
    },
    selectAllUser(){
      if(this.selectAll){
        this.selectedUser = this.getVisitors.map(e=>e.uuid)
      }else{
        this.selectedUser = [];
      }
    },
    editNameGuest(invitation){
      if(this.editingName){
        this.editingName = false;
        this.newVisitorName = '';
        this.savingVisitorName= false;
      }else{
        this.editingName = invitation.uuid;
        this.newVisitorName = invitation.name;
      }
    },
    async saveNewVisitorName(invitation){
      if(!this.newVisitorName) return
      this.savingVisitorName = true;
      await setRemoteUser(invitation.uuid, {
            name: this.newVisitorName,
            visitorData: {
              name: this.newVisitorName
            }
       }).then(() => {
         this.savingVisitorName = false;
         this.editNameGuest(invitation)
       })
    },
    resetCheckbox(){
      this.typeInvitation = "";
    },
    selectCustomTemplate(){
      if ( this.customTextSelected != null || this.customTextSelected != undefined ) {
        this.resetCheckbox();
      }
    },
    changeEmail(){
      this.email = this.email?.trim().toLowerCase()
    },
    async getTotalPaid(visitors) {
      if (visitors.length) {
        if (this._fetchingTotalPaid) return;
        this._fetchingTotalPaid = true;
        await parallel(1, [...visitors], async (visitor) => {
          let total = "";
          const result = await getPaymentsForGuest(visitor.visitorId);
          if (result && result.length && result[0].isPayLink === 1) {
            total = `${result[0].totalPaid} ${result[0].currency}`;
            this.totalPaid[visitor.visitorId] = total;
          }
          this.totalPaid[visitor.visitorId] = total;
        });
        this._fetchingTotalPaid = false;
      }
    },
    async removeCustomTextFromList(item) {
      const newCustomListTexts =
        this.state.namespaceSettings.inviteGuestCustomTexts.filter(
          (e) => e.title !== item.title
        );
      await setNamespaceSetting("inviteGuestCustomTexts", newCustomListTexts);
      if (this.state.namespaceSettings.inviteGuestCustomTexts.length == 0) {
        this.resetCustomMessage();
      }
    },
    selectText(data) {
      this.customTextSelected = data.item.title;
      this.inviteText = data.item.text;
    },
    saveText() {
      if (!this.customTextSelected) {
        // save as
        this.saveTextAsModal();
      } else {
        // normal save
        const updateRecord = {
          title: this.customTextSelected,
          text: this.inviteText,
        };
        const currentTextIndex =
          this.state.namespaceSettings.inviteGuestCustomTexts.findIndex(
            (item) => item.title === this.customTextSelected
          );
        if (currentTextIndex !== -1) {
          this.state.namespaceSettings.inviteGuestCustomTexts.splice(
            currentTextIndex,
            1,
            updateRecord
          );
        }
      }
    },
    saveTextAs(title) {
      const newTextObject = {
        title,
        text: this.inviteText,
      };
      let currentTexts = this.state.namespaceSettings.inviteGuestCustomTexts;
      currentTexts.push(newTextObject);
      setNamespaceSetting("inviteGuestCustomTexts", currentTexts);
      this.openCloseSaveTextModal();
    },
    openCloseSaveTextModal() {
      this.showSaveTextModal = !this.showSaveTextModal;
    },
    saveTextAsModal() {
      // open a modal with the name field input
      this.openCloseSaveTextModal();
    },
    autocompleteMinutes(hour) {
      this.linkTimeTo = hour + ":00";
      this.durationMin = null
    },
    setHideNoteInviteGuest(value) {
      store.setHideNoteInviteGuest(value);
    },
    changeTypeInvitation() {
      if(this.typeInvitation != "" || this.typeInvitation != null) {
        this.customTextSelected = null;
      }
      if (this.typeInvitation === "appointment") {
        this.inviteText =
          (this.state.namespaceSettings.customTemplateWaitingRoomSelected || {})
            .text || this.$t("components.invitesText.defaultGuestText");
        this.originalInviteText = this.inviteText;
        this.fixedDate = true;
      } else if (this.typeInvitation === "asap") {
        this.clearDates();
        this.inviteText =
          (this.state.namespaceSettings.customTemplateWaitingRoomSelected || {})
            .text || this.$t("components.invitesText.defaultGuestASAPText");
        this.originalInviteText = this.inviteText;
        this.fixedDate = false;
      } else if (this.typeInvitation === "soon") {
        this.clearDates();
        this.inviteText =
          (this.state.namespaceSettings.customTemplateWaitingRoomSelected || {})
            .text || this.$t("components.invitesText.defaultGuestSoonText");
        this.originalInviteText = this.inviteText;
        this.fixedDate = false;
      } else {
        this.inviteText =
          (this.state.namespaceSettings.customTemplateWaitingRoomSelected || {})
            .text || this.$t("components.invitesText.defaultGuestText");
        this.originalInviteText = this.inviteText;
        if(this.fixedDate){
          this.clearDates();
          this.fixedDate = false;
        }
      }
    },
    openListTab() {
      this.tab = 1;
    },
    closeInvitationForm() {
      this.setCurrentContentVisile("waitingroom", true, this.$router);
    },
    formatDateToShow(created) {
      return this.moment(new Date(created)).format("DD.MM.YY, HH:mm");
    },
    getInviteType(inv) {
      let oInvType = null;
      if (!inv.inviteType) {
        if (inv.conferenceId && inv.conferenceId.length) {
          oInvType = this.$t(
            "components.inviteVisitor.invitationTypeAppointment"
          );
        } else {
          oInvType = " ";
        }
      } else {
        if (inv.inviteType && inv.inviteType == "appointment") {
          oInvType = this.$t(
            "components.inviteVisitor.invitationTypeAppointment"
          );
        } else if (inv.inviteType && inv.inviteType == "asap") {
          oInvType = this.$t("components.inviteVisitor.invitationTypeASAP");
        } else if (inv.inviteType && inv.inviteType == "soon") {
          oInvType = this.$t("components.inviteVisitor.invitationTypeSoon");
        }
      }
      return oInvType;
    },
    allowedDates(val) {
      return (
        moment(val).isAfter(moment(new Date()).format("YYYY-MM-DD")) ||
        val === moment(new Date()).format("YYYY-MM-DD")
      );
    },
    async checkEmailPreSend(evt) {
      const email = this.email.trim().toLowerCase();
      const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;

      if (email.includes(",") || email.includes(";")) {
        this.emailValid = false;
        this.errMssg = true;
        return;
      }
      if (!re.test(email)) {
        this.emailValid = false;
        this.errMssg = true;
        return;
      } else {
        this.errMssg = false;
        this.emailValid = true;
      }
      this.emailExists = false;
    },
    loadActualMessage() {
      if (this.state.user.customInviteGuestMessage) {
        if (
          !this.state.persisted.customInviteGuestMessage &&
          typeof this.state.user.customInviteGuestMessage === "string"
        ) {
          this.state.persisted.customInviteGuestMessage =
            this.state.user.customInviteGuestMessage.trim();
        }
        this.$delete(this.state.user, "customInviteGuestMessage");
      }
      this.inviteText =
        (this.state.namespaceSettings.customTemplateWaitingRoomSelected || {})
          .text || this.$t("components.invitesText.defaultGuestText");

      this.originalInviteText = this.inviteText;
    },
    async copyLink(evt, visitor) {
      let instalink = visitor.instalink;
      if (!instalink) {
        if (this.link === "") {
          instalink = await getUserInviteLink(visitor.uuid);
          visitor.instalink = instalink;
        } else {
          instalink = this.link;
          visitor.instalink = this.link;
        }
      }
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
        this.link = "";
      }, 1000);
    },
    SendLinkToVisitor(uuid, visitor, extraOption = {}) {
      return sendVisitorEmail(visitor, extraOption)
        .then(() => {
          dispatchSuccessAlert(
            this.$t("components.inviteVisitor.invitationLinkSent")
          );
        })
        .catch((err) => {
          dispatchErrorAlert(err.message || err);
        });
    },
    async deleteVisitor(visId) {
      const wasSelected = this.selectedUser.findIndex(uuid=>uuid === visId)
      if(wasSelected !==-1){
         this.selectedUser.splice(wasSelected, 1);
      }
      await deleteVisitor(visId, this.timelineEvents);
    },
    closeShowInviteInfo() {
      this.showInvitationModal = false;
    },
    setShowModalSenMsg(uuid, type = "") {
      setHistoryMessagesModalEvent(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    goWaitingRoom() {
      this.$router.go(-1);
    },
    onTelInput({ number, valid, country } = {}) {
      const data = { number, valid, country };
      if (this.telNumberModel.length > 0) {
        if (data.number) {
          // new number
          if (data.valid) {
            this.dialCode = data.country ? "+" + data.country.dialCode : "";
            this.phoneNumberRegister = data.number.replace(/[-\s]/g, "");
          } else {
            this.dialCode = "";
            this.phoneNumberRegister = "";
          }
          this.phoneInputValid = data.valid;
        } else {
          // number from the saved list
          this.phoneNumberRegister = (this.phoneNumberInput || "").replace(
            /\s/g,
            ""
          );
          this.telNumberModel = this.phoneNumberRegister;
          this.phoneInputValid = true;
        }
      } else {
        this.dialCode = "";
        this.phoneNumberRegister = "";
        this.phoneInputValid = true;
      }
    },
    checkPin() {
      if (this.complexPinActive) {
        return this.complexPinRegex.test(this.pinCode);
      } else if (this.pinCode) {
        return /^\d{6}$/.test(this.pinCode);
      } else {
        return true;
      }
    },
    isValidSearch(term) {
      if (term !== "") {
        const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
        return re.test(term);
      } else {
        return false;
      }
    },
    handleSelectEmail(item) {
      if (item) {
        if (this.invitationSend === "email") {
          this.contactSelected = item.name;
          this.name = item.name;
          this.email = item.email;
          this.phoneNumberRegister = item.phoneNumberRegister;
          this.telNumberModel = item.telNumberModel;
          this.noteForContact = item.noteForContact || "";
          if (this.errMssg) {
            this.errMssg = false;
          }
        }
      }
    },
    removeUserFromList(item) {
      if (this.invitationSend === "email") {
        const existingEmail = store.state.user.videoCallInvitations.filter(
          (e) => e.email !== item.email
        );
        store.setVideoCallInvitations(existingEmail);
      } else if (this.invitationSend === "phone") {
        const existingPhone = store.state.user.videoCallInvitations.filter(
          (e) => e.phone !== item.phone
        );
        store.setVideoCallInvitations(existingPhone);
      }
      this.contactSelected = null;
      this.contactSearch = null;
    },
    async addUserToList() {
      const existingVideoCallInvitations =
        store.state.user.videoCallInvitations;
      if (existingVideoCallInvitations.length < 20) {
        if (this.isValidSearch(this.email)) {
          findUserEmailEvent(this.email);
          await this.$nextTick(); // Wait for effector event
          if (this.foundExistingEmail.length === 0) {
            const emailAlreadyExists = existingVideoCallInvitations.findIndex(
              (e) => e.email === this.email
            );
            if (emailAlreadyExists !== -1) {
              if (
                this.name ===
                  existingVideoCallInvitations[emailAlreadyExists].name &&
                this.noteForContact ===
                  existingVideoCallInvitations[emailAlreadyExists]
                    .noteForContact &&
                this.phoneNumberRegister ===
                  existingVideoCallInvitations[emailAlreadyExists]
                    .phoneNumberRegister &&
                this.telNumberModel ===
                  existingVideoCallInvitations[emailAlreadyExists]
                    .telNumberModel
              ) {
                return;
              } else if (
                this.name !==
                  existingVideoCallInvitations[emailAlreadyExists].name ||
                this.noteForContact !==
                  existingVideoCallInvitations[emailAlreadyExists]
                    .noteForContact ||
                this.phoneNumberRegister !==
                  existingVideoCallInvitations[emailAlreadyExists]
                    .phoneNumberRegister ||
                this.telNumberModel !==
                  existingVideoCallInvitations[emailAlreadyExists]
                    .telNumberModel
              ) {
                existingVideoCallInvitations.splice(emailAlreadyExists, 1);
              }
            }
            const newItem = {
              name: this.name,
              email: this.email,
              noteForContact: this.noteForContact,
              phoneNumberRegister: this.phoneNumberRegister,
              telNumberModel: this.telNumberModel,
            };
            existingVideoCallInvitations.push(newItem);
            store.setVideoCallInvitations(existingVideoCallInvitations);
            dispatchSuccessAlert(
              this.$t("components.inviteVisitor.saveSuccessText1") +
                " " +
                this.name +
                " " +
                this.$t("components.inviteVisitor.saveSuccessText2")
            );
          }
        }
      } else {
        this.userSaveLimit = true;
        this.showHideInfoModal();
      }
    },
    showHideInfoModal() {
      // clear name and email fields
      this.name = "";
      this.email = "";
      this.phoneNumberInput = "";
      this.phoneInputValid = true;
      this.showInfoModal = !this.showInfoModal;
    },
    async checkValuePayLink() {
      const settings = this.paypalSettings || (await getPayPalSettings());
      this.amountPayLink = parseFloat(
        this.amountPayLink.toString().replace("+", "").replace("-", "")
      );
      if (
        this.amountPayLink &&
        (!this.isPayEnabled || !settings || !settings.APIUser)
      ) {
        this.paypalError = true;
        this.amountPayLink = null;
        const data = {
          show: true,
          header: this.$t("generics.info"),
          body: this.$t("components.inviteVisitor.payLinkNote"),
        };
        return store.setinfoModal(data);
      } else {
        this.paypalError = false;
      }
      if (this.amountPayLink <= 0) {
        this.amountPayLink = null;
      } else if (this.amountPayLink > 10000) {
        this.amountPayLink = 10000;
      }
      if (this.amountPayLink && !this.paypalError) {
        this.paypalSettings = settings;
      }
    },
    keypressEvent(evt) {
      const keyCode = evt.which || evt.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        evt.preventDefault();
      }
    },
    checkValue() {
      if (this.durationMin > 120) {
        this.durationMin = 120;
      } else if (this.durationMin <= 0) {
        this.durationMin = "";
      }
    },
    saveCustomMessage(hideToaster) {
      this.setCustomInviteGuestMessage(this.inviteText);
      if (!hideToaster) {
        dispatchSuccessAlert(
          this.$t("components.invitesText.invitationTextSaved")
        );
      }
    },
    resetCustomMessage(showToaster) {
      this.inviteText = this.$t("components.invitesText.defaultGuestText");
      this.saveCustomMessage(true);
      this.customTextSelected = null;
      if (showToaster) {
        dispatchSuccessAlert(
          this.$t("components.inviteVisitor.invitationTextWasReset")
        );
      }
    },
    setCustomInviteGuestMessage(value) {
      return store.setCustomInviteGuestMessage(value);
    },
    formatDate(dateString) {
       // Parse the date string into a Date object
        const date = new Date(dateString);

      // Get day, month, and year from the Date object
      const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if necessary
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, add 1
      const year = date.getFullYear();

      // Return the formatted date string
      return `${day}.${month}.${year}`;
      // console.log('date', date)
      // if (!date) return null;

      // const [year, month, day] = date.split("-");
      // return `${day}.${month}.${year}`;
    },
    async precheckNewVisitor() {
      const foundInMyGuest = Object.entries(this.state.persisted.userVisitors || {}).reduce((acc, [uuid, entry]) => {
        if (!(uuid in this.state.group)) {
          store.setUserVisitor(uuid, undefined);
        } else if (entry && entry.email && entry.email.trim() === this.email.trim()) {
          acc.push(entry);
        }
        return acc;
      }, []);
      findUserEmailEvent(this.email);
      await this.$nextTick(); // Wait for effector event
      if (this.foundExistingEmail.length === 0 && foundInMyGuest.length === 0) {
        await this.handleNewVisitor();
        this.emailExists = false;
      } else {
        if (foundInMyGuest[0]) {
          if (store.state.group[foundInMyGuest[0].visitorId]?.paylinkInfo) {
            setDeleteVisitorPaylinkModalEvent({showModal:foundInMyGuest[0].visitorId, isPaylink:true});
            return;
          } else if (!store.state.group[foundInMyGuest[0].visitorId]?.paylinkInfo && this.amountPayLink){
            setDeleteVisitorPaylinkModalEvent({showModal:foundInMyGuest[0].visitorId, isPaylink:false});
          } else {
            if (
              this.name !== foundInMyGuest[0].name ||
              this.linkDateTo !== foundInMyGuest[0].linkDateFrom ||
              this.linkTimeTo !== foundInMyGuest[0].linkTimeFrom ||
              this.linkDateTo !== foundInMyGuest[0].linkDateTo ||
              this.noteForContact !== foundInMyGuest[0].noteForContact ||
              moment(this.linkTimeTo, "HH:mm").add(
                this.getDurationMin).format('HH:mm') !== foundInMyGuest[0].linkTimeTo
            ) {
              setRemoteUser(foundInMyGuest[0].visitorId, {
                name: this.name,
                visitorData: {
                  name: this.name,
                  linkDateFrom: this.linkDateTo,
                  linkTimeFrom: this.linkTimeTo,
                  linkDateTo: this.linkDateTo,
                  linkTimeTo: moment(this.linkTimeTo, "HH:mm").add(
                    this.getDurationMin,
                    "minutes"
                  ).format('HH:mm'),
                  inviteText: this.finalInviteText() + "\n",
                  inviteType: this.typeInvitation,
                  created: Date.now(),
                  subject: await this.getSubjectFromType(),
                  noteForContact: this.noteForContact
                },
              }).then(() => {
                // correct subject
                let carboncopyData = this.carboncopy
                const extraOptions = {
                  carbonCopy: carboncopyData
                }
                this.SendLinkToVisitor(
                  foundInMyGuest[0].visitorId,
                  store.state.group[foundInMyGuest[0].visitorId].user.visitorData,
                  extraOptions
                );
                let extantEvent = this.timelineEvents.find((e) => 
                  e.users.indexOf(foundInMyGuest[0].visitorId) !== -1
                )
                if (extantEvent && extantEvent.start && extantEvent.end) {
                  extantEvent.start = (new Date(this.linkDateTo.split('.').reverse().join('-') + " " + this.linkTimeTo)).toISOString()
                  extantEvent.end = this.moment(extantEvent.start).add(
                      this.getDurationMin,
                      "minutes"
                    ).format()
                  updateTimelineItemEffect([extantEvent.uuid, extantEvent]);
                }
              });
            } else {
              let carboncopyData = this.carboncopy
              const extraOptions = {
                  carbonCopy: carboncopyData
                }
              this.SendLinkToVisitor(
                foundInMyGuest[0].visitorId,
                foundInMyGuest[0],
                extraOptions
              );
            }
          }
          this.closeInvitationForm();
          return;
        }
        if (this.foundExistingEmail.length > 0) {
          this.errMssg = true;
          this.emailExists = true;
        }
      }
    },
    getSubjectFromType() {
      let formattedSub = "";
      if (this.typeInvitation === "asap") {
        formattedSub =
          this.subject || this.$t("components.invitesText.inviteSubjectASAP");
      } else if (this.typeInvitation === "soon") {
        formattedSub =
          this.subject || this.$t("components.invitesText.inviteSubjectSoon");
      } else if (this.typeInvitation === "appointment") {
        formattedSub =
          this.subject || this.$t("components.inviteVisitor.inviteSubject");
      }
      return formattedSub;
    },
    async saveData(confInfo) {
      let meetingId = null;
      let dateTimeEnd;
      let isUnlimited = false;
      if (!this.canAddEvent) throw new Error("Not allowed");
      const dateTimeStart = new Date(this.dateTo);
      const [hours, minutes] = this.linkTimeTo.split(":");
      dateTimeStart.setHours(parseInt(hours));
      dateTimeStart.setMinutes(parseInt(minutes));
      if (this.durationMin) {
        const dateTimeStart2 = new Date(this.dateTo);
        const [hours, minutes] = this.linkTimeTo.split(":");
        dateTimeStart2.setHours(parseInt(hours));
        dateTimeStart2.setMinutes(parseInt(minutes));
        const durationMs = parseInt(this.getDurationMin) * 60000;
        dateTimeEnd = new Date(dateTimeStart2.getTime() + durationMs);
        // dateTimeEnd =
        //   new Date(this.dateTo + " " + this.linkTimeTo).getTime() +
        //   parseInt(this.getDurationMin) * 60000;
      } else {
        // unlimited
        isUnlimited = true;
        const dateTimeEnd = new Date(this.dateTo);
          const [hours, minutes] = this.linkTimeTo.split(":");
          dateTimeEnd.setHours(parseInt(hours));
          dateTimeEnd.setMinutes(parseInt(minutes));
      }
      const tmpData = {
        name: confInfo.conferenceName || this.$t("components.conferenceForm.newConference"),
        title: confInfo.title || this.$t("components.conferenceForm.newConference"),
        details: "",
        users: confInfo.confUsers,
        start: new Date(dateTimeStart),
        end: new Date(dateTimeEnd),
        isUnlimited: isUnlimited,
        eventType: "guesscall",
        color: "orange",
        type: "conference call",
        groupId: confInfo.conferenceId,
        host: store.state.user.uuid,
      };
      tmpData.rsvp = false;
      console.log('tmpData', tmpData)
      const meeting = await createTimelineEntryEffect(tmpData);
      meetingId = meeting.uuid;
      return meetingId;
    },
    async createGroup(confInfo) {
      let newGroup = {};
      let tempArr = [];
      const dateTimeStart = new Date(this.dateTo + " " + this.linkTimeTo);
      newGroup.id = confInfo.conferenceId;
      newGroup.newGroupName =
        confInfo.conferenceName.trim() || this.$t("components.conferenceForm.newConference");
      newGroup.title = confInfo.conferenceName.trim() || this.$t("components.conferenceForm.newConference");
      confInfo.confUsers.forEach((element) => {
        const data = {
          name: ((store.state.group[element] || {}).user || {}).name || "Anonymous",
          uuid: element,
          addUserToCall: true,
        };
        if (element) tempArr.push(data);
      });
      newGroup.members = tempArr;
      newGroup.owner = (store.state.user || {}).name || "Anonymous";
      newGroup.ownerUUID = store.state.ownUUID;
      newGroup.isTemplate = false;
      newGroup.isConferenceWaitingRoom = true;
      newGroup.timelineConference = "";
      newGroup.pinProtected = false;
      if (dateTimeStart && dateTimeStart.length > 5) {
        newGroup.dateTimeStart = dateTimeStart;
        newGroup.durationMin = this.getDurationMin;
      }
      store.state.user.userGroups.push(newGroup);
    },
    async createConference(confInfo) {
      newConferenceEvent(confInfo);
    },
    async handleNewVisitor(name = null, email = null, mobilePhone = null) {
      const myUUIDS = [];
      this.sideBarSubject = this.subject;
      this.isforMail = true;
      let odata = {}
      let options = {}
      try {
        const { key, found } = this.email
          ? await isEmailRegistered(this.email)
          : { key: uuidv4(), found: false };
        if (found && !((this.state.group[key] || {}).user || {}).guest) {
          await sendInviteMailCustom(key, {
            name: this.state.group[key].user.email,
            email: this.email,
            guest: this.state.group[key].user.guest,
          });
          this.lastUuid = this.state.group[key].user.uuid;
        } else {
          if (!this.lastUuid || this.lastEmail !== this.email) {
            this.lastUuid = uuidv4();
          }
          const formattedSubject = await this.getSubjectFromType();
          odata = {
            visitorId: this.lastUuid,
            name: this.name,
            email: this.email || "",
            mobilePhone: this.phoneNumberRegister || "",
            function: this.visFunction,
            inviteText: this.finalInviteText() + "\n",
            subject: formattedSubject,
            sideBarSubject: this.sideBarSubject,
            subjectDisplay: formattedSubject,
            created: Date.now(),
            linkDateFrom: this.linkDateTo,
            linkTimeFrom: this.linkTimeTo,
            linkDateTo: this.linkDateTo,
            linkTimeTo: moment(this.linkTimeTo, "HH:mm").add(
              this.getDurationMin,
              "minutes"
            ).format('HH:mm'),
            internalnote: this.internalnote,
            noteForContact: this.noteForContact,
            instalink: this.instalink,
            duration: this.getDurationMin,
            tariff: this.price,
            isSupportCall: this.isSupportCall,
            isWaitingRoom: this.isWaitingRoom,
            isOneTime: this.isOneTime,
            inviteType: this.typeInvitation,
            inviters: {
              [this.isAssistsInviteForm
                ? this.isAssistsInviteForm
                : this.ownUUID]: true,
            },
            userInviter: this.isAssistsInviteForm
              ? this.isAssistsInviteForm
              : this.ownUUID,
          };
          odata.conferenceId = null;
          if (this.multiUser) {
            odata.conferenceId = this.conferenceId;
            odata.conferenceTitle = this.conferenceTitle;
          }
          odata.variables = {
            "%APPOINTMENT_FROM%": this.linkDateFrom
              ? this.$t("components.inviteVisitor.appointment") +
                " " +
                this.linkDateFrom +
                " " +
                this.$t("components.inviteVisitor.at") +
                " " +
                this.linkTimeFrom
              : "",
            "%APPOINTMENT_EXPIRES%": this.linkDateTo
              ? this.$t("components.inviteVisitor.expires") +
                " " +
                this.linkDateTo +
                " " +
                this.$t("components.inviteVisitor.at") +
                " " +
                this.linkTimeTo
              : "",
          };
          options =
            this.pinCode || this.complexPinActive
              ? { "2fa": { pin: { code: this.pinCode } } }
              : {};
          if (this.amountPayLink) {
            options.paylink = true;
            options.amount = `${this.amountPayLink}`.replace(",", ".");
          }
          const inviteLink = await createVisitor(odata, {
            sendMail: false,
            carbonCopy: this.carboncopy,
            ...options,
          });
          if (this.phoneNumberRegister && this.phoneNumberRegister.length) {
            // if have phone number send a whatsapp #2983
            await sendInviteWhatsApp(odata.visitorId, {
              ...options,
            });
          }
          if (myUUIDS.indexOf(odata.visitorId) === -1) {
            myUUIDS.push(odata.visitorId);
          }
          odata.instalink = this.instalink = inviteLink;
          this.isforMail = false;
          if (this.isAssistsInviteForm) {
            await this.doTransferVisitor(
              this.isAssistsInviteForm,
              odata.visitorId,
              this.isWaitingRoom ? "isWaitingRoom" : ""
            );
          } else {
            store.setUserVisitor(odata.visitorId, odata);
          }
          if (this.price != "") {
            // update user tariffs if needed
            store.setUserOneCallTariff(this.price);
          }
          if (!this.multiUser) {
            // update persisted options if any
            this.updateAppntOptions();
          }
        }

        if (!this.multiUser) {
          this.conferenceId = uuidv4();
          const confInfo = {
            conferenceId: this.conferenceId,
            conferenceName: this.subject,
            confUsers: myUUIDS,
            timelineConference: "",
            title: this.$t("components.inviteGuestPanel.subjectInvite", [this.state.user.name, this.name])
          };
          await this.createConference(confInfo);
          await this.createGroup(confInfo);
          // create event if dates
          if (this.canAddEvent) {
            const timelineConference = await this.saveData(confInfo);
            if(timelineConference) options.eventUUID = timelineConference
            // update group information
            let extant = store.state.user.userGroups.find(
              (e) => e.id == confInfo.conferenceId
            );
            if (extant && extant.id) {
              store.state.user.userGroups.find(
                (e) => e.id == confInfo.conferenceId
              ).timelineConference = timelineConference;
            }
          }
          this.lastEmail = this.email;
          // this.lastUuid = "";
        }
        if (Object.keys(odata).length) {
          await sendVisitorEmail(odata, { carbonCopy: this.carboncopy, ...options })
            .then(() => {
              if (!this.multiUser) {
                dispatchSuccessAlert(
                  Vue.prototype.$t("components.inviteVisitor.successSent")
                );
              }
            })
            .catch((err) => {
              dispatchErrorAlert(err.message || err);
            });
        }
      } catch (err) {
        console.warn("handeNewVisitor", err, err.code);
      }
      this.createVisitorBusy = false;
      if (!this.multiUser) {
      } else {
        return this.lastUuid;
      }
      this.clearForm();
      this.goWaitingRoom();
    },
    async doTransferVisitor(transferTo, transferUUID, visitorType) {
      const result = await transferVisitor(transferTo, transferUUID);
      if (result) {
        const visitor = store.state.group[transferUUID].user && JSON.parse(JSON.stringify(store.state.group[transferUUID].user));
        if (!visitor) return;
        const extantWaitingRoomUser = await isWaitingRoomUser(transferUUID);
        if (extantWaitingRoomUser && visitorType !== "isWaitingRoom") {
          visitor.visitorData.isWaitingRoom = false;
        }
        delete visitor.visitorData.inviters[store.state.ownUUID];
        visitor.visitorData.inviters[transferTo] = true;
        store.setUserVisitor(transferUUID, visitor.visitorData);
        delete store.state.persisted.userVisitors[transferUUID];
      }
    },
    updateAppntOptions() {
      let allOptions = this.myCombinedAppointmentOptions();
      if (
        this.appmtOptionselect &&
        allOptions.indexOf(this.appmtOptionselect) === -1
      ) {
        store.state.persisted.usersCustomAppointmentEntries.push(
          this.appmtOptionselect
        );
        store.setusersCustomAppointmentEntries(
          store.state.persisted.usersCustomAppointmentEntries
        );
      }
    },
    clearForm() {
      this.visitorId = null;
      this.title = "";
      this.name = "";
      this.company = "";
      this.email = null;
      this.phoneNumberInput = "";
      this.phoneInputValid = true;
      this.addToPlanner = false;
      this.subject = "";
      this.sideBarSubject = "";
      this.created = "";
      this.linkDateFrom = "";
      this.linkTimeFrom = "";
      this.linkDateTo = "";
      this.linkTimeTo = "";
      this.internalnote = "";
      this.instalink = "";
      this.noteForContact = "";
      this.durationMin = "";
      this.pinCode = null;
      this.invitateLink = undefined;
      this.price = "";
      this.isSupportCall = false;
      this.isOneTime = false;
      this.errMssg = false;
      this.appmtOptionselect = null;
      this.typeInvitation = null;
      this.inviteText = this.$t("components.invitesText.defaultGuestText");
    },
    clearDates(){
      this.durationMin = "";
      this.linkDateFrom = "";
      this.linkTimeFrom = "";
      this.linkDateTo = "";
      this.linkTimeTo = "";
    },
    myCombinedAppointmentOptions() {
      const baseOptions = [
        this.$t("components.inviteVisitor.shortly"),
        this.$t("components.inviteVisitor.immediately"),
        this.$t("components.inviteVisitor.tillsixpm"),
        this.$t("components.inviteVisitor.asap"),
        this.$t("components.inviteVisitor.morning"),
        this.$t("components.inviteVisitor.afterTomorrow"),
      ];
      const additionalOptions = (
        store.state.persisted.usersCustomAppointmentEntries || []
      ).filter((v) => !!v);
      const options = additionalOptions.length
        ? baseOptions.concat(additionalOptions)
        : baseOptions;
      return options;
    },
    hasUuidBeenOnPage(uuid) {
      const person = this.state.group[uuid];
      return ((person || {}).user || {}).rtcCallStart !== undefined;
    },
    fixedDetailText() {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      let line2 =
        this.$t("components.inviteVisitor.conferenceStartsAt") +
        " " +
        this.linkDateTo +
        " " +
        this.$t("components.inviteVisitor.at") +
        " " +
        this.linkTimeTo +
        " " +
        this.$t("components.meetingLineModal.hours") +
        ` (${tZone})` +
        "." +
        "\n";
      let line2_1 = "";
      if (this.getDurationMin) {
        line2_1 =
          line2_1 +
          "\n" +
          this.getDurationPhrase(this.getDurationMin) +
          " " +
          "\n";
      }
      let line3 =
        this.$t("components.inviteVisitor.callRequestedOn") +
        ": " +
        this.appmtOptionselect +
        "\n";
      let line4 =
        this.$t("components.inviteVisitor.linkGoodUntil") +
        " " +
        this.linkDateFrom +
        "\n";
      if (!this.linkDateTo || !this.linkTimeTo) {
        line2 = "";
      }
      if (!this.durationMin || this.durationMin == 0) {
        line2_1 = "";
      }
      if (!this.appmtOptionselect) {
        line3 = "";
      }
      if (!this.linkDateFrom) {
        line4 = "";
      }
      const line5 = this.amountPayLink
        ? this.$t("components.inviteVisitor.paylinkText", [
            this.amountPayLink,
            this.getCurrencySymbol,
          ]) + "\n"
        : "";
      let reqsLine = '';
      reqsLine +=
        this.$t("components.invitePreview.inviteGuestRequirements") +
        "\n" +
        this.$t("components.invitePreview.inviteGuestReqObjects") +
        "\n" +
        this.$t("components.invitePreview.inviteGuestVerify") +
        "\n" +
        this.$t("components.invitePreview.inviteGuestBrowsers") +
        "\n" +
        this.$t("components.invitePreview.inviteGuestBrowserConf") +
        "\n" +
        this.$t("components.invitePreview.inviteGuestRecomendation") +
        "\n" +
        this.$t("components.invitePreview.inviteGuestLinkPhrase") +
        "\n" ;

      if (!this.isforMail) {
        return line2 + line2_1 + line3 + line4 + line5 + reqsLine;
      } else if(this.typeInvitation === "asap" || this.typeInvitation === "soon"){
        return line3 + line4 + line5;
      } else {
        return line2 + line2_1 + line3 + line4 + line5 + reqsLine;
      }
    },
    finalInviteText() {
      let inviteText = this.inviteText || ""; // Free Text
      const hasDynamicTextTag = inviteText.length && this.inviteText.indexOf("%DYNAMIC_TEXT%") !== -1;
      const hasInviteLinkVariable = inviteText.length && this.inviteText.indexOf("%INVITE_LINK%") !== -1;
      const fixedDetailText = this.fixedDetailText() || ""; // Information
      if (hasDynamicTextTag && fixedDetailText.length) {
        const fixedLink = hasInviteLinkVariable ? "" : "%INVITE_LINK%\n\n";
        inviteText = inviteText
          .replace("%DYNAMIC_TEXT%", `${fixedLink}${fixedDetailText}`)
          .replace("%DYNAMIC_TEXT%", "");
      }
      if (hasDynamicTextTag) {
        inviteText = inviteText.replace(/\s*%DYNAMIC_TEXT%\s*/g, "\n\n");
      } else {
        const fixedLink = hasInviteLinkVariable ? "" : "%INVITE_LINK%\n\n";
        inviteText = inviteText.trim() + "\n\n" + fixedLink + fixedDetailText;
      }
      return inviteText.trim();
    },
    getHeaderTitle() {
      if (!this.isAssistsInviteForm) {
        const titel =
          ((store.state.group[this.ownUUID] || {}).user || {}).titel || "";
        const uName =
          titel +
          " " +
          (((store.state.group[this.ownUUID] || {}).user || {}).name ||
            "Anonymous");
        return this.$t("components.waitingRoom.myWaitinRoomTT", [uName]);
      } else {
        const titel =
          ((store.state.group[this.isAssistsInviteForm] || {}).user || {})
            .titel || "";
        const uName =
          titel +
          " " +
          (((store.state.group[this.isAssistsInviteForm] || {}).user || {})
            .name || "Anonymous");
        return this.$t("components.waitingRoom.myWaitinRoomTT", [uName]);
      }
    },
    getDurationPhrase(num) {
      let hours = num / 60;
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      if (rhours === 1 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHourMinute", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours === 1 && rminutes > 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHourMinutes", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours > 1 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHoursMinute", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours > 1 && rminutes > 1)
        return (
          this.$t(
            "components.invitePreview.inviteAllLineDurationHoursMinutes",
            [rhours, rminutes]
          ) + "."
        );
      if (rhours === 0 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationMinute", [
            rminutes,
          ]) + "."
        );
      if (rhours === 0 && rminutes > 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDuration", [
            rminutes,
          ]) + "."
        );
      if (rhours === 1 && rminutes === 0)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHour", [
            rhours,
          ]) + "."
        );
      if (rhours > 1 && rminutes === 0)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHours", [
            rhours,
          ]) + "."
        );
    },
  },
  computed: {
    getCheckboxDisabled(){
      if ( this.customTextSelected == null || this.customTextSelected == undefined ) return false;
      this.resetCheckbox();
      return true;
    },
    hideElements() {
      if (this.$vssWidth < 1315) {
        return true;
      }
      return false;
    },
    isAssistsInviteForm() {
      if (
        this.$route.path.includes("/invitationform/") &&
        this.$route.params.id
      ) {
        return this.$route.params.id;
      } else {
        return false;
      }
    },
    getSelectedTitle() {
      return this.templateSearch;
    },
    disabledSaveText() {
      if (
        this.isTextEmpty ||
        (this.getCustomInviteTexts.length >= 20 && !this.customTextSelected)
      ) {
        return true;
      }
      return false;
    },
    updateText() {
      if (
        (this.originalInviteText && this.originalInviteText.trim()) !=
        (this.inviteText && this.inviteText.trim())
      ) {
        return true;
      }
      return false;
    },
    getDurationMin() {
      // Check if this.durationMin is not set, or doesn't have the expected pattern.
      if (!this.durationMin || !/^\d{1,2}:\d{2}$/.test(this.durationMin)) {
        return null;
      } else {
        // Parse the duration minutes and seconds from this.durationMin
        const durationParts = this.durationMin.split(':');
        const durationMinutes = parseInt(durationParts[0], 10);
        const durationSeconds = parseInt(durationParts[1], 10);
        
        // Convert duration to total minutes
        const totalDuration = durationMinutes + (durationSeconds / 60);

        // Assuming this.linkTimeTo is a valid time string in the format "HH:MM"
        const timeParts = this.linkTimeTo.split(':');
        if (timeParts.length !== 2) {
          // Handle invalid linkTimeTo format
          throw new Error("Invalid time format in linkTimeTo.");
        }
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);

        // Create date object for the start time and set its hours and minutes
        const startTime = new Date(this.dateTo);
        startTime.setHours(hours);
        startTime.setMinutes(minutes);

        // Calculate the end time by adding the total duration in milliseconds
        const endTime = new Date(startTime.getTime() + totalDuration * 60000);

        // Get the actual duration in minutes between start time and end time
        const durationInMinutes = (endTime - startTime) / 60000; // This will equal totalDuration

        return durationInMinutes;
      }
    },
    getCurrencySymbol() {
      switch (this.state.namespaceSettings.currency) {
        case "EUR":
          return "€";
        case "GBP":
          return "£";
        case "CHF":
          return "₣";
        default:
          return "€";
      }
    },
    isPayAvailable() {
      // See also websrc/components/modal/newAdminSettingsModal.vue isPayAvailable
      return webLicensedBaseFeatures.isVisitorPayAvailable;
    },
    isPayEnabled() {
      // See also websrc/components/content/calls/remoteButtonsBar.vue isPayEnabled
      return (
        webLicensedBaseFeatures.isVisitorPayAvailable &&
        this.state.namespaceSettings.paySetting
      );
    },
    showTimeline() {
      if (
        this.$route.path === "/invitationformuser" ||
        this.$route.path === "/invitationform"
      ) {
        return false;
      }
      return true;
    },
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    isTextEmpty() {
      return this.inviteText == "" || this.inviteText.trim().length === 0;
    },
    getVisitors() {
      const inviterUUID = this.isAssistsInviteForm || this.ownUUID;
      const visitors = Object.keys(store.state.group)
        .filter(
          (uuid) =>
            store.state.group[uuid] &&
            store.state.group[uuid].user &&
            store.state.group[uuid].user.visitorData &&
            store.state.group[uuid].user.visitorData.userInviter == inviterUUID &&
            !store.state.group[uuid].connected
        )
        .map((uuid) => ({
          ...store.state.group[uuid].user,
          uuid,
          visitorId: uuid,
        }));
      if (!this.isAssistsInviteForm) {
        Object.keys(store.state.persisted.userVisitors)
          .filter(
            (uuid) =>
              !!store.state.persisted.userVisitors[uuid] &&
              store.state.group[uuid] &&
              !store.state.group[uuid].connected
          )
          .map((uuid) => ({
            ...store.state.persisted.userVisitors[uuid],
            uuid,
            visitorId: uuid,
          }))
          .forEach((entry) => {
            const idx = visitors.findIndex((e) => e.uuid === entry.uuid);
            if (idx >= 0) {
              visitors[idx] = entry;
            } else {
              visitors.push(entry);
            }
          });
      }
      this.getTotalPaid(visitors);
      return visitors.sort((a, b) => {
        const aDate = (a.visitorData && a.visitorData.created) ? a.visitorData.created : a.created;
        const bDate = (b.visitorData && b.visitorData.created) ? b.visitorData.created : b.created;
        if (aDate < bDate) return 1;
        if (aDate > bDate) return -1;
        // If dates are equal, fallback to comparing UUIDs
        const aUUID = a.uuid || "";
        const bUUID = b.uuid || "";
        if (aUUID < bUUID) return -1;
        if (aUUID > bUUID) return 1;
        return 0; // They are equal
      });
    },
    isEmailInvalid() {
      if (this.email && this.email.length > 0) {
        const email = this.email.trim().toLowerCase();
        const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
        return !re.test(email);
      } else {
        return true;
      }
    },
    isWhatsAppInviteAvailable() {
      // See also isWhatsAppInviteAvailable @ websrc/components/timeline/conferencesDirectCallMask.vue
      // https://gitlab.ra-micro.de/devcups/voffice/-/issues/401
      return false;
      /*
      if (process.env.PKG) {
        return false;
      } else {
        return true;
      }
      */
    },
    virtualOfficePrefix() {
      return (
        this.state.namespaceSettings.companyInfo.virtualOfficePrefix ||
        store.getVirtualOfficePrefix()
      );
    },
    canAddEvent() {
      return this.dateTo !== "" && this.linkTimeTo;
    },
    getCustomInviteTexts() {
      const customTexts = this.state.namespaceSettings.inviteGuestCustomTexts;
      return customTexts;
    },
    filteredInvitations() {
      const myInvitations = store.state.user.videoCallInvitations || [];
      return myInvitations.filter((t) => {
        if (t.email) return t;
      });
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    disableSendButton() {
      if (
        (this.name || "").trim() == "" ||
        /* Test email */ this.email == "" ||
        !this.email ||
        this.email.length < 5 ||
        this.isEmailInvalid ||
        this.inviteText.trim().length == 0 ||
        this.errMssg ||
        !this.phoneInputValid ||
        (this.paypalError && this.amountPayLink) ||
        !this.checkPin()
      ) {
        return true;
      }
      if (this.fixedDate) {
        if (this.linkDateTo.length < 3 || !this.linkTimeTo) {
          return true;
        }
      }
      return false;
    },
    combinedAppointmentOptions() {
      const options = this.myCombinedAppointmentOptions();
      return options;
    },
  },
};
</script>

<style lang="scss">
// Labels aside checkboxes
.v-input__control{
  .v-selection-control{
    .v-label{
      margin-left: 0.25em;
    }
  }
}
.selectTimeWidthPadd,
.selectDurationWidthPadd{
  .v-input__control{
    // Icons inside inputs
    .v-field{
      .v-field__field{
        .v-field__input{
          margin-left: 64%;
        }
      }
    }
  }
}
.selectDateWidthPadd {
  .v-input__control{
    // Icons inside inputCalendar icon-field{
    .v-field__field{
      .v-field__input{
        margin-left: 75%;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.mt-18{
  margin-top: 4.5em;
}
.facePayImg {
  height: 30px;
  width: 30px;
}
.textCenterd {
  text-align: center;
}
.maxwidth150 {
  max-width: 150px;
}
.maxWidth {
  widows: 70px;
  max-width: 70px;
}
.relative {
  position: relative;
}
.noteStyle {
  font-weight: 300 !important;
}
.deleteContactBasket {
  font-size: 20px;
  position: "absolute";
  top: 25;
  right: 25;
}
.focusStyle:focus-within {
  border: 2px solid var(--v-primary-color);
  border-radius: 4px;
  box-shadow: none;
}
.vue-tel-input :focus::-webkit-input-placeholder {
  visibility: hidden; /*hide placeholder on focus*/
}
.focusStyle:focus-within + .customLabelTlf {
  display: block;
}
.bgBlack {
  background: #1e1e1e;
}
.bgWhite {
  background: #fff;
}
.customLabelTlf {
  display: none;
  position: absolute;
  left: 11px;
  top: -11px;
  padding: 0 3px;
  font-size: 16px;
  letter-spacing: normal;
  transform: scale(0.75);
  color: #2a3133;
}
.vue-tel-input {
  height: 40px !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
}
.contactStyle {
  width: 100%;
}
.InviteFormContainerMobile .v-card, .InviteFormContainer .v-card {
  box-shadow: none;
}
.InviteFormContainer {
  height: 100%;
  overflow: auto;
}
.InviteFormContainerMobile {
  height: calc(100% - 10%);
  overflow: auto;
}
.selectDateWidthPadd {
  width: 46%;
  // padding-right: 2%;
}
.selectTimeWidthPadd,
.selectDurationWidthPadd {
  width: 25%;
  margin-left: 2%;
}
.visitorListTable {
  height: calc(100vh - 33%);
  overflow-y: auto;
}

.greenClass {
  font-weight: 800;
  color: green;
}
.Table {
  padding-top: 6px;
  height: 50px;
}
.ListAvatar {
  width: 5%;
  height: 100%;
  // max-width: 71px;
  min-width: 47px;
}
.AvatarImage {
  max-height: 40px;
  height: 100%;
  max-width: 40px;
}
.ListMessage {
  width: 4%;
  height: 100%;
  // max-width: 48px;
  min-width: 40px;
}
.ListName {
  width: 25%;
  height: 100%;
  overflow: hidden;
  // max-width: 306px;
  min-width: 236px;
}
.ListEmail {
  width: 24%;
  height: 100%;
  overflow: hidden;
  // max-width: 393px;
  min-width: 226px;
}
.listDate {
  width: 20%;
  height: 100%;
  overflow: hidden;
  line-height: 15px;
  // max-width: 244px;
  min-width: 188px;
}
.ListResend {
  width: 4%;
  height: 100%;
  min-width: 36px;
  // max-width: 48px;
}
.ListCopy {
  width: 4%;
  height: 100%;
  min-width: 36px;
  // max-width: 48px;
}
.ListDelete {
  width: 4%;
  height: 100%;
  min-width: 36px;
  // max-width: 48px;
}
.ListCheckbox{
  width: 4%;
  height: 100%;
  min-width: 36px;
  // max-width: 48px;
}
.ListPayLink {
  width: 10%;
  height: 100%;
  display: flex;
  vertical-align: middle;
  min-width: 75px;
  // max-width: 131px;
}
.buttonsTextarea {
  margin-top: -30px;
}
/*old check*/
.positionRelative {
  position: relative;
}
.v-application .mt-5 {
  margin-top: 5px !important;
}
.labelTitle {
  font-size: 13px !important;
}
.v-theme--dark .footerRow {
  background-color: #1f1f1f;
}
.v-theme--light .footerRow {
  background-color: #ffffff;
}

.v-theme--dark .labelTitle {
  color: #ffffff;
}
.v-theme--light .labelTitle {
  color: #212121;
}
.calendarIcon {
  font-size: 20px;
  cursor: pointer;
}
.invitationMenuList {
  width: calc(100vw - 0px);
  padding-top: 0px;
  max-width: 1024px;
  max-height: 820px;
}
.pre {
  white-space: pre;
}
.v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  height: 40px !important;
  font-size: 30px !important;
}
.autoscroll {
  overflow-y: auto;
}
.cursorPointer {
  cursor: pointer;
}
.v-menu__content {
  min-width: unset !important;
}
.AvatarImage .v-image__image {
  border-radius: 4px;
}
.listTitle {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}
.listSubtitle {
  font-size: 15px !important;
  font-weight: 300 !important;
}
.inviteTextAreaClass textarea {
  align-self: auto !important;
  line-height: 1.5rem !important;
  padding-right: 30px !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
  margin-bottom: 15px;
  max-height: calc(100vh - 431px) !important;
  overflow-y: auto !important;
}
.inviteTextAreaClass {
  & .v-input__slot {
    height: auto !important;
    max-height: none;
    min-height: 300px !important;
  }
}
.__view {
  width: unset !important;
}
.v-theme--light.v-input textarea {
  &::placeholder {
    color: black !important;
  }
}
.v-theme--dark.v-input textarea {
  &::placeholder {
    color: white !important;
  }
}
.checkCallPeriod {
  & .v-input__control {
    max-height: 35px;
  }
}
.checkCallPeriod {
  margin-top: 0px !important;
  padding-top: 0px !important;
  max-height: 25px;
}
</style>