<template>
    <div class="px-0 py-0">
      <v-row class="px-3">
        <v-col v-if="calendarApp" class="font-weight-bold">
          <div v-if="typeView === 'month'">{{ $t("components.workingTime.month") }}: {{timeCalculation}}</div>
          <div v-if="typeView === 'week'">{{ $t("components.workingTime.week") }}: {{timeCalculation}}</div>
          <div v-if="typeView === 'day'">{{ $t("components.workingTime.day") }}: {{timeCalculation}}</div>
        </v-col>
        <v-col class="text-right">
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                class=""
               :color="isDark ? 'white':'primary'"
                density="compact"
                variant="text"
                icon
                @click="changeCalendarTypeView('month')"
              >
                <font-awesome-icon
                  :icon="['fal', 'calendar']"
                  :style="{ fontSize: '25px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.workingTime.monthlyView") }}</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                class=""
                v-bind="props"
               :color="isDark ? 'white':'primary'"
                density="compact"
                variant="text"
                icon
                @click="changeCalendarTypeView('week')"
              >
                <font-awesome-icon
                  :icon="['fal', 'calendar-week']"
                  :style="{ fontSize: '25px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.workingTime.weeklyView") }}</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                class="mr-2"
               :color="isDark ? 'white':'primary'"
                density="compact"
                variant="text"
                icon
                @click="changeCalendarTypeView('day')"
              >
                <font-awesome-icon
                  :icon="['fal', 'calendar-day']"
                  :style="{ fontSize: '25px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.workingTime.dayView") }}</span>
          </v-tooltip>
          <!-- <v-btn outlined class="" color="grey darken-2" @click="value = ''"> -->
          <v-btn
            v-if="showAddMoreTimeBtn"
            class="mr-4"
            color=""
            density="compact"
            variant="text"
            icon
            @click="addTimeModal()"
          >
            <font-awesome-icon
              :icon="['fal', 'plus']"
              :style="{ fontSize: '25px' }"
            />
          </v-btn>
          <v-btn variant="outlined" class="ml-2 mr-4" :color="isDark ?'white' : 'primary'" @click="setCalendarToday()">
            {{ $t("components.workingTime.today") }}
          </v-btn>
          <v-btn
            icon
            class="ml-2 btnCalendar"
            density="compact"
            variant="text"
            @click="setCalendarPreviousPage()"
          >
            <font-awesome-icon
              :icon="['fal', 'chevron-left']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
          <v-btn
            icon
            class="btnCalendar"
            density="compact"
            variant="text"
            @click="setCalendarNextPage()"
          >
            <font-awesome-icon
              :icon="['fal', 'chevron-right']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
          <span :class="isDark ?'text-white' : ''" class="text-subtitle-1 font-weight-bold">
            {{ calendarMonth }} {{ calendarYear }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <!-- filter table -->
        <v-col class="mx-2 calendarContainer">
          <v-card v-if="calendarApp" variant="text" class="h100" id="calendarCard">
              <!--
                VUE3 calendar implementation
               -->
                <!-- <div v-if="calendarApp" class="h100 w100 border-none"> -->
                <ScheduleXCalendar
                    :calendar-app="calendarApp"
                  >
                  <!-- TimeGrid event slot -->
                  <template #timeGridEvent="{ calendarEvent }">
                    <v-menu :id="calendarEvent.id" location="top" min-width="150px">
                      <template v-slot:activator="{ props }">
                        <div v-bind="props"
                        class="eventTemplate text-white px-2"
                        :style="{ backgroundColor: getEventColor(calendarEvent) }"
                        v-tooltip.top="`${ calendarEvent.title }, ${getFormatedTime(calendarEvent.start)} - ${getFormatedTime(calendarEvent.end)}`">
                          {{ calendarEvent.title }}, {{ getFormatedTime(calendarEvent.start) }} - {{ getFormatedTime(calendarEvent.end) }}
                        </div>
                      </template>
                      <!-- <span>{{ getFormatedTime(calendarEvent.start) }} - {{ getFormatedTime(calendarEvent.end) }}</span> -->
                      <v-card v-if="(calendarEvent.type &&  calendarEvent.type === 'custom' &&
                                      (selectedUser === state.ownUUID || amIAdmin)) !== undefined"
                                      color="grey-lighten-4" flat @click.stop>
                        <v-toolbar density="compact">
                          <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                          <v-toolbar-title
                            :style="{ fontSize: '15px!important' }"
                            v-html="$sanitize(calendarEvent.title)"
                          ></v-toolbar-title>
                          <!-- <v-spacer></v-spacer> -->
                          <DeleteBasket
                            :indexData="calendarEvent"
                            :delFunction="removeEvent"
                            extraClasses="cursorPointer mx-3"
                            fontSize="18"
                          />
                        </v-toolbar>
                      </v-card>
                    </v-menu>
                  </template>
                  <!-- MonthGrid event slot -->
                  <template #monthGridEvent="{ calendarEvent }">
                    <v-menu :id="calendarEvent.id" location="end" min-width="150px">
                      <template  v-slot:activator="{ props }">
                        <div
                          v-bind="props"
                          class="eventTemplate text-white px-2"
                          :style="{ backgroundColor: getEventColor(calendarEvent) }"
                          v-tooltip.top="`${ calendarEvent.title }, ${getFormatedTime(calendarEvent.start)} - ${getFormatedTime(calendarEvent.end)}`"
                        >
                          {{ calendarEvent.title }}, {{ getFormatedTime(calendarEvent.start) }} - {{ getFormatedTime(calendarEvent.end) }}
                        </div>
                      </template>
                      <v-card v-if="(calendarEvent.type &&  calendarEvent.type === 'custom' &&
                                      (selectedUser === state.ownUUID || amIAdmin)) !== undefined"
                                      color="grey-lighten-4" flat @click.stop>
                        <v-toolbar density="compact">
                          <v-toolbar-title
                            :style="{ fontSize: '15px!important' }"
                            v-html="$sanitize(calendarEvent.title)"
                          ></v-toolbar-title>
                          <!-- <v-spacer></v-spacer> -->
                          <DeleteBasket
                            :indexData="calendarEvent"
                            :delFunction="removeEvent"
                            extraClasses="cursorPointer mx-3"
                            fontSize="18"
                          />
                        </v-toolbar>
                      </v-card>
                    </v-menu>
                  </template>
                </ScheduleXCalendar>
                <!-- </div> -->

               <!--
                 END VUE3 calendar implementation
               -->
          </v-card>
        </v-col>
      </v-row>
      <template>
        <v-dialog
          :model-value="showAddTimeModal" @update:model-value="showAddTimeModal = $event"
          persistent
          max-width="560"
          @keydown.esc="closeModal"
        >
          <v-card>
            <HeaderModal
              :titleModal="`${$t('components.workingTime.workingTime')} ${formatDateTitle(calendarSelectedValue)}`"
              :closeModalFunction="closeModal"
            />
            <v-card-text class="mx-auto py-2">
              <div>
                <v-row>
                  <v-col cols="6" class="pb-0 pl-4">
                    <v-menu
                      ref="menuStartPicker"
                      :model-value="menuStartPicker" @update:model-value="menuStartPicker = $event"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="startTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          @keydown.space="(event) => event.preventDefault()"
                          :model-value="startTime" @update:model-value="startTime = $event"
                          type="time"
                          class="timePicker"
                          variant="outlined"
                          density="compact"
                          v-bind="props"
                          hide-details
                          :label="$t('components.conferenceForm.from')"
                        ></v-text-field>
                        <!-- @blur="$refs.menuStartPicker.save(startTime)" -->
                      </template>
                      <v-time-picker
                        v-if="menuStartPicker"
                        format="24hr"
                        :model-value="startTime" @update:model-value="startTime = $event"
                        full-width
                        @update:minute="handleMinuteClick($event)"
                        @update:hour="autocompleteMinutes($event)"
                        min="00:00"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="pb-0 pr-4">
                    <v-menu
                      ref="menuEndPicker"
                      :model-value="menuEndPicker"
                      @update:model-value="menuEndPicker = $event"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="endTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          variant="outlined"
                          density="compact"
                          @keydown.space="(event) => event.preventDefault()"
                          :model-value="endTime"
                          @update:model-value="endTime = $event"
                          type="time"
                          class="timePicker"
                          :label="$t('components.conferenceForm.to')"
                          :disabled="!startTime"
                          hide-details
                          v-bind="props"
                        ></v-text-field>
                        <!-- @blur="$refs.menuEndPicker.save(endTime)" -->
                      </template>
                      <v-time-picker
                        v-if="menuEndPicker"
                        format="24hr"
                        :model-value="endTime" @update:model-value="endTime = $event"
                        full-width
                        @update:minute="handleMinuteClickEnd($event)"
                        @update:hour="autocompleteMinutesEnd($event)"
                        :min="startTime"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="pl-4 pt-4" cols="12">
                    <v-combobox
                      v-model="statusSelect"
                      :items="statusItems"
                      variant="outlined"
                      :label="$t('components.drawerContentCard.status')"
                      hide-details
                      density="compact"
                      @keydown="preventTyping"
                    >
                      <template v-slot:item="{ item, props }">
                        <div v-bind="props" class="text-subtitle-2 pointer mx-2 py-2"
                            :style="{ color: getStatusColor(item.value) + '!important' }">
                          <!-- {{ $t(`status.${item.title}`) }} -->
                            {{ getStatusTitleTranslated(item.title) }}
                        </div>
                      </template>
                      <template v-slot:selection="{ item }">
                        <div v-show="item.title" class="text-subtitle-2"
                            :style="{ color: getStatusColor(item.value) + '!important' }">
                          <!-- {{ $t(`status.${item.title}`) }} -->
                          {{ getStatusTitleTranslated(item.title) }}
                        </div>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col class="pt-0">
                    <v-textarea
                      class="mt-3"
                      variant="outlined"
                      :model-value="eventDetail" @update:model-value="eventDetail = $event"
                      no-resize
                      :label="$t('generics.info')"
                      outlined
                      counter="255"
                      maxlength="255"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <FooterModal :closeModalFunction="closeModal" :showClose="true">
              <v-btn
                color="primary"
                class="ml-2"
                @click="saveAddTimeModal"
                :disabled="!eventDetail.length || !endTime || !startTime || !statusSelect.length"
                >{{ $t("generics.save") }}</v-btn
              >
            </FooterModal>
            <v-spacer></v-spacer>
          </v-card>
        </v-dialog>
      </template>
    </div>
</template>

<script>
  // import stringify from 'json-stringify-safe';
  import { shallowRef } from 'vue';
  import moment from "../../../sharedsrc/moment";
  import store from "../../store";
  import { v4 as uuidv4 } from "uuid";
  import {
    readDailyTimeCountings,
    delCustomDailyTimeCounting,
    setCustomDailyTimeCounting,
  } from "../../lib/wsMsg";
  import { getTimezone, checkIsDateInRange } from "../../utils/basicFunctions";
  import { getColorByStatus } from "../../utils/color";
  import { colorStatus } from "../../utils/status";
  import HeaderModal from "../modal/modalComponents/headerModal.vue";
  import FooterModal from "../modal/modalComponents/footerModal.vue";
  import DeleteBasket from "../ui/deleteBasket.vue";
  import { ScheduleXCalendar } from '@schedule-x/vue';
  import { createCalendar, viewWeek, viewMonthGrid, viewDay } from '@schedule-x/calendar';
  import { createEventsServicePlugin } from '@schedule-x/events-service';
  import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls';
  import '@schedule-x/theme-default/dist/index.css';

  export default {
    props: ["selectedUser"],
    components: { HeaderModal, FooterModal, DeleteBasket, ScheduleXCalendar },
    data() {
      return {
        config: {},
        state: store.state,
        search: "",
        setCurrentContentVisile: store.setCurrentContentVisile,
        typeView: "month",
        addEventMenu: { showMenu: false, parentUuid: null },
        calendarMonth: "",
        calendarYear: "",
        calendarEvents: [],
        filteredEvents: [],
        weekday: [1, 2, 3, 4, 5, 6, 0],
        calendarSelectedValue: [new Date()],
        workingInputMenuStart: "",
        workingInputMenuEnd: "",
        workingInputStart: "",
        workingInputEnd: "",
        // items: ["Daily Work", "Pause"],
        items: [
          {
            name: "Daily work",
            disabled: false,
          },
          {
            name: "Pause",
            disabled: true,
          },
          {
            name: "Lunch",
            disabled: true,
          },
        ],
        workingTimeType: "Daily work",
        currentDay: "",
        temporalPreviewEvents: [],
        showAddTimeModal: false,
        menuStartPicker: false,
        menuEndPicker: false,
        startTime: null,
        endTime: null,
        eventDetail: "",
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        statusItems: ['Available', 'Out of Office', 'Break', 'Holidays'],
        statusSelect: '',
        calendarApp: shallowRef(null),
        eventsServicePlugin: null,
        calendarControls: null,
        timeCalculationOp: {
          // Available
          'green': 'plus',
          '#008000': 'plus', // green
          '#006400': 'plus', // dark green
          // Out of Office
          'yellow': 'custom',
          '#ffa500': 'custom', // orange
          '#ffac13': 'custom', // golden yellow
          '#ce7e00': 'custom', // burnt orange
          // Offline
          'gray': 'minus',
          // Break
          'red': 'minus',
          '#ff0000': 'minus', // red
          '#8b0000': 'minus', // dark red
        },
      };
    },
    watch: {
      isDark: {
        deep: true,
        handler(value) {
          if (this.calendarApp) {
            this.calendarApp.setTheme(value ? 'dark' : 'light');
          }
        },
      },
      addEventMenu: {
        deep: true,
        handler(value) {
          if (value.showMenu === false) this.temporalPreviewEvents = [];
        },
      },
      selectedUser: {
        immediate: true,
        handler(value) {
          if (value) {
            this.calendarEvents = [];
            this.eventsServicePlugin?.set(this.calendarEvents); // Clean events when navigating
            this.$nextTick(async () => {
              if (this.selectedUser === value) {
                const dataEvents = await this.getDataEvents(value);
                if (this.selectedUser === value) this.getEvents(dataEvents, value);
              }
            });
          }
        },
      },
      startTime: {
        handler(value) {
          if (this.endTime < value) {
            this.endTime = null;
          }
        },
      },
      calendarEvents: {
        immediate: true,
        deep:true,
        handler(events) {
          // We will create a new filtered array without the duplicates as per conditions.
          const filteredEvents = events.filter((event, _index, self) => {
            if (event.type === "custom") return true;
            // Check if there is another event of type custom:
            return self.findIndex(e => e.type === "custom" && (
              // That replaces the system event with start/end times within two minutes.
              ((this.timeCalculationOp[e.color] === "plus" || this.timeCalculationOp[e.color] === this.timeCalculationOp[event.color]) &&
              Math.abs(this.formatCalendarAsDate(e.start) - this.formatCalendarAsDate(event.start)) <= 120000 &&
              Math.abs(this.formatCalendarAsDate(e.end) - this.formatCalendarAsDate(event.end)) <= 120000) ||
              // That replaces the system Offline event with an overlapping custom Out of Office event.
              (this.timeCalculationOp[e.color] === "custom" && this.timeCalculationOp[event.color] === "minus" && e.start <= event.start && e.end >= event.end)
            )) === -1;
          });
          this.filteredEvents = filteredEvents;
        },
      }
    },
    mounted() {
      const localeLang = this.$locale.current();
      const todayDate = new Date();
      this.calendarSelectedValue = [todayDate];
      this.eventsServicePlugin = createEventsServicePlugin();
      this.calendarControls = createCalendarControlsPlugin();
      this.calendarApp = createCalendar({
        // ... views, events and other options
        views: [viewMonthGrid, viewWeek, viewDay],
        events: [],
        plugins: [this.eventsServicePlugin, this.calendarControls],
        /**
         * Set the language. List of supported languages: https://schedule-x.dev/docs/calendar/supported-languages
         * For support of further languages, please open a PR, adding your translations under the folder:
         * packages/translations/src/locales/xx-XX
         *
         * Defaults to 'en-US'
         * Adjusted to use ONLY 'es-ES', 'de-DE' or 'en-US' as fallback for any other thing
         *
         */
        locale: localeLang == 'de' || localeLang == 'es' ? `${localeLang}-${localeLang.toUpperCase()}` : 'en-US',

        /**
         * Set which day is to be considered the starting day of the week. 0 = Sunday, 1 = Monday, (...other days) 6 = Saturday
         * Defaults to 1 (Monday)
         */
        firstDayOfWeek: 1,

        /**
         * The preferred view to display when the calendar is first rendered.
         * all views that you import have a "name" property, which helps you identify them.
         * Defaults to the first view in the "views" array
         */
        defaultView: viewMonthGrid.name,

        /**
         * The default date to display when the calendar is first rendered. Only accepts YYYY-MM-DD format.
         * Defaults to today's date
         */
        selectedDate: moment(new Date()).format('YYYY-MM-DD HH:mm'),

        /**
         * Render the calendar in dark mode.
         * Defaults to false
         */
        isDark: this.isDarkMode,

        /**
         * Decides which hours should be displayed in the week and day grids. Defaults to midnight - midnight (a full day)
         * Can also be set to a "hybrid" day, such as { start: '06:00', end: '03:00' }, meaning each day starts at 6am but
         * extends into the next day until 3am.
         */
        dayBoundaries: {
          start: '05:00',
          end: '20:00',
        },

        /**
         * The minimum and maximum date that can be displayed
         * Defaults to undefined, meaning no minimum or maximum date
         */
        // minDate: '2024-01-01',
        // maxDate: '2024-12-31',

        weekOptions: {
          /**
           * The total height in px of the week grid (week- and day views)
           */
          gridHeight: 1000,
        },

        monthGridOptions: {
          /**
           * Number of events to display in a day cell before the "+ N events" button is shown
           */
          nEventsPerDay: 3
        },

        /**
         * Callbacks for events that occur in the calendar
         */
        callbacks: {
          /**
           * Is called when:
           * 1. Selecting a date in the date picker
           * 2. Selecting a new view
           */
          onRangeUpdate: (range) => {
            // console.log('new calendar range start date', range.start)
            // console.log('new calendar range end date', range.end)
            // this.checkCalendarBoundaries();
          },

          /**
           * Is called when an event is updated through drag and drop
           */
          onEventUpdate(updatedEvent) {
            // console.log('onEventUpdate', updatedEvent)
          },

          /**
           * Is called when an event is clicked.
           * Using arrow function to have access to 'this' from the Vue instance.
           */
          onEventClick: (calendarEvent) => {
            // console.log('onEventClick', calendarEvent);
            // console.log('onEventClick nativeEvent', this);
            // #463 - We just want to open the tooltip with deleteBasket
            // this.openEvent(this, calendarEvent);
          },

          /**
           * Is called when clicking a date in the month grid
           */
          onClickDate: (date) => {
            // console.log('onClickDate', date) // e.g. 2024-01-01
            const dateToSet = moment(new Date(date)).format('YYYY-MM-DD');
            // this.calendarControls.setDate(dateToSet);
            this.setDateinCaledar(dateToSet);
            this.changeCalendarTypeView('week');
          },

          /**
           * Is called when clicking somewhere in the time grid of a week or day view
           */
          onClickDateTime: (dateTime) => {
            // console.log('onClickDateTime', dateTime) // e.g. 2024-01-01 12:37
            const dateToSet = moment(new Date(dateTime)).format('YYYY-MM-DD');
            // this.calendarControls.setDate(dateToSet);
            this.setDateinCaledar(dateToSet);
          },

          /**
           * Is called when selecting a day in the month agenda
           */
          onClickAgendaDate(date) {
            // console.log('onClickAgendaDate', date) // e.g. 2024-01-01
          },

          /**
           * Is called when double clicking a date in the month grid
           */
          onDoubleClickDate(date) {
            // console.log('onClickDate', date) // e.g. 2024-01-01
          },

          /**
           * Is called when double clicking somewhere in the time grid of a week or day view
           */
          onDoubleClickDateTime(dateTime) {
            // console.log('onDoubleClickDateTime', dateTime) // e.g. 2024-01-01 12:37
          },

          /**
           * Is called when clicking the "+ N events" button of a month grid-day
           */
          onClickPlusEvents(date) {
            // console.log('onClickPlusEvents', date) // e.g. 2024-01-01
          },

          /**
           * Is called when the selected date is updated
           */
          onSelectedDateUpdate: (date) => {
            // console.log('onSelectedDateUpdate', date);
          },
        },
      })
    },
    methods: {
      getStatusTitleTranslated(statusTitle) {
        // Avoid timming issues loading the component
        return statusTitle && statusTitle !== '' ? this.$t(`status.${statusTitle}`) : '';
      },
      preventTyping(event) {
        // Prevent all key events except for navigation keys
        const navigationKeys = ['ArrowUp', 'ArrowDown', 'Escape', 'Enter', 'Tab'];
        if (!navigationKeys.includes(event.key)) {
          event.preventDefault();
        }
      },
      setDateinCaledar(date){
        const dateToSet = moment(new Date(date)).format('YYYY-MM-DD');
        this.calendarControls.setDate(dateToSet);
        this.calendarSelectedValue = [new Date(date)];
        this.refreshCurrentMonth();
      },
      refreshCurrentMonth(){
        if(this.calendarControls?.getDate()){
        const actualDate = new Date(this.calendarControls.getDate());
        const month = actualDate.getMonth() + 1;
        const year = actualDate.getFullYear();
        this.calendarMonth = this.$t(
          `components.calendarContainer.months.${month}`
        );
        this.calendarYear = year;
        }
      },
      setCalendarPreviousPage() {
        const now = this.calendarControls.getDate();
        switch (this.typeView) {
          case 'month': {
            // We take today and subtract a month
            const todayMinusOneMonth = moment(now).subtract(1, 'months').format('YYYY-MM-DD');
            // this.calendarControls.setDate(todayMinusOneMonth);
            this.setDateinCaledar(todayMinusOneMonth);
            this.changeCalendarTypeView('month')
            break;
          }
          case 'week': {
            // We take today and subtract 7 days
            const todayMinusSevenDays = moment(now).subtract(7, 'days').format('YYYY-MM-DD');
            // this.calendarControls.setDate(todayMinusSevenDays);
            this.setDateinCaledar(todayMinusSevenDays);
            this.changeCalendarTypeView('week')
            break;
          }
          case 'day': {
            // We take today and subtract 1 day
            const todayMinusOneDay = moment(now).subtract(1, 'days').format('YYYY-MM-DD');
            // this.calendarControls.setDate(todayMinusOneDay);
            this.setDateinCaledar(todayMinusOneDay);
            this.changeCalendarTypeView('day')
            break;
          }
          default: {
            // Default case logic here if necessary
            break;
          }
        }
        // this.refreshCurrentMonth();
      },
      setCalendarNextPage() {
        const now = this.calendarControls.getDate();
        switch (this.typeView) {
          case 'month': {
            // We take today and add a month
            const todayPlusOneMonth = moment(now).add(1, 'months').format('YYYY-MM-DD');
            // this.calendarControls.setDate(todayPlusOneMonth);
            this.setDateinCaledar(todayPlusOneMonth);
            this.changeCalendarTypeView('month')
            break;
          }
          case 'week': {
            // We take today and add a 7 days more
            const todayPlusSevenDays = moment(now).add(7, 'days').format('YYYY-MM-DD');
            // this.calendarControls.setDate(todayPlusSevenDays);
            this.setDateinCaledar(todayPlusSevenDays);
            this.changeCalendarTypeView('week')
            break;
          }
          case 'day': {
            // We take today and add a 1 day more
            const todayPlusOneDay = moment(now).add(1, 'days').format('YYYY-MM-DD');
            // this.calendarControls.setDate(todayPlusOneDay);
            this.setDateinCaledar(todayPlusOneDay);
            this.changeCalendarTypeView('day')
            break;
          }
          default: {
            // Default case logic here if necessary
            break;
          }
        }
        // this.refreshCurrentMonth();
      },
      setCalendarToday() {
        const now = new Date();
        const today = moment(now).format('YYYY-MM-DD')
        // this.calendarControls.setDate(today);
        this.changeCalendarTypeView(this.typeView);
        this.setDateinCaledar(today);
        // this.refreshCurrentMonth();
      },
      changeCalendarTypeView(viewType, withBoundaries=true) {
        this.typeView = viewType;
        switch (viewType) {
          case 'month':
            this.calendarControls.setView(viewMonthGrid.name);
          break;
          case 'week':
            this.calendarControls.setView(viewWeek.name);
            // config.dayBoundaries.start = 500;
            // calendarEvents.list.value = [...calendarEvents.list.value];
          break;
          case 'day':
            this.calendarControls.setView(viewDay.name);
            // config.dayBoundaries.start = 0;
            // calendarEvents.list.value = [...calendarEvents.list.value];
          break;
          default:
            this.calendarControls.setView(viewDay.name);
          break;
        }
        // After change typeview, we need to check boundaries
        if (this.typeView !== 'month' && withBoundaries) this.checkCalendarBoundaries();
      },
      /* 
          Check what events are inside the date range
         to adjust the boundaries based on events start/end.
         It does not need params as everything is based on calendar services
      */
      checkCalendarBoundaries() {
        // We don't need to adjust boundaries when calendar is in 'monthView'
        if (this.typeView === 'month') return;
        const { config } = this.calendarApp.$app;
        const datesRange = this.calendarControls.getRange();
        let earliestEvent = 5; //config.dayBoundaries.start / 100; // start by default value, divide by 100 to match lib behaviour
        let latestEvent =  20; //config.dayBoundaries.end / 100; // start by default value, divide by 100 to match lib behaviour
        this.calendarEvents.forEach( (event) => {
          // Check what events are in the shown range
          if (checkIsDateInRange(datesRange.start, datesRange.end, event.start)){
            // Extract hours from the Date object
            const eventStartHour = new Date(event.start).getHours();
            const eventEndHour = new Date(event.end).getHours();
            // Check the boundaries
            if (eventStartHour < earliestEvent) earliestEvent = eventStartHour;
            // We need to add +1 to eventEndHour to cover events ending at that hour and some more minutes
            if (eventEndHour + 1 > latestEvent) latestEvent = eventEndHour + 1; 
          }
        });
        // After checking the boundaries for every event in the range, 
        // we adjust the real boundaries at the calendar with the tricky function
        config.dayBoundaries.start = earliestEvent * 100; // multiply by 100 matches the library use of this internal prop
        config.dayBoundaries.end = latestEvent * 100; // multiply by 100 matches the library use of this internal prop
        // calendarEvents.list.value = [...calendarEvents.list.value];
        this.refreshCalendarUiEvents();
       
      },
      //   Refresh calendar/events after manipulating the config at the DOM
      // at the lib, there is no function to trigger a refresh Ui
      // this custom is a bit tricky but it works! :)
      refreshCalendarUiEvents(){
        if (this.currentTypeView !== 'week'){
          const currentTypeView = this.typeView;
          this.changeCalendarTypeView('week', false);
          setTimeout(() => {
            this.changeCalendarTypeView(currentTypeView, false);
            // this.eventsServicePlugin.set(this.calendarEvents);
          }, 20);
        }
      },
      formatDateTitle(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);

        // Ensure the date is valid
        if (isNaN(date)) {
          return 'Invalid Date';
        }
        // Locale doesn't matter here as we just want YYYY-MM-DD
        const formattedDate = date.toLocaleDateString('en-CA', options);

        return formattedDate;
      },
      /**
       * Adjusts events to a single timeline by normalizing their start and end times and ensuring no overlap for the same operation.
       * @param {Array} events An array of event objects with start and end times in RFC or ISO format.
       * @returns {Array} The adjusted events to fit a single timeline.
       */
      adjustEventsToSingleTimeline(events) {
        // Normalize event times
        const normalizedEvents = events.map(event => ({
          ...event,
          start: this.formatCalendarAsDate(event.start),
          end: this.formatCalendarAsDate(event.end),
        })).sort((a, b) => a.start - b.start); // Sort by start time
        // Group events by operation
        const groupedByOp = normalizedEvents.reduce((acc, event) => {
          const op = this.timeCalculationOp[event.color] || 'unknown';
          if (!acc[op]) acc[op] = [];
          acc[op].push(event);
          return acc;
        }, {});
        // Adjust overlapping events within the same operation group
        const adjustedEvents = [];
        for (const op in groupedByOp) {
          const opEvents = groupedByOp[op];
          // Adjust the overlapping events
          for (let i = 0; i < opEvents.length - 1; i++) {
            const current = opEvents[i];
            const next = opEvents[i + 1];
            if (current.end > next.start) { // Check for overlap
              // Adjust the start of the next event to the end of the current event
              next.start = current.end;
              // Optional: If the next event ends before it starts (due to adjustment), end it right after it starts
              if (next.end < next.start) {
                next.end = next.start;
              }
            }
          }
          adjustedEvents.push(...opEvents);
        }
        // Convert the dates back to the original format
        return adjustedEvents.sort((a, b) => a.start - b.start) // Sort by start time
          .map(event => ({
            ...event,
            start: this.formatDateForCalendar(event.start),
            end: this.formatDateForCalendar(event.end),
          }));
      },
      autocompleteMinutesEnd(hour) {
        let finalHour = hour;
        if (hour < 10) finalHour = '0' + hour;
        this.endTime = finalHour + ":00";
      },
      handleMinuteClickEnd(minutes) {
        const hour = this.endTime.slice(0,2);
        let newtimeEnd = hour + ':';
        if (minutes > 9) {
          newtimeEnd += minutes;
        } else {
          newtimeEnd += '0' + minutes;
        }
        if (newtimeEnd) {
          this.endTime = newtimeEnd;
          this.menuEndPicker = false;
        }
      },
      autocompleteMinutes(hour) {
        let finalHour = hour;
        if (hour < 10) finalHour = '0' + hour;
        this.startTime = finalHour + ":00";
      },
      handleMinuteClick(minutes) {
        const hour = this.startTime.slice(0,2);
        let newTimeStart = hour + ':';
        const newTimeEnd = hour + ':' + (minutes + 10 <= 59 ? minutes + 10 : '59'); //Set TimeEnd 10 mins later for reference
        if (minutes > 9) {
          newTimeStart += minutes;
        } else {
          newTimeStart += '0' + minutes;
        }
        if (newTimeStart) {
          this.startTime = newTimeStart;
          this.endTime = newTimeEnd;
          this.menuStartPicker = false;
        }
      },
      removeEvent(event) {
        if (event.start && event.id) {
          delCustomDailyTimeCounting(
            (this.selectedUser || this.state.ownUUID),
            this.formatCalendarDateToISO(event.start).substring(0, 10),
            event.id
          );
          const menu = document.getElementById(event.id);
          if (menu) {
            menu.style.display = 'none';
          }
          this.eventsServicePlugin.remove(event.id); // This removes the event from the real calendar
          const eventIndex = this.calendarEvents.findIndex((e) => e.id === event.id);
          if (eventIndex !== -1) {
            this.calendarEvents.splice(eventIndex, 1); // This removes the event from our own list
          }
        }
        this.selectedEvent = {};
        this.selectedElement = null;
        this.selectedOpen = false;
        // Check if we need refresh calendar UI
        this.refreshCurrentMonth();
        this.checkCalendarBoundaries();
      },
      // NOT IN USE
      // openEvent( nativeEvent, event ) {
      //   if (
      //     event.type &&
      //     event.type === "custom" &&
      //     (this.selectedUser === this.state.ownUUID || this.amIAdmin)
      //   ) {
      //     const open = () => {
      //       this.selectedEvent = event;
      //       this.selectedElement = nativeEvent.target;
      //       requestAnimationFrame(() =>
      //         requestAnimationFrame(() => (this.selectedOpen = true))
      //       );
      //     };
      //     if (this.selectedOpen) {
      //       this.selectedOpen = false;
      //       requestAnimationFrame(() => requestAnimationFrame(() => open()));
      //     } else {
      //       open();
      //     }
      //     nativeEvent.stopPropagation();
      //   }
      // },
      saveAddTimeModal() {
        let eventColor = '#ff0000';
        switch (this.statusSelect) {
          case 'Available':
            eventColor = 'green'
            break;
          case 'Out of Office':
            eventColor = '#ffac13';
            break;
          case 'Break':
            eventColor = '#ff0000'
            break;
          case 'Holidays':
            eventColor = 'purple'
            break;
          default:
            break;
        }
        const parseEvent = {};
        const dateStartObject = new Date(this.calendarSelectedValue[0]);
        dateStartObject.setHours(this.startTime.split(':')[0], this.startTime.split(':')[1], 0, 0);
        const dateEndObject = new Date(this.calendarSelectedValue[0]);
        dateEndObject.setHours(this.endTime.split(':')[0], this.endTime.split(':')[1], 0, 0);
        parseEvent.start = moment(dateStartObject).format('YYYY-MM-DD HH:mm');
        parseEvent.end = moment(dateEndObject).format('YYYY-MM-DD HH:mm');
        // parseEvent.time = { start: this.formatCalendarAsDate(dateStartObject), end: this.formatCalendarAsDate(dateEndObject) };
        parseEvent.name = parseEvent.title = this.eventDetail;
        parseEvent.color = eventColor;
        parseEvent.timed = false;
        parseEvent.id = uuidv4().replace(/-/g, '');
        parseEvent.type = "custom";
        const day = this.formatCalendarDateToISO(this.calendarSelectedValue[0]).substring(0, 10);
        const customChanges = [...this.calendarEvents, parseEvent]
          .filter((e) => e.type === "custom")
          .map((e) => ({
            _id: e.id,
            start: this.formatCalendarDateToISO(e.start),
            end: this.formatCalendarDateToISO(e.end),
            name: e.name || e.title,
            color: e.color,
          }))
          .filter((e) => {
            const { start, end, name } = e;
            const valid =
              typeof start === "string" &&
              typeof end === "string" &&
              typeof name === "string" &&
              start.startsWith(day) &&
              end.startsWith(day);
            return valid;
          });
        const saveEventPromise = setCustomDailyTimeCounting(
          (this.selectedUser || this.state.ownUUID),
          this.formatCalendarDateToISO(this.calendarSelectedValue[0]).substring(0, 10),
          customChanges
        );
        saveEventPromise.then(
          (row) => {
            const ours =
              row?.customChanges &&
              [...row.customChanges]
                .reverse()
                .find(
                  (e) =>
                    e?.start === this.formatCalendarDateToISO(parseEvent.start) &&
                    e?.end === this.formatCalendarDateToISO(parseEvent.end)
                );
            if (ours) {
              parseEvent.id = ours._id;
              parseEvent.name = parseEvent.title = ours.name;
              this.calendarEvents.push(parseEvent);
              this.checkCalendarBoundaries();
              setTimeout(() => {
                this.eventsServicePlugin.set(this.calendarEvents);
              }, 50);
            } else {
              console.warn("Failed to enter additional times", { row, ours, parseEvent });
            }
          },
          (err) => {
            console.warn("Error entering additional times", err);
          }
        );
        this.closeModal();
      },
      closeModal() {
        this.showAddTimeModal = !this.showAddTimeModal;
        this.startTime = null;
        this.endTime = null;
        this.eventDetail = "";
        this.statusSelect = "";
      },
      disableItem(item) {
        if (item.name === "Daily work" && this.isDailyWorkSetted) return true;
        if (item.name !== "Daily work" && !this.isDailyWorkSetted) return true;
        return false;
      },
      cleanForm() {
        this.workingInputStart = "";
        this.workingInputEnd = "";
        this.workingTimeType = "";
        this.workingInputMenuStart = "";
        this.workingInputMenuEnd = "";
      },
      getFormatedTime(time) {
        const date = new Date(time);
        return `${String(date.getHours()).padStart(2, "0")}:${String(
          date.getMinutes()
        ).padStart(2, "0")}`;
      },
      calChangeHandler(page) {
        this.calendarMonth = this.$t(
          `components.calendarContainer.months.${page.start.month}`
        );
        this.calendarYear = page.start.year;
        this.calendarEvents = [];
        const value = this.selectedUser;
        this.$nextTick(async () => {
          if (this.selectedUser === value) {
            const dataEvents = await this.getDataEvents(value);
            if (this.selectedUser === value) this.getEvents(dataEvents, value);
          }
        });
      },
      async getDataEvents(userUuid) {
        return await readDailyTimeCountings(userUuid);
      },
      getEvents(dataEvents, selectedUser) {
        if (!dataEvents) return [];
        const now = new Date();
        dataEvents.forEach((workDay) => {
          const tmpEvent = JSON.parse(JSON.stringify(workDay || {}));
          // Set work day
          if (selectedUser && tmpEvent?.statusChanges?.length) {
            if (!tmpEvent.loginTS)
              tmpEvent.loginTS = tmpEvent.statusChanges[0].ts;
            if (!tmpEvent.logoutTS && tmpEvent.loginTS) {
              const logoutTS = new Date(
                tmpEvent.statusChanges[tmpEvent.statusChanges.length - 1].ts
              );
              if (
                store.state.group[selectedUser]?.connected &&
                now > logoutTS &&
                this.isToday(logoutTS)
              ) {
                tmpEvent.logoutTS = now.toISOString();
              } else if (logoutTS > new Date(tmpEvent.loginTS)) {
                tmpEvent.logoutTS = logoutTS.toISOString();
              }
            }
          }
          const valid = this.setWorkDay(tmpEvent);
          // Set status changes
          if (valid || tmpEvent.customChanges)
            this.setStatusChanges(tmpEvent, valid);
        });
      },
      isToday(someDate) {
        const date = new Date(store.state.currentTS);
        if (typeof someDate === "string") {
          const today = date.toISOString().slice(0, 10);
          return someDate.startsWith(today);
        } else if (someDate instanceof Date && !isNaN(someDate)) {
          const today = date;
          return (
            someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
          );
        }
        return false;
      },
      setStatusChanges(workDay, setStatusChanges) {
        if (
          setStatusChanges &&
          workDay.statusChanges &&
          workDay.statusChanges.length
        ) {
          const statusData = workDay.statusChanges;
          statusData.forEach((status, index) => {
            const parseEvent = {};
            // Set disconnected
            if (!status.connected && index < statusData.length - 1) {
              const date1 = new Date(status.ts);
              const date2 = new Date(statusData[index + 1].ts);
              const diffTime = Math.abs(date2 - date1);
              if (diffTime >= 600000 /* 10 minutes ago */) {
                parseEvent.start = moment(status.ts).format('YYYY-MM-DD HH:mm');
                parseEvent.end = moment(statusData[index + 1].ts).format('YYYY-MM-DD HH:mm')
                // parseEvent.time = { start: new Date(status.ts), end: statusData[index + 1].ts};
                parseEvent.title = this.$t("status.Offline");

                parseEvent.color = "gray";
                parseEvent.timed = false;
                parseEvent.id = status._id;
                const exist = this.calendarEvents.find((data) => data.id === status._id);
                if (!exist) {
                  this.calendarEvents.push(parseEvent);
                }
              }
            }
            // Set status
            if (
              status.activity !== "Available" &&
              status.connected &&
              index < statusData.length - 1
            ) {
              const key = `status.${status.activity}`;
              const translation = this.$t(key) || key;
              const activity =
                key === translation ? `${status.activity}` : translation;
              parseEvent.start = moment(status.ts).format('YYYY-MM-DD HH:mm');
              parseEvent.end = moment(statusData[index + 1].ts).format('YYYY-MM-DD HH:mm');
              // parseEvent.time = { start: new Date(status.ts), end: statusData[index + 1].ts};

              parseEvent.title = activity;
              parseEvent.color = this.getColorByStatus(status.activity);
              parseEvent.timed = false;
              parseEvent.id = status._id;
              const exist = this.calendarEvents.find((data) => data.id === status._id);
              if (!exist) {
                this.calendarEvents.push(parseEvent);
              }
            }
          });
        }
        if (
          workDay.customChanges &&
          workDay.customChanges.length
        ) {
          const statusData = workDay.customChanges;
          statusData.forEach((status, index) => {
            const { name, start, end, _id, color } = status;
            const parseEvent = {};
            parseEvent.start = moment(start).format('YYYY-MM-DD HH:mm');
            parseEvent.end = moment(end).format('YYYY-MM-DD HH:mm');
            // parseEvent.time = { start: new Date(start), end: new Date(end)};
            parseEvent.title = name;
            parseEvent.color = color || "purple";
            parseEvent.timed = false;
            parseEvent.id = _id;
            parseEvent.type = "custom";
            const exist = this.calendarEvents.find((data) => data.id === status._id);
            if (!exist) {
              this.calendarEvents.push(parseEvent);
            }
          });
        }
        this.eventsServicePlugin.set(this.calendarEvents)

      },
      setWorkDay(tmpEvent) {
        const exist = this.calendarEvents.find((data) => data.id === tmpEvent._id);
        if (!exist && tmpEvent.loginTS && tmpEvent.logoutTS) {
          const parseEvent = {};
          parseEvent.start = moment(tmpEvent.loginTS).format('YYYY-MM-DD HH:mm');
          parseEvent.end = moment(tmpEvent.logoutTS).format('YYYY-MM-DD HH:mm');
          // parseEvent.time = { start: new Date(tmpEvent.loginTS), end: new Date(tmpEvent.logoutTS)};
          parseEvent.title = this.$t("status.Available");
          parseEvent.color = "green";
          parseEvent.timed = false;
          parseEvent.id = tmpEvent._id;
          this.calendarEvents.push(parseEvent);
          this.eventsServicePlugin.set(this.calendarEvents)
          return true;
        }
        return false;
      },
      getColorByStatus(status) {
        return getColorByStatus(status);
      },
      formatDateForCalendar(timeStamp) {
        const date = new Date(timeStamp);
        return `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString().padStart(2, "0")}-${date.getDate()
          .toString().padStart(2, "0")} ${date.getHours()
          .toString().padStart(2, "0")}:${date.getMinutes()
          .toString().padStart(2, "0")}`;
      },
      /**
       * Formats a date string to a Date object or an ISO string.
       * @param {Date|string} dateString The date as a Date object, or string in RFC 3339 or ISO 8601 format.
       * @param {boolean} [asDateObject=true] Determines if the output should be a Date object. If false, returns an ISO string.
       * @returns {Date|string} The formatted Date object or ISO string.
       */
      formatCalendarAsDate(dateString, asDateObject = true) {
        if (dateString && typeof dateString === 'object' && dateString instanceof Date && !isNaN(dateString)) {
          return asDateObject ? new Date(dateString) : dateString.toISOString(); // Input is native date object
        }
        const [date, time] = (typeof dateString === 'string' && dateString.split(" ")) || []; // RFC 3339 on local time-offset
        if (dateString && !date && !time) {
          const momentDate = moment(dateString).toDate();
          return asDateObject ? momentDate : momentDate.toISOString(); // Try processing with moment.js
        }
        const obj = !time && typeof date === 'string' && date.includes("T") // ISO 8601 with embedded timezone
          ? new Date(date)
          : new Date(date
            .split("-")
            .map((e) => e.padStart(2, "0"))
            .join("-") +
            "T" +
            time
              .split(":")
              .map((e) => e.padStart(2, "0"))
              .join(":") +
            ":00" +
            getTimezone(date, time)
          );
        return asDateObject ? obj : obj.toISOString();
      },
      /**
       * Formats a date string to an ISO string.
       * @param {Date|string} dateString The date as a Date object, or string in RFC 3339 or ISO 8601 format.
       * @returns {string} The formatted ISO string.
       */
      formatCalendarDateToISO(dateString) {
        return this.formatCalendarAsDate(dateString, false);
      },
      saveEvent() {
        const foundParentUuid = this.calendarEvents.find(
          (event) => event.parentUuid == this.addEventMenu.parentUuid
        );
        if (!foundParentUuid) {
          this.calendarEvents.push(...this.temporalPreviewEvents);
          this.eventsServicePlugin.set(this.calendarEvents)
        }
        this.addEventMenu = {};
      },
      closeStatus() {
        this.setCurrentContentVisile("home", true, this.$router);
      },
      viewDay(event) {
        this.changeCalendarSelectedDate(event.date);
        this.changeCalendarTypeView("week");
      },
      getEventColor(event) {
        if (event.type === "custom") {
          switch (event.color) {
            case 'green':
            case '#008000': // green
              return '#006400'; // dark green
            case 'red':
            case '#ff0000': // red
              return '#8b0000'; // dark red
            case 'yellow':
            case '#ffa500': // orange
            case '#ffac13': // golden yellow
              return '#ce7e00'; // burnt orange
          }
        }
        return event.color;
      },
      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a;
      },
      setColorByTypeEvent(type) {
        switch (type) {
          case "Daily work":
            return "green";
          case "Lunch":
            return "yellow";
          case "Pause":
            return "red";
          default:
            return "green";
        }
      },
      getEventBackgroundColor(color) {
        switch (color) {
          case "green":
            return "#008000";
          case "yellow":
            return "#ffa500";
          case "red":
            return "#ff0000";
          default:
            return "#008000";
        }
      },
      getEventsByParentUuid(parentUuid) {
        if (!parentUuid) return;
        const data = this.calendarEvents.filter(
          (event) => event.parentUuid == parentUuid
        );
        return data;
      },
      getEventsByDate(date) {
        if (!date) return;
        const data = this.calendarEvents.filter(
          (event) => this.formatCalendarDateToISO(event.start).slice(0, 10) == date);
        return data;
      },
      addTimeModal() {
        // open modal for add time
        this.showAddTimeModal = true;
      },
      getArrayDatesBetween(start, end){
        for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
          arr.push(new Date(dt));
       }
       return arr.map((v)=>v.toISOString().slice(0,10))
      },
      getStatusColor(status) {
        if (status === 'Out of Office') return '#ffac13';
        return colorStatus(status);
      },
    },
    computed: {
      isDarkMode() {
        return this.state.persisted.isDark;
      },
      isDark() {
        return store.state.persisted.isDark;
      },
      timeCalculation() {
        if(!this.calendarControls.$app) return '00:00';
        const calendarDate = this.calendarControls.getDate() || null;
        const date = moment(calendarDate);
        let viewStart = null;
        let viewEnd = null;
        switch (this.typeView) {
          case 'month':
          viewStart = date.clone().startOf('month').format('YYYY-MM-DD');
          viewEnd =  date.clone().endOf('month').format('YYYY-MM-DD');
          break;
          case 'week':
          viewStart =  date.clone().startOf('week').format('YYYY-MM-DD');
          viewEnd =  date.clone().endOf('week').format('YYYY-MM-DD');
          break;
          case 'day':
          viewStart = date.format('YYYY-MM-DD');
          viewEnd =  date.format('YYYY-MM-DD');
          break;
          default:
          break;
        }
        if (!this.calendarEvents.length || !viewStart || !viewEnd) return '00:00';
        const events = [];
        // const daysInMonth =  this.$refs.calendarWorkingTime.daysInMonth
        // const viewStart = daysInMonth[0].date;
        // const viewEnd = daysInMonth[daysInMonth.length-1].date;
        const eventsDate = this.getArrayDatesBetween(viewStart, viewEnd);
        eventsDate.forEach(date => {
          const data = this.getEventsByDate(new Date(date).toISOString().slice(0, 10));
          data.forEach(info => {
            const findIndex = events.findIndex(e => e.id === info.id);
            if (findIndex === -1 && info.id && this.filteredEvents.includes(info)) {
              events.push(info);
            }
          });
        });
        let hours = 0, minutes = 0;
        const adjustedEvents = this.adjustEventsToSingleTimeline(events);
        adjustedEvents.forEach(event => {
          // Assuming event.start and event.end are in the ISO format
          const startDate = new Date(event.start);
          const endDate = new Date(event.end);
          // Extracting hours and minutes using getHours and getMinutes methods
          const eventStartMinutes = (startDate.getHours() * 60) + startDate.getMinutes();
          const eventEndMinutes = (endDate.getHours() * 60) + endDate.getMinutes();
          const calculateMinutes = eventEndMinutes - eventStartMinutes;
          switch (event.color) {
            case 'green':
            case '#008000': // green
            case '#006400': // dark green
              minutes += calculateMinutes;
              break;
            case 'gray':
            case 'red':
            case '#ff0000': // red
            case '#8b0000': // dark red
              minutes -= calculateMinutes;
              break;
            case 'yellow':
            case '#ffa500': // orange
            case '#ffac13': // golden yellow
            case '#ce7e00': // burnt orange
              {
                let overlapsAvailable = false;
                let overlapsBusy = false;
                let overlapsOffline = false;
                let overlapDuration = 0;
                // Check if there are overlaps
                const hasOverlapEvent = adjustedEvents.some(e => {
                  const eStartSplit = e.start.split(' ', 2);
                  const eEndSplit = e.end.split(' ', 2);
                  const isSameDay = event.start.startsWith(eStartSplit[0]) && event.end.startsWith(eEndSplit[0]);
                  if (isSameDay && event !== e && (
                    e.color === 'gray' || // offline
                    e.color === 'green' ||
                    e.color === '#008000' || // green
                    e.color === '#006400' || // dark green
                    e.color === 'yellow' ||
                    e.color === '#ffa500' || // orange
                    e.color === '#ffac13' || // golden yellow
                    e.color === '#ce7e00') // burnt orange
                  ) {
                    const eStartHours = eStartSplit[1].split(':');
                    const eEndHours = eEndSplit[1].split(':');
                    const eStartMinutes = parseInt(eStartHours[0]) * 60 + parseInt(eStartHours[1]);
                    const eEndMinutes = parseInt(eEndHours[0]) * 60 + parseInt(eEndHours[1]);
                    // Check if the event is within the same time span
                    return (
                      (eventStartMinutes >= eStartMinutes && eventStartMinutes < eEndMinutes) ||
                      (eventEndMinutes > eStartMinutes && eventEndMinutes <= eEndMinutes) ||
                      (eventStartMinutes <= eStartMinutes && eventEndMinutes >= eEndMinutes)
                    );
                  }
                  return false;
                });
                if (hasOverlapEvent) {
                  // Find overlaps and calculate their span
                  adjustedEvents.forEach(e => {
                    if (event === e) return;
                    const eStartSplit = e.start.split(' ', 2);
                    const eEndSplit = e.end.split(' ', 2);
                    const isSameDay = event.start.startsWith(eStartSplit[0]) && event.end.startsWith(eEndSplit[0]);
                    if (!isSameDay) return;
                    const eIsAvailable = (e.color === 'green' || e.color === '#008000' /* green */ || e.color === '#006400' /* dark green */);
                    const eIsBusy = (e.color === 'yellow' || e.color === '#ffa500' /* orange */ || e.color === '#ffac13' /* golden yellow */ || e.color === '#ce7e00' /* burnt orange */);
                    const eIsOffline = (e.color === 'gray' /* offline */);
                    if (eIsAvailable || eIsBusy || eIsOffline) {
                      const eStart = e.start.split(' ')[1].split(':');
                      const eEnd = e.end.split(' ')[1].split(':');
                      const eStartMinutes = parseInt(eStart[0]) * 60 + parseInt(eStart[1]);
                      const eEndMinutes = parseInt(eEnd[0]) * 60 + parseInt(eEnd[1]);
                      if (eventStartMinutes < eEndMinutes && eventEndMinutes > eStartMinutes) {
                        const overlapStart = Math.max(eventStartMinutes, eStartMinutes);
                        const overlapEnd = Math.min(eventEndMinutes, eEndMinutes);
                        if (overlapEnd - overlapStart > 0) {
                          overlapDuration += overlapEnd - overlapStart;
                          overlapsAvailable = overlapsAvailable || eIsAvailable;
                          overlapsBusy = overlapsBusy || eIsBusy;
                          overlapsOffline = overlapsOffline || eIsOffline;
                        }
                      }
                    }
                  });
                }
                if (calculateMinutes - overlapDuration > 0) {
                  minutes += calculateMinutes - overlapDuration;
                } else if (overlapsBusy || !overlapsAvailable || overlapsOffline) {
                  minutes += calculateMinutes;
                }
              }
              break;
            default:
              break;
          }
        });
        hours = Math.floor(minutes/60);
        minutes = minutes % 60;
        if (hours < 0) hours = 0;
        if (minutes < 0) minutes = 0;
        const formattedNumber = (num) => ("" + num).padStart(2, "0");
        const workingTime = formattedNumber(hours) + ':' + formattedNumber(minutes);
        return workingTime;
      },
      showAddMoreTimeBtn() {
        // On the daily view for the last 7 days including today only we show + button
        if (this.typeView === "day") {
          const today = new Date();
          const lastWeek = Date.parse(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
          );
          const selectedDate = new Date(this.calendarControls.getDate());
          selectedDate.setHours(0, 0, 0, 0);
          if (
            Date.parse(selectedDate) < lastWeek ||
            Date.parse(selectedDate) >
              Date.parse(
                new Date(today.getFullYear(), today.getMonth(), today.getDate())
              )
          ) {
            return false;
          } else {
            if (this.selectedUser === this.state.ownUUID || this.amIAdmin) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      },
      isDailyWorkSetted() {
        const dailyIndex = this.temporalPreviewEvents.findIndex(
          (element) => element.name === "Daily work"
        );
        if (dailyIndex !== -1) return true;
        return false;
      },
      disabledAddEventButton() {
        if (
          this.workingInputStart === "" ||
          this.workingInputEnd === "" ||
          this.workingTimeType === ""
        )
          return true;
        return false;
      },
      amIAdmin() {
        return store.getUserIsAdmin(this.state.ownUUID);
      },
    },
  };
  </script>
  <style scoped lang="scss">
  .w100 {
    width: 100%;
  }
  .h100 {
    height: 100%;
  }
  .calendarContainer {
    height: calc(100vh - 230px);
  }

  .workingTimeWrapper {
    display: flex;
  }
  .eventPreviewRow {
    display: block;
    overflow-y: auto;
    max-height: calc(100vh - 560px);
  }
  .eventItem {
    padding: 0;
    display: flex;
  }
</style>
<style lang="scss">
  .sx__time-grid-event.sx__event{
    min-height: 20px !important;
  }
  .v-picker-title{
    display: none;
  }
  .sx-vue-calendar-wrapper{
    height: 100%;
  }
  .timePicker input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    pointer-events: none;
  }
  .eventTemplate{
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .sx__month-grid-day__header-date{
    cursor: pointer;
  }
  .is-leading-or-trailing {
      background-color: var(--v-primary-100) !important;
  }
  .is-dark {
    .is-leading-or-trailing {
      background-color: var(--v-primary-600) !important;
    }
  }
  .sx__calendar-wrapper{
    .sx__calendar{
      // border-top: none !important;
    }
  }
</style>