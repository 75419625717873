<template>
  <div>
    <v-badge
      v-if="totalNotification"
      bordered
      color="error"
      :content="totalNotification"
      :value="totalNotification"
      overlap
      :offset-y="isMobile && isNavbar ? 14 : !isMobile && isNavbar ? 12 : null"
      :offset-x="isMobile && isNavbar ? 13 : !isMobile && isNavbar ? 15 : null"
    >
      <v-menu
        offset-y
        :close-on-click="true"
        :max-height="370"
        :max-width="350"
        :model-value="menuOpen" @update:model-value="menuOpen = $event"
      >
        <template v-slot:activator="{ props }">
          <div v-bind="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <v-btn
                  :small="isMobile"
                  icon
                  :color="
                    !hasPrivilege(state.ownUUID)
                      ? '#2a3133'
                      : wholeBarWithColors()
                      ? 'white'
                      : 'black'
                  "
                  v-bind="props"
                  @click="setNotificationEffector()"
                  id="v-tour-messages"
                >
                  <font-awesome-icon
                    v-if="!hasPrivilege(state.ownUUID)"
                    :color="isDarkMode ? 'white' : ''"
                    :icon="['fal', 'bell']"
                    :style="{ fontSize: '16px' }"
                  />
                  <font-awesome-icon
                    v-else
                    :icon="['fal', 'paper-plane']"
                    :style="{ fontSize: '16px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.drawerContentCard.messages") }}</span>
            </v-tooltip>
          </div>
        </template>
        <NotificatiosnWaitingRoomDropDownEffector
          :notificationType="'message'"
          :closeMenu="closeMenu"
          :menuOpen="menuOpen"
        />
      </v-menu>
    </v-badge>
    <v-menu
    v-else
      offset-y
      :close-on-click="true"
      :max-height="370"
      :max-width="350"
      :model-value="menuOpen" @update:model-value="menuOpen = $event"
    >
      <template v-slot:activator="{ props }">
        <div v-bind="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-btn
                :small="isMobile"
                icon
                :color="
                  !hasPrivilege(state.ownUUID)
                    ? '#2a3133'
                    : wholeBarWithColors()
                    ? 'white'
                    : 'black'
                "
                v-bind="props"
                @click="setNotificationEffector()"
                id="v-tour-messages"
              >
                <font-awesome-icon
                  v-if="!hasPrivilege(state.ownUUID)"
                  :color="isDarkMode ? 'white' : ''"
                  :icon="['fal', 'bell']"
                  :style="{ fontSize: '16px' }"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '16px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.drawerContentCard.messages") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-menu>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import NotificatiosnWaitingRoomDropDownEffector from "../notificationsWaitingRoomDropDownEffector.vue";
import {
  unseenMessagesToMe,
  dispatchViewedMessageEvent,
  messagesToMe,
} from "../../../effector/message";
import DownloadsDropdown from "../downloadsDropdown.vue";
import { wholeBarWithColor } from "../../../utils/color";
import { hasPrivilege } from "../../../utils/privileges";

export default {
  props: ["setTotalNotificationCall", "setTotalNotificationFile", "isNavbar"],
  components: { NotificatiosnWaitingRoomDropDownEffector, DownloadsDropdown },
  data() {
    const effector = {
      unseenMessagesToMe: unseenMessagesToMe,
      messagesToMe: messagesToMe,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      menuOpen: false,
      isMobile: isMobile(),
      // Effector
      ...effector,
    }
  },
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    closeMenu() {
      this.menuOpen = false;
    },
    setNotificationEffector() {
      this.setAllNotification();
    },
    setAllNotification() {
      if (this.messagesToMe.length > 0) {
        dispatchViewedMessageEvent();
      }
      if (this.state.persisted.totalNotificationCall > 0) {
        this.setTotalNotificationCall(0);
      }
      if (this.state.persisted.totalNotificationFile > 0) {
        this.setTotalNotificationFile(0);
      }
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    totalNotification() {
      return (
        this.unseenMessagesToMe +
        this.state.persisted.totalNotificationCall +
        this.state.persisted.totalNotificationFile
      );
    },
  },
};
</script>