<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="950"
    :fullscreen="isMobile"
    @keydown.esc="closeModalFunction"
  >
    <v-card flat>
      <HeaderModal
        :titleModal="$t('components.userSettings.userSettings')"
        :closeModalFunction="closeModalFunction"
      />
      <v-row class="mx-3 mt-3">
        <v-col class="col-12 py-0 px-0" :class="{h78vh: isMobile}">
          <div class="mb-4" :class="{ rowSettingsMobile: isMobile, rowSettings: !isMobile }">
            <v-expansion-panels
              :accordion="false"
              :popout="false"
              :inset="false"
              :multiple="false"
              :focusable="false"
              :disabled="false"
              :readonly="false"
              :flat="false"
              :hover="false"
              :tile="false"
              :model-value="panel" @update:model-value="panel = $event"
            >
              <v-expansion-panel
                v-for="(item, i) in getExpansionPanels"
                :key="i"
                :style="[
                  (item.index === 11 &&
                    !(state.nerd && canShowSpeechliveByDomain)) ||
                  (item.index === 10 && !state.nerd) ||
                  (item.index == 17 && !state.user.enabledBeacon)
                    ? { display: 'none' }
                    : { display: 'block' },
                ]"
              >
                <v-expansion-panel-title class="px-3">
                  <v-list-item-title class="text-subtitle-2 font-weight-medium">
                    {{ item.name }}
                    <v-tooltip
                      top
                      v-if="
                        item.index == 15 || item.index == 16 || item.index == 5 || item.index == 18
                      "
                      content-class="videoButtonTT"
                    >
                      <template v-slot:activator="{ props }">
                        <font-awesome-icon
                          v-bind="props"
                          color="#2a3133"
                          :icon="['fal', 'info-circle']"
                          :style="{
                            fontSize: '17px',
                            position: 'relative',
                            top: '2px',
                          }"
                          @click.stop.prevent="openInfoModalMobile(item.index)"
                        />
                      </template>
                      <span>
                        {{
                          item.index == 16
                            ? $t("components.userSettings.screensaverTT")
                            : item.index == 5
                            ? $t("components.userSettings.startviewTT")
                            : item.index == 15
                            ? $t("components.userSettings.waitingroomTT")
                            : item.index == 18
                            ? $t("components.userSettings.calendarInfoTT")
                            : ""
                        }}
                      </span>
                    </v-tooltip>
                    <br />
                  </v-list-item-title>
                </v-expansion-panel-title>
                <!-- VIEW SECTION -->
                <v-expansion-panel-text v-if="item.index == 0">
                  <v-row>
                    <v-col>
                      <v-list-item class="py-0 px-0">
                        <div class="py-1 pl-3 my-0 px-2">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <v-switch
                              class="pb-1 mt-0"
                                v-bind="props"
                                hide-details
                                :model-value="showVideoCallPlanner" @update:model-value="showVideoCallPlanner = $event; showHideSection()"
                                :label="
                                  $t(
                                    'components.userSettings.showHidePlanerLine'
                                  )
                                "
                              ></v-switch>
                            </template>
                            <span>
                              {{ $t("components.userSettings.showPlannerTT") }}
                            </span>
                          </v-tooltip>
                        </div>
                      </v-list-item>
                      <v-list-item class="py-0 px-0">
                        <div class="py-1 pl-3 my-0 px-2">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <v-switch
                                v-bind="props"
                                class="pb-1 mt-0"
                                hide-details
                                :model-value="showStatusNumber" @update:model-value="showStatusNumber = $event; changeStatusNumber()"
                                :label="
                                  $t('components.userSettings.showStatusNumber')
                                "
                              ></v-switch>
                            </template>
                            <span>
                              {{
                                $t("components.userSettings.showStatusNumberTT")
                              }}
                            </span>
                          </v-tooltip>
                        </div>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item class="py-0 px-0">
                        <div class="py-1 my-0 pl-3 px-2">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <v-switch
                                v-bind="props"
                                hide-details
                                :model-value="state.user.colorsettings.wholeBar" @update:model-value="state.user.colorsettings.wholeBar = $event; changeColor($event, 'whole')"
                                class="pb-1 mt-0"
                                :label="
                                  $t(
                                    'components.userSettings.displayStatusColor'
                                  )
                                "
                              ></v-switch>
                            </template>
                            <span>
                              {{
                                $t(
                                  "components.userSettings.displayStatusColorTT"
                                )
                              }}
                            </span>
                          </v-tooltip>
                        </div>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 1">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div class="d-flex align-center" :class="isMobile? 'flex-column': ''">
                          <span :class="!isMobile? 'mw-445':''">
                            {{ $t("components.userSettings.2faPin") }}</span
                          >
                          <v-btn flat
                            :class="isMobile? 'mt-2 mb-2': ''"
                            class="ml-2"
                            color="primary"
                            @click="toggle2faSettings"
                          >
                            {{ $t("generics.display") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div class="d-flex align-center" :class="isMobile? 'flex-column': ''">
                          <span :class="!isMobile? 'mw-445':''">
                            {{
                              $t("components.2faModal.twoFaModalNumberTitle")
                            }}</span
                          >
                          <v-btn flat
                          :class="isMobile? 'mt-2': ''"
                            class="ml-2"
                            color="primary"
                            @click="toggle2faActivationModal"
                          >
                            {{ $t("generics.display") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 2">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div class="d-flex align-center" :class="isMobile? 'flex-column': ''">
                          <span>
                            {{
                              $t("components.holidays.setYourHolidays")
                            }}</span
                          >
                          <v-btn flat
                            class="ml-2"
                            :class="isMobile? 'mt-2': ''"
                            color="primary"
                            @click="toggleShowHolidaysModal(null)"
                          >
                            {{ $t("generics.manage") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 3">
                  <div class="w100">
                    <v-list-item class="pl-0 py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div class="d-flex align-center">
                          <v-btn flat
                            class="ml-2"
                            color="primary"
                            @click="showProtocol"
                          >
                            {{ $t("generics.display") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 4">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="pl-0 py-1 my-0 px-2 w100">
                        <div class="d-flex align-center">
                          <v-btn flat
                            class="ml-2"
                            color="primary"
                            @click="showDialUpTime"
                          >
                            {{ $t("generics.display") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 5">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div class="d-flex align-center" :class="isMobile? 'flex-column': ''">
                          <span>
                            {{ $t("components.userSettings.startView") }}</span
                          >
                          <v-btn flat
                            class="ml-2"
                            color="primary"
                            @click="showStartView"
                            :class="isMobile? 'mt-2 mb-2': ''"
                          >
                            {{ $t("generics.display") }}</v-btn
                          >
                          <span class="mx-5"
                            >{{
                              $t("components.userSettings.selectedSetting")
                            }}
                            {{ getDefaultPageText() }}</span
                          >
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 7">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <v-radio-group
                          inline
                          class="ml-6 mt-0 px-4"
                          :model-value="radiosLanguage" @update:model-value="radiosLanguage = $event; changeLanguage()"
                          :mandatory="false"
                        >
                          <v-radio value="DE" style="width: 150px!important">
                            <!-- <template v-slot:label> -->
                              <!-- <v-list-item> -->
                                <v-avatar
                                  tile
                                  width="23"
                                  height="17"
                                  min-width="23"
                                  max-height="17"
                                  max-width="23"
                                >
                                  <v-img width="23"
                                  height="17"
                                  min-width="23"
                                  max-height="17"
                                  max-width="23" 
                                  :src="'img/flags/DE.png'"></v-img>
                                </v-avatar>
                                <label class="notranslate text-primary mr-2">
                                  DE
                                </label>
                              <!-- </v-list-item> -->
                            <!-- </template> -->
                          </v-radio>
                          <v-radio value="EN"  style="width: 150px!important">
                            <!-- <template v-slot:label>
                              <v-list-item> -->
                                <v-avatar
                                  tile
                                  width="23"
                                  height="17"
                                  min-width="23"
                                  max-height="17"
                                  max-width="23"
                                >
                                  <v-img  
                                  height="17"
                                  min-width="23"
                                  max-height="17"
                                  max-width="23" 
                                  :src="'img/flags/EN.png'"></v-img>
                                </v-avatar>
                                <label
                                  class="notranslate text-primary mr-2"
                                  >EN</label
                                >
                              <!-- </v-list-item>
                            </template> -->
                          </v-radio>
                          <v-radio value="ES"  style="width: 150px!important">
                            <!-- <template v-slot:label>
                              <v-list-item> -->
                                <v-avatar
                                  tile
                                  width="23"
                                  height="17"
                                  min-width="23"
                                  max-height="17"
                                  max-width="23"
                                >
                                  <v-img
                                  height="17"
                                  min-width="23"
                                  max-height="17"
                                  max-width="23"  
                                  :src="'img/flags/ES.png'"></v-img>
                                </v-avatar>
                                <label
                                  class="notranslate text-primary mr-2"
                                  >ES</label
                                >
                              <!-- </v-list-item>
                            </template> -->
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 8">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div class="w100">
                          <span>{{$t("components.userSettings.sortFavoritesList")}}</span>
                          <v-radio-group
                            :column="isMobile"
                            :inline="!isMobile"
                            hide-details
                            class="ml-2 mt-0"
                            :model-value="favoritesSort" @update:model-value="favoritesSort = $event; changefavoritesSort()"
                            :mandatory="false"
                          >
                            <v-radio value="lastName">
                              <template v-slot:label>
                                <v-list-item>
                                  <v-list-item-title
                                    class="notranslate text-primary"
                                    >{{
                                      $t("components.userSettings.sortByLastName") 
                                    }}</v-list-item-title
                                  >
                                </v-list-item>
                              </template>
                            </v-radio>
                            <v-radio value="alphabetical">
                              <template v-slot:label>
                                <v-list-item>
                                  <v-list-item-title
                                    class="notranslate text-primary"
                                    >{{
                                      $t("components.userSettings.sortByFirstName")
                                    }}</v-list-item-title
                                  >
                                </v-list-item>
                              </template>
                            </v-radio>
                            <v-radio value="statusColor">
                              <template v-slot:label>
                                <v-list-item>
                                  <v-list-item-title
                                    class="notranslate text-primary"
                                    >{{
                                      $t("components.userSettings.sortByStatusColor")
                                    }}</v-list-item-title
                                  >
                                </v-list-item>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                        <div class="w100">
                          <span>{{$t("components.userSettings.favoritesViewOnMobile")}}</span>
                          <v-radio-group
                            :column="isMobile"
                            :inline="!isMobile"
                            hide-details
                            class="ml-2 mt-0"
                            :model-value="favoritesViewMobile" @update:model-value="favoritesViewMobile = $event; changefavoritesViewMobile()"
                            :mandatory="false"
                          >
                            <v-radio value="grid">
                              <template v-slot:label>
                                <v-list-item>
                                  <v-list-item-title
                                    class="notranslate text-primary"
                                    >{{
                                      $t("generics.grid")
                                    }}</v-list-item-title
                                  >
                                </v-list-item>
                              </template>
                            </v-radio>
                            <v-radio value="swipe">
                              <template v-slot:label>
                                <v-list-item>
                                  <v-list-item-title
                                    class="notranslate text-primary"
                                    >{{
                                       $t("generics.swipe")
                                    }}</v-list-item-title
                                  >
                                </v-list-item>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 9">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <v-radio-group
                          inline
                          class="ml-2 mt-0"
                          :model-value="usersSort" @update:model-value="usersSort = $event;changeUsersSort()"
                          :mandatory="false"
                        >
                          <v-radio value="lastName">
                            <template v-slot:label>
                              <v-list-item>
                                <v-list-item-title
                                  class="notranslate text-primary"
                                  >{{
                                    $t("components.userSettings.sortByLastName")
                                  }}</v-list-item-title
                                >
                              </v-list-item>
                            </template>
                          </v-radio>
                          <v-radio value="alphabetical">
                            <template v-slot:label>
                              <v-list-item>
                                <v-list-item-title
                                  class="notranslate text-primary"
                                  >{{
                                    $t("components.userSettings.sortByFirstName")
                                  }}</v-list-item-title
                                >
                              </v-list-item>
                            </template>
                          </v-radio>
                          <v-radio value="statusColor">
                            <template v-slot:label>
                              <v-list-item>
                                <v-list-item-title
                                  class="notranslate text-primary"
                                  >{{
                                    $t("components.userSettings.sortByStatusColor")
                                  }}</v-list-item-title
                                >
                              </v-list-item>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 11">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div class="d-flex align-center" :class="isMobile? 'flex-column': ''">
                          <span>
                            {{ $t("components.userSettings.speechLive") }}</span
                          >
                          <v-btn flat
                            class="ml-2"
                            color="primary"
                            :class="isMobile? 'mt-2': ''"
                            @click="toggleSpeechlive"
                          >
                            {{ $t("generics.manage") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 13">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div class="d-flex align-center" :class="isMobile? 'flex-column': ''">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <v-btn flat
                                v-bind="props"
                                class="ml-2"
                                :class="isMobile? 'mb-2': ''"
                                color="primary"
                                @click="goToStats('stats')"
                              >
                                {{ $t("components.stats.status") }}</v-btn
                              >
                            </template>
                            <span>{{
                              $t("components.userSettings.sortedUserStatusTT")
                            }}</span>
                          </v-tooltip>
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <v-btn flat
                                v-bind="props"
                                class="ml-2"
                                color="primary"
                                @click="goToStats('conference')"
                              >
                                {{
                                  $t("components.stats.conferenceStats")
                                }}</v-btn
                              >
                            </template>
                            <span>{{
                              $t("components.userSettings.conferenceStatsTT")
                            }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 14">
                  <div class="w100">
                    <v-list-item class="pl-0 py-0 switchWrapper">
                      <div class="pl-0 py-1 my-0 px-2 w100">
                        <div class="d-flex align-center">
                          <v-tooltip location="top" max-width="300">
                            <template v-slot:activator="{ props }">
                              <v-btn flat
                                v-bind="props"
                                class="ml-2"
                                color="primary"
                                @click="
                                  setShowModalSendInvitation(
                                    state.ownUUID,
                                    state.user
                                  )
                                "
                              >
                                {{ $t("generics.send") }}</v-btn
                              >
                            </template>
                            <span>{{
                              $t("components.userSettings.sendEmailTT")
                            }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 15">
                  <div class="w100">
                    <v-list-item class="py-0 switchWrapper">
                      <div class="py-1 my-0 px-2 w100">
                        <div id="combobox_waitingRoomAssistants" class="d-flex align-center">
                          <v-combobox
                            variant="outlined"
                            :model-value="waitingRoomAssistants" @update:model-value="waitingRoomAssistants = $event;searchUsers = '';saveWaitingRoomAssistants();"
                            :items="dataComboboxAllUsers"
                            :search-input.sync="searchUsers"
                            select
                            :label="
                              $t('components.waitingRoom.waitingroomAssistants')
                            "
                            multiple
                            hide-details
                            class="mb-5"
                            density="compact"
                            item-title="text"
                            item-value="value"
                            :return-object="true"
                            attach="#combobox_waitingRoomAssistants"
                          >
                          </v-combobox>
                        </div>
                      </div>
                    </v-list-item>
                  </div>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 16">
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-2 my-0 px-2 w100">
                      <div id="select_screenSaver" class="d-flex align-center">
                        <v-select
                          :model-value="screensaverMinutes" @update:model-value="screensaverMinutes = $event; saveScreenSaver()"
                          :items="dataComboBoxScreensaver"
                          :label="
                            $t('components.userSettings.screenSaverLabel')
                          "
                          hide-details
                          variant="outlined"
                          density="compact"
                          class="mb-5"
                          item-title="text"
                          item-value="value"
                          return-object
                          attach="#select_screenSaver"
                        ></v-select>
                      </div>
                    </div>
                  </v-list-item>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 17">
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-1 my-0 px-2 w100">
                      <div class="d-flex align-center">
                        <v-checkbox
                          :model-value="enabledBeaconUserSetting" @update:model-value="enabledBeaconUserSetting = $event; changeBeaconSetting()"
                          :label="$t('generics.active')"
                          hide-details
                          class="mt-0"
                        ></v-checkbox>
                      </div>
                    </div>
                  </v-list-item>
                  <v-list-item class="pt-2 switchWrapper">
                    <div class="py-1 my-0 px-2 w100">
                      <div class="align-center">
                        <v-radio-group
                          inline
                          :model-value="beaconCall" @update:model-value="beaconCall = $event; changeBeaconCall()"
                          class="ml-0 mt-2"
                        >
                          <v-radio
                            :label="$t('components.userSettings.directCall')"
                            value="directCall"
                          ></v-radio>
                          <v-radio
                            :label="$t('components.userSettings.waitingCall')"
                            value="waitingCall"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </v-list-item>
                  <v-list-item class="pt-0 switchWrapper">
                    <div class="py-1 my-0 px-2 w100">
                      <div class="align-center">
                        <span>
                          {{ $t("components.userSettings.beaconInCall") }}</span
                        >
                        <v-radio-group
                          inline
                          :model-value="beaconInCall" @update:model-value="beaconInCall = $event; changeBeaconInCall()"
                          class="mt-2"
                        >
                          <v-radio
                            :label="
                              $t('components.userSettings.beaconInCallActive')
                            "
                            value="active"
                          ></v-radio>
                          <v-radio
                            :label="
                              $t('components.userSettings.beaconInCallInactive')
                            "
                            value="inactive"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </v-list-item>
                </v-expansion-panel-text>
                <v-expansion-panel-text v-if="item.index == 18">
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-1 my-0 px-2 w100">
                      <v-row class="pl-2">
                        <v-col class="pl-0" cols="12"
                          >{{ $t("components.userSettings.plannerExport") }}
                          <v-tooltip location="top" max-width="450">
                            <template v-slot:activator="{ props }">
                              <font-awesome-icon
                                v-bind="props"
                                color="#2a3133"
                                :icon="['fal', 'info-circle']"
                                :style="{
                                  fontSize: '17px',
                                  position: 'relative',
                                  top: '2px',
                                }"
                                @click="isMobile? openInfoModal( $t('generics.info'), $t('components.userSettings.plannerExportTT')) :  null"
                              />
                            </template>
                            <span>{{
                              $t("components.userSettings.plannerExportTT")
                            }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col :cols="isMobile? '12':'10'" :class="isMobile?'py-2 pl-2':'py-0 px-0'">
                          <v-text-field
                            autocomplete="off"
                            :value="calendarExportURL"
                            hide-details
                            :label="
                              $t('components.userSettings.calendarExport')
                            "
                            variant="outlined"
                            density="compact"
                            readonly="readonly"
                            ref="calendarExportURLRef"
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="isMobile?'12':'2'" class="py-0 px-0">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <font-awesome-icon
                                v-bind="props"
                                @click="copycalendarExportURL()"
                                class="outlookSave"
                                :icon="['fal', 'copy']"
                                :style="{
                                  fontSize: '20px',
                                  color: colorIconCopycalendarExportURL,
                                }"
                                :class="isMobile? 'mb-0': ''"
                                
                              />
                            </template>
                            <span>{{ $t("generics.copy") }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-1 my-0 px-2 w100">
                      <v-row class="pl-2">
                        <v-col class="pl-0 " cols="12"
                          >{{ $t("components.userSettings.outlookCalendar") }}
                          <v-tooltip location="top" max-width="450">
                            <template v-slot:activator="{ props }">
                              <font-awesome-icon
                                v-bind="props"
                                color="#2a3133"
                                :icon="['fal', 'info-circle']"
                                :style="{
                                  fontSize: '17px',
                                  position: 'relative',
                                  top: '2px',
                                }"
                                @click="isMobile? openInfoModal( $t('generics.info'), $t('components.userSettings.outlookCalendarTT')) :  null"
                              />
                            </template>
                            <span>{{
                              $t("components.userSettings.outlookCalendarTT")
                            }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col :cols="isMobile? '12':'10'" :class="isMobile?'py-2 pl-2':'py-0 px-0'">
                          <v-text-field
                            autocomplete="off"
                            :model-value="outlookCalendarImportURL" @update:model-value="outlookCalendarImportURL = $event"
                            hide-details
                            :label="
                              $t(
                                'components.userSettings.outlookCalendarExport'
                              )
                            "
                            variant="outlined"
                            density="compact"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col :cols="isMobile?'12':'2'" class="py-0 px-0" >
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <font-awesome-icon
                                v-bind="props"
                                @click="saveOutlookImportUrl"
                                class="text-primary outlookSave"
                                :icon="['fal', 'save']"
                                :style="{ fontSize: '20px' }"
                                :class="isMobile? 'mb-0': ''"
                              />
                            </template>
                            <span>{{ $t("generics.save") }}</span>
                          </v-tooltip>
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <font-awesome-icon
                                v-bind="props"
                                @click="deleteOutlookImportUrl"
                                class="text-primary outlookSave"
                                :icon="['fal', 'trash']"
                                :style="{ fontSize: '20px' }"
                                :class="isMobile? 'mb-0': ''"
                              />
                            </template>
                            <span>{{ $t("generics.remove") }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-1 my-0 pl-2 w100">
                      <v-row class="pl-2">
                        <v-col class="pl-0" cols="12"
                          >{{ $t("components.userSettings.appleCalendar") }}
                          <v-tooltip location="top" max-width="450">
                            <template v-slot:activator="{ props }">
                              <font-awesome-icon
                                v-bind="props"
                                color="#2a3133"
                                :icon="['fal', 'info-circle']"
                                :style="{
                                  fontSize: '17px',
                                  position: 'relative',
                                  top: '2px',
                                }"
                                @click="isMobile? openInfoModal( $t('generics.info'), $t('components.userSettings.appleCalendarTT')) :  null"
                              />
                            </template>
                            <span>{{
                              $t("components.userSettings.appleCalendarTT")
                            }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col :cols="isMobile? '12':'10'" :class="isMobile?'py-2 pl-2':'py-0 px-0'">
                          <v-text-field
                            autocomplete="off"
                            :model-value="appleCalendarImportURL" @update:model-value="appleCalendarImportURL = $event"
                            hide-details
                            :label="
                              $t('components.userSettings.appleCalendarExport')
                            "
                            variant="outlined"
                            density="compact"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col :cols="isMobile?'12':'2'" class="py-0 px-0">
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <font-awesome-icon
                                v-bind="props"
                                @click="saveAppleImportUrl"
                                class="text-primary outlookSave"
                                :icon="['fal', 'save']"
                                :style="{ fontSize: '20px' }"
                                :class="isMobile? 'mb-0': ''"
                               
                              />
                            </template>
                            <span>{{ $t("generics.save") }}</span>
                          </v-tooltip>
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <font-awesome-icon
                                v-bind="props"
                                @click="deleteAppleImportUrl"
                                class="text-primary outlookSave"
                                :icon="['fal', 'trash']"
                                :style="{ fontSize: '20px' }"
                                :class="isMobile? 'mb-0': ''"
                               
                              />
                            </template>
                            <span>{{ $t("generics.remove") }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                </v-expansion-panel-text>
                <!-- User additional info (item 19) -->
                <v-expansion-panel-text v-if="item.index == 19" class="additionalInfo">
                  <!-- Ausbildung/Abschluss/Beruf/Hintergrund / Education/degree/occupation/background madatory field -->
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-1 my-0 pl-2 w100">
                      <v-row class="pl-2">
                        <v-col class="pl-0 pb-0 pt-1  font-weight-medium" cols="12"
                          >{{ $t('components.userSettings.educationBackground') }}
                        </v-col>
                        <v-col cols="12" :class="isMobile?'py-2 pl-2':'pl-0'">
                          <v-textarea
                            autocomplete="off"
                            :model-value="educationBackground" @update:model-value="educationBackground = $event"
                            :maxlength=300
                            auto-grow
                            rows=1
                            variant="outlined"
                            counter
                            density="compact"
                            :rules="educationRules"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                  <!-- Seit (Datum der Teamzugehörigkeit) start date in team DATEPICKER to fill it -->
                  <v-list-item class="py-0 switchWrapper">
                    <div class="pt-0 pb-3 my-0 pl-2">
                      <v-row class="pl-2">
                        <v-col class="pl-0 pb-0 pt-2 font-weight-medium" cols="12"
                          >{{ $t('components.userSettings.startDate') }}
                        </v-col>
                        <v-col cols="12" :class="isMobile?'py-2 pl-2':'pl-0'">
                          <v-menu
                            ref="startTeamDate"
                            id="startTeamDate"
                            :model-value="startDateMenu" @update:model-value="startDateMenu = $event"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ props }">
                              <div class="date-picker-wrapper">
                                <v-text-field
                                  :model-value="startTeamDate"
                                  @update:model-value="startTeamDate = $event"
                                  readonly
                                  v-bind="props"
                                  density="compact"
                                  hide-details
                                  variant="outlined"
                                />
                                <DeleteBasket
                                  indexData
                                  :delFunction="cleanDateTeamStart"
                                  extraClasses="dateTeamStartBasket ml-2"
                                  fontSize="18"
                                />
                              </div>
                            </template>
                            <v-date-picker
                              :model-value="datePickerValue"
                               @update:model-value="onDateSelected"
                              no-title
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                  <!-- Zusätzliche Kenntnisse/Fähigkeiten/Ausbildung / Additional knowledge/skills/education -->
                  <v-list-item class="py-0 mt-3 switchWrapper">
                    <div class="py-1 my-0 pl-2 w100">
                      <v-row class="pl-2">
                        <v-col class="pl-0 pb-0 pt-1 font-weight-medium" cols="12"
                          >{{ $t('components.userSettings.additionalKnowledge') }}
                        </v-col>
                        <v-col cols="12" :class="isMobile?'py-2 pl-2':'pl-0'">
                          <v-textarea
                            autocomplete="off"
                            :model-value="additionalKnowledge" @update:model-value="additionalKnowledge = $event"
                            maxlength="300"
                            auto-grow
                            rows=1
                            variant="outlined"
                            counter
                            density="compact"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                  <!-- Kurzbeschreibung Tätigkeit / Brief description of activity (sinnvolle Zeichenbegrenzungen) -->
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-1 my-0 pl-2 w100">
                      <v-row class="pl-2">
                        <v-col class="pl-0 pb-0 pt-1 font-weight-medium" cols="12"
                          >{{ $t('components.userSettings.briefDesc') }}
                        </v-col>
                        <v-col cols="12" :class="isMobile?'py-2 pl-2':'pl-0'">
                          <v-textarea
                            autocomplete="off"
                            :model-value="additionalBriefDesc" @update:model-value="additionalBriefDesc = $event"
                            maxlength="500"
                            auto-grow
                            rows=1
                            variant="outlined"
                            counter
                            density="compact"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                  <!-- Berufserfahrung / work experience -->
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-1 my-0 pl-2 w100">
                      <v-row class="pl-2">
                        <v-col class="pl-0 pb-0 pt-1  font-weight-medium" cols="12"
                          >{{ $t('components.userSettings.workExp') }}
                        </v-col>
                        <v-col cols="12" :class="isMobile?'py-2 pl-2':'pl-0'">
                          <v-textarea
                            autocomplete="off"
                            :model-value="additionalWorkExp" @update:model-value="additionalWorkExp = $event"
                            maxlength="300"
                            auto-grow
                            rows=1
                            variant="outlined"
                            counter
                            density="compact"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                  <!-- Interessen / Interests -->
                  <v-list-item class="py-0 switchWrapper">
                    <div class="py-1 my-0 pl-2 w100">
                      <v-row class="pl-2">
                        <v-col class="pl-0 pb-0 pt-1 font-weight-medium" cols="12"
                          >{{ $t('components.userSettings.interests') }}
                        </v-col>
                        <v-col cols="12" :class="isMobile?'py-2 pl-2':'pl-0'">
                          <v-textarea
                            autocomplete="off"
                            :model-value="additionalInterests" @update:model-value="additionalInterests = $event"
                            maxlength="300"
                            auto-grow
                            rows=1
                            variant="outlined"
                            counter
                            density="compact"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item>
                  <v-col cols="12" class="pl-0 saveButton text-end mx-0">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn flat
                          v-bind="props"
                          :class="isMobile ? 'mt-2 mb-2': ''"
                          class="ml-2"
                          color="primary"
                          :disabled="educationBackground.trim().length <= 0"
                          @click="saveAdditionalUserInfo()"
                        >
                        {{ $t("generics.save") }}</v-btn
                      >
                      </template>
                      <span>{{ $t("generics.save") }}</span>
                    </v-tooltip>
                  </v-col>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModalFunction" />
    </v-card>
    <TwoFactorActivationModal
      v-if="showActivation"
      :showActivation="showActivation"
      :toggle2faActivationModal="toggle2faActivationModal"
      :saveEntryNoPhone="saveEntryNoPhone"
    ></TwoFactorActivationModal>
    <SendInvitationModal
      v-if="showInvitationModal"
      :closeShowInviteInfo="closeShowInviteInfo"
      :showInviteInfo="showInvitationModal"
    />
  </v-dialog>
</template>

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { hasPrivilege } from "../../utils/privileges";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { dispatchSuccessAlert } from "../../effector/alerts";
import TwoFactorActivationModal from "../2fa/modals/steps/2faActivationModal.vue";
import { resetUserSettingsEvent } from "../../effector/modals";
import { canShowSpeechliveByDomain } from "../../utils/basicFunctions";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import moment from "../../../sharedsrc/moment";
import DeleteBasket from "../ui/deleteBasket.vue";
import {
  sendInviteMailCustom,
  setRemoteUser,
  getCalendarExportURL,
  setRepresentedUsers
} from "../../lib/wsMsg";
export default {
  props: [
    "showModal",
    "toggle2faSettings",
    "toggleShowHolidaysModal",
    "showProtocol",
    "showDialUpTime",
    "showStartView",
    "toggleSpeechlive",
  ],
  data() {
    return {
      moment: moment,
      state: store.state,
      isMobile: isMobile(),
      showActivation: false,
      calendarExportURL: "",
      outlookCalendarImportURL:
        store.state.user.userSettings.outlookCalendarImportURL,
      appleCalendarImportURL:
        store.state.user.userSettings.appleCalendarImportURL,
      showWaitingRoom: store.state.user.userSettings.showWaitingRoom,
      showVideoCallPlanner: store.state.user.userSettings.showVideoCallPlanner,
      showLocation: store.state.user.userSettings.showLocation,
      showSpeechLive: store.state.user.userSettings.showSpeechLive,
      dynamicSidePanel: store.state.user.userSettings.dynamicSidePanel,
      showStatusNumber: store.state.user.userSettings.showStatusNumber,
      panel: [],
      radiosLanguage: "",
      radiosLocation: "",
      usersSort: store.state.user.userSettings.usersSort || "statusColor",
      favoritesSort:
        store.state.user.userSettings.favoritesSort || "alphabetical",
      favoritesViewMobile: store.state.user.userSettings.favoritesViewMobile || "grid",
      messagesSort: store.state.user.userSettings.messagesSort || "newestAbove", //"newestBelow",
      beacon: store.state.user.userSettings.beacon,
      expansionsPanles: [],
      showInvitationModal: false,
      waitingRoomAssistants: [],
      originalWaitingRoomAssistants: [],
      searchUsers: null,
      screensaverMinutes: store.state.user.userSettings.screensaver || {
        text: this.$t("components.adminSettingsModal.never"),
        value: "never",
      },
      enabledBeaconUserSetting:
        store.state.user.userSettings.enabledBeaconUserSetting || false,
      beaconCall: store.state.user.userSettings.beaconCall || "waitingCall",
      beaconInCall: store.state.user.userSettings.beaconInCall || "active",
      colorIconCopycalendarExportURL: "#2a3133",
      showAssistantAppointment:
        store.state.user.userSettings.showAssistantAppointment,
      educationBackground: store.state.user.userSettings.educationBackground || "",
      additionalKnowledge : store.state.user.userSettings.additionalKnowledge || "",
      additionalBriefDesc: store.state.user.userSettings.additionalBriefDesc || "",
      additionalWorkExp: store.state.user.userSettings.additionalWorkExp || "",
      additionalInterests: store.state.user.userSettings.additionalInterests || "",
      additionalStartDate: store.state.user.userSettings.additionalStartDate ? new Date(store.state.user.userSettings.additionalStartDate) : '',
      startDateMenu: false,
      educationRules: [v => !!v || this.$t("generics.required")],
    };
  },
  components: {
    HeaderModal,
    FooterModal,
    TwoFactorActivationModal,
    SendInvitationModal,
    DeleteBasket
  },
  created() {
    getCalendarExportURL().then((value) => (this.calendarExportURL = value));
  },
  mounted() {
    this.radiosLanguage = this.$locale.current().toUpperCase();
    this.radiosLocation = this.state.user.userLocation;
  },
  watch: {
    "state.user.waitingRoomAssistants": {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.getWaitingRoomAssistants();
        }
      },
    },
  },
  methods: {
    onDateSelected(value) {
      this.additionalStartDate = value;
      this.startDateMenu = false; // Close the menu after a date is selected
    },
    cleanDateTeamStart() {
      this.additionalStartDate = '';
    },
    saveAdditionalUserInfo(){
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.educationBackground =  this.educationBackground;
      temporalUserSettings.additionalKnowledge =  this.additionalKnowledge;
      temporalUserSettings.additionalBriefDesc =  this.additionalBriefDesc;
      temporalUserSettings.additionalWorkExp =  this.additionalWorkExp;
      temporalUserSettings.additionalInterests =  this.additionalInterests;
      temporalUserSettings.additionalStartDate = this.additionalStartDate;
      dispatchSuccessAlert(
        this.$t("components.bridgeCallTooltips.settingsSaveSuccess")
      );
      return store.setUserSettings(temporalUserSettings);
    },
    openInfoModal(header, body){
      if(header && body){
        const data = {
              show: true,
              header: header,
              body: body,
        };
        return store.setinfoModal(data);
      }
    },
    openInfoModalMobile(index){
      if(this.isMobile && (index == 16 || index == 5 || index == 15 || index == 18)){
        let text = ''
        switch (index) {
          case 16:
            text = this.$t("components.userSettings.screensaverTT")
            break;
          case 5:
            text = this.$t("components.userSettings.startviewTT")
            break;
          case 15:
            text = this.$t("components.userSettings.waitingroomTT")
            break;
          case 18:
            text = this.$t("components.userSettings.calendarInfoTT")
            break;
          default:
            break;
        }
        this.openInfoModal(this.$t("generics.info"), text)
      }
    },
    changeAssistantAppointment() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.showAssistantAppointment =
        this.showAssistantAppointment;
      return store.setUserSettings(temporalUserSettings);
    },
    copycalendarExportURL() {
      let text = this.calendarExportURL;
      navigator.clipboard.writeText(text).then(
        () => {
          this.colorIconCopycalendarExportURL = "green";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
      setTimeout(() => {
        this.colorIconCopycalendarExportURL = "#2a3133";
      }, 500);
    },
    saveAppleImportUrl() {
      if (this.appleCalendarImportURL == "") return;
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.appleCalendarImportURL = this.appleCalendarImportURL;
      dispatchSuccessAlert(
        this.$t("components.bridgeCallTooltips.settingsSaveSuccess")
      );
      return store.setUserSettings(temporalUserSettings);
    },
    deleteAppleImportUrl() {
      const temporalUserSettings = this.state.user.userSettings;
      if (temporalUserSettings.appleCalendarImportURL == "") return;
      temporalUserSettings.appleCalendarImportURL = "";
      this.appleCalendarImportURL = "";
      dispatchSuccessAlert(
        this.$t("components.bridgeCallTooltips.settingsSaveSuccess")
      );
      return store.setUserSettings(temporalUserSettings);
    },
    saveOutlookImportUrl() {
      if (this.outlookCalendarImportURL == "") return;
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.outlookCalendarImportURL =
        this.outlookCalendarImportURL;
      dispatchSuccessAlert(
        this.$t("components.bridgeCallTooltips.settingsSaveSuccess")
      );
      return store.setUserSettings(temporalUserSettings);
    },
    deleteOutlookImportUrl() {
      const temporalUserSettings = this.state.user.userSettings;
      if (temporalUserSettings.outlookCalendarImportURL == "") return;
      temporalUserSettings.outlookCalendarImportURL = "";
      this.outlookCalendarImportURL = "";
      dispatchSuccessAlert(
        this.$t("components.bridgeCallTooltips.settingsSaveSuccess")
      );
      return store.setUserSettings(temporalUserSettings);
    },
    changeBeaconSetting() {
      let temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.enabledBeaconUserSetting =
        this.enabledBeaconUserSetting;
      return store.setUserSettings(temporalUserSettings);
    },
    saveScreenSaver() {
      let temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.screensaver = this.screensaverMinutes;
      return store.setUserSettings(temporalUserSettings);
    },
    hasPrivilege(userUuid) {
      return hasPrivilege(userUuid);
    },
    getWaitingRoomAssistants() {
      const fullArray = this.state.user.assistants.concat(
        this.state.user.waitingRoomAssistants
      );
      const replacedDuplicates = fullArray.filter((value, index) => {
        return fullArray.indexOf(value) === index && (value in this.state.group);
      });
      this.waitingRoomAssistants = replacedDuplicates.map((uuid) => {
        return {
          text: this.state.group[uuid].user.name,
          value: uuid,
          disabled:
            this.state.user.assistants.indexOf(uuid) !== -1 ? true : false,
        };
      });
      this.originalWaitingRoomAssistants = this.waitingRoomAssistants;
    },
    async saveWaitingRoomAssistants() {
      // copy originalWaitingRoomAssistants for use in impersonate person
      const originalRepresentative = this.originalWaitingRoomAssistants
      if (this.originalWaitingRoomAssistants.length > 0) {
        for (let i = 0; i < this.originalWaitingRoomAssistants.length; i++) {
          const waitingRoomAssistantUUID =
            this.originalWaitingRoomAssistants[i].value;
          if (
            this.waitingRoomAssistants.indexOf(waitingRoomAssistantUUID) === -1
          ) {
            // original assistant removed
            // remove in waitingRoomAssists the user uuid remote
            const waitingRoomAssists =
              this.state.group[waitingRoomAssistantUUID].user
                .waitingRoomAssists;
            await setRemoteUser(waitingRoomAssistantUUID, {
              waitingRoomAssists: waitingRoomAssists.filter(
                (uuid) => uuid !== this.state.ownUUID
              ),
            });
          }
        }
      }
      for (let i = 0; i < this.waitingRoomAssistants.length; i++) {
        // add in waitingRoomAssists the user uuid remote
        const waitingRoomAssistantUUID = this.waitingRoomAssistants[i].value;
        const waitingRoomAssists =
          this.state.group[waitingRoomAssistantUUID].user.waitingRoomAssists ||
          [];
        if (waitingRoomAssists.indexOf(this.state.ownUUID) == -1) {
          await setRemoteUser(waitingRoomAssistantUUID, {
            waitingRoomAssists: [...waitingRoomAssists, this.state.ownUUID],
          });
        }
      }
      // change own waiting room assistants
      const finalResult = this.waitingRoomAssistants.map((user) => {
        return user.value;
      });
      await setRemoteUser(this.state.ownUUID, {
        waitingRoomAssistants: finalResult,
      });
      // set assistants to impersonate person
      let arrayUser = this.waitingRoomAssistants.map(assistants => {
        return { uuid: assistants.value }
      })
      let activeRepresentative = !!arrayUser.length
      store.setRepresentatives(activeRepresentative, arrayUser);
      if(!activeRepresentative){
        originalRepresentative.forEach(element => {
          setRepresentedUsers(element.value, this.state.ownUUID, activeRepresentative);
        });
      }
      arrayUser.forEach(element => {
        setRepresentedUsers(element.uuid, this.state.ownUUID, activeRepresentative);
      });
    },
    closeShowInviteInfo() {
      this.showInvitationModal = false;
    },
    async setShowModalSendInvitation(uuid, person) {
      await sendInviteMailCustom(uuid, person);
      store.state.sendInvitationGuestModal = {
        newInvitation: true,
        invitationData: {
          name: person.name,
          email: person.emailAlias || person.email,
          date: new Date(),
        },
      };
      this.showInvitationModal = true;
      setTimeout(() => {
        if (this.showInvitationModal) {
          this.showInvitationModal = false;
        }
      }, 2000);
    },
    goToStats(section) {
      if (section == "stats") {
        this.setCurrentContentVisile("/status/available", true, this.$router);
      } else if (section == "conference") {
        this.setCurrentContentVisile("/statsConferences", true, this.$router);
      }
      this.closeModalFunction();
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    saveEntryNoPhone() {
      this.showActivation = false;
    },
    toggle2faActivationModal() {
      this.showActivation = !this.showActivation;
    },
    changefavoritesSort() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.favoritesSort = this.favoritesSort;
      return store.setUserSettings(temporalUserSettings);
    },
    changefavoritesViewMobile() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.favoritesViewMobile = this.favoritesViewMobile;
      return store.setUserSettings(temporalUserSettings);
    },
    changeUsersSort() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.usersSort = this.usersSort;
      return store.setUserSettings(temporalUserSettings);
    },
    changeLanguage() {
      this.panel = [];
      setTimeout(() => {
        this.$locale.change(this.radiosLanguage.toLowerCase());
        this.$set(this.state.user, "language", this.$locale.current());
      }, 300);
    },
    changeBeaconInCall() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.beaconInCall = this.beaconInCall;
      return store.setUserSettings(temporalUserSettings);
    },
    changeBeaconCall() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.beaconCall = this.beaconCall;
      return store.setUserSettings(temporalUserSettings);
    },
    changeSidePanel() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.dynamicSidePanel = this.dynamicSidePanel;
      return store.setUserSettings(temporalUserSettings);
    },
    changeStatusNumber() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.showStatusNumber = this.showStatusNumber;
      return store.setUserSettings(temporalUserSettings);
    },
    closeModalFunction() {
      resetUserSettingsEvent();
    },
    showHideSection() {
      // showOneCalls: this.showOneCalls,
      // showGroups: this.showGroups,
      const temporalUserSettings = {
        showWaitingRoom: this.showWaitingRoom,
        showVideoCallPlanner: this.showVideoCallPlanner,
        showLocation: this.showLocation,
        showSpeechLive: this.showSpeechLive,
      };
      return store.setUserSettings(temporalUserSettings);
    },
    getDefaultPageText() {
      let daPage = store.state.user.userSettings.startView;
      let selectedOption = this.$t("components.userSettings.favorites");
      switch (daPage) {
        case "home":
          selectedOption = " Logo";
          break;
        case "my-favorites":
          selectedOption = this.$t("components.userSettings.favorites");
          break;
        case "users":
          selectedOption = this.$t("components.userSettings.user");
          break;
        case "organisation":
          selectedOption = this.$t("components.userSettings.organization");
          break;
        case "waitingroom":
          selectedOption = this.$t("components.waitingRoom.waitingRoom");
          break;
        default:
          selectedOption = this.$t("components.userSettings.favorites");
          break;
      }
      return " " + selectedOption;
    },
  },
  computed: {
    datePickerValue() {
      if (!this.additionalStartDate) {
        return new Date(); // Ensure it returns today's date if additionalStartDate is not set
      }
      return new Date(this.additionalStartDate);
    },
    startTeamDate() {
      if (!this.additionalStartDate) {
        return null;
      }
      return this.moment(new Date(this.additionalStartDate)).format("DD.MM.YYYY");
    },
    dataComboBoxScreensaver() {
      const never = this.$t("components.adminSettingsModal.never");
      const duration = [
        600000,
        1200000,
        1800000,
        2400000,
        3000000,
        3600000,
        "never",
      ];
      const durationText = ["10", "20", "30", "40", "50", "60", "never"];
      let finalResult = [];
      duration.forEach((minute, i) => {
        const item = {
          value: minute === "never" ? "never" : minute,
          text:
            minute != "never"
              ? durationText[i] +
                " " +
                this.$t("components.eventEntryForm.minutes")
              : never,
        };
        finalResult.push(item);
      });
      return finalResult;
    },
    dataComboboxAllUsers() {
      const membersList = this.state.group;
      const assistants = this.state.user.assistants
        ? Object.values(this.state.user.assistants)
        : [];
      let finalResult = [];
      for (let uuid in membersList) {
        let person = JSON.parse(JSON.stringify(membersList[uuid]));
        if (
          person &&
          person.user &&
          person.user.name &&
          person.user.uuid &&
          person.user.uuid !== this.state.ownUUID &&
          hasPrivilege(uuid)
        ) {
          const item = {
            value: person.user.uuid,
            text: person.user.name,
            disabled:
              assistants.indexOf(person.user.uuid) !== -1 ? true : false,
          };
          finalResult.push(item);
        }
      }
      return finalResult;
    },
    getExpansionPanels() {
      return [
        { index: 0, name: this.$t("components.userSettings.view") },
        { index: 1, name: this.$t("components.userSettings.2fa") },
        { index: 2, name: this.$t("components.holidays.setYourHolidays") },
        { index: 3, name: this.$t("components.drawerContentCard.protocol") },
        { index: 4, name: this.$t("components.userSettings.tone") },
        { index: 5, name: this.$t("components.userSettings.startView") },
        { index: 7, name: this.$t("generics.language") },
        {
          index: 8,
          name: this.$t("components.userSettings.favoritesList"),
        },
        { index: 9, name: this.$t("components.userSettings.sortUsersList") },
        { index: 11, name: this.$t("components.userSettings.speechLive") },
        { index: 13, name: this.$t("generics.stats") },
        { index: 14, name: this.$t("components.userListItem.sendAccessLink") },
        {
          index: 15,
          name: this.$t("components.waitingRoom.waitingroomAssistants"),
        },
        { index: 16, name: this.$t("components.userSettings.screenSaver") },
        { index: 17, name: this.$t("components.adminSettingsModal.beacon") },
        {
          index: 18,
          name: this.$t("components.userSettings.calendarSettings"),
        },
        {
          index: 19,
          name: this.$t("components.userSettings.myUserAddInfo")
        }
      ].sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    canShowSpeechliveByDomain() {
      return canShowSpeechliveByDomain();
    },
  },
};
</script>
<style lang="scss">
.outlookSave {
  cursor: pointer;
  font-size: 42px !important;
  padding-left: 10px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  position: relative;
}
.additionalInfo .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
#select_screenSaver{
  .v-menu__content{
    margin-top: 46px;
    margin-left: 24px;
  }
}
#combobox_waitingRoomAssistants{
  .v-menu__content{
    margin-top: 8px;
    margin-left: 24px;
  }
}
</style>
<style scoped lang="scss">
.date-picker-wrapper {
  display: flex;
  align-items: center;
}
.pencil-icon {
  cursor: pointer;
  margin-left: 8px;
}
.dateTeamStartBasket{
  cursor: pointer;
  margin-top: 3px;
}
.h78vh{
  height: 78vh;
}
.videoButtonTT {
  max-width: 250px;
  background: rgb(97, 97, 97);
  opacity: 1 !important;
}
.rowSettingsMobile {
  // max-height: calc(100% - 133px);
  max-height: 100%;
  overflow: auto;
}
.rowSettings {
  max-height: 500px;
  overflow: auto;
}
.saveButton {
  float: right;
  margin-right: 40px;
}
@media (max-width: 800px) {
  .rowSettings {
    max-height: 350px!important;
  }
}
.mw-445 {
  min-width: 445px;
}
</style>
